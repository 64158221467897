import type { AxiosError, AxiosInstance } from 'axios';
import type { ServerCodeVersionResponseData } from '@/types/version';
import type { ServerHeaderResponseData } from '@/types/header';
import { handleAxiosError, errorHandler } from '@/api/utils';
const API_NAME = 'Local - shop';

export function getServerCodeVersion(apiInstance: AxiosInstance): Promise<ServerCodeVersionResponseData> {
  return errorHandler<ServerCodeVersionResponseData>(async () => ({
    data: (await apiInstance.get(`/version.json?t=${Date.now()}`)).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getServerCodeVersion',
    }),
  }));
}

export function getHeaders(apiInstance: AxiosInstance): Promise<ServerHeaderResponseData> {
  return errorHandler<ServerHeaderResponseData>(async () => ({
    data: (await apiInstance.post(`/api/headers?t=${Date.now()}`)).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getHeaders',
    }),
  }));
}
