import type { RecommendationResponseData } from '@/types/recommendation';

export function recommendationModel(responseData: RecommendationResponseData | null) {
  const response: RecommendationResponseData = responseData ?? {};

  // Wrap single items in array for consistency
  if (response.data?.recommendations.domain && !Array.isArray(response.data.recommendations.domain)) {
    response.data.recommendations.domain = [response.data.recommendations.domain];
  }
  response.data?.recommendations.domain.forEach((domain, index) => {
    if (domain.items?.item && !Array.isArray(domain.items.item)) {
      response.data!.recommendations.domain[index].items!.item = [domain.items.item];
    }
  });

  return {
    hasError(): boolean {
      return response.error !== undefined;
    },

    isInitialized(): boolean {
      return responseData !== null;
    },

    getProductIdsByDomain(): string[][] {
      return (
        response.data?.recommendations?.domain.reduce(
          (ids: string[][], domain) => [...ids, (domain.items?.item ?? []).map((item) => item['@id'])],
          [],
        ) ?? []
      );
    },

    getProductIds(): string[] {
      return this.getProductIdsByDomain().reduce((ids, idsByDomain) => [...ids, ...idsByDomain], []);
    },

    getRecommendationIdsByDomain(): string[] {
      return response.data?.recommendations?.domain.map((domain) => domain.recommendationId.$) ?? [];
    },

    getDebugData() {
      return response;
    },
  };
}
