import type { AxiosInstance } from 'axios';
import { getAuthenticationToken, getCustomer } from './apiAuth';
import { registerBackInStockNotification } from './apiBackInStock';
import { getCheckoutToken } from './apiCheckout';
import { createNpsScore } from './apiNpsScore';
import { getCustomerOrders, getCustomerOrder, getCheckoutOrder } from './apiOrders';
import { getStores, getOpticalStores } from './apiStoreFinder';
import { createCustomerFeedback } from './apiDeletedCustomerFeedback';
import { createSupportEmail } from './apiSupportEmail';
import {
  getReservations,
  getReservationById,
  createReservation,
  requestReservation,
  deleteReservation,
} from './apiReservation';
import { getStockData } from './apiGetStock';
import { createNewsletterUnsubscription } from './apiNewsletterUnsubscriptions';
import type { CreateReservationData, ReservationStatus } from '@/types/reservation';
import type { StoreSearchParams, OpticalStoreSearchParams } from '@/types/store';
import type { NpsScoreRequestData } from '@/types/nps';
import type { CheckoutCustomData } from '@/types/checkout';
import type { CustomerFeedbackParams, CustomerSupportEmailParams } from '@/types/customer';
import type { BackInStockNotificationParameters, StockResponseParams } from '@/types/product';

export default function initApiAws(apiInstance: AxiosInstance) {
  return {
    getAuthenticationToken: (locale: string, callbackUrl: string) =>
      getAuthenticationToken(apiInstance, locale, callbackUrl),
    getCustomer: (accessToken: string, shopId: number, basketKey?: string, wishlistKey?: string) =>
      getCustomer(apiInstance, accessToken, shopId, basketKey, wishlistKey),
    getStockData: (params: StockResponseParams) => getStockData(apiInstance, params),
    registerBackInStockNotification: (payload: BackInStockNotificationParameters) =>
      registerBackInStockNotification(apiInstance, payload),
    getCheckoutToken: (
      basketId: string,
      accessToken: string,
      shopId: string,
      locale: string,
      options: CheckoutCustomData,
    ) => getCheckoutToken(apiInstance, basketId, accessToken, shopId, locale, options),
    createNpsScore: (npsScore: NpsScoreRequestData) => createNpsScore(apiInstance, npsScore),
    getCustomerOrder: (authToken: string, orderId: string, customerId: number | undefined) =>
      getCustomerOrder(apiInstance, authToken, orderId, customerId),
    getCustomerOrders: (authToken: string, params?: any) => getCustomerOrders(apiInstance, authToken, params),
    getCheckoutOrder: (customerId: number, hash: string, orderId?: number) =>
      getCheckoutOrder(apiInstance, customerId, hash, orderId),
    getStores: (params: StoreSearchParams) => getStores(apiInstance, params),
    getOpticalStores: (params: OpticalStoreSearchParams) => getOpticalStores(apiInstance, params),
    createCustomerFeedback: (params: CustomerFeedbackParams) => createCustomerFeedback(apiInstance, params),
    createSupportEmail: (params: CustomerSupportEmailParams) => createSupportEmail(apiInstance, params),
    getReservations: (authToken: string) => getReservations(apiInstance, authToken),
    getReservationById: (authToken: string, reservationId: string) =>
      getReservationById(apiInstance, authToken, reservationId),
    createReservation: (authToken: string, reservationData: CreateReservationData) =>
      createReservation(apiInstance, authToken, reservationData),
    requestReservation: (authToken: string, reservationStatus: ReservationStatus, reservationId: string) =>
      requestReservation(apiInstance, authToken, reservationStatus, reservationId),
    deleteReservation: (authToken: string, reservationId: string) =>
      deleteReservation(apiInstance, authToken, reservationId),
    createNewsletterUnsubscription: (reason: string, customAnswer?: string, sessionId?: string) =>
      createNewsletterUnsubscription(apiInstance, reason, customAnswer, sessionId),
  };
}

export type ApiAws = ReturnType<typeof initApiAws>;
