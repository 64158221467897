import type { CartLineItemResponseData } from '@/types/cart';
import type { ProductModel, VariantModel, Price, GiftCard, GiftCardDeliveryMethod } from '@/types/product';
import { productModel, variantModel } from '@/models';
import { getPrice } from '@/models/utils/priceUtils';

export function cartLineItemModel(response: CartLineItemResponseData) {
  return {
    getProduct(): ProductModel {
      return productModel({ data: response.product });
    },

    getVariant(): VariantModel {
      return variantModel({
        data: {
          ...response.variant,
          customData: response.customData,
        },
      });
    },

    getQuantity(): number {
      return response.quantity ?? 0;
    },

    getKey(): string {
      return response.key;
    },

    getUnitPrice(): Price {
      return getPrice(response.price.unit);
    },

    getTotalPrice(): Price {
      return getPrice(response.price.total);
    },

    getAvailableQuantity(): number {
      return response.availableQuantity ?? 0;
    },

    getCustomData(): { [key: string]: any } {
      return response?.customData || {};
    },

    isGiveaway(): boolean {
      return response?.customData?.isGiveaway === true;
    },

    isGiftCard(): boolean {
      return response?.customData?.isGiftCard === true;
    },

    getLastAddedTimestamp(): number {
      return response?.customData?.lastAddedTimestamp || 0;
    },

    getGiftMessage(): GiftCard {
      const message: GiftCard | undefined = response?.customData?.giftCard;
      return {
        from: decodeURIComponent(message?.from ?? ''),
        to: decodeURIComponent(message?.to ?? ''),
        message: decodeURIComponent(message?.message ?? ''),
        deliveryMethod: decodeURIComponent(message?.deliveryMethod ?? 'postal') as GiftCardDeliveryMethod,
      };
    },

    getDebugData(): any {
      return response;
    },
  };
}
