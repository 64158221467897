<script setup lang="ts">
import { getHtmlFromRichText, getMedia, getElementUiObject } from '@mop/cms/utils/utils';
import type { CmsUiTimelineItem } from './uiCmsTimeline';

defineOptions({
  name: 'UiCmsTimelineItem',
});

const props = defineProps({
  data: {
    type: Object as PropType<CmsUiTimelineItem>,
    required: true,
  },
});

const isVisibleRef = ref(false);
const visibilityConfig = {
  callback: (isVisible: boolean) => {
    if (isVisible) {
      isVisibleRef.value = true;
    }
  },
  once: true,
  delay: 500,
  rootMargin: '0px 0px 0px 0px',
};

const element: CmsUiTimelineItem = getElementUiObject(props.data, {
  year: props.data.year,
  title: props.data.title,
  image: getMedia(props.data),
  body: getHtmlFromRichText(props.data.body),
});
</script>

<template>
  <div
    v-storyblok-editable="data"
    v-observe-visibility="visibilityConfig"
    :class="[
      'ui-cms-timeline-item',
      {
        'ui-cms-timeline-item--shown': isVisibleRef,
      },
    ]"
  >
    <div class="ui-cms-timeline-item__img">
      <UiCmsMedia :data="element.image" lazy />
    </div>
    <div class="ui-cms-timeline-item__text">
      <div class="ui-cms-timeline-item__title">{{ element.year }} | {{ element.title }}</div>
      <div class="ui-cms-timeline-item__desc" v-html="element.body" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
$transform: calc(100% + #{$global-padding});

.ui-cms-timeline-item {
  display: grid;
  grid-template-areas: 'image text';
  grid-template-columns: 200px 1fr;
  column-gap: $space50;
  position: relative;

  @include apply-upto(small) {
    grid-template-areas:
      'text'
      'image';
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    column-gap: 0;
    row-gap: $space20;
    padding-left: $space30;
  }

  &::before {
    position: absolute;
    content: '';
    width: 9px;
    height: 10px;
    background: $black;
    left: 224px;
    top: 0;

    @include apply-upto(small) {
      left: 1px;
    }
  }
}

.ui-cms-timeline-item__img {
  grid-area: image;
  transform: translateX(calc(-100% - #{$global-padding}));
}

.ui-cms-timeline-item__text {
  grid-area: text;
}

.ui-cms-timeline-item__title {
  @include text-style(weighted-bold);

  text-transform: uppercase;
  line-height: 16px;
  transform: translateX($transform);
}

.ui-cms-timeline-item__desc {
  margin-top: $space10;
  transform: translateX($transform);

  :deep(p) {
    @include text-style(common);

    min-height: unset;
    margin: 0;
  }
}

.ui-cms-timeline-item__desc,
.ui-cms-timeline-item__title,
.ui-cms-timeline-item__img {
  transition: transform 1s ease;

  @include apply-upto(small) {
    transform: translateX($transform);
  }
}

.ui-cms-timeline-item--shown {
  .ui-cms-timeline-item__img,
  .ui-cms-timeline-item__desc {
    transform: translateX(0);
    transition-delay: 1s;
  }

  .ui-cms-timeline-item__title {
    transform: translateX(0);
  }
}
</style>
