import type { AxiosInstance } from 'axios';

import {
  registerNewsletter,
  activateNewsletter,
  getNewsletterPreferences,
  setNewsletterPreferences,
} from './apiNewsletter';

import { deleteCustomer, validatePrecard, getBonusVoucherSteps, customerExists } from './apiCustomer';

import { createAddress } from './apiAddress';

import type { CreateAddressDataPayload } from '@/types/customer';
import type {
  NewsletterRegistrationRequestData,
  NewsletterActivationRequestData,
  NewsletterPreferenceRequestKeys,
  NewsletterPreferenceSettings,
} from '@/types/newsletter';

export default function initApiCrmMiddleware(apiInstance: AxiosInstance) {
  return {
    registerNewsletter: (params: NewsletterRegistrationRequestData) => registerNewsletter(apiInstance, params),
    activateNewsletter: (params: NewsletterActivationRequestData) => activateNewsletter(apiInstance, params),
    getNewsletterPreferences: (pathKeys: NewsletterPreferenceRequestKeys) =>
      getNewsletterPreferences(apiInstance, pathKeys),
    setNewsletterPreferences: (pathKeys: NewsletterPreferenceRequestKeys, params: NewsletterPreferenceSettings) =>
      setNewsletterPreferences(apiInstance, pathKeys, params),
    deleteCustomer: (authToken: string, customerEmail: string) => deleteCustomer(apiInstance, authToken, customerEmail),
    customerExists: (authToken: string, customerEmail: string) => customerExists(apiInstance, authToken, customerEmail),
    validatePrecard: (authToken: string, precardNumber: string) =>
      validatePrecard(apiInstance, authToken, precardNumber),
    getBonusVoucherSteps: (authToken: string) => getBonusVoucherSteps(apiInstance, authToken),
    createAddress: (authToken: string, payload: CreateAddressDataPayload) =>
      createAddress(apiInstance, authToken, payload),
  };
}

export type ApiCrmMiddleware = ReturnType<typeof initApiCrmMiddleware>;
