import type { AxiosError, AxiosInstance } from 'axios';
import { handleAxiosError, errorHandler } from '@/api/utils';
import type { NpsScoreRequestData, NpsScoreResponseData } from '@/types/nps';
const API_NAME = 'AWS - nps score';

export function createNpsScore(
  apiInstance: AxiosInstance,
  npsScore: NpsScoreRequestData,
): Promise<NpsScoreResponseData> {
  return errorHandler<NpsScoreResponseData>(async () => ({
    data: await apiInstance.post('/npsscore', npsScore),
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'createNpsScore',
      payload: npsScore,
    }),
  }));
}
