<script setup lang="ts">
import type { CmsUiTripleTeaserSmall } from './uiCmsTripleTeaser';

defineOptions({
  name: 'UiCmsTripleTeaserItemSmall',
});

const props = defineProps({
  data: {
    type: Object as PropType<CmsUiTripleTeaserSmall>,
    required: true,
  },
  lazyLoad: {
    type: Boolean,
    default: false,
  },
});

const element = props.data;

if (element.media) {
  element.media.imageType = 'cms-triple-teaser-small';
}
</script>

<template>
  <UiNuxtLink v-bind="element.link" class="ui-cms-triple-teaser-item-small">
    <UiCmsMedia
      v-if="element.media"
      class="ui-cms-triple-teaser-item-small__image"
      :data="element.media"
      :lazy="lazyLoad"
    />
    <div v-storyblok-editable="element" class="ui-cms-triple-teaser-item-small__textbox">
      <div v-if="element.headline" class="ui-cms-triple-teaser-item-small__textbox-headline">
        {{ element.headline }}
      </div>
      <div v-if="element.text" class="ui-cms-triple-teaser-item-small__textbox-text">
        {{ element.text }}
      </div>
      <div v-if="element.cta" class="ui-cms-triple-teaser-item-small__texbox-cta">
        <UiCmsCta class="ui-cms-triple-teaser-item-small__textbox-cta" :data="element.cta" no-padding />
      </div>
    </div>
  </UiNuxtLink>
</template>

<style lang="scss" scoped>
.ui-cms-triple-teaser-item-small {
  @include apply-only(medium) {
    width: columns(36);
  }
}

.ui-cms-triple-teaser-item-small__textbox {
  @include apply-only(medium) {
    padding: 0 columns(1, 18);
  }

  @include apply-upto(small) {
    padding: columns-in-vw(1);
  }
}

.ui-cms-triple-teaser-item-small__textbox-headline {
  @include text-style(headline-2);

  margin-top: $space20;

  @include apply-from(xs) {
    margin-bottom: $space5;
  }
}

.ui-cms-triple-teaser-item-small__textbox-text {
  @include text-style(running);
}

.ui-cms-triple-teaser-item-small__textbox-cta {
  justify-content: flex-end;
}
</style>
