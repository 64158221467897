<script setup lang="ts">
import { getCta, getElementUiObject } from '@mop/cms/utils/utils';
import type { CmsUiAnchor } from './uiCmsAnchor';

defineOptions({
  name: 'UiCmsAnchor',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const data = props.data;

function handleClick(hash: string) {
  if (document.location.hash === `#${hash}`) {
    document.location.hash = '';
  }
}

const element: CmsUiAnchor = getElementUiObject(data, {
  headline: data.headline,
  subline: data.subline,
  text: data.text,
  cta: getCta(data.cta?.[0]),
  anchorLinksHeadline: data.anchorLinksHeadline,
  anchorLinks: data.anchorLinks?.map((link: any) => {
    return getElementUiObject(link, {
      customContentModuleId: link.customContentModuleId ?? '',
      name: link.name || '',
    });
  }),
  uiStyleLeft: {
    '--color': data.textColorLeft?.color,
    backgroundColor: data.backgroundColorLeft?.color,
  },
  uiStyleRight: {
    '--color': data.textColorRight.color,
    backgroundColor: data.backgroundColorRight.color,
    borderColor: data.textColorRight.color,
  },
});

const trackingId = element.headline || element.subline || '';
</script>

<template>
  <div v-storyblok-editable="element" class="ui-cms-anchor" :data-track-element-item="`${trackingId}`">
    <div
      v-if="element.headline || element.subline || element.text"
      class="ui-cms-anchor__left"
      :style="element.uiStyleLeft"
    >
      <div class="ui-cms-anchor-text-content">
        <div v-if="element.subline" class="ui-cms-anchor-text-content__subline">
          {{ element.subline }}
        </div>
        <div v-if="element.headline" class="ui-cms-anchor-text-content__headline">
          {{ element.headline }}
        </div>
        <div v-if="element.text" class="ui-cms-anchor-text-content__text">
          {{ element.text }}
        </div>
        <UiCmsCta v-if="element.cta" :data="element.cta" no-padding />
      </div>
    </div>
    <div class="ui-cms-anchor__right" :style="element.uiStyleRight">
      <div v-if="element.anchorLinksHeadline" class="ui-cms-anchor__list-headline">
        {{ element.anchorLinksHeadline }}
      </div>
      <div class="ui-cms-anchor__list">
        <NuxtLink
          v-for="(link, index) in element.anchorLinks"
          :key="link.customContentModuleId + index"
          v-storyblok-editable="link"
          class="ui-cms-anchor__link"
          :to="{ hash: `#${link.customContentModuleId}` }"
          no-prefetch
        >
          <div @click="() => handleClick(link.customContentModuleId)">
            {{ link.name || '&nbsp;' }}
          </div>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ui-cms-anchor {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  width: 100%;
}

.ui-cms-anchor__left,
.ui-cms-anchor__right {
  display: flex;
  padding: $space40;
  box-sizing: border-box;

  @include apply-upto(medium) {
    padding: $space30 columns(1) $space5 columns(1);
  }

  @include apply-upto(small) {
    padding: $space20 $global-padding;
    width: 100%;
  }
}

.ui-cms-anchor__left {
  width: 50%;
  align-items: flex-start;

  @include apply-upto(small) {
    width: 100%;
  }
}

.ui-cms-anchor__right {
  width: 50%;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;

  @include apply-upto(small) {
    width: 100%;
  }
}

.ui-cms-anchor__list {
  display: flex;
  flex-flow: row wrap;
}

.ui-cms-anchor__link {
  @include text-style(default);

  position: relative;
  width: 100%;
  padding-left: $space30;
  counter-increment: item;

  &:not(:first-of-type) {
    margin-top: $space5;
  }

  @include hover {
    &::before {
      border-bottom: 2px solid;
      border-color: inherit;
    }
  }

  &:not(:last-child) {
    margin-bottom: $space10;
  }

  &::before {
    @include text-style(strong-small);

    position: absolute;
    top: 0;
    left: 0;
    margin-right: $space10;
    line-height: 26px;
    content: counter(item, decimal-leading-zero);
  }
}

.ui-cms-anchor-text-content {
  width: 100%;
}

.ui-cms-anchor-text-content__subline {
  @include text-style(common);
}

.ui-cms-anchor__list-headline,
.ui-cms-anchor-text-content__headline {
  @include font-smoothing(strong);
  @include text-style(headline);

  @include apply-from(large) {
    margin-bottom: $space40;
  }

  text-transform: uppercase;
}

.ui-cms-anchor-text-content__text {
  @include text-style(default);
}
</style>
