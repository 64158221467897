import type { Directive, DirectiveBinding } from 'vue';
import { logWarning } from '@mop/shared/utils/logger';

type HtmlElementStoryblok = HTMLElement & { _editable: string };

export const storyblokDirective: Directive = {
  beforeMount(el: HtmlElementStoryblok, { value }: DirectiveBinding) {
    if (!value || !value._editable || useNuxtApp().$storyblokLivePreview.isEnabledInIframe !== true) {
      return;
    }

    try {
      const options: any = JSON.parse(value._editable.replace('<!--#storyblok#', '').replace('-->', ''));
      el.setAttribute('data-blok-c', JSON.stringify(options));
      el.setAttribute('data-blok-uid', options.id + '-' + options.uid);
      el.classList.add('storyblok__outline');
    } catch (error) {
      logWarning(`Storyblok editable data is broken: ${JSON.stringify(value._editable)}`);
    }
  },
};
