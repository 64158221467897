import { isClient } from '@mop/shared/utils/util';
import type { RedisOptions } from 'ioredis';

// @ts-ignore
let redisClient: Redis | undefined;

export async function getRedisClient() {
  if (isClient) {
    return;
  }
  if (!process.env.REDIS_ENABLED) {
    return;
  }
  if (redisClient) {
    return redisClient;
  }
  const { Redis } = await import(/* @vite-ignore */ `ioredis`);
  const opts: RedisOptions = {
    lazyConnect: true,
    port: parseInt(process.env.REDIS_PORT as string),
    host: process.env.REDIS_HOST,
    password: process.env.REDIS_PASS,
    db: parseInt(process.env.REDIS_DB as string), // Defaults to 0
    tls: {
      servername: process.env.REDIS_HOST,
      minVersion: 'TLSv1.2',
    },
  };
  redisClient = new Redis(opts);

  return redisClient;
}
