import type { AxiosError, AxiosInstance } from 'axios';
import { handleAxiosError, errorHandler } from '@/api/utils';
import type { NewsletterUnsubscriptionResponseData } from '@/types/newsletter';
const API_NAME = 'AWS - newsletter unsubscriptions';

export function createNewsletterUnsubscription(
  apiInstance: AxiosInstance,
  reason: string,
  customAnswer?: string,
  sessionId?: string,
): Promise<NewsletterUnsubscriptionResponseData> {
  const payload = {
    reason,
    customAnswer,
    sessionId,
  };
  return errorHandler<NewsletterUnsubscriptionResponseData>(async () => ({
    data: await apiInstance.post('/newsletter-unsubscriptions', payload),
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'newsletterUnsubscription',
      payload,
    }),
  }));
}
