import { logWarning } from '@mop/shared/utils/logger';
import type {
  CategoryResponseData,
  CategoryListResponseData,
  CategoryModel,
  CategoryData,
  CategoryDataProperties,
  CategoryDataPropertiesAlternate,
  SortingType,
} from '@/types/category';
import type { Alternate } from '@/types/cms';
import type { CountryConfigModel } from '@/types/countryConfig';
import { globalEShopLocale } from '@/i18n/localeList';

function getCategoryModelList(data: CategoryData[] | undefined, showHidden = false): CategoryModel[] {
  if (data === undefined) {
    return [];
  }

  return data.reduce((list: CategoryModel[], categoryData) => {
    const category: CategoryModel = categoryModel({ data: categoryData });
    if (showHidden || category.isVisible()) {
      list.push(category);
    }
    return list;
  }, []);
}

export function categoryModel(responseData: CategoryResponseData | null): CategoryModel {
  const response: CategoryResponseData = responseData ?? {};
  let mobileMenuCategories: CategoryModel[];

  const localCache: {
    alternates?: Alternate[];
  } = {};

  return {
    hasError(): boolean {
      return response.error !== undefined;
    },

    hasErrorNotFound(): boolean {
      return response.error?.code === constants.ERROR_CODE.NOT_FOUND;
    },

    isInitialized(): boolean {
      return responseData !== null;
    },

    getId(): number {
      return parseInt(this.getDataSplit()[0]) || 0;
    },

    getSorting(sortingType: SortingType = 'default'): string {
      return `${this.getId()}-${sortingType}`;
    },

    getName(mopConfig: CountryConfigModel): string {
      const customName = mopConfig?.getCategoryPreference(this.getMopId())?.customName;
      return customName || (response.data?.customName?.trim() ?? this.getDataSplit()[2] ?? '');
    },

    getPath(): string {
      return this.getDataSplit()[1] ?? '';
    },

    isSaleCategory(): boolean {
      return this.getPath().includes(`/${constants.CATEGORY_SLUG.SALE}`);
    },

    getDataSplit(): any {
      return response.data?.data?.split('|') ?? [];
    },

    getDepth(): number {
      return parseInt(this.getDataSplit()[3]) || 0;
    },

    isVisible(): boolean {
      return response.data?.hidden !== 1;
    },

    getSlug(): string {
      return this.getPath().substr(this.getPath().lastIndexOf('/') + 1);
    },

    getUrl(): string {
      const redirect: string = this.getRedirect();
      if (redirect) {
        return redirect.indexOf('/') === 0 ? redirect : `/${redirect}`;
      }
      return `${this.getPath()}`;
    },

    getChildren(showHidden = false): CategoryModel[] {
      return getCategoryModelList(response.data?.children, showHidden);
    },

    hasChildren(): boolean {
      if (!response.data?.children?.length) {
        return false;
      }
      return response.data.children.some((child) => !child.hidden);
    },

    getPropertyValue(propertyName: keyof CategoryDataProperties): unknown | undefined {
      return response.data?.[propertyName];
    },

    showInMobileMenu(): boolean {
      return response.data?.showInMobileMenu !== 0;
    },

    getMopId(): string {
      return this.getDataSplit()[4] ?? '';
    },

    showProductCount(): boolean {
      return isProductionBuild ? Boolean(this.getProductCount()) : true;
    },

    includeNameInSearchSuggest(): boolean {
      return response.data?.includeNameInSearchSuggest === 1;
    },

    hasMobileMenuCategories(): boolean {
      return this.getMobileMenuCategories().length > 0;
    },

    isLandingPage(): boolean {
      return response.data?.isLandingPage === 1;
    },

    getGlobalEBlacklist(): string[] {
      return response.data?.globalEBlacklist || [];
    },

    getMobileMenuCategories(): CategoryModel[] {
      if (mobileMenuCategories !== undefined) {
        return mobileMenuCategories;
      }
      if (response.data?.children === undefined) {
        mobileMenuCategories = [];
        return mobileMenuCategories;
      }

      mobileMenuCategories = response.data.children.reduce((list: CategoryModel[], categoryData: CategoryData) => {
        const category: CategoryModel = categoryModel({ data: categoryData });
        if (category.isVisible() && category.showInMobileMenu()) {
          list.push(category);
        }
        return list;
      }, []);
      return mobileMenuCategories;
    },

    // Only available in categories from useCategoryTree
    getProductCount(): number {
      return parseInt(this.getDataSplit()[5]) || 0;
    },

    isEmpty(): boolean {
      return Boolean(parseInt(this.getDataSplit()[6] ?? '0'));
    },

    getAlternates(paramSlug = ''): Alternate[] {
      if (localCache.alternates) {
        return localCache.alternates;
      }
      const slugString: string = response.data?.alternate ?? '';
      if (!slugString) {
        return [];
      }

      let slugs: CategoryDataPropertiesAlternate[];
      try {
        slugs = JSON.parse(slugString);
      } catch (error) {
        logWarning(`Category alternates "${slugString}" on category ${this.getId()} is not a valid JSON.`);
        return [];
      }

      if (!slugs || slugs.length === 0) {
        return [];
      }

      const defaultGlobalEAlternate: CategoryDataPropertiesAlternate | undefined = slugs.find(
        (slug) => slug.locale === globalEShopLocale,
      );
      // appending forced entries to slugs
      if (defaultGlobalEAlternate) {
        constants.FORCED_ALTERNATE_SLUGS.forEach((forceEntry) => {
          slugs.push({
            url: defaultGlobalEAlternate.url,
            locale: forceEntry.toLowerCase(),
          });
        });
      }

      localCache.alternates = slugs.reduce((list: Alternate[], slug: CategoryDataPropertiesAlternate) => {
        const lang: string = slug.locale?.toLocaleLowerCase() || '';
        const langSplit: string[] = lang?.split('-') || [];
        if (!lang || langSplit.length !== 2) {
          return list;
        }
        list.push({
          href: `${slug.url.substr(6)}${paramSlug}`,
          lang,
        });
        return list;
      }, []);

      return localCache.alternates;
    },

    getRedirect(): string {
      return response.data?.redirect?.trim() ?? '';
    },

    getTextColor(): string {
      if (!response.data?.textColor) {
        return '';
      }

      const hexColor: string = response.data.textColor;
      const hexColorRegex = /^#([0-9A-F]{3}){1,2}$/i;
      return hexColorRegex.test(hexColor) ? hexColor : '';
    },

    getDebugData(): CategoryData | undefined {
      return response.data;
    },

    isHomeCategory(): boolean {
      return ['men', 'women'].includes(this.getMopId());
    },
  };
}

export function categoryListModel(responseData: CategoryListResponseData | null) {
  const response: CategoryListResponseData = responseData ?? {};

  return {
    hasError(): boolean {
      return response.error !== undefined;
    },

    getCategoryModelList(showHidden = false): CategoryModel[] {
      return getCategoryModelList(response.data, showHidden);
    },
  };
}
