import type { AxiosError } from 'axios';
import type { BapiClient } from '@aboutyou/backbone';
import { handleAxiosError, errorHandler } from '@/api/utils';
import type { GetFiltersParams, FilterListResponseData } from '@/types/filters';
const API_NAME = 'AYC - filters';

export function getFilters(apiInstance: BapiClient, params: GetFiltersParams): Promise<FilterListResponseData> {
  // if not added then it excludes non sale products
  params.campaignKey = 'px';

  return errorHandler<FilterListResponseData>(async () => ({
    data: await apiInstance.filters.get(params),
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getFilters',
      params,
    }),
  }));
}
