/**
 * LocalStorage with fallback to memory storage in case not supported.
 * Some browsers in private mode is not allowing to read nor store to localstorage
 */
import { isClient } from '@mop/shared/utils/util';
import { logError } from '@mop/shared/utils/logger';

let inMemoryStorage: { [key: string]: string } = {};
let isStorageSupported: boolean;

function isSupported() {
  if (isStorageSupported !== undefined) {
    return isStorageSupported;
  }
  try {
    const key = '__storage_validation__';
    window.localStorage.setItem(key, key);
    window.localStorage.removeItem(key);
    isStorageSupported = true;
  } catch (error) {
    isStorageSupported = false;
  }
  return isStorageSupported;
}

export function localStorageGet(name: string): string | null {
  if (!isClient) {
    return null;
  }

  if (isSupported()) {
    try {
      return window.localStorage.getItem(name);
    } catch (err: unknown) {
      logError(err);
    }
  }
  return inMemoryStorage[name] ?? null;
}

export function localStorageSet(name: string, value: string) {
  if (!isClient) {
    return;
  }
  if (isSupported()) {
    try {
      window.localStorage.setItem(name, value);
    } catch (err: unknown) {
      inMemoryStorage[name] = String(value);
      logError(err);
    }
  } else {
    inMemoryStorage[name] = String(value);
  }
}

export function localStorageRemove(name: string) {
  if (!isClient) {
    return;
  }
  if (isSupported()) {
    try {
      window.localStorage.removeItem(name);
    } catch (err: unknown) {
      delete inMemoryStorage[name];
      logError(err);
    }
  } else {
    delete inMemoryStorage[name];
  }
}

export function localStorageClear() {
  if (!isClient) {
    return;
  }
  if (isSupported()) {
    window.localStorage.clear();
  } else {
    inMemoryStorage = {};
  }
}
