import { logError } from '@mop/shared/utils/logger';
import { getRedisClient } from '@/redis/redis';

function processCallback<T>(callback?: Function): Promise<T | null> {
  return new Promise((resolve) => {
    if (!callback) {
      return resolve(null);
    }
    return resolve(callback());
  });
}

function getTimeZoneOffsetInSeconds(date: Date) {
  const options: Intl.DateTimeFormatOptions = {
    timeZone: 'Europe/Berlin',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };
  const germanTime: number = new Date(new Intl.DateTimeFormat([], options).format(date)).getTime();
  options.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const serverTime: number = new Date(new Intl.DateTimeFormat([], options).format(date)).getTime();
  return Math.floor(Math.abs(germanTime - serverTime) / 1000);
}

export function getExpirationInSeconds(expirationInSeconds?: number) {
  if (expirationInSeconds !== undefined) {
    return expirationInSeconds;
  }
  const date: Date = new Date();
  const timeZoneOffsetInSeconds: number = getTimeZoneOffsetInSeconds(date);
  const daySeconds: number = 24 * 60 * 60;
  return (
    (Math.floor((date.setHours(23, 59, 59, 999) - Date.now()) / 1000) - timeZoneOffsetInSeconds + daySeconds) %
    daySeconds
  );
}

async function asyncGet<T>(key: string, callback: Function, expirationInSeconds?: number): Promise<T | null> {
  const expiration = getExpirationInSeconds(expirationInSeconds);
  if (
    !expiration ||
    typeof process === 'undefined' ||
    process?.env?.REDIS_ENABLED !== 'true' ||
    !process?.env?.REDIS_DB
  ) {
    return processCallback<T>(callback);
  }
  try {
    const redisClient = await getRedisClient();
    if (!redisClient) {
      return processCallback<T>(callback);
    }
    const data = await redisClient.get(key);
    if (data) {
      try {
        return JSON.parse(data) as T;
      } catch (error) {
        // in case value is somehow corrupted, clean it
        await redisClient.del(key);
      }
    }
    const callbackValue = await processCallback<T>(callback);
    if (callbackValue) {
      await redisClient.setex(key, expiration, JSON.stringify(callbackValue));
      return callbackValue;
    }
  } catch (err) {
    logError(err);
  }
  return null;
}

export function ssrCache() {
  return {
    get<T>(key: string, callback: Function, expirationInSeconds?: number): Promise<T | null> {
      return asyncGet<T>(key, callback, expirationInSeconds);
    },
  };
}
