<script setup lang="ts">
import { getElementUiObject } from '@mop/cms/utils/utils';
import type { CmsUiHeroCard, CmsPromoTile, CmsUiProductGridTextTile } from '@/types/cms';

defineOptions({
  name: 'MopCmsHeroCard',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const data: any = props.data;

let isTextBottom = false;
const heroTile: CmsPromoTile | undefined = getCmsPromoTile(data.heroItem?.[0]);
if (heroTile) {
  heroTile.layout = 'hero';
  const imageType = 'product-tile-hero';
  heroTile.imageType = imageType;
  if (heroTile.media) {
    heroTile.media.imageType = imageType;
  }
}
const textTile: CmsUiProductGridTextTile | undefined = getCmsUiPromoTextTile(data.textItem?.[0]);
if (textTile) {
  textTile.layout = 'double';
  textTile.imageType = 'product-tile-double';
  isTextBottom = textTile.alignment === 'bottom';
}
const tiles: CmsPromoTile[] = data.items?.map((item: any) => {
  const tile: CmsPromoTile = getCmsPromoTile(item) as CmsPromoTile;
  tile.layout = 'single';
  const imageType = 'product-tile';
  tile.imageType = imageType;
  if (tile.media) {
    tile.media.imageType = imageType;
  }
  return tile;
});

const element: CmsUiHeroCard = getElementUiObject(data, {
  isHeroItemLeft: data.isHeroItemLeft,
  isTextBottom,
  heroTile,
  textTile,
  tiles,
  position: parseInt(data.position || 0),
});
</script>

<template>
  <div
    v-if="element"
    v-storyblok-editable="element"
    :class="[
      'cms-hero-card',
      {
        'cms-hero-card--hero-left': element.isHeroItemLeft,
        'cms-hero-card--text-bottom': element.isTextBottom,
      },
    ]"
  >
    <UiGrid class="cms-hero-card__grid">
      <UiGridItem v-if="element.heroTile" layout="hero" type="product" class="cms-hero-card__grid-hero">
        <MopTile
          :data="element.heroTile"
          :image-type="element.heroTile.imageType"
          disable-image-hover-change
          track-position="hero"
        />
      </UiGridItem>

      <UiGridItem v-if="element.textTile" layout="double" type="text" class="cms-hero-card__grid-text">
        <MopTile :data="element.textTile as any" disable-image-hover-change track-position="text" />
      </UiGridItem>

      <div class="cms-hero-card__grid-items">
        <UiGridItem
          v-for="(tile, index) in element.tiles"
          :key="`${index}-${tile._uid}`"
          layout="single"
          type="product"
          :class="['cms-hero-card__grid-item', `cms-hero-card__grid-item${index + 1}`]"
        >
          <MopTile
            :data="tile"
            :image-type="tile.imageType"
            disable-image-hover-change
            :track-position="(index + 1).toString()"
          />
        </UiGridItem>
      </div>
    </UiGrid>
  </div>
</template>

<style lang="scss" scoped>
.cms-hero-card {
  padding-right: $global-padding;
  padding-left: $global-padding;
  margin-bottom: $space20;

  @include apply-upto(small) {
    padding: 0;
  }
}

.cms-hero-card__grid {
  grid-template-areas:
    'text hero'
    'items hero';
  grid-template-columns: 1fr 1fr;
  row-gap: $space-8;
  grid-auto-flow: column;

  @include apply-upto(small) {
    grid-template-areas:
      'text'
      'items'
      'hero';
    grid-template-columns: 1fr;
  }
}

.cms-hero-card--text-bottom {
  .cms-hero-card__grid {
    grid-template-areas:
      'items hero'
      'text hero';

    @include apply-upto(small) {
      grid-template-areas:
        'text'
        'items'
        'hero';
      grid-template-columns: 1fr;
    }
  }

  @include apply-from(medium) {
    :deep(.tile-text) {
      bottom: $space10;
    }
  }

  @include apply-from(large) {
    :deep(.tile-text) {
      bottom: $space80;
    }
  }
}

.cms-hero-card--hero-left {
  .cms-hero-card__grid {
    grid-template-areas:
      'hero text'
      'hero items';

    @include apply-upto(small) {
      grid-template-areas:
        'text'
        'hero'
        'items';
    }
  }
}

.cms-hero-card--text-bottom.cms-hero-card--hero-left {
  .cms-hero-card__grid {
    grid-template-areas:
      'hero items'
      'hero text';

    @include apply-upto(small) {
      grid-template-areas:
        'text'
        'hero'
        'items';
    }
  }
}

.cms-hero-card__grid-hero {
  grid-area: hero;
}

.cms-hero-card__grid-text {
  grid-area: text;

  :deep(.tile-text__headline) {
    @include apply-only(medium) {
      @include text-overflow(3);
    }

    @include apply-upto(medium) {
      font-size: 30px;
      line-height: 32px;
    }
  }
}

.cms-hero-card__grid-items {
  grid-area: items;
  align-self: end;
  display: flex;
  overflow: hidden;
}

.cms-hero-card__grid-item {
  flex-basis: 50%;
  width: 50%;
  padding-left: $space5;

  &:first-child {
    padding-left: 0;
    padding-right: $space5;
  }
}
</style>
