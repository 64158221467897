import { securedWrap } from '@mop/shared/utils/securedWrap';
import type { NuxtPage } from '@nuxt/schema';
import { customerModel, statusModel, addressModel } from '@/models';
import type {
  CreateAddressDataPayload,
  CustomerFeedbackParams,
  CustomerModel,
  CustomerModelRef,
  CustomerResponseData,
  UpdateCustomData,
  UpdateEmailPayload,
  UpdatePasswordPayload,
  UpdatePersonalDataPayload,
  CustomerSupportEmailParams,
  CustomerBonusVoucherStep,
} from '@/types/customer';
import type {
  AddressModelRef,
  UpdateAddressData,
  CreateAddressData,
  AddressResponseData,
  CreateAddressCrmParams,
} from '@/types/address';
import type { LocaleObject } from '@/types/locale';

type LoadingState = {
  handleAddresses: boolean;
  handleCustomer: boolean;
  updatePersonalData: boolean;
  updatePassword: boolean;
  updateCustomData: boolean;
  updateEmail: boolean;
  updateAddress: boolean;
  deleteCustomer: boolean;
  customerExists: boolean;
  createAddress: boolean;
  createSupportEmail: boolean;
  bonusVoucherSteps: boolean;
  loading: boolean;
};

type CustomerComposableStorage = {
  customer: CustomerModelRef;
  address: AddressModelRef;
};

export function useMopCustomer() {
  const storage = initStorage<CustomerComposableStorage>('useCustomer');
  const customerModelRef = storage.get('customer') || storage.saveAndGet('customer', ref(customerModel(null)));
  const addressModelRef = storage.get('address') || storage.saveAndGet('address', ref(addressModel(null)));
  const statusModelRef = ref(statusModel(null));
  const bonusVoucherStepsRef = ref<CustomerBonusVoucherStep[]>([]);
  const loadingRef: Ref<LoadingState> = ref({
    handleCustomer: false,
    handleAddresses: false,
    updatePersonalData: false,
    updatePassword: false,
    updateCustomData: false,
    updateEmail: false,
    updateAddress: false,
    deleteCustomer: false,
    customerExists: false,
    createAddress: false,
    createSupportEmail: false,
    bonusVoucherSteps: false,
    loading: computed(() => isLoading(loadingRef)),
  });
  const nuxtApp = useNuxtApp();
  const { $apiCrmMiddleware, $apiAws, $apiAyc, $mopI18n } = nuxtApp;

  async function handleCustomer(shopId: number, basketKey?: string, wishlistKey?: string): Promise<void> {
    loadingRef.value.handleCustomer = true;

    const [awsResult, aycResult] = await Promise.allSettled([
      $apiAws.getCustomer(getAccessToken(), shopId, basketKey, wishlistKey),
      $apiAyc.getCustomer(getAccessToken()),
    ]);

    if (awsResult && awsResult.status === 'fulfilled' && !awsResult.value.error) {
      customerModelRef.value = customerModel(awsResult.value);
    } else if (aycResult && aycResult.status === 'fulfilled') {
      customerModelRef.value = customerModel(aycResult.value);
    } else {
      customerModelRef.value = customerModel(null);
    }

    loadingRef.value.handleCustomer = false;
  }

  async function handleAddresses(shopId: number) {
    loadingRef.value.handleAddresses = true;
    const aycAddressResult = await $apiAyc.getAddresses(getAccessToken(), shopId, 1000);
    addressModelRef.value = addressModel(aycAddressResult, $mopI18n.country);
    loadingRef.value.handleAddresses = false;
  }

  async function updatePersonalData(payload: UpdatePersonalDataPayload): Promise<void> {
    loadingRef.value.updatePersonalData = true;
    const response: CustomerResponseData = await $apiAyc.updatePersonalData(getAccessToken(), payload);
    const customer: CustomerModel = customerModel(response);
    statusModelRef.value = statusModel(response);

    if (!customer.hasError()) {
      customerModelRef.value = customer;
    }

    loadingRef.value.updatePersonalData = false;
  }

  async function updatePassword(payload: UpdatePasswordPayload): Promise<void> {
    loadingRef.value.updatePassword = true;
    const response: CustomerResponseData = await $apiAyc.updatePassword(getAccessToken(), payload);
    const customer: CustomerModel = customerModel(response);
    statusModelRef.value = statusModel(response);

    if (!customer.hasError()) {
      customerModelRef.value = customer;
    }

    loadingRef.value.updatePassword = false;
  }

  async function updateEmail(payload: UpdateEmailPayload): Promise<void> {
    loadingRef.value.updateEmail = true;
    const response: CustomerResponseData = await $apiAyc.updateEmail(getAccessToken(), payload);
    const customer: CustomerModel = customerModel(response);
    statusModelRef.value = statusModel(response);

    if (!customer.hasError()) {
      customerModelRef.value = customer;
    }

    loadingRef.value.updateEmail = false;
  }

  async function updateCustomData(payload: UpdateCustomData): Promise<void> {
    loadingRef.value.updateCustomData = true;
    const response: CustomerResponseData = await $apiAyc.updateCustomData(getAccessToken(), payload);
    const customer: CustomerModel = customerModel(response);
    statusModelRef.value = statusModel(response);

    if (!customer.hasError()) {
      customerModelRef.value = customer;
    }

    loadingRef.value.updateCustomData = false;
  }

  async function updateAddress(addressId: number, shopId: number, payload: UpdateAddressData): Promise<void> {
    loadingRef.value.updateAddress = true;
    const response: AddressResponseData = await $apiAyc.updateAddress(getAccessToken(), addressId, shopId, payload);
    statusModelRef.value = statusModel(response);

    loadingRef.value.updateAddress = false;
  }

  async function createAddress(shopId: number, payload: CreateAddressData): Promise<void> {
    loadingRef.value.createAddress = true;
    const response: AddressResponseData = await $apiAyc.createAddress(getAccessToken(), shopId, payload);
    statusModelRef.value = statusModel(response);

    loadingRef.value.createAddress = false;
  }

  async function deleteCustomer(payload: CustomerFeedbackParams, customerEmail: string): Promise<void> {
    loadingRef.value.deleteCustomer = true;

    statusModelRef.value = statusModel(await $apiAws.createCustomerFeedback(payload));
    if (statusModelRef.value.hasError()) {
      loadingRef.value.deleteCustomer = false;
      return;
    }

    statusModelRef.value = statusModel(await $apiCrmMiddleware.deleteCustomer(getAccessToken(), customerEmail));
    loadingRef.value.deleteCustomer = false;
  }

  async function customerExists(customerEmail: string): Promise<void> {
    loadingRef.value.customerExists = true;
    statusModelRef.value = statusModel(await $apiCrmMiddleware.customerExists(getAccessToken(), customerEmail));
    loadingRef.value.customerExists = false;
  }

  async function createAddressCrm(params: CreateAddressCrmParams): Promise<void> {
    const { getAccessToken, customerModelRef } = useMopCustomer();
    const { shopId, alpha3, country, lang, localeList } = nuxtApp.$mopI18n;
    const customer: CustomerModel = customerModelRef.value;
    const customerCountry: string =
      localeList.find((item: LocaleObject) => item.code.endsWith(params.countryCode.toLocaleLowerCase()))?.alpha3 ||
      'DEU';
    const payload: CreateAddressDataPayload = {
      locale: {
        appId: shopId,
        countryCode: alpha3,
        languageCode: `${lang}-${country.toUpperCase()}`,
      },
      customer: {
        birthDate: params.birthdate,
        phone: params?.phone,
        precard: params?.precard,
        title: customer.getTitle(),
      },
      payload: {
        additional: params.address1,
        city: params.city,
        countryCode: customerCountry,
        houseNumber: params.houseNumber,
        street: params.street,
        zipCode: params.zip,
        recipient: {
          firstName: customer.getFirstName(),
          lastName: customer.getLastName(),
          gender: customer.getGender(),
          title: customer.getTitle(),
        },
      },
    };

    loadingRef.value.createAddress = true;
    statusModelRef.value = statusModel(await $apiCrmMiddleware.createAddress(getAccessToken(), payload));
    loadingRef.value.createAddress = false;
  }

  async function createSupportEmail(params: FormData) {
    loadingRef.value.createSupportEmail = true;
    statusModelRef.value = statusModel(await $apiAws.createSupportEmail(params as any as CustomerSupportEmailParams));
    loadingRef.value.createSupportEmail = false;
  }

  async function validatePrecard(precardNumber: string): Promise<void> {
    statusModelRef.value = statusModel(await $apiCrmMiddleware.validatePrecard(getAccessToken(), precardNumber));
  }

  async function getBonusVoucherSteps() {
    loadingRef.value.bonusVoucherSteps = true;
    bonusVoucherStepsRef.value = ((await $apiCrmMiddleware.getBonusVoucherSteps(getAccessToken()))?.data ||
      []) as CustomerBonusVoucherStep[];
    loadingRef.value.bonusVoucherSteps = false;
  }

  function getAccessToken() {
    return nuxtApp.$cookie.get(constants.COOKIE.ACCESS_TOKEN_LOCALE);
  }

  function getAccountRoutes(accountRoutes: NuxtPage[] = []): NuxtPage[] {
    const isLoyaltyEnabled: boolean = nuxtApp.$mopConfig?.isLoyaltyProgramEnabled() === true;
    return accountRoutes.filter((route) => {
      return !route.meta?.loyaltyCountryOnly || (route.meta?.loyaltyCountryOnly === true && isLoyaltyEnabled);
    });
  }

  return securedWrap({
    customerModelRef,
    addressModelRef,
    statusModelRef,
    bonusVoucherStepsRef,
    loadingRef,
    handleCustomer,
    handleAddresses,
    updatePersonalData,
    updatePassword,
    updateEmail,
    updateCustomData,
    updateAddress,
    deleteCustomer,
    customerExists,
    createAddress,
    createAddressCrm,
    createSupportEmail,
    getAccessToken,
    validatePrecard,
    getBonusVoucherSteps,
    getAccountRoutes,
  });
}
