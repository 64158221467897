<script setup lang="ts">
import { getElementUiObject, getLink, getMedia, getCta } from '@mop/cms/utils/utils';
import type { CmsLink, HorizontalAlignment, CmsMedia, CmsUiCta } from '@mop/cms/types';
import type { EditableCmsComponent } from '@/types/cms';

export type CmsUiSuperBannerOverlayCard = EditableCmsComponent & {
  imageType?: string;
  cta?: CmsUiCta;
  text?: string;
  link?: CmsLink;
  textColor?: string;
  textAlignment?: HorizontalAlignment;
  backgroundColor?: string;
  media?: CmsMedia;
};

defineOptions({
  name: 'MopCmsSuperBannerOverlayCard',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  componentCount: {
    type: Number,
    default: 1,
  },
});

const data: any = props.data;
const length: number = props.componentCount;

let imageType = 'default';
if (length === 2) {
  imageType = 'cms-two-column-no-ratio';
} else if (length === 3) {
  imageType = 'cms-three-column-no-ratio';
}

const element: CmsUiSuperBannerOverlayCard = getElementUiObject(data, {
  text: data.text,
  cta: getCta(data.cta?.[0]),
  link: getLink(data.link),
  backgroundColor: data.backgroundColor?.color,
  textColor: data.textColor?.color,
  textAlignment: data.textAlignment,
  media: getMedia(data.media?.[0]),
  imageType,
});

if (element.media) {
  element.media.imageType = imageType;
}

if (element.cta) {
  // Overwrite cta, if component is already linked
  if (element.link!.componentName !== 'div') {
    element.cta.link = {};
  }
}

const uiStyle = {
  '--color': element.textColor,
  textAlign: element.textAlignment,
};
</script>

<template>
  <UiNuxtLink v-storyblok-editable="element" v-bind="element.link" class="superbanner-overlay-card">
    <div v-if="element.media" class="superbanner-overlay-card__image">
      <UiCmsMedia :data="element.media" :cover="true" />
    </div>
    <div v-if="element.text || element.cta" class="superbanner-overlay-card__content" :style="uiStyle">
      <div v-if="element.text" class="superbanner-overlay-card__text">
        {{ element.text }}
      </div>
      <div v-if="element.cta" class="superbanner-overlay-card__cta">
        <UiCmsCta :data="element.cta" no-padding />
      </div>
    </div>
  </UiNuxtLink>
</template>

<style lang="scss" scoped>
.superbanner-overlay-card {
  display: flex;
  flex-direction: column;
  position: relative;
  flex-basis: 100%;
  height: 100%;
}

.superbanner-overlay-card__image {
  flex-shrink: 0;
  height: 100%;
}

.superbanner-overlay-card__content {
  @include text-style(strong);

  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100%;
  padding-left: $space10;
  bottom: $space10;
  line-height: 14px;

  .ui-cms-cta {
    justify-content: flex-start;
  }

  :deep(.ui-cms-cta__element) {
    padding: 0;
  }
}
</style>
