<script setup lang="ts">
import { getElementUiObject, getLink, getHeadlineWithAllOptions, getCta } from '@mop/cms/utils/utils';
import type { CmsUiHeadline } from './uiCmsHeadline';

defineOptions({
  name: 'UiCmsHeadline',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const data: any = props.data;

const element: CmsUiHeadline = getElementUiObject(data, {
  headline: getHeadlineWithAllOptions(data),
  headlineFontStyle: data.headlineFontStyle,
  subline: data.subline,
  isSublineUppercase: data.isSublineUppercase,
  text: data.text,
  isTextUppercase: data.isTextUppercase,
  fontStyle: data.fontStyle,
  textAlignment: data.textAlignment,
  cta: getCta(data.cta?.[0]),
  link: getLink(data.link),
});

// Decided to leave previous font values as migration is not worth it
const fontMap: Record<string, string> = {
  georgia: 'ui-cms-headline--running',
  tradeGothicLight: 'ui-cms-headline--common',
  tradeGothicBold: 'ui-cms-headline--strong',
};

const headlineFontMap: Record<string, string> = {
  default: 'ui-cms-headline__headline--default',
  serifCondensed: 'ui-cms-headline__headline--serif-condensed',
};

const uiFontClass = fontMap[element.fontStyle];
const uiHeadlineFontClass = headlineFontMap[element.headlineFontStyle || 'default'];
const trackingId = element.headline.text || element.subline || '';
</script>

<template>
  <UiNuxtLink
    v-storyblok-editable="element"
    v-bind="element.link"
    :class="[
      'ui-cms-headline ui-cms-padding',
      element.textAlignment === 'left' ? 'ui-cms-headline--left' : 'ui-cms-headline--center',
      uiFontClass,
    ]"
    :data-track-element-item="`${trackingId}`"
  >
    <div
      v-if="element.subline"
      :class="{
        'ui-cms-headline__subline--uppercase': element.isSublineUppercase,
      }"
    >
      {{ element.subline }}
    </div>

    <UiHeading
      v-if="element.headline.text"
      :class="[
        'ui-cms-headline__headline',
        uiHeadlineFontClass,
        {
          'ui-cms-headline__headline--uppercase': element.headline.isUppercase,
        },
      ]"
      :level="element.headline.level"
    >
      {{ element.headline.text }}
    </UiHeading>

    <div
      v-if="element.text"
      :class="['ui-cms-headline__text', { 'ui-cms-headline__text--uppercase': element.isTextUppercase }]"
    >
      {{ element.text }}
    </div>

    <UiCmsCta v-if="element.cta" class="ui-cms-headline__cta" :data="element.cta" no-padding />
  </UiNuxtLink>
</template>

<style lang="scss" scoped>
.ui-cms-headline {
  flex-direction: column;
  padding: $space30 $global-padding;
}

// Reset styles from hN elements.
.ui-cms-headline__headline {
  @include text-style(headline, true);

  margin-bottom: 0;
  margin-top: 0;
}

.ui-cms-headline__headline--serif-condensed {
  @include text-style(headline-serif-condensed, true);
}

.ui-cms-headline__headline--uppercase,
.ui-cms-headline__subline--uppercase,
.ui-cms-headline__text--uppercase {
  text-transform: uppercase;
}

.ui-cms-headline__text {
  padding-bottom: $space20;
  width: 100%;
  line-height: 15px;
}

.ui-cms-headline__cta {
  @include text-style(common);

  margin: -$space15 0;
}

.ui-cms-headline--left {
  align-items: flex-start;
}

.ui-cms-headline--center {
  align-items: center;
  text-align: center;
}

.ui-cms-headline--running {
  @include text-style(running);

  font-size: 13px;
  line-height: 18px;
}

.ui-cms-headline--common {
  @include text-style(common);
}

.ui-cms-headline--strong {
  @include text-style(strong);
}

.ui-cms-headline--strong {
  @include text-style(strong);
}
</style>
