import type { AxiosError } from 'axios';
import type { BapiClient } from '@aboutyou/backbone';
import { handleAxiosError, errorHandler } from '@/api/utils';
import { mapProductAttributes } from '@/models/utils/productUtils';
import type { GetWishlistParams, WishlistResponseData, AddWishlistItemParams } from '@/types/wishlist';
const API_NAME = 'AYC - wishlist';

function mapWishlistProductResponse(items: any) {
  items.forEach((item: any) => {
    mapProductAttributes(item.product);
  });
}

export function getWishlist(
  apiInstance: BapiClient,
  wishlistKey: string,
  params?: GetWishlistParams,
): Promise<WishlistResponseData> {
  return errorHandler<WishlistResponseData>(async () => {
    const data: any = await apiInstance.wishlist.get(wishlistKey, params);
    mapWishlistProductResponse(data.items);
    return {
      data,
    };
  }).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getWishlist',
      wishlistKey,
      params,
    }),
  }));
}

export function addWishlistItem(
  apiInstance: BapiClient,
  wishlistKey: string,
  ayProductId: number,
  params?: AddWishlistItemParams,
): Promise<WishlistResponseData> {
  return errorHandler<WishlistResponseData>(async () => {
    const result = await apiInstance.wishlist.addItem(wishlistKey, { productId: ayProductId }, params);
    mapWishlistProductResponse(result.wishlist.items);
    if (result.type === 'failure') {
      return {
        data: result.wishlist,
        error: {
          code: constants.ERROR_CODE.WISHLIST.ITEM_NOT_ADDED,
        },
      };
    }

    return {
      data: result.wishlist,
    };
  }).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getWishlist',
      wishlistKey,
      ayProductId,
      params,
    }),
  }));
}

export function removeWishlistItem(
  apiInstance: BapiClient,
  wishlistKey: string,
  wishlistItemKey: string,
  params?: GetWishlistParams,
): Promise<WishlistResponseData> {
  return errorHandler<WishlistResponseData>(async () => {
    const data: any = await apiInstance.wishlist.deleteItem(wishlistKey, wishlistItemKey, params);
    mapWishlistProductResponse(data.items);
    return {
      data,
    };
  }).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getWishlist',
      wishlistKey,
      wishlistItemKey,
      params,
    }),
  }));
}
