import type { AxiosError, AxiosInstance } from 'axios';
import { handleAxiosError, errorHandler } from '@/api/utils';
import type { BackInStockNotificationParameters, BackInStockNotificationResponseData } from '@/types/product';
const API_NAME = 'AWS - back in stock';

export function registerBackInStockNotification(
  apiInstance: AxiosInstance,
  payload: BackInStockNotificationParameters,
): Promise<BackInStockNotificationResponseData> {
  return errorHandler<BackInStockNotificationResponseData>(async () => ({
    data: await apiInstance.post('/backinstock', payload),
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'registerBackInStockNotification',
      payload,
    }),
  }));
}
