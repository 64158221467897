import { isSafari, getPassiveEventOption, throttle, isClient } from '@mop/shared/utils/util';
export default defineNuxtPlugin((nuxtApp) => {
  // Default screen size is mobile 400 x 700
  const viewportWidthRef = ref(400);
  const viewportHeightRef = ref(700);
  const viewportOrientationRef = ref(0);
  const isSafariBrowser: boolean = isSafari();
  const calculateViewportDimensions = throttle(() => {
    viewportWidthRef.value = isSafariBrowser ? document.documentElement.clientWidth : window.innerWidth;
    viewportHeightRef.value = window.innerHeight;
    if (window.matchMedia('(orientation: portrait)').matches) {
      // you're in PORTRAIT mode
      viewportOrientationRef.value = 1;
    } else {
      // you're in LANDSCAPE mode
      viewportOrientationRef.value = 2;
    }
  }, 100);

  const documentHeightRef = ref(0);
  const calculateDocumentHeight = throttle(() => {
    documentHeightRef.value = document.body.offsetHeight;
  }, 300);

  if (isClient) {
    window.addEventListener('resize', () => calculateViewportDimensions() as any, getPassiveEventOption());
    setTimeout(calculateViewportDimensions, 100);

    const resizeObserver = new ResizeObserver(() => {
      calculateDocumentHeight();
    });
    resizeObserver.observe(document.body);
    calculateDocumentHeight();
  }

  nuxtApp.provide('resize', {
    viewportWidthRef,
    viewportHeightRef,
    documentHeightRef,
    viewportOrientationRef,
  });
});
