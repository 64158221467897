<script setup lang="ts">
import type { UiImageSettingsTypeKey, CmsImage } from '@mop/cms/types';
import type { Timer } from '@mop/types';

defineOptions({
  name: 'UiImageCarousel',
});

const props = defineProps({
  images: {
    type: Array as PropType<CmsImage[]>,
    default: () => [],
  },
  imagesMobile: {
    type: Array as PropType<CmsImage[]>,
    default: () => [],
  },
  imageType: {
    type: String as PropType<UiImageSettingsTypeKey>,
    default: 'default',
  },
  lazy: {
    type: Boolean,
    default: false,
  },
  cover: {
    type: Boolean,
    default: false,
  },
  speed: {
    type: Number,
    default: 1000,
  },
});

const emit = defineEmits(['loaded']);

const { $breakpoint } = useNuxtApp();

const isLoadedRef = ref(false);

const isMobileRef = computed(() => $breakpoint.isMobileRef.value);

const carouselImagesRef = computed(() => {
  const carouselImages = isMobileRef.value && props.imagesMobile?.length ? [...props.imagesMobile] : [...props.images];
  // Remove aspect ratio to make it work with position absolute
  carouselImages.forEach((image) => {
    delete image.aspectRatioWidth;
    delete image.aspectRatioHeight;
  });
  return carouselImages;
});

const placeholderImageRef = computed(() => {
  return isMobileRef.value && props.imagesMobile?.length ? props.imagesMobile?.[0] : props.images?.[0];
});

const activeImageRef = ref(0);

function slideImage() {
  const maxIndexReached = activeImageRef.value >= carouselImagesRef.value.length - 1;
  activeImageRef.value = maxIndexReached ? 0 : activeImageRef.value + 1;
}

let interval: Timer;

function handleLoaded() {
  isLoadedRef.value = true;
  emit('loaded');
  if (interval) {
    clearInterval(interval);
  }
  interval = setInterval(slideImage, props.speed);
}

onBeforeUnmount(() => {
  clearInterval(interval);
});
</script>

<template>
  <div
    v-if="carouselImagesRef.length"
    :key="`${isMobileRef}`"
    :class="[
      'image-crousel',
      {
        'image-crousel--loaded': isLoadedRef,
      },
    ]"
  >
    <UiImage
      class="image-crousel__item-placeholder"
      :image="placeholderImageRef"
      :type="imageType"
      :lazy="lazy"
      :cover="cover"
      @loaded="handleLoaded"
    />
    <UiImage
      v-for="(carouselImage, i) in carouselImagesRef"
      :key="`${carouselImage?.url}-${i}`"
      :class="[
        'image-crousel__item',
        {
          'image-crousel__item--active': activeImageRef === i,
        },
      ]"
      :image="carouselImage"
      :lazy="lazy"
      :cover="cover"
    />
  </div>
</template>

<style lang="scss" scoped>
.image-crousel {
  position: relative;
}

.image-crousel__item {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity ease 0.1s;

  :deep(.image) {
    position: relative;
  }
}

.image-crousel--loaded {
  .image-crousel__item-placeholder {
    visibility: hidden;
  }

  .image-crousel__item {
    display: block;
  }
}

.image-crousel__item--active {
  opacity: 1;
}
</style>
