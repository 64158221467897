import type { RouteLocationNormalizedLoaded } from 'vue-router';
import { isClient, generateRandomChars } from '@mop/shared/utils/util';
import type { CookieStorage } from '@mop/shared/utils/cookie';
import type { CustomerGroup } from '@/types/customer';
import type { CmsVisibility } from '@/types/cms';
import type { FilterValueModel } from '@/types/filters';
import type { ProductData } from '@/types/product';
import type { CountryConfigModel } from '@/types/countryConfig';

function atob(base64string: string) {
  if (isClient) {
    return window.atob(base64string);
  }
  return Buffer.from(base64string, 'base64').toString('binary');
}

export function jwtDecode(token: string) {
  const base64Url: string = token.split('.')[0];
  const base64: string = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload: string = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
  try {
    return JSON.parse(jsonPayload);
  } catch (error) {
    return {};
  }
}

// Simulate AY token to make global e checkout compatible
export function jwtEncode(payload: any) {
  if (!isClient) {
    return;
  }
  const jsonPayload: string = encodeURIComponent(window.btoa(JSON.stringify(payload))).replace(/%3D/g, '');
  return jsonPayload;
}

type SocialMediaMetaProperties = {
  twitterCard: string;
  ogType: string;
  url: string;
  title: string;
  image: string;
  description: string;
};

type SocialMediaTag = {
  property?: string;
  name?: string;
  content: string;
};

export function createSocialMediaTags(options: SocialMediaMetaProperties) {
  type metaKeys = keyof typeof options;

  const tags: SocialMediaTag[] = Object.keys(options).map((option) => ({
    name: `twitter:${option}`,
    property: `og:${option}`,
    content: options[option as metaKeys],
  }));

  return [
    ...tags,
    {
      name: 'twitter:card',
      content: options.twitterCard,
    },
    {
      name: 'og:type',
      content: options.ogType,
    },
  ];
}

export function isRedirectRoutePath(routePath: string): boolean {
  return Object.values(constants.REDIRECT_PATHS).some((redirectPath) => routePath.includes(redirectPath));
}

/**
 * Taken from ./node_modules/vue-router/src/components/link.js
 */
export function guardEvent(e: MouseEvent) {
  // don't redirect with control keys
  if (e.metaKey || e.altKey || e.ctrlKey || e.shiftKey) {
    return;
  }
  // don't redirect when preventDefault called
  if (e.defaultPrevented) {
    return;
  }
  // don't redirect on right click
  if (e.button !== undefined && e.button !== 0) {
    return;
  }
  // don't redirect if `target="_blank"`
  // @ts-ignore
  if (e.currentTarget && e.currentTarget.getAttribute) {
    // @ts-ignore
    const target: string = e.currentTarget.getAttribute('target');
    if (/\b_blank\b/i.test(target)) {
      return;
    }
  }
  // this may be a Weex event which doesn't have this method
  if (e.preventDefault) {
    e.preventDefault();
  }
  return true;
}

export function isTouchDevice() {
  if (!isClient) {
    return false;
  }
  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    // @ts-ignore
    navigator.msMaxTouchPoints > 0
  );
}

export function isVisibleForCustomer(visibility?: CmsVisibility, customerGroups?: CustomerGroup[]) {
  if (
    !visibility ||
    [constants.STORYBLOK.VISIBILITY_ALL, constants.STORYBLOK.VISIBILITY_HIDDEN].includes(visibility) ||
    !customerGroups?.length
  ) {
    return true;
  }
  const isLivePreviewCustomerGroupAll: boolean = (customerGroups[0] as string) === constants.STORYBLOK.VISIBILITY_ALL;
  if (isLivePreviewCustomerGroupAll) {
    return true;
  }
  if (visibility === constants.STORYBLOK.VISIBILITY_NONE) {
    return false;
  }
  return customerGroups.includes(visibility as CustomerGroup);
}

export const isHttpsEnabled: boolean = isClient
  ? (window as any).__NUXT__.config.public.BASE_URL.startsWith('https://')
  : Boolean(process.env.PUBLIC_BASE_URL && process.env.PUBLIC_BASE_URL.startsWith('https://'));

export const isLocalhost: boolean = isClient
  ? (window as any).__NUXT__.config.public.BASE_URL.includes('localhost')
  : Boolean(process.env.PUBLIC_BASE_URL && process.env.PUBLIC_BASE_URL.includes('localhost'));

export const isProductionBuild: boolean =
  isHttpsEnabled &&
  (isClient
    ? (window as any).__NUXT__.config.public.RUNTIME_ENVIRONMENT === constants.RUNTIME_ENVIRONMENT.PRODUCTION
    : Boolean(process.env.PUBLIC_RUNTIME_ENVIRONMENT === constants.RUNTIME_ENVIRONMENT.PRODUCTION));

export const isCDNEnabled: boolean = isClient
  ? (window as any).__NUXT__.config.public.CDN_ENABLED
  : process.env.PUBLIC_CDN_ENABLED === 'true';

export function getAyWarehouseId(warehouse: 'ARVATO' | 'CROSSDOCKING' | 'SHIP_FROM_STORE'): number {
  const warehouseKey = `AY_WAREHOUSE_${warehouse}_ID`;
  return isClient
    ? parseInt((window as any).__NUXT__.config.public[warehouseKey])
    : parseInt(String(process.env['PUBLIC_' + warehouseKey]));
}

export const defaultStoryblokLanguage: string =
  (isClient
    ? (window as any).__NUXT__.config.public.DEFAULT_STORYBLOK_LANGUAGE
    : process.env.PUBLIC_DEFAULT_STORYBLOK_LANGUAGE) || 'en-se';

export function isRedirectedFromCheckout(route: RouteLocationNormalizedLoaded): boolean {
  return route?.query?.login === '1';
}

export function sortSizes(list: FilterValueModel[] | string[]) {
  if (list === undefined || list.length === 0) {
    return;
  }
  const weights: { [key: string]: number } = {
    os: -130,
    oso: -120,
    xxxs: -110,
    xxs: -100,
    xs: -90,
    s: -80,
    sm: -75,
    m: -70,
    l: -60,
    lxl: -55,
    xl: -50,
    xxl: -40,
    xxxl: -30,
    '3xl': -20,
    a: 1,
    b: 2,
    c: 3,
    d: 4,
    e: 5,
    f: 6,
    g: 7,
  };
  const livingSectionRegex = / [+-] (\d*\/)*/gi; // digit stands for the count of pillows.. 200x220 - 2/80x80 in this case 2
  const cleanUpRegex = /[x_+-/ ]/gi;
  const womenSizesRegex = /[a-z]/gi;
  function getValueWeight(value: string): number {
    const inputValue: string = value.toLowerCase();
    // defined weighted sorting
    if (weights[inputValue] !== undefined) {
      return weights[inputValue];
    }

    // living section sorting..
    if (livingSectionRegex.test(inputValue)) {
      const livingValue: string = inputValue.replace(livingSectionRegex, '|').replace(cleanUpRegex, '');
      const [mainWeightText, suplementaryWeightText] = livingValue.split('|');
      const mainWeight: string = mainWeightText?.replace(/\D+/g, '')?.substring(0, 6) || '';
      const suplementaryWeight: string = suplementaryWeightText?.replace(/\D+/g, '')?.substring(0, 6) || '';
      // ensure consistent ending for ending dimmensions being 6 digits, 200260 or 006070 this ensures correct numeric values in the end
      return parseInt(mainWeight + '0'.repeat(6 - suplementaryWeight.length) + suplementaryWeight);
    }

    const itemValue: string = inputValue.replace(cleanUpRegex, '');
    // sorting of the number + alphabet char, 36A, 36B etc..
    if (womenSizesRegex.test(itemValue)) {
      return parseInt(itemValue.replace(womenSizesRegex, (value: string) => String(weights[value])));
    }

    return parseInt(itemValue);
  }

  function sortResult(valueA: number, valueB: number): number {
    if (valueA > valueB) {
      return 1;
    } else if (valueA < valueB) {
      return -1;
    }
    return 0;
  }

  if (typeof list[0] === 'string') {
    (list as string[]).sort((itemA: string, itemB: string) => sortResult(getValueWeight(itemA), getValueWeight(itemB)));
  } else {
    (list as FilterValueModel[]).sort((itemA: FilterValueModel, itemB: FilterValueModel) =>
      sortResult(getValueWeight(itemA.getName()), getValueWeight(itemB.getName())),
    );
  }
}

export function getMopProductId(input: string, forceFullMatch = false): string | undefined {
  /*
    Covering cases such as:
    product-name-B0126542701103_790
    product-name-730285-102_464
    product-name-00126542701103_790
    product-name-000823102003_D01
    product-name-4-730323-770_449 -> id is 730323-770_449
    product-name-2574KV10401900_100
    product-name-B512AL10501900_940
  */

  const regex: RegExp = forceFullMatch
    ? /^[^-]+\d{3,}[^-]*(-[^-]*\d{3,})?_.+$/gim
    : /[^-]+\d{3,}[^-]*(-[^-]*\d{3,})?_.+/gim;
  return regex.exec(input)?.[0];
}

export function isFirstClientLoad() {
  return !isClient || (window as any).__NUXT__.isHydrationDone !== true;
}

export function getCacheIdByRoute(route: RouteLocationNormalizedLoaded): string {
  return isFirstClientLoad() ? route.path : route.fullPath;
}

export function isLoading(state: Ref<{ [key: string]: boolean }>) {
  return Object.keys(state.value).some((key: string) => key !== 'loading' && state.value[key] === true);
}

export function redirectClient(path: string, query?: RouteLocationNormalizedLoaded['query']) {
  setTimeout(() => {
    return useRouter().replace({
      path,
      query,
    });
  }, 0);
}

export function getUuidAndSaveToCookie(cookieStorage: CookieStorage, cookieName: string): string {
  const uuidValue: string = cookieStorage.get(cookieName);

  if (uuidValue.length > 0) {
    return uuidValue;
  }

  const uuid: string = getUuid();
  cookieStorage.store(cookieName, uuid);
  return uuid;
}

export function getUuid() {
  return [...Array(4)].map(() => generateRandomChars(4)).join('-');
}

export function enrichProductResponseWithCmsData(productData: ProductData, mopConfig: CountryConfigModel) {
  const referenceKey: string = productData?.referenceKey;
  if (referenceKey) {
    const vimeoId: string | undefined = mopConfig.getProductPreference(referenceKey)?.vimeoId;
    if (vimeoId) {
      productData.vimeoId = vimeoId;
    }
    const popularityFlag =
      mopConfig.getPopularityFlagPreference(referenceKey, 'itemsOnTrend') ||
      mopConfig.getPopularityFlagPreference(referenceKey, 'itemsBestseller') ||
      mopConfig.getPopularityFlagPreference(referenceKey, 'itemsPopular');
    if (popularityFlag) {
      productData.popularityFlag = popularityFlag;
    }
  }
}

export function removeValuesFromArray(
  array: Array<number | string | boolean>,
  ...values: Array<number | string | boolean>
) {
  if (!Array.isArray(array) || array.length === 0) {
    return;
  }
  values.forEach((value) => {
    const index: number = array.indexOf(value);
    if (index !== -1) {
      array.splice(index, 1);
    }
  });
}

export function removeValuesFromArrayRef(
  list: Ref<Array<number | string | boolean>>,
  ...values: Array<number | string | boolean>
) {
  removeValuesFromArray(list.value, ...values);
}

export function addValuesToArrayRef(
  list: Ref<Array<number | string | boolean>>,
  ...values: Array<number | string | boolean>
) {
  addValuesToArray(list.value, ...values);
}

export function addValuesToArray(array: Array<number | string | boolean>, ...values: Array<number | string | boolean>) {
  if (!Array.isArray(array)) {
    return;
  }
  values.forEach((value) => {
    const index: number = array.indexOf(value);
    if (index === -1) {
      array.push(value);
    }
  });
}
