import type { BapiClient } from '@aboutyou/backbone';
import type { AxiosInstance } from 'axios';
import { getCart, addCartItem, removeCartItem, updateCartItem } from './apiCart';
import {
  getAllCategories,
  getCategoryById,
  getCategoryByPath,
  getCategoryListByIds,
  getCategoriesCount,
  getSaleCategoriesCount,
} from './apiCategory';
import { queryProducts, getProduct } from './apiProduct';
import { getFilters } from './apiFilter';
import { getSearchSuggestions } from './apiSearchSuggestions';
import { addWishlistItem, getWishlist, removeWishlistItem } from './apiWishlist';
import {
  getCustomer,
  getAddresses,
  updatePersonalData,
  updateEmail,
  updatePassword,
  updateCustomData,
  updateAddress,
  createAddress,
} from './apiCustomer';
import type { AddWishlistItemParams, GetWishlistParams } from '@/types/wishlist';
import type { SearchSuggestionParams } from '@/types/searchSuggestions';
import type { GetFiltersParams } from '@/types/filters';
import type { ProductSearchParameter, ProductsSearchParameter } from '@/types/product';
import type { SearchCategoryCountParams, SearchCategoryParams } from '@/types/category';
import type { AddItemParams, GetCartParams, RemoveItemParams, UpdateItemParams } from '@/types/cart';
import type {
  UpdateCustomData,
  UpdateEmailPayload,
  UpdatePasswordPayload,
  UpdatePersonalDataPayload,
} from '@/types/customer';
import type { UpdateAddressData, CreateAddressData } from '@/types/address';

export function initApiBackbone(apiInstance: BapiClient) {
  return {
    getCart: (basketKey: string, params?: GetCartParams) => getCart(apiInstance, basketKey, params),
    addCartItem: (basketKey: string, variantId: number, quantity?: number, params?: AddItemParams) =>
      addCartItem(apiInstance, basketKey, variantId, quantity, params),
    updateCartItem: (basketKey: string, itemKey: string, quantity: number, params?: UpdateItemParams) =>
      updateCartItem(apiInstance, basketKey, itemKey, quantity, params),
    removeCartItem: (basketKey: string, itemKey: string, params?: RemoveItemParams) =>
      removeCartItem(apiInstance, basketKey, itemKey, params),
    getAllCategories: () => getAllCategories(apiInstance),
    getCategoryById: (id: number, params: SearchCategoryParams = {}) => getCategoryById(apiInstance, id, params),
    getCategoryListByIds: (ids: number[]) => getCategoryListByIds(apiInstance, ids),
    getCategoriesCount: (params: SearchCategoryCountParams = {}) => getCategoriesCount(apiInstance, params),
    getSaleCategoriesCount: () => getSaleCategoriesCount(apiInstance),
    getCategoryByPath: (path: string[]) => getCategoryByPath(apiInstance, path),
    queryProducts: (params?: ProductsSearchParameter) => queryProducts(apiInstance, params),
    getProduct: (id: string, params?: ProductSearchParameter) => getProduct(apiInstance, id, params),
    getFilters: (params: GetFiltersParams) => getFilters(apiInstance, params),
    getSearchSuggestions: (term: string, params?: SearchSuggestionParams) =>
      getSearchSuggestions(apiInstance, term, params),
    addWishlistItem: (wishlistKey: string, ayProductId: number, params?: AddWishlistItemParams) =>
      addWishlistItem(apiInstance, wishlistKey, ayProductId, params),
    getWishlist: (wishlistKey: string, params?: GetWishlistParams) => getWishlist(apiInstance, wishlistKey, params),
    removeWishlistItem: (wishlistKey: string, wishlistItemKey: string, params?: GetWishlistParams) =>
      removeWishlistItem(apiInstance, wishlistKey, wishlistItemKey, params),
  };
}

export function initApiAyc(apiInstance: AxiosInstance) {
  return {
    getCustomer: (accessToken: string) => getCustomer(apiInstance, accessToken),
    getAddresses: (accessToken: string, shopId: number, perPage: number) =>
      getAddresses(apiInstance, accessToken, shopId, perPage),
    updatePersonalData: (accessToken: string, payload: UpdatePersonalDataPayload) =>
      updatePersonalData(apiInstance, accessToken, payload),
    updateEmail: (accessToken: string, payload: UpdateEmailPayload) => updateEmail(apiInstance, accessToken, payload),
    updatePassword: (accessToken: string, payload: UpdatePasswordPayload) =>
      updatePassword(apiInstance, accessToken, payload),
    updateCustomData: (accessToken: string, payload: UpdateCustomData) =>
      updateCustomData(apiInstance, accessToken, payload),
    updateAddress: (accessToken: string, addressId: number, shopId: number, payload: UpdateAddressData) =>
      updateAddress(apiInstance, accessToken, addressId, shopId, payload),
    createAddress: (accessToken: string, shopId: number, payload: CreateAddressData) =>
      createAddress(apiInstance, accessToken, shopId, payload),
  };
}

export type ApiBackbone = ReturnType<typeof initApiBackbone>;
export type ApiAyc = ReturnType<typeof initApiAyc>;
