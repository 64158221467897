import type { AxiosError, AxiosInstance } from 'axios';
import { errorHandler, handleAxiosError } from '@/api/utils';
import type {
  NewsletterResponseData,
  NewsletterRegistrationRequestData,
  NewsletterActivationRequestData,
  NewsletterPreferenceRequestKeys,
  NewsletterPreferenceSettings,
} from '@/types/newsletter';
const API_NAME = 'CRM - newsletter';

export function registerNewsletter(
  apiInstance: AxiosInstance,
  params: NewsletterRegistrationRequestData,
): Promise<NewsletterResponseData> {
  return errorHandler<NewsletterResponseData>(async () => ({
    data: (await apiInstance.post('/api/DoiRegister', params)).data,
  })).catch((error) => {
    return {
      error: {
        code: error.response?.status ?? 0,
        message: error.response?.data?.message,
      },
    };
  });
}

export function activateNewsletter(
  apiInstance: AxiosInstance,
  params: NewsletterActivationRequestData,
): Promise<NewsletterResponseData> {
  return errorHandler<NewsletterResponseData>(async () => ({
    data: (await apiInstance.post('/api/DoiActivate', params)).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'activateNewsletter',
    }),
  }));
}

export function getNewsletterPreferences(
  apiInstance: AxiosInstance,
  pathKeys: NewsletterPreferenceRequestKeys,
): Promise<NewsletterResponseData> {
  return errorHandler<NewsletterResponseData>(async () => ({
    data: (await apiInstance.get(`/api/Preference/${pathKeys.contactnumber}/${pathKeys.contactid}`)).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getNewsletterPreferences',
      pathKeys,
    }),
  }));
}

export function setNewsletterPreferences(
  apiInstance: AxiosInstance,
  pathKeys: NewsletterPreferenceRequestKeys,
  params: NewsletterPreferenceSettings,
): Promise<NewsletterResponseData> {
  return errorHandler<NewsletterResponseData>(async () => ({
    data: (await apiInstance.patch(`/api/Preference/${pathKeys.contactnumber}/${pathKeys.contactid}`, params)).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'setNewsletterPreferences',
      pathKeys,
    }),
  }));
}
