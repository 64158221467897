<script setup lang="ts">
import { getElementUiObject, getMediaVideoVimeo } from '@mop/cms/utils/utils';
import type { CmsUiVideoVimeo } from '@mop/cms/types';

defineOptions({
  name: 'UiCmsVideoVimeo',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  lazyLoad: {
    type: Boolean,
    default: false,
  },
  scaleFullWidth: {
    type: Boolean,
    default: false,
  },
});

const data: any = props.data;
const element: CmsUiVideoVimeo = getElementUiObject(data, {
  ...getMediaVideoVimeo(data),
});

if (element) {
  element.imageType = 'cms-video';
}
</script>

<template>
  <UiVideoVimeo
    v-storyblok-editable="element"
    :data="element"
    :lazy-load="lazyLoad"
    :scale-full-width="scaleFullWidth"
    :data-track-element-item="element.trackingId"
  />
</template>
