<script setup lang="ts">
import type { Timer } from '@mop/types';
import { getElementUiObject, getDate, getHeadlineWithUppercaseOption } from '@mop/cms/utils/utils';
import type { CmsUiCountdown } from './uiCmsCountdown';

defineOptions({
  name: 'UiCmsCountdown',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const element: CmsUiCountdown = getElementUiObject(props.data, {
  backgroundColor: props.data.backgroundColor.color,
  textColor: props.data.textColor.color,
  headline: getHeadlineWithUppercaseOption(props.data),
  text: props.data.text,
  dateTime: getDate(props.data.dateTime) || new Date(),
});

const secondsDifference = ref<number>(0);

let interval: Timer;

const minuteInSeconds = 60;
const hourInSeconds: number = minuteInSeconds * 60;
const dayInSeconds: number = hourInSeconds * 24;

const targetDate: Date = element.dateTime;
const targetDateInSeconds: number = getSecondsSinceEpoch(targetDate);

const countdownValues = computed(() => {
  const countdownValues: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  } = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  let fullSeconds: number = secondsDifference.value;
  if (fullSeconds > dayInSeconds) {
    countdownValues.days = Math.floor(fullSeconds / dayInSeconds);
    fullSeconds = fullSeconds % dayInSeconds;
  }
  if (fullSeconds > hourInSeconds) {
    countdownValues.hours = Math.floor(fullSeconds / hourInSeconds);
    fullSeconds = fullSeconds % hourInSeconds;
  }
  if (fullSeconds > minuteInSeconds) {
    countdownValues.minutes = Math.floor(fullSeconds / minuteInSeconds);
    fullSeconds = fullSeconds % minuteInSeconds;
  }
  if (fullSeconds > 0) {
    countdownValues.seconds = fullSeconds;
  }

  return countdownValues;
});

function getSecondsSinceEpoch(date: Date): number {
  return Math.floor(date.getTime() / 1000);
}

function calculateDateDifference(): void {
  const now = new Date();
  const difference: number = targetDateInSeconds - getSecondsSinceEpoch(now);
  if (difference >= 0) {
    secondsDifference.value = difference;
  } else {
    clearInterval(interval);
  }
}

onMounted(() => {
  interval = setInterval(calculateDateDifference, 1000);
  calculateDateDifference();
});

onBeforeUnmount(() => {
  clearInterval(interval);
});

const trackingId = element.headline.text || '';
</script>

<template>
  <div
    v-storyblok-editable="element"
    class="ui-cms-countdown"
    :style="`background-color: ${element.backgroundColor}; --color: ${element.textColor}`"
    :data-track-element-item="`${trackingId}`"
  >
    <div
      v-if="element.headline.text"
      :class="[
        'ui-cms-countdown__headline',
        {
          'ui-cms-countdown__headline--uppercase': element.headline.isUppercase,
        },
      ]"
    >
      {{ element.headline.text }}
    </div>
    <div class="ui-cms-countdown__number-container">
      <div
        v-for="(countdownValue, countdownValueKey) of countdownValues"
        :key="countdownValueKey"
        class="ui-cms-countdown__item"
      >
        <div class="ui-cms-countdown__item-number">
          {{ countdownValue }}
        </div>
        <span class="ui-cms-countdown__item-key">
          {{ $mopI18n.t(`components.countdown.${countdownValueKey}`, countdownValue) }}
        </span>
      </div>
    </div>
    <p v-if="element.text" class="ui-cms-countdown__text">
      {{ element.text }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
.ui-cms-countdown {
  width: columns(36);
  padding: $space30 $global-padding;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.ui-cms-countdown__number-container {
  display: flex;
  justify-content: space-between;
  width: columns(14, 32);

  @include apply-only(medium) {
    width: columns(18, 32);
  }

  @include apply-upto(small) {
    width: columns(32, 32);
  }
}

.ui-cms-countdown__headline {
  @include text-style(headline);

  @include apply-from(extralarge) {
    margin-bottom: $space30;
  }
}

.ui-cms-countdown__headline--uppercase {
  text-transform: uppercase;
}

.ui-cms-countdown__text {
  @include text-style(running);

  margin-top: $space30;
}

.ui-cms-countdown__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: columns(2, 14);

  @include apply-only(medium) {
    width: columns(3, 18);
  }

  @include apply-upto(small) {
    width: columns(7, 32);
  }
}

.ui-cms-countdown__item-number {
  @include text-style(headline);

  @include apply-from(tiny) {
    margin-bottom: $space5;
  }
}

.ui-cms-countdown__item-key {
  @include text-style(small);
}
</style>
