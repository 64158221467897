// @ts-ignore
import cssExport from '@mop/shared/scss/export.module.scss';
import { isClient } from '@mop/shared/utils/util';
import type { Breakpoint, ViewportSize } from '@/types/breakpoint';

const isValueBetween: Function = (value: number, from: number, to: number) => value >= from && value < to;

export default defineNuxtPlugin((nuxtApp) => {
  const { TINY, SMALL, MEDIUM, LARGE, EXTRA_LARGE, EXTRA_EXTRA_LARGE, INFINITE } = constants.BREAKPOINT;
  const currentViewportRef = ref<ViewportSize>('infinite');
  const tiny: number = parseInt(cssExport[`breakpoint-${TINY}`]);
  const small: number = parseInt(cssExport[`breakpoint-${SMALL}`]);
  const medium: number = parseInt(cssExport[`breakpoint-${MEDIUM}`]);
  const large: number = parseInt(cssExport[`breakpoint-${LARGE}`]);
  const xLarge: number = parseInt(cssExport[`breakpoint-${EXTRA_LARGE}`]);
  const xxLarge: number = parseInt(cssExport[`breakpoint-${EXTRA_EXTRA_LARGE}`]);
  const infinite: number = parseInt(cssExport[`breakpoint-${INFINITE}`]);

  const {
    // @ts-ignore
    $resize: { viewportWidthRef },
  } = nuxtApp;
  watch(
    viewportWidthRef,
    () => {
      const width: number = viewportWidthRef.value;
      if (isValueBetween(width, tiny, small)) {
        currentViewportRef.value = TINY;
      } else if (isValueBetween(width, small, medium)) {
        currentViewportRef.value = SMALL;
      } else if (isValueBetween(width, medium, large)) {
        currentViewportRef.value = MEDIUM;
      } else if (isValueBetween(width, large, xLarge)) {
        currentViewportRef.value = LARGE;
      } else if (isValueBetween(width, xLarge, xxLarge)) {
        currentViewportRef.value = EXTRA_LARGE;
      } else if (isValueBetween(width, xxLarge, infinite)) {
        currentViewportRef.value = EXTRA_EXTRA_LARGE;
      } else if (width > infinite) {
        currentViewportRef.value = INFINITE;
      }
    },
    { immediate: true },
  );

  let maxBreakpoint: string = INFINITE;
  let maxBreakpointValue: number = infinite;
  const screenWidth: number = isClient ? window.screen.width : 1024;
  if (screenWidth <= tiny) {
    maxBreakpoint = TINY;
    maxBreakpointValue = tiny;
  } else if (screenWidth <= small) {
    maxBreakpoint = SMALL;
    maxBreakpointValue = small;
  } else if (screenWidth <= medium) {
    maxBreakpoint = MEDIUM;
    maxBreakpointValue = medium;
  } else if (screenWidth <= large) {
    maxBreakpoint = LARGE;
    maxBreakpointValue = large;
  } else if (screenWidth <= xLarge) {
    maxBreakpoint = EXTRA_LARGE;
    maxBreakpointValue = xLarge;
  } else if (screenWidth <= xxLarge) {
    maxBreakpoint = EXTRA_EXTRA_LARGE;
    maxBreakpointValue = xxLarge;
  }

  const breakpoint: Breakpoint = {
    currentRef: currentViewportRef,
    isTinyRef: computed(() => currentViewportRef.value === TINY),
    isSmallRef: computed(() => currentViewportRef.value === SMALL),
    isMediumRef: computed(() => currentViewportRef.value === MEDIUM),
    isLargeRef: computed(() => currentViewportRef.value === LARGE),
    isExtraLargeRef: computed(() => currentViewportRef.value === EXTRA_LARGE),
    isExtraExtraLargeRef: computed(() => currentViewportRef.value === EXTRA_EXTRA_LARGE),
    isInfiniteRef: computed(() => currentViewportRef.value === INFINITE),
    isMobileRef: computed(() => [TINY, SMALL].includes(currentViewportRef.value)),
    tiny,
    small,
    medium,
    large,
    xLarge,
    xxLarge,
    infinite,
    maxBreakpoint,
    maxBreakpointValue,
  };

  nuxtApp.provide('breakpoint', breakpoint);
});
