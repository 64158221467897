<script setup lang="ts">
import type { ObserveScrollConfig, ActionPosition } from '@mop/types';
import type { CmsUiTripleTeaserBig } from './uiCmsTripleTeaser';

defineOptions({
  name: 'UiCmsTripleTeaserItemBig',
});

const props = defineProps({
  data: {
    type: Object as PropType<CmsUiTripleTeaserBig>,
    required: true,
  },
  lazyLoad: {
    type: Boolean,
    default: false,
  },
});

const { $breakpoint } = useNuxtApp();
const element = props.data;
if (element.media) {
  element.media.imageType = 'cms-triple-teaser-big';
}

const textboxStickyClass = 'ui-cms-triple-teaser-item-big__textbox--sticky';

const uiStyle = { '--color': element.textColor, backgroundColor: element.backgroundColor };

const isMediumOrSmallerRef = computed(
  () => $breakpoint.isTinyRef.value || $breakpoint.isSmallRef.value || $breakpoint.isMediumRef.value,
);

const uiCmsTripleTeaserItemBigStickyConfig: ObserveScrollConfig = {
  throttleDelay: 50,
  allowedViewports: 'xxl',
  calculateActionPositions: (element) => {
    if (!element) {
      return [];
    }
    const browserHeight = window.innerHeight;
    const wrapperElementPositionAndSize = element.parentElement!.getBoundingClientRect();
    const wrapperElementOffsetTop = wrapperElementPositionAndSize.top;
    const wrapperElementHeight = wrapperElementPositionAndSize.height;
    const elementHeight = element.offsetHeight;

    const isTooSmallForSticky = browserHeight - wrapperElementOffsetTop - elementHeight < 0;
    const stickyStart = wrapperElementOffsetTop - browserHeight;
    let stickyEnd = browserHeight - wrapperElementOffsetTop - wrapperElementHeight;

    const actionPositionFixedBottom: ActionPosition = {
      onEnter: () => {
        element.classList.add(textboxStickyClass);
      },
      onLeave: () => {
        element.classList.remove(textboxStickyClass);
      },
      positionStart: stickyStart,
    };

    if (stickyEnd <= 0 && !isTooSmallForSticky) {
      stickyEnd = Math.abs(stickyEnd);
      actionPositionFixedBottom.positionStart = stickyStart;
      actionPositionFixedBottom.positionEnd = stickyEnd;
    }

    return [actionPositionFixedBottom];
  },
};
</script>

<template>
  <UiNuxtLink v-bind="element.link" class="ui-cms-triple-teaser-item-big">
    <div class="ui-cms-triple-teaser-item-big__header">
      <div v-if="element.subline" class="ui-cms-triple-teaser-item-big__header-subline">
        {{ element.subline }}
      </div>
      <div v-if="element.headline" class="ui-cms-triple-teaser-item-big__header-headline">
        {{ element.headline }}
      </div>
    </div>

    <UiCmsMedia
      v-if="element.media"
      class="ui-cms-triple-teaser-item-big__image"
      :data="element.media"
      :lazy="lazyLoad"
    />

    <div
      v-storyblok-editable="element"
      v-observe-scroll="uiCmsTripleTeaserItemBigStickyConfig"
      :class="[
        'ui-cms-triple-teaser-item-big__textbox',
        {
          'ui-cms-triple-teaser-item-big__textbox--white': isMediumOrSmallerRef,
        },
      ]"
      :style="!isMediumOrSmallerRef ? uiStyle : ''"
    >
      <div v-if="element.subline" class="ui-cms-triple-teaser-item-big__textbox-subline">
        {{ element.subline }}
      </div>
      <div v-if="element.headline" class="ui-cms-triple-teaser-item-big__textbox-headline">
        {{ element.headline }}
      </div>
      <div v-if="element.text" class="ui-cms-triple-teaser-item-big__textbox-text">
        {{ element.text }}
      </div>
      <div v-if="element.cta" class="ui-cms-triple-teaser-item-big__texbox-cta">
        <UiCmsCta class="ui-cms-triple-teaser-item-big__textbox-cta" :data="element.cta" no-padding />
      </div>
    </div>
  </UiNuxtLink>
</template>

<style lang="scss" scoped>
.ui-cms-triple-teaser-item-big__header {
  padding: columns-in-vw(1);

  @include apply-from(medium) {
    display: none;
  }
}

.ui-cms-triple-teaser-item-big__header-subline {
  @include text-style(common);
}

.ui-cms-triple-teaser-item-big__header-headline {
  @include text-style(headline);

  @include apply-upto(small) {
    margin-bottom: 0;
  }
}

.ui-cms-triple-teaser-item-big {
  position: relative;
  width: columns(23);

  @include apply-from(extraextralarge) {
    width: columns(21);
  }

  @include apply-only(extralarge) {
    width: columns(22);
  }

  @include apply-upto(medium) {
    width: columns(36);
  }

  @include apply-only(medium) {
    display: flex;
    flex-direction: row-reverse;
  }
}

.ui-cms-triple-teaser-item-big__image {
  width: 100%;
}

.ui-cms-triple-teaser-item-big__textbox {
  position: absolute;
  bottom: 0;
  left: 0;
  width: columns-in-vw(7);
  padding: columns-in-vw(1);

  @include apply-upto(small) {
    width: auto;
  }

  @include apply-only(medium) {
    display: flex;
    flex-direction: column;
    position: relative;
    width: columns-in-vw(14);
    height: auto;
    padding: $space30;
    justify-content: flex-end;
  }

  @include apply-only(large) {
    width: columns-in-vw(9);
    padding: columns-in-vw(1);
  }

  @include apply-upto(small) {
    position: static;
    height: auto;
  }
}

.ui-cms-triple-teaser-item-big__textbox--white {
  background-color: $white;
  color: $black;

  @include apply-only(medium) {
    padding-bottom: 5px;
  }
}

.ui-cms-triple-teaser-item-big__textbox--sticky {
  @include apply-from(large) {
    position: fixed;
    bottom: 0;
    left: 0;
  }
}

.ui-cms-triple-teaser-item-big__textbox-subline {
  @include text-style(common);

  @include apply-upto(small) {
    display: none;
  }
}

.ui-cms-triple-teaser-item-big__textbox-headline {
  @include text-style(headline);

  @include apply-upto(small) {
    display: none;
  }
}

.ui-cms-triple-teaser-item-big__textbox-text {
  @include text-style(running);

  margin-bottom: $space30;

  @include apply-upto(small) {
    margin-bottom: $space20;
  }
}

.ui-cms-triple-teaser-item-big__textbox-cta {
  justify-content: flex-end;
}
</style>
