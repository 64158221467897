import { isClient } from '@mop/shared/utils/util';
import { RichtextResolver } from 'storyblok-js-client';
import type {
  CmsMedia,
  CmsMediaVideoVimeo,
  CmsMediaImage,
  CmsMediaImageCarousel,
  CmsUiVideoVimeoData,
  CmsUiVideoVimeo,
  CmsImage,
  CmsImageFocusPoint,
  CmsHeadlineWithLevelOption,
  CmsHeadlineLevel,
  CmsHeadlineWithAllOptions,
  CmsUiCardItem,
  CmsImageDimension,
  CmsUiCta,
  CmsLink,
  CmsUiHotspot,
  CmsDeviceVisibility,
} from '@mop/cms/types';

export type CmsUiRichTextType = 'normal' | 'small';

// Do not copy - use $storyblokLivePreview.isEnabledInIframe if possible
// @ts-ignore
const isStoryblokLivePreviewIframe: boolean =
  (isClient && (window as any).__NUXT__?.data?.storyblokLivePreviewEnabled === true) ?? false;

function pick(attrs: { [x: string]: any }, allowed: string | string[]) {
  if (!attrs) {
    return null;
  }
  const h: any = {};
  for (const key in attrs) {
    const value: any = attrs[key];
    if (allowed.includes(key) && value !== null) {
      h[key] = value;
    }
  }
  return h;
}

const storyblokRichtTextSchema = {
  nodes: {
    horizontal_rule() {
      return {
        singleTag: 'hr',
      };
    },
    blockquote() {
      return {
        tag: 'blockquote',
      };
    },
    bullet_list() {
      return {
        tag: 'ul',
      };
    },
    code_block(node: { attrs: any }) {
      return {
        tag: [
          'pre',
          {
            tag: 'code',
            attrs: node.attrs,
          },
        ],
      };
    },
    hard_break() {
      return {
        singleTag: 'br',
      };
    },
    heading(node: { attrs: { level: any } }) {
      return {
        tag: `h${node.attrs.level}`,
      };
    },
    image(node: { attrs: any }) {
      return {
        singleTag: [
          {
            tag: 'img',
            attrs: pick(node.attrs, ['src', 'alt', 'title']),
          },
        ],
      };
    },
    list_item() {
      return {
        tag: 'li',
      };
    },
    ordered_list() {
      return {
        tag: 'ol',
      };
    },
    paragraph() {
      return {
        tag: 'p',
      };
    },
  },
  marks: {
    bold() {
      return {
        tag: 'b',
      };
    },
    strike() {
      return {
        tag: 'strike',
      };
    },
    underline() {
      return {
        tag: 'u',
      };
    },
    strong() {
      return {
        tag: 'strong',
      };
    },
    code() {
      return {
        tag: 'code',
      };
    },
    italic() {
      return {
        tag: 'i',
      };
    },
    link(node: { attrs: { linktype?: 'url' } }) {
      const url = getLink(node.attrs);
      const attrs: any = { ...node.attrs };
      attrs.href = url.to || url.href;

      return {
        tag: [
          {
            tag: 'a',
            attrs,
          },
        ],
      };
    },
    styled(node: { attrs: any }) {
      return {
        tag: [
          {
            tag: 'span',
            attrs: node.attrs,
          },
        ],
      };
    },
  },
};

export function escapeStoryblokUrl(slug?: string): string {
  if (!slug) {
    return '';
  }
  /**
   * Remove "/c/", "shop/fw21", "/shop/" and trailing slash. The order is important to get an empty slug for the homepages (like
   * "/en-se").
   */
  return `/${slug}`.replace(/\/(c|shop\/fw21|shop|theme-company)\//, '/').replace(/\/$/, '');
}

// Example date api response from cms: "2023-03-15 23:59"
export function getDate(date: string): Date | undefined {
  if (!date) {
    return;
  }
  return new Date(date.replace(/\s/, 'T') + 'Z');
}

type ElementWithCmsLink = { [link: string]: CmsLink } | undefined;
// This method is needed, when in the cms the component gets linked, but also the nested cta,
// which would introduce a wrong HTML markup (<a> within an <a> tag)
export function cleanUpNestedLinks(parent: ElementWithCmsLink, children: ElementWithCmsLink[] = []) {
  if (!parent?.link?.isLink) {
    return;
  }
  children.forEach((child) => {
    if (child?.link?.isLink) {
      child.link = {};
    }
  });
}

export function getDeviceVisibility(object: any): CmsDeviceVisibility {
  return (object?.deviceVisibility ?? 'all').toLocaleLowerCase();
}

export function getLink(object: any): CmsLink {
  const defaultLinkObject: CmsLink = {
    componentName: 'div',
    isLink: false,
  };

  if (!object || isStoryblokLivePreviewIframe) {
    // Do not link in live preview
    return defaultLinkObject;
  }

  const story: any = object.story;
  const url: any = object.url || object.href;
  const email: any = object.email || url;
  const anchor: any = object.anchor;
  const isValidLink: boolean = story || url || email;

  if (!isValidLink) {
    return defaultLinkObject;
  }

  if (story) {
    if (!story.full_slug) {
      return defaultLinkObject;
    }
    const link: CmsLink = getStoryLink(story);
    return getLinkWithAnchors(link, anchor);
  }

  if (object.linktype === 'email') {
    return getEmailLink(email);
  }

  const mopProductId: string | undefined = getMopProductId(url, true);
  if (mopProductId) {
    return getProductRedirectLink(mopProductId);
  }
  if (url.startsWith('http')) {
    return getExternalLink(url);
  }
  const link: CmsLink = getInternalLink(url);
  return getLinkWithAnchors(link, anchor);
}

export function getElementUiObject(element: any, data: any) {
  if (data.link && data.cta?.link) {
    cleanUpNestedLinks(data, [data.cta]);
  }
  return {
    _editable: element?._editable,
    _uid: element?._uid,
    ...data,
  };
}

export function getCta(object: any): CmsUiCta | undefined {
  if (!object) {
    return;
  }
  return getElementUiObject(object, {
    text: object?.text ?? '',
    textTranslationKey: object?.textTranslationKey ?? '',
    isUppercase: object?.isUppercase ?? false,
    isBackLink: object?.isBackLink ?? false,
    align: object?.align ?? '',
    type: object?.type ?? '',
    link: getLink(object?.link),
    textColor: object?.textColor?.color ?? '',
  } as CmsUiCta);
}

export function getCard(object: any): CmsUiCardItem | undefined {
  if (!object) {
    return;
  }
  /**
   * To enable this functionality add `resolve_relations: 'Card.linkPreview'` to the getStory params
   * This is currently used only with company theme
   */
  const linkPreview: any = object.linkPreview;

  const headline = getHeadlineWithAllOptions(object);
  if (!headline.text) {
    headline.text = linkPreview?.content?.previewTitle ?? linkPreview?.content?.title;
  }
  const fallbackSubline: string | undefined = linkPreview?.content?.previewSubTitle || linkPreview?.content?.subTitle;
  const fallbackLink: any = linkPreview ? { story: linkPreview } : undefined;
  const fallbackColor: string | undefined = linkPreview?.content?.textColor?.color;
  const fallbackImage: any = linkPreview ? { image: linkPreview?.content?.previewImage } : undefined;

  return getElementUiObject(object, {
    headline,
    headlineFontStyle: object.headlineFontStyle,
    subline: object.subline || fallbackSubline,
    cta: getCta(object.cta?.[0]),
    link: getLink(object.link || fallbackLink),
    textColor: object.textColor?.color || fallbackColor,
    media: getMedia(object.media?.[0] || fallbackImage, true),
  } as CmsUiCardItem);
}

export function getHtmlFromTable(table: any, headline?: string): string {
  if (!headline && !table) {
    return '';
  }

  let html = `
    <div class="table rich-text table--columns-${table.tbody.body?.[0].length || table.thead.length}">
  `;
  if (headline) {
    html += `
      <div class="table-headline">${headline}</div>`;
  }

  html += `
    <table>
      ${getTableBody(table)}
    </table>
  </div>`;

  return html;
}

export function getHtmlFromSizeTable(sizeTableItem: any): string {
  if (!sizeTableItem) {
    return '';
  }

  const { tableSizes, tableHeadlines, tableCaption } = sizeTableItem;

  let html = `
    <div class="table rich-text table--columns-${tableSizes.tbody.body?.[0].length || tableHeadlines.thead.length}">
    <table class="table-size">
  `;

  if (tableCaption) {
    html += `
      <caption class="table-size__caption">${tableCaption}</caption>`;
  }

  html += `
      ${getTableBody({
        thead: tableHeadlines.thead,
        tbody: tableSizes.tbody,
      })}
    </table>
  </div>`;

  return html;
}

export function getHtmlFromRichText(richText: any, type?: CmsUiRichTextType): string {
  if (!richText) {
    return '';
  }
  if (typeof richText === 'string') {
    return richText;
  }
  const resolver: any = getRichTextResolver();
  let className = 'rich-text';
  if (type) {
    className += ` rich-text--${type}`;
  }
  return `<div class="${className}">${resolver.render(richText) ?? ''}</div>`;
}

let richTextResolver: any;
function getRichTextResolver() {
  if (richTextResolver) {
    return richTextResolver;
  }
  richTextResolver = new RichtextResolver(storyblokRichtTextSchema);
  return richTextResolver;
}

function getTableBody(table: any): string {
  let html = '';
  if (!table) {
    return html;
  }

  const renderTableHead: boolean = table.thead.some((head: any) => Boolean(head.value));
  if (renderTableHead) {
    html += `
      <thead>
        <tr class="table__tr">
          ${table.thead.map((head: any) => `<th class="table__th">${head.value}</th>`).join('')}
        </tr>
      </thead>
    `;
  }

  html += `
      <tbody>
        ${table.tbody
          .map((tr: any) => {
            return `
          <tr class="table__tr">
            ${tr.body.map((td: any) => `<td class="table__td">${td.value}</td>`).join('')}
          </tr>`;
          })
          .join('')}
      </tbody>
  `;

  return html;
}

function prependLinkForStoryblokLivePreview(slug: string) {
  const validLocaleRegex = /^[a-z]{2}-[a-z]{2}\/.+/gi;
  if (!validLocaleRegex.test(slug)) {
    const validShortLocaleRegex = /^[a-z]{2}\/.+/gi;
    if (validShortLocaleRegex.test(slug)) {
      // Short locale, like "de/", "fr/" ...
      slug = `${slug.substring(0, 2)}-${slug}`;
    } else if (typeof window !== 'undefined') {
      // No locale, add default language
      slug = `${(window as any).__NUXT__.config.public.DEFAULT_STORYBLOK_LANGUAGE}/${slug}`;
    } else {
      slug = `/${slug}`;
    }
  }
  return slug;
}

function getStoryLink(story: any): CmsLink {
  let fullSlug: string = story.full_slug;

  if (isStoryblokLivePreviewIframe) {
    fullSlug = prependLinkForStoryblokLivePreview(fullSlug);
  }

  return {
    componentName: 'NuxtLink',
    to: escapeStoryblokUrl(fullSlug),
    isLink: true,
  };
}

function getProductRedirectLink(mopProductId: string): CmsLink {
  return {
    componentName: 'NuxtLink',
    to: `/to-pid/${mopProductId}`,
    isLink: true,
  };
}

function getExternalLink(url: string): CmsLink {
  return {
    componentName: 'a',
    href: url,
    target: '_blank',
    isLink: true,
  };
}

function getInternalLink(to: string): CmsLink {
  return {
    componentName: 'NuxtLink',
    to,
    isLink: true,
  };
}

function getEmailLink(email: string): CmsLink {
  return {
    componentName: 'a',
    href: `mailto:${email}`,
    isLink: true,
  };
}

function getLinkWithAnchors(linkObject: CmsLink, anchor?: string): CmsLink {
  if (anchor) {
    if (linkObject.href) {
      linkObject.href = `${linkObject.href}#${anchor}`;
    }
    if (linkObject.to) {
      linkObject.to = `${linkObject.to}#${anchor}`;
    }
  }
  return linkObject;
}

// Example image url: https://a.storyblok.com/f/93654/1000x1407/94fdd4ff7e/1000x1407.png
// https://www.storyblok.com/faq/image-dimensions-assets-js
function getCmsImageDimensionFromUrl(url: string): CmsImageDimension | undefined {
  if (!url) {
    return;
  }
  const dimensions = url.split('/')?.[5]?.split('x') || [];
  if (dimensions[0] && dimensions[1]) {
    return {
      width: parseInt(dimensions[0]),
      height: parseInt(dimensions[1]),
    };
  }
}

function applyDimensionsToImage(image: CmsImage, dimensions?: CmsImageDimension) {
  image.aspectRatioWidth ??= dimensions?.width;
  image.aspectRatioHeight ??= dimensions?.height;
}

function getHotspots(object: any, attributeKey: string) {
  const hotspotData: CmsUiHotspot[] =
    object[attributeKey]?.map((hotspot: any) => {
      return getElementUiObject(hotspot, {
        productId: hotspot.productId,
        positionX: hotspot.positionX?.value,
        positionY: hotspot.positionY?.value,
        isOpen: Boolean(hotspot.isOpen),
        isAlwaysOpen: hotspot.isAlwaysOpen,
        openPosition: hotspot.openPosition || 'left',
        richText: getHtmlFromRichText(hotspot.richText),
      } as CmsUiHotspot);
    }) || [];
  return hotspotData;
}

function getImageTrackingId(image?: CmsImage) {
  if (!image) {
    return '';
  }
  return image.title || image.name || /\/([^/]+)$/.exec(image.url)?.[1];
}

export function getMedia(object: any, calculateAspectRatioFromImageDimensions = false) {
  if (!object) {
    return;
  }
  if (object.vimeoId) {
    const media = getMediaVideoVimeo(object);
    (media as CmsMedia).type = 'vimeo';
    (media as CmsMedia).trackingId = `vimeoid:${object.vimeoId}`;
    return media as CmsMedia;
  } else if (object.image?.filename) {
    const media = getMediaImage(object);
    (media as CmsMedia).type = 'image';
    if (calculateAspectRatioFromImageDimensions) {
      if (media.image) {
        const dimensions = getCmsImageDimensionFromUrl(media.image.url);
        applyDimensionsToImage(media.image, dimensions);
      }
      if (media.imageMobile) {
        const dimensions = getCmsImageDimensionFromUrl(media.imageMobile.url);
        applyDimensionsToImage(media.imageMobile, dimensions);
      }
    }
    (media as CmsMedia).hotspots = getHotspots(object, 'hotspots');
    (media as CmsMedia).hotspotsMobile = getHotspots(object, 'hotspotsMobile');
    (media as CmsMedia).trackingId = getImageTrackingId(media.image);
    return media as CmsMedia;
  } else if (object.images?.length) {
    const media = getMediaImageCarousel(object);
    (media as CmsMedia).type = 'imageCarousel';
    if (calculateAspectRatioFromImageDimensions) {
      if (media.images?.length) {
        const dimensions = getCmsImageDimensionFromUrl(media.images[0].url);
        media.images.forEach((image) => applyDimensionsToImage(image, dimensions));
      }
      if (media.imagesMobile?.length) {
        const dimensions = getCmsImageDimensionFromUrl(media.imagesMobile[0].url);
        media.imagesMobile.forEach((image) => applyDimensionsToImage(image, dimensions));
      }
    }
    const firstImageTrackingId = getImageTrackingId(media.images?.[0]);
    (media as CmsMedia).trackingId = `imageCarousel:${firstImageTrackingId}`;
    media.speed = parseInt(object.speed);
    return media as CmsMedia;
  }
}

export function getMediaVideoVimeo(object: any): CmsMediaVideoVimeo | undefined {
  const data: any = object;

  if (!data) {
    return;
  }

  const desktopCoverImage: CmsImage | undefined = getImage(data.coverImage);
  setAspectRatio(desktopCoverImage, data.aspectRatioWidth, data.aspectRatioHeight);
  const desktopVideoData: CmsUiVideoVimeoData = {
    vimeoId: data.vimeoId ?? '',
    coverImage: desktopCoverImage,
    config: {
      showControls: data.showControls ?? false,
      loop: data.loop ?? false,
      mute: data.mute ?? false,
      autoPlay: data.autoPlay ?? false,
      widthRatio: data.widthRatio ?? 16,
      heightRatio: data.heightRatio ?? 9,
    },
  };
  setAspectRatio(desktopVideoData, data.aspectRatioWidth, data.aspectRatioHeight);

  const mobileCoverImage: CmsImage | undefined = getImage(data.coverImageMobile);
  setAspectRatio(mobileCoverImage, data.aspectRatioWidthMobile, data.aspectRatioHeightMobile);
  const mobileVideoData: CmsUiVideoVimeoData = {
    vimeoId: data.vimeoIdMobile ?? '',
    coverImage: mobileCoverImage,
    config: {
      showControls: data.showControlsMobile ?? false,
      loop: data.loopMobile ?? false,
      mute: data.muteMobile ?? false,
      autoPlay: data.autoPlayMobile ?? false,
      widthRatio: data.widthRatioMobile ?? 16,
      heightRatio: data.heightRatioMobile ?? 9,
    },
  };
  setAspectRatio(mobileVideoData, data.aspectRatioWidthMobile, data.aspectRatioHeightMobile);

  return getElementUiObject(data, {
    desktop: desktopVideoData,
    mobile: mobileVideoData,
  } as CmsUiVideoVimeo);
}

function setAspectRatio(object: any, aspectRatioWidth: string, aspectRatioHeight: string): void {
  if (object && parseInt(aspectRatioWidth) && parseInt(aspectRatioHeight)) {
    object.aspectRatioWidth = parseInt(aspectRatioWidth);
    object.aspectRatioHeight = parseInt(aspectRatioHeight);
  }
}

function getMediaImage(object: any): CmsMediaImage {
  const mediaImage: CmsMediaImage = getElementUiObject(object, {
    image: getImage(object?.image),
    imageMobile: getImage(object?.imageMobile),
  } as CmsMediaImage);
  return mediaImage;
}

function getMediaImageCarousel(object: any): CmsMediaImageCarousel {
  const mediaImageCarousel: CmsMediaImage = getElementUiObject(object, {
    images: object.images?.map((image: any) => getImage(image)),
    imagesMobile: object.imagesMobile?.map((image: any) => getImage(image)),
  } as CmsMediaImageCarousel);
  return mediaImageCarousel;
}

export function getImage(object: any): CmsImage | undefined {
  if (!object || !object.filename) {
    return;
  }

  let focusPoint: CmsImageFocusPoint | undefined;
  if (object.focus) {
    const dimensions = getCmsImageDimensionFromUrl(object.filename);
    const focusPointCoordinates: string[] = object.focus.split(':')?.[0]?.split('x');
    if (focusPointCoordinates[0] && focusPointCoordinates[1] && dimensions) {
      focusPoint = {
        xPercent: Math.round((parseInt(focusPointCoordinates[0]) * 100) / dimensions.width),
        yPercent: Math.round((parseInt(focusPointCoordinates[1]) * 100) / dimensions.height),
      };
    }
  }
  return {
    id: object?.id,
    url: object?.filename,
    title: object?.title,
    copyright: object?.copyright,
    focusPoint,
    alt: object?.alt,
    name: object?.name,
    trackingId: object!.title || object!.name || /\/([^/]+)$/.exec(object!.url)?.[1],
  };
}

export function getHeadline(object: any) {
  if (!object) {
    return {
      text: '',
    };
  }

  return {
    text: String(object.headline),
  };
}

export function getHeadlineWithUppercaseOption(object: any) {
  if (!object) {
    return {
      isUppercase: false,
      text: '',
    };
  }

  return {
    ...getHeadline(object),
    isUppercase: Boolean(object.isHeadlineUppercase),
  };
}

export function getHeadlineWithLevelOption(object: any): CmsHeadlineWithLevelOption {
  if (!object) {
    return {
      text: '',
      level: undefined,
    };
  }

  return {
    ...getHeadline(object),
    level: object?.headlineLevel === '' ? undefined : (parseInt(object?.headlineLevel) as CmsHeadlineLevel),
  };
}

export function getHeadlineWithAllOptions(object: any): CmsHeadlineWithAllOptions {
  if (!object) {
    return {
      isUppercase: false,
      text: '',
      level: undefined,
    };
  }

  return {
    ...getHeadline(object),
    ...getHeadlineWithUppercaseOption(object),
    ...getHeadlineWithLevelOption(object),
  };
}

function getMopProductId(input: string, forceFullMatch = false): string | undefined {
  /*
    Covering cases such as:
    product-name-B0126542701103_790
    product-name-730285-102_464
    product-name-00126542701103_790
    product-name-000823102003_D01
    product-name-4-730323-770_449 -> id is 730323-770_449
    product-name-2574KV10401900_100
    product-name-B512AL10501900_940
  */

  const regex: RegExp = forceFullMatch
    ? /^[^-]+\d{3,}[^-]*(-[^-]*\d{3,})?_.+$/gim
    : /[^-]+\d{3,}[^-]*(-[^-]*\d{3,})?_.+/gim;
  return regex.exec(input)?.[0];
}
