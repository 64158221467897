import type { AxiosInstance } from 'axios';
import { getServerCodeVersion, getHeaders } from './apiShop';

export default function initApiLocal(apiInstance: AxiosInstance) {
  return {
    getServerCodeVersion: () => getServerCodeVersion(apiInstance),
    getHeaders: () => getHeaders(apiInstance),
  };
}

export type ApiLocal = ReturnType<typeof initApiLocal>;
