import { logError } from '@mop/shared/utils/logger';
import { isClient } from '@mop/shared/utils/util';
export function sessionStorageGet(name: string): string | null {
  if (!isClient) {
    return null;
  }
  return window.sessionStorage.getItem(name);
}

export function sessionStorageSet(name: string, value: string) {
  if (!isClient) {
    return;
  }
  try {
    window.sessionStorage.setItem(name, value);
  } catch (error) {
    logError(error);
  }
}

export function sessionStorageRemove(name: string) {
  if (!isClient) {
    return;
  }
  try {
    window.sessionStorage.removeItem(name);
  } catch (error) {
    logError(error);
  }
}

export function sessionStorageClear() {
  if (!isClient) {
    return;
  }
  try {
    window.sessionStorage.clear();
  } catch (error) {
    logError(error);
  }
}
