import type { AxiosError, AxiosInstance } from 'axios';
import { handleAxiosError, errorHandler } from '@/api/utils';
import type { CheckoutCustomData, CheckoutTokenResponseData } from '@/types/checkout';
const API_NAME = 'AWS - checkout';

export function getCheckoutToken(
  apiInstance: AxiosInstance,
  basketId: string,
  accessToken: string,
  shopId: string,
  locale: string,
  options: CheckoutCustomData = {},
): Promise<CheckoutTokenResponseData> {
  options.customData ??= {};
  // Needed for member sale login in checkout
  options.customData.basketId = basketId;
  const payload: any = {
    ...options,
    bearer: accessToken,
    shopId,
    basketId,
    locale,
  };
  return errorHandler<CheckoutTokenResponseData>(async () => ({
    data: (await apiInstance.post('/checkouttoken', payload)).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getCheckoutToken',
      payload,
    }),
  }));
}
