import type { CategoryModel } from '@/types/category';
import { categoryModel } from '@/models';
import type { SearchSuggestionResponseData, SearchSuggestionData } from '@/types/searchSuggestions';

function trimHtml(value: string): string {
  return value.replace(/<\/?[^>]+>|[/]/gi, '').toLowerCase();
}

export function searchSuggestionModel(responseData: SearchSuggestionResponseData | null) {
  const response: SearchSuggestionResponseData = responseData ?? {
    data: {
      suggestions: [],
    },
  };

  const categoryList: CategoryModel[] = [];
  const suggestionList: string[] = [];

  function applySuggestionData(suggestion: SearchSuggestionData) {
    if (suggestion.type === 'product') {
      const suggestionValue: string = trimHtml(suggestion.productSuggestion.suggestion);
      if (!suggestionList.includes(suggestionValue)) {
        suggestionList.push(suggestionValue);
      }
      return;
    }
    const category: CategoryModel = categoryModel({
      data: suggestion.brandOrCategorySuggestion.category,
    });
    if (category.getPath().split('/').length <= 5) {
      categoryList.push(category);
    }
  }

  if (response.data?.suggestions !== undefined) {
    response.data.suggestions.forEach(applySuggestionData);
  }

  return {
    hasError(): boolean {
      return response.error !== undefined;
    },

    getSuggestionList(): string[] {
      return suggestionList;
    },

    getCategoryList(): CategoryModel[] {
      return categoryList;
    },

    getMatchingHint(inputValue: string): string {
      if (inputValue === '') {
        return '';
      }
      const hint: string = suggestionList.reduce((shortValue: string, suggestionValue: string) => {
        const matches: boolean = RegExp(`^${inputValue}`, 'gi').test(suggestionValue);
        // filter shortest result matching the input start
        if (matches && (shortValue.length === 0 || shortValue.length > suggestionValue.length)) {
          return suggestionValue;
        }
        return shortValue;
      }, '');

      if (hint === '') {
        return '';
      }
      return inputValue + hint.replace(inputValue.toLowerCase(), '');
    },

    hasBrandOrCategoryTarget(): boolean {
      return response.data?.suggestions.some((item) => item.type === 'brandOrCategory') ?? false;
    },
  };
}
