import { getDate } from '@mop/cms/utils/utils';
import type { PromotionBasedOn, PromotionConfig, PromotionConfigFlag, PromotionModel } from '@/types/promotion';
import type { CustomerGroup } from '@/types/customer';
import type { CartLineItemModel, CartModel } from '@/types/cart';
import type { CmsVisibility } from '@/types/cms';

export function promotionModel(promotionConfig: PromotionConfig | null, customerGroups: CustomerGroup[]) {
  return {
    isInitialized(): boolean {
      return promotionConfig !== null;
    },

    isEnabled(): boolean {
      return promotionConfig?.isActive === true;
    },

    showCallOutMessage(): boolean {
      return promotionConfig?.showCallOutOnPdpPlp === true;
    },

    getBasedOn(): PromotionBasedOn {
      return promotionConfig?.basedOn ?? 'sale';
    },

    isValidCart(cart: CartModel): boolean {
      const qualifyingCartitems = cart.getLineItems().filter((item: CartLineItemModel) => {
        const product = item.getProduct();
        return this.isValidCategory(product.getMopCategoryIds());
      });

      const hasMinOrderValue =
        this.getMinOrderValue() <
        qualifyingCartitems.reduce((acc: number, item: CartLineItemModel) => {
          return acc + item.getTotalPrice().salesPrice;
        }, 0);

      return (
        qualifyingCartitems.length > 0 &&
        hasMinOrderValue &&
        this.isValidTimeFrame(new Date()) &&
        this.isValidCustomerGroup() &&
        cart.getCount(false) > 0
      );
    },

    isValidProduct(mopCategoryIds: string[]) {
      return this.isValidCustomerGroup() && this.isValidTimeFrame(new Date()) && this.isValidCategory(mopCategoryIds);
    },

    getDebugData(): PromotionConfig | null {
      return promotionConfig;
    },

    getMinOrderValue(): number {
      return promotionConfig?.minOrderValue ?? 0;
    },

    getCallOutMessage(): string {
      return promotionConfig?.callOutMessage || '';
    },

    getPromotionKey(): string {
      return promotionConfig?.promotionKey || '';
    },

    isValidTimeFrame(now: Date): boolean {
      const config: PromotionConfig | null = promotionConfig || null;

      if (!config) {
        return false;
      }

      const dateValidFrom: Date = getDate(config.validFrom) || new Date();
      const dateValidTo: Date = getDate(config.validTo) || new Date();

      if (dateValidFrom >= dateValidTo) {
        return false;
      }

      return now > dateValidFrom && now < dateValidTo;
    },

    isValidCategory(mopCategoryIds: string[]): boolean {
      if (!promotionConfig) {
        return false;
      }

      if (promotionConfig.categoryIds.length === 0) {
        return true;
      }

      if (mopCategoryIds.length) {
        return promotionConfig.categoryIds.some((categoryId: string) => mopCategoryIds.includes(categoryId));
      }

      return false;
    },

    getVisibilityGroups(): CmsVisibility[] {
      return promotionConfig?.visibilityGroups || [];
    },

    isValidCustomerGroup(): boolean {
      const visibilityGroups: CmsVisibility[] | undefined = this.getVisibilityGroups();
      if (visibilityGroups.length === 0 || visibilityGroups.includes(constants.STORYBLOK.VISIBILITY_ALL)) {
        return true;
      }
      return customerGroups.some((customerGroup: CustomerGroup) => visibilityGroups.includes(customerGroup));
    },

    getPromotionFlag(): PromotionConfigFlag | undefined {
      return promotionConfig?.flags.find((flag) => {
        const visibilityGroups = flag.customerGroups;
        if (visibilityGroups.length === 0 || visibilityGroups.includes(constants.STORYBLOK.VISIBILITY_ALL)) {
          return true;
        }
        return customerGroups.some((customerGroup: CustomerGroup) => visibilityGroups.includes(customerGroup));
      });
    },

    getGiveawayProductReferenceKey(): string {
      return promotionConfig?.giveawayProduct ?? '';
    },

    getRequiredProductCount(): number {
      return promotionConfig?.requiredProductCount ?? 0;
    },

    getExpirationDate(): number {
      return new Date(promotionConfig?.validTo || new Date()).getTime();
    },
  };
}

export function promotionListModel(cmsPromotionConfigs: PromotionConfig[] | null, customerGroups: CustomerGroup[]) {
  return {
    isInitialized(): boolean {
      return cmsPromotionConfigs !== null;
    },

    getPromotionModelByName(name: string): PromotionModel {
      const promotion: PromotionConfig | undefined = cmsPromotionConfigs?.find(
        (promotionConfig: PromotionConfig) => promotionConfig?.component === name.trim(),
      );
      return promotionModel(promotion || null, customerGroups);
    },

    getPromotionModelList(): PromotionModel[] {
      if (!cmsPromotionConfigs) {
        return [];
      }

      return cmsPromotionConfigs.map((promotionConfig: PromotionConfig) =>
        promotionModel(promotionConfig, customerGroups),
      );
    },
  };
}
