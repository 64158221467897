import { securedWrap } from '@mop/shared/utils/securedWrap';
import { isClient } from '@mop/shared/utils/util';
import { logError } from '@mop/shared/utils/logger';

const MAX_ENTRIES = 30; // approx 10 plp pages

type ClientCacheStorage = {
  count: Ref<number>;
  mgr: Record<string, number>;
  cache: Record<number, string>;
};

export function useClientCache() {
  const storage = initStorage<ClientCacheStorage>('useClientCache');
  const countRef = storage.get('count') || storage.saveAndGet('count', ref(0));
  const cacheMgr = storage.get('mgr') || storage.saveAndGet('mgr', {});
  const cache = storage.get('cache') || storage.saveAndGet('cache', {});

  function createCacheKey(obj: any) {
    return getOrderedString(obj);
  }

  function getOrderedString(object: any): string {
    return Object.keys(object)
      .sort()
      .reduce((str, key) => {
        const value = object[key];
        const valueStr = isObject(value) || Array.isArray(value) ? getOrderedString(value) : String(value);
        return `${str}${key}:${valueStr};`;
      }, '');
  }

  function isObject(value: any) {
    return value && typeof value === 'object' && value.constructor === Object;
  }

  function getCachedValue(key: string) {
    let result: any = null;
    try {
      const cacheEntry = cacheMgr[key];
      if (cacheEntry) {
        result = cache[cacheEntry] ?? null;
      }
    } catch (error: unknown) {
      logError(error);
    }
    return result;
  }

  function storeCachedValue(key: string, value: any) {
    try {
      countRef.value += 1;
      const cacheCount = countRef.value;
      cacheMgr[key] = cacheCount;
      cache[cacheCount] = value;
    } catch (error: unknown) {
      logError(error);
    }
  }

  async function getCachedResponse(payload: any, callback: Function) {
    if (!isClient) {
      return await callback();
    }
    const key = createCacheKey(payload);
    const cachedValue = getCachedValue(key);
    const response = cachedValue || (await callback());
    if (!cachedValue) {
      storeCachedValue(key, response);

      // cleanup of previous entries
      delete cache[countRef.value - MAX_ENTRIES - 1];
    }

    return response;
  }

  return securedWrap({
    getCachedResponse,
  });
}
