<script setup lang="ts">
import { resolveDynamicComponent } from 'vue';

defineOptions({
  name: 'UiSlot',
});

const props = defineProps({
  slotComponentName: {
    type: String,
    required: true,
  },
});

const isComponentAvailable = resolveDynamicComponent(props.slotComponentName) !== props.slotComponentName;
</script>

<template>
  <component :is="slotComponentName" v-if="isComponentAvailable" v-bind="$attrs" />
</template>
