<script setup lang="ts">
import type { CmsUiVideoVimeo, CmsUiVideoVimeoData } from '@mop/cms/types';
import type { CmsUiSliderItem } from './uiCmsSlider';

defineOptions({
  name: 'UiCmsSliderSlide',
});

const props = defineProps({
  data: {
    type: Object as PropType<CmsUiSliderItem>,
    required: true,
  },
  lazyLoad: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['loaded']);

const element: CmsUiSliderItem = props.data;

// Create new objects, because of storyblok live preview component key logic
// => manipulating data would trigger a rerender
const cta = { ...element.cta };
const media = element.media ? { ...element.media } : element.media;

const textStyleRef = ref(`
      --text-style-color: ${element.textColor};
      --text-style-color-mobile: ${element.mobileTextColor}
    `);

function setSliderVideoConfig(videoData: CmsUiVideoVimeoData | undefined) {
  if (!videoData || !videoData.config) {
    return;
  }
  videoData.config.showControls = false;
  videoData.config.loop = true;
  videoData.config.mute = true;
  videoData.config.autoPlay = true;
}

if (media) {
  media.imageType = 'cms-slider';
  if (media.type === 'vimeo') {
    const videoMedia: CmsUiVideoVimeo = media as CmsUiVideoVimeo;
    setSliderVideoConfig(videoMedia.desktop);
    setSliderVideoConfig(videoMedia.mobile);
  }
}

if (cta) {
  cta.type = 'arrowRightSmall';
  cta.align = element.textAlignment;
}
</script>

<template>
  <UiNuxtLink v-bind="element.link" class="ui-cms-slider-slide">
    <div v-if="media" class="ui-cms-slider-slide__background">
      <UiCmsMedia :data="media" :lazy="lazyLoad" @loaded="emit('loaded')" />
    </div>
    <div
      v-storyblok-editable="data"
      :class="[
        'ui-cms-slider-slide__textbox-wrapper',
        `ui-cms-slider-slide__textbox-wrapper--${element.textAlignment}`,
      ]"
      :style="textStyleRef"
    >
      <div class="ui-cms-slider-slide__textbox">
        <UiHeading
          v-if="element.headline.text"
          :class="[
            'ui-cms-slider-slide__textbox-headline',
            {
              'ui-cms-slider-slide__textbox-headline--small': element.headlineSize === 's',
              'ui-cms-slider-slide__textbox-headline--uppercase': element.headline.isUppercase,
            },
          ]"
          :level="element.headline.level || 3"
        >
          {{ element.headline.text }}
        </UiHeading>
        <div v-if="element.subline" class="ui-cms-slider-slide__textbox-subline">
          {{ element.subline }}
        </div>

        <div v-if="element.text" class="ui-cms-slider-slide__textbox-text">
          {{ element.text }}
        </div>
      </div>
      <div v-if="cta" :class="['ui-cms-slider-slide__cta', `ui-cms-slider-slide__cta--${element.textAlignment}`]">
        <UiCmsCta :data="cta" no-padding />
      </div>
    </div>
  </UiNuxtLink>
</template>

<style lang="scss" scoped>
.ui-cms-slider-slide {
  position: relative;
  display: block;
  max-height: 80vh;
  height: 100%;

  :deep(.vimeo-player) {
    height: 100%;
  }

  :deep(.video-player__wrapper) {
    height: 100%;
    padding-top: 0;
  }
}

.ui-cms-slider-slide__textbox-wrapper {
  --color: var(--text-style-color);

  @include z(global, content);

  position: absolute;
  width: 100%;
  margin-bottom: $space20;
  padding-left: $space-16;
  color: var(--color);

  @include apply-upto(small) {
    --color: var(--text-style-color-mobile);

    width: 80%;
  }
}

.ui-mop-slider--bullets {
  .ui-cms-slider-slide__textbox-wrapper {
    margin-bottom: $space20;

    @include apply-upto(small) {
      margin-bottom: $space35;
    }
  }
}

.ui-cms-slider-slide__textbox-wrapper--right {
  right: columns-in-vw(1);
  bottom: 0;
}

.ui-cms-slider-slide__textbox-wrapper--left {
  bottom: 0;
}

.ui-cms-slider-slide__textbox {
  display: flex;
  flex-direction: column;
}

.ui-cms-slider-slide__textbox-subline {
  @include text-style(sub-headline);

  line-height: 14px;
  letter-spacing: 0.3px;
}

.ui-cms-slider-slide__textbox-headline {
  @include font-smoothing(strong);
  @include text-style(headline);

  @include apply-upto(small) {
    font-size: 22px;
    letter-spacing: 0.3px;
    line-height: 22px;
    margin-bottom: 0;
  }

  @include apply-from(medium) {
    font-size: 40px;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 0;
  }
}

.ui-cms-slider-slide__textbox-headline--small {
  @include apply-upto(small) {
    font-size: 20px;
    letter-spacing: 0.05em;
    line-height: 26px;
  }

  @include apply-from(medium) {
    font-size: 27px;
    letter-spacing: 0.05em;
    line-height: 29px;
  }

  @include apply-from(extralarge) {
    font-size: 37px;
    letter-spacing: 0.05em;
    line-height: 39px;
  }
}

.ui-cms-slider-slide__textbox-headline--uppercase {
  text-transform: uppercase;
}

.ui-cms-slider-slide__cta {
  @include z(global, content);

  :deep(.ui-cms-padding) {
    padding: 0;
  }

  :deep(.ui-cms-cta__element) {
    @include link-base;
    @include text-style(strong);

    padding-top: $space15;
    padding-bottom: 0;
    color: currentColor;
  }
}

.ui-cms-slider-slide__cta--center {
  text-align: center;
}

.ui-cms-slider-slide__textbox-text {
  @include text-style(running);

  margin-bottom: $space30;

  @include apply-upto(medium) {
    margin-bottom: $space20;
  }

  @include apply-upto(small) {
    margin-bottom: $space15;
  }
}

.ui-cms-slider-slide__textbox-wrapper--center {
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  margin: 0 auto;

  .ui-cms-slider-slide__textbox-subline,
  .ui-cms-slider-slide__textbox-headline,
  .ui-cms-slider-slide__textbox-text {
    text-align: center;
  }

  .ui-cms-slider-slide__cta {
    position: absolute;
    bottom: $space25;
    margin: auto;
  }
}

/* stylelint-disable max-nesting-depth, selector-max-compound-selectors */
.ui-cms-slider-slide__background {
  max-height: 80vh;
  overflow: hidden;

  :deep(.img),
  :deep(.ui-cms-media-wrapper) {
    height: 80vh;

    .image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
/* stylelint-enable max-nesting-depth, selector-max-compound-selectors */
</style>
