import { logError, logInfo } from '@mop/shared/utils/logger';
import { isClient } from '@mop/shared/utils/util';
import { sessionStorageGet, sessionStorageSet } from '@mop/shared/utils/sessionStorage';
import type { ServerCodeVersionResponseData } from '@/types/version';

export default defineNuxtPlugin((nuxtApp) => {
  // Opted out from app:error logging the errors for now.

  if (isClient) {
    useRouter().onError(performRefresh);

    window.addEventListener(
      'error',
      function (event: any) {
        event.preventDefault();
        event.stopPropagation();
        const isMopScriptError = event.target?.src?.includes(`/_nuxt`) || event.target?.href?.indexOf(`/_nuxt`);
        if (!isMopScriptError) {
          return;
        }

        performRefresh(event);
      },
      true,
    );

    async function performRefresh(event: any) {
      const lastCheckTime: number | undefined =
        parseInt(sessionStorageGet('ServerCodeVersionCheckTime') || '') || undefined;
      if (!lastCheckTime || Date.now() - lastCheckTime > 1800000) {
        sessionStorageSet('ServerCodeVersionCheckTime', String(Date.now()));
        // @ts-ignore
        const serverCodeVersion: ServerCodeVersionResponseData = await nuxtApp.$apiLocal.getServerCodeVersion();

        if (
          serverCodeVersion.error !== undefined ||
          !serverCodeVersion.data?.version ||
          serverCodeVersion.data?.version === String(nuxtApp.$config.public.VERSION)
        ) {
          logError(event);
          // No new server version
          return;
        }

        logInfo(
          `Version mismatch. Server: ${serverCodeVersion.data?.version}. Client: ${nuxtApp.$config.public.VERSION}`,
          true,
        );
        setTimeout(() => {
          location.reload();
        }, 50);
      }
    }
  } else {
    nuxtApp.hook('app:error', (error) => {
      // @ts-ignore
      if (process.logMonitoring) {
        // @ts-ignore
        process.logMonitoring.trackException({
          exception: error,
        });
      }
    });

    nuxtApp.hook('vue:error', (error) => {
      // @ts-ignore
      if (process.logMonitoring) {
        // @ts-ignore
        process.logMonitoring.trackException({
          exception: error,
        });
      }
    });
  }
});
