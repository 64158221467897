import type { AxiosError } from 'axios';
import type { BapiClient } from '@aboutyou/backbone';
import { handleAxiosError, errorHandler } from '@/api/utils';
import { mapProductAttributes, mapVariantAttributes } from '@/models/utils/productUtils';
import type { ApiError } from '@/types/error';
import type {
  ProductsSearchParameter,
  ProductSearchParameter,
  ProductsSearchResponseData,
  ProductDataResponse,
  ProductResponseData,
  Pagination,
  VariantDataResponse,
} from '@/types/product';
const API_NAME = 'AYC - product';

type ProductSearch = {
  entities: ProductDataResponse[];
  pagination: Pagination;
};

export function queryProducts(
  apiInstance: BapiClient,
  params?: ProductsSearchParameter,
): Promise<ProductsSearchResponseData> {
  return errorHandler<ProductsSearchResponseData>(async () => {
    const data: ProductSearch = (await apiInstance.products.query(params)) as unknown as ProductSearch;
    data.entities.forEach((entity: ProductDataResponse) => {
      mapProductAttributes(entity);
      if (entity.siblings) {
        entity.siblings.forEach((sibling: ProductDataResponse) => {
          mapProductAttributes(sibling);
          if (sibling.variants) {
            sibling.variants.forEach((variant: VariantDataResponse) => {
              mapVariantAttributes(variant);
            });
          }
        });
      }
      if (entity.variants) {
        entity.variants.forEach((variant: VariantDataResponse) => {
          mapVariantAttributes(variant);
        });
      }
    });
    return {
      data,
    };
  }).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'queryProducts',
      params,
    }),
  }));
}
export function getProduct(
  apiInstance: BapiClient,
  id: string,
  params?: ProductSearchParameter,
): Promise<ProductResponseData> {
  return errorHandler<ProductResponseData>(async () => {
    const data: ProductDataResponse | null = (await apiInstance.products.getByReferenceKey(
      id,
      params,
    )) as unknown as ProductDataResponse;
    if (!data) {
      return {
        code: 0,
        message: 'PRODUCT_NOT_FOUND',
      } as ApiError;
    }
    mapProductAttributes(data);
    if (data.siblings) {
      data.siblings.forEach((sibling: ProductDataResponse) => {
        mapProductAttributes(sibling);
        if (sibling.variants) {
          sibling.variants.forEach((variant: VariantDataResponse) => {
            mapVariantAttributes(variant);
          });
        }
      });
    }
    if (data.variants) {
      data.variants.forEach((variant: VariantDataResponse) => {
        mapVariantAttributes(variant);
      });
    }

    return {
      data,
    };
  }).catch((error: AxiosError) => {
    return {
      error: handleAxiosError(error, {
        api: API_NAME,
        method: 'getProduct',
        id,
        params,
      }),
    };
  });
}
