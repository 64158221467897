/**
 * Isomorphic cookie storage
 */
// @ts-ignore
import { IncomingMessage } from 'node:http';
import { securedWrap } from '@mop/shared/utils/securedWrap';
import { isClient } from '@mop/shared/utils/util';

export type CookieOptions = {
  sameSite?: string;
  expires?: string | Date;
  path?: string;
  httpOnly?: boolean;
  type?: 'session' | 'permanent';
  domain?: string;
};

export type CookieStorage = {
  get(name: string): string;
  store(name: string, value: string, options?: CookieOptions): void;
  remove(name: string, options?: CookieOptions): void;
  removeByPattern(name: string): void;
};

export default function cookieStorage(req?: IncomingMessage): CookieStorage {
  return securedWrap({
    get(cookieName: string): string {
      const cookieStr: string = isClient ? document.cookie : req?.headers?.cookie || '';

      const cookie: RegExpMatchArray | null = cookieStr?.match(
        '(^|;)\\s*' + encodeURIComponent(cookieName) + '\\s*=\\s*([^;]+)',
      );
      return cookie !== null && cookie.length > 0 ? decodeURIComponent(cookie.pop() || '') : '';
    },

    store(cookieName: string, cookieValue: string, cookieOptions: CookieOptions = {}) {
      if (!isClient) {
        return;
      }

      const expirationDate: Date = new Date();
      // if nothing set, by default 1 month
      expirationDate.setMonth(expirationDate.getMonth() + 1);

      const defaults: CookieOptions = {
        sameSite: 'Lax',
        path: '/',
        expires: expirationDate,
        httpOnly: false,
        type: 'permanent',
        domain: '',
      };
      const options: CookieOptions = {
        ...defaults,
        ...cookieOptions,
      };

      const cookieValueList: string[] = [encodeURIComponent(cookieName) + '=' + encodeURIComponent(cookieValue)];
      if (options.type === 'permanent') {
        if (typeof options.expires === 'string') {
          cookieValueList.push('expires=' + options.expires);
        } else if (options.expires instanceof Date) {
          cookieValueList.push('expires=' + options.expires.toUTCString());
        }
      }

      if (options.path) {
        cookieValueList.push('path=' + options.path);
      }

      if (options.domain) {
        cookieValueList.push('domain=' + options.domain);
      }

      cookieValueList.push('SameSite=' + options.sameSite);

      // due to localhost being not https, removing secure option
      // this will be https everywhere else
      const isHttpsEnabled: boolean = window.location.protocol === 'https:';
      if (isHttpsEnabled) {
        cookieValueList.push('Secure');
      }

      const serializedValue: string = cookieValueList.join(';');
      document.cookie = serializedValue;

      // Removing server response with set-cookie to prevent CDN "sharing" cookies.
    },

    remove(cookieName: string, cookieOptions: CookieOptions = {}) {
      const expirationDate: Date = new Date();
      expirationDate.setMonth(expirationDate.getMonth() - 1);

      this.store(cookieName, '', {
        expires: expirationDate,
        ...cookieOptions,
      });
    },

    removeByPattern(cookiePattern: string) {
      if (!isClient) {
        return;
      }
      const regExpPattern: string = '(^|;)\\s*(' + cookiePattern + ')(?==)';
      const regExp = new RegExp(regExpPattern, 'g');
      let match: string[] | null;
      do {
        match = regExp.exec(document.cookie);
        if (match && match[2]) {
          this.remove(match[2]);
        }
      } while (match);
    },
  });
}
