<script setup lang="ts">
import { localStorageGet, localStorageSet, localStorageRemove } from '@mop/shared/utils/localStorage';
import type { NavigationItem } from '@mop/ui/types/uiComponents';
import type { Timer } from '@mop/types';
import SVGSearch from '@mop/shared/images/misc/search.svg?component';
import type { CategoryModel } from '@/types/category';
import type { ProductModel } from '@/types/product';

defineOptions({
  name: 'MopHeaderSearchSuggestionsOverlay',
});

const STORAGE_KEY = 'searchedTerms';
const STORAGE_LIMIT = 5;
const { $mopConfig, $mopI18n, $urls } = useNuxtApp();
const { searchProducts, productModelListRef, paginationModelRef } = useMopProducts('search-suggestions');
const { searchSuggestions, searchSuggestionModelRef } = useMopSearchSuggestionClient();
const route = useRoute();
const router = useRouter();
const { activeCategoryPathRef } = useMopRouter();
const { isFreeShippingEnabled, isSaleEnabled, isGiveawayEnabled } = useMopPromotions();
const { reportEngagement } = useMopTrackingClient();
const { getCategoryByPath, getCategoriesInPath } = useMopCategoryTree();
const SEARCH_DELAY = 300;
const PRODUCT_LIMIT = 4;
const CATEGORY_LIMIT = 4;
const SUGGESTION_LIMIT = 4;
const inputField = ref<HTMLElement>();
const navigationItemsRef = ref<NavigationItem[]>([]);
const suggestionListRef = ref<string[]>([]);
const productListRef = ref<ProductModel[]>([]);
const searchInputRef = ref('');
const hintInputRef = ref('');
const lastSearchCategoryUrlRef = ref('');
const hasResultRef = ref(false);
const resetValues = () => {
  navigationItemsRef.value = [];
  suggestionListRef.value = [];
  productListRef.value = [];
  hintInputRef.value = '';
};
const path = route.params.path ?? '';
const category: CategoryModel = getCategoryByPath(path as string);
const searchTermRef = ref(getSearchTermList());
let searchTimer: Timer;

function isValidMinLength() {
  return searchInputRef.value.length > 2;
}

function productSearch(searchTerm: string, categoryId?: number) {
  const searchParams = {
    ...requestParameters.PRODUCT_TILE,
    where: {
      categoryId,
      term: searchTerm,
    },
    pagination: {
      perPage: PRODUCT_LIMIT,
      page: 1,
    },
  };
  return searchProducts(searchParams, { forceClientRefetch: true });
}

function getCategories() {
  const topCategory = activeCategoryPathRef.value[0];
  const categoryList = searchSuggestionModelRef.value.getCategoryList();
  // try to fill in categories for the chosen segment (women, men, etc..)
  let categories: CategoryModel[] = [];
  if (topCategory !== undefined) {
    // segment based on selected top level category
    categories = categoryList.filter((category: CategoryModel) =>
      RegExp(`^${topCategory.getPath()}`).test(category.getPath()),
    );
  }

  if (categories.length >= CATEGORY_LIMIT) {
    categories = categories.slice(0, CATEGORY_LIMIT);
  }
  // fill in the remaining spots upto a limit
  for (const category of categoryList) {
    if (categories.length === CATEGORY_LIMIT) {
      break;
    }
    // ensure no duplicates
    const categoryExists = categories.some(
      (resultCategory: CategoryModel) => resultCategory.getId() === category.getId(),
    );
    if (categoryExists) {
      continue;
    }
    categories.push(category);
  }

  return categories.map((category): NavigationItem => {
    return {
      id: category.getId(),
      url: `${$mopI18n.localePath(category.getUrl())}#list=Searchresult_category`,
      name: generateCategoryName(category),
    };
  });
}

function generateCategoryName(category: CategoryModel) {
  const categoryTree: CategoryModel[] = getCategoriesInPath(category.getPath());
  if (categoryTree.length < 2) {
    return `${category.getName($mopConfig)}`;
  }
  if (categoryTree.length > 2 && categoryTree[1].includeNameInSearchSuggest()) {
    return `${categoryTree[0].getName($mopConfig)}
          ${categoryTree[1].getName($mopConfig)}
          ${category.getName($mopConfig)}`;
  }
  return `${categoryTree[0].getName($mopConfig)} ${category.getName($mopConfig)}`;
}

onMounted(() => {
  inputField.value?.focus();
});

async function handleSearchSubmit() {
  if (!isValidMinLength()) {
    return;
  }
  handleAddSearchTerm();
  handleSearchClick('show_all_button');
  // redirecting to root search
  await useMopOverlay().closeAll();
  const searchQuery: string = hintInputRef.value || searchInputRef.value;
  if (lastSearchCategoryUrlRef.value === '') {
    router.push({
      path: $mopI18n.localePath($urls.SEARCH),
      query: {
        q: searchQuery,
      },
    });
    return;
  }
  router.push({
    path: $mopI18n.localePath(lastSearchCategoryUrlRef.value),
    query: {
      q: searchQuery,
    },
  });
}

watch(searchInputRef, (value: string) => {
  clearTimeout(searchTimer);
  hintInputRef.value = '';
  hasResultRef.value = true;
  if (!isValidMinLength()) {
    resetValues();
    return;
  }

  const topCategory = activeCategoryPathRef.value[0];
  const topCategoryId: number | undefined = topCategory ? topCategory.getId() : undefined;
  lastSearchCategoryUrlRef.value = topCategory ? topCategory.getUrl() : '';
  searchTimer = setTimeout(async () => {
    let searchResultsTrackingTarget = 'no_results';

    await Promise.all([
      searchSuggestions(value, {
        term: value,
        productLimit: PRODUCT_LIMIT,
      }),
      productSearch(value, topCategoryId),
    ]);

    // retry on root search in case no results
    if (topCategoryId !== undefined && productModelListRef.value.getProductModelList().length === 0) {
      lastSearchCategoryUrlRef.value = '';
      await productSearch(value);
    }
    hasResultRef.value = navigationItemsRef.value.length > 0 || productListRef.value.length > 0;

    if (hasResultRef.value) {
      const isTargetBrandOrCategory = searchSuggestionModelRef.value.hasBrandOrCategoryTarget();
      const isTargetProduct = productListRef.value.length > 0;

      if (isTargetBrandOrCategory && !isTargetProduct) {
        searchResultsTrackingTarget = 'category_results';
      } else if (!isTargetBrandOrCategory && isTargetProduct) {
        searchResultsTrackingTarget = 'product_results';
      } else {
        searchResultsTrackingTarget = 'category_results product_results';
      }
    }

    reportEngagement({
      gtm: {
        type: 'Click',
        data: {
          custom: {
            event: 'site_search_results',
            category: searchResultsTrackingTarget,
            label: value,
          },
        },
      },
    });
  }, SEARCH_DELAY);
});

// Search suggestion category list and did you mean handling
watch(searchSuggestionModelRef, () => {
  if (!isValidMinLength()) {
    resetValues();
    return;
  }

  hintInputRef.value = searchSuggestionModelRef.value.getMatchingHint(searchInputRef.value);
  navigationItemsRef.value = getCategories();
  suggestionListRef.value = searchSuggestionModelRef.value
    .getSuggestionList()
    .filter((suggestion) => suggestion !== searchInputRef.value.toLowerCase())
    .slice(0, SUGGESTION_LIMIT);
});

// Search products handling
watch(productModelListRef, () => {
  if (!isValidMinLength()) {
    resetValues();
    return;
  }
  productListRef.value = productModelListRef.value.getProductModelList();
});

// Search term handling
function getSearchTermList() {
  try {
    const terms: string | null = localStorageGet(STORAGE_KEY);
    if (terms === null) {
      return [];
    }
    return JSON.parse(terms);
  } catch (error) {
    return [];
  }
}

function handleSearchClick(targetCategory: string, path?: string) {
  reportEngagement({
    gtm: {
      type: 'Click',
      data: {
        custom: {
          event: 'site_search_click',
          category: targetCategory,
          label: searchInputRef.value,
          // @ts-ignore
          value: `${document.location.origin}${path ? $mopI18n.localePath(path) : document.location.pathname}`,
        },
      },
    },
  });
}

function handleClearAllSearchTerms() {
  try {
    localStorageRemove(STORAGE_KEY);
    searchTermRef.value = getSearchTermList();
  } catch (error) {
    // do nothing
  }
}

function handleAddSearchTerm() {
  const searchValue: string = searchInputRef.value.trim();
  if (searchValue.length === 0) {
    return;
  }
  const termList: string[] = getSearchTermList();
  if (termList.length === 0) {
    searchTermRef.value = [searchValue];
    localStorageSet(STORAGE_KEY, JSON.stringify(searchTermRef.value));
    return;
  }
  try {
    if (termList.includes(searchValue)) {
      return;
    }
    searchTermRef.value = [searchValue, ...termList].splice(0, STORAGE_LIMIT);
    localStorageSet(STORAGE_KEY, JSON.stringify(searchTermRef.value));
  } catch (error) {
    // do nothing
  }
}

function onSearchInputKeydown(keyDownEvent: KeyboardEvent) {
  if (keyDownEvent.code === 'Escape') {
    searchInputRef.value = '';
  } else if (keyDownEvent.code === 'Tab') {
    keyDownEvent.preventDefault();
    searchInputRef.value = hintInputRef.value;
  }
}
</script>

<template>
  <div class="search-suggest">
    <form action="#" class="search-suggest__form" @submit.prevent="handleSearchSubmit">
      <button type="submit" class="search-suggest__button">
        <SVGSearch width="30" height="19" />
      </button>
      <input
        v-model="hintInputRef"
        type="text"
        autocomplete="off"
        spellcheck="false"
        readonly="true"
        class="search-suggest__hint-input"
      />
      <input
        ref="inputField"
        v-model="searchInputRef"
        class="search-suggest__search-input"
        autocomplete="off"
        spellcheck="false"
        :placeholder="$mopI18n.t('components.suggest_overlay.search_placeholder.short')"
        @blur="handleAddSearchTerm"
        @keydown="onSearchInputKeydown"
      />
    </form>
    <div v-if="!searchInputRef && searchTermRef.length" class="search-suggest__searched-terms">
      <span v-for="(searchTerm, index) in searchTermRef" :key="index" class="search-suggest__searched-term-wrap">
        <a class="search-suggest__searched-term" href="#" @click.prevent="searchInputRef = searchTerm">
          {{ searchTerm }}
        </a>
      </span>
      <span class="search-suggest__searched-term-wrap search-suggest__searched-term-wrap--transparent">
        <button class="search-suggest__searched-terms-clear" @click="handleClearAllSearchTerms">
          {{ $mopI18n.t('components.suggest_overlay.result.clear') }}
        </button>
      </span>
    </div>
    <div class="search-suggest__scroll">
      <div v-if="navigationItemsRef.length > 0 || productListRef.length > 0" class="search-suggest__result">
        <div
          v-if="navigationItemsRef.length > 0 || paginationModelRef.getTotal() > 0"
          class="search-suggest__container"
        >
          <div v-if="paginationModelRef.getTotal() > 0" class="search-suggest__search-count">
            {{ $mopI18n.t(`components.plp.filter_count`, [paginationModelRef.getTotal()]) }}
          </div>

          <UiScrollNavigation
            :class="[
              {
                'search-suggest__suggestions-only': paginationModelRef.getTotal() === 0,
              },
            ]"
            :navigation-items="navigationItemsRef"
            @item-click="handleSearchClick('category_result')"
          />
        </div>
        <div v-if="productListRef.length > 0" class="search-suggest__container">
          <div class="search-suggest__products">
            <div class="search-suggest__product-list">
              <MopProductTile
                v-for="(product, index) in productListRef"
                :key="product.getMopId()"
                :product="product"
                :position="index"
                :is-free-shipping-enabled="isFreeShippingEnabled(undefined, [category.getMopId()])"
                :is-sale-enabled="isSaleEnabled()"
                :is-giveaway-enabled="isGiveawayEnabled(undefined, [category.getMopId()])"
                :gtm-search-term="searchInputRef"
                gtm-list-type="Searchresult_suggestions"
                image-type="searchsuggest"
                class="search-suggest__product"
                @click="handleSearchClick('product_result', product.getUrl())"
              />
            </div>
            <div class="search-suggest__show-all">
              <button class="button button--primary" @click="handleSearchSubmit">
                {{ $mopI18n.t('components.suggest_overlay.result.more') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="isValidMinLength() && !hasResultRef" class="search-suggest__no-result">
        <div class="search-suggest__no-result-info">
          <div class="search-suggest__no-result-title">
            {{ $mopI18n.t(`components.suggest_overlay.no_result.title`, [searchInputRef.toUpperCase()]) }}
          </div>
          <div v-if="suggestionListRef.length > 0" class="search-suggest__suggestions">
            <div class="search-suggest__suggestions-title">
              {{ $mopI18n.t('components.suggest_overlay.suggestions-title') }}
            </div>
            <div class="search-suggest__suggestions-list">
              <a
                v-for="suggestion in suggestionListRef"
                :key="suggestion"
                href="#"
                class="search-suggest__suggestions-link"
                @click.prevent="searchInputRef = suggestion"
              >
                {{ suggestion }}
              </a>
            </div>
          </div>
        </div>
        <MopRecommendation
          class="search-suggest__recommendation"
          widget="OTHER"
          disable-loading
          section="SEARCH_OVERLAY_NO_RESULT"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search-suggest__product-list {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, minmax(calc(100% / 4 - #{$space10}), 1fr));
  column-gap: $space10;
  row-gap: $space30;

  @include apply-upto(medium) {
    grid-template-columns: repeat(2, minmax(calc(100% / 2 - #{$space10}), 1fr));
  }
}

.search-suggest__product {
  display: none;

  @include apply-from(large) {
    &:nth-of-type(-n + 4) {
      display: block;
    }
  }

  @include apply-upto(medium) {
    &:nth-of-type(-n + 2) {
      display: block;
    }
  }
}

.search-suggest__products {
  width: 100%;

  @include apply-upto(small) {
    border-top: 1px solid $middle-grey;
  }
}

.search-suggest__form {
  display: flex;
  width: columns(36);
  padding: $space5 0;
  border-bottom: 1px solid $black;

  @include apply-upto(medium) {
    padding: $space2 $global-padding;
  }
}

.search-suggest__button {
  border: 0;
  background: transparent;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @include apply-upto(medium) {
    width: 22px;
  }
}

.search-suggest__search-input,
.search-suggest__hint-input {
  @include text-style(headline-condensed);

  position: absolute;
  right: $global-padding;
  left: 46px; // 30px space between search icon and input, 16px width of icon
  height: 40px;
  margin: 0;
  padding: $space10 0;
  border: 0;
  text-transform: uppercase;

  @include apply-upto(medium) {
    font-size: 16px; // prevent zoom in Search input for iOS
    width: calc(#{columns(32)} - 36px);
  }
}

.search-suggest__search-input {
  background: transparent;
}

.search-suggest__search-input::-ms-clear,
.search-suggest__hint-input::-ms-clear {
  display: none;
}

.search-suggest__search-input::placeholder,
.search-suggest__hint-input {
  color: $dark-grey;
  opacity: 1;
}

.search-suggest__search-input:focus {
  outline: none;
}

.search-suggest__result {
  padding: 0 $global-padding;
  display: flex;
  flex-direction: column;

  @include apply-upto(small) {
    padding: 0;
  }
}

.search-suggest__container {
  padding: $space20 0 0;
  display: flex;

  @include apply-upto(small) {
    flex-direction: column;
    padding: $space20 $global-padding;

    &:nth-of-type(2) {
      padding: 0 $global-padding;
    }
  }
}

.search-suggest__search-count {
  @include text-style(small);

  align-self: center;
  width: columns(2, 32);

  @include apply-upto(large) {
    width: columns(3, 32);
    margin-right: 0;
  }

  @include apply-upto(medium) {
    width: columns(5, 32);
    margin-right: 0;
  }

  @include apply-upto(small) {
    width: columns(32, 32);
    margin-right: 0;
  }
}

.search-suggest__suggestions-only {
  margin: 0;
}

.search-suggest__show-all {
  margin: $space70 0 $space40;

  @include apply-only(large) {
    margin: $space40 0 $space30;
  }

  @include apply-only(medium) {
    margin: $space30 0;
  }

  @include apply-upto(small) {
    margin: $space15 0 $space10;
  }
}

.search-suggest__no-result {
  padding: 0;
  flex-direction: column;
}

.search-suggest__no-result-info {
  padding: $space30 $global-padding $space30 46px; // aligning with search text, 16px padding + 16px icon

  @include apply-upto(large) {
    padding: $space30 $global-padding;
  }

  @include apply-upto(small) {
    padding: $space20 $global-padding;
  }
}

.search-suggest__suggestions {
  margin: $space5 0;
  overflow: hidden;
}

.search-suggest__suggestions-title {
  padding: $space10 0;
  text-decoration: underline;
  text-underline-offset: $space2;
}

.search-suggest__suggestions-list {
  align-items: center;
}

.search-suggest__suggestions-link {
  @include text-style(strong);

  display: block;
  text-transform: uppercase;
  padding: $space5 0;

  @include hover {
    background: $light-grey;
    cursor: pointer;
  }
}

.search-suggest__searched-terms {
  width: 100%;
  padding: $space20 0 $space20 46px; // aligning with search text, 16px padding + 16px icon

  @include apply-upto(medium) {
    padding: $space20 0 $space20 $global-padding;
  }
}

.search-suggest__searched-term-wrap {
  display: flex;
  align-items: center;
  padding: $space5 0 $space5;

  @include apply-upto(medium) {
    padding: $space5 0;
  }

  @include hover {
    background: $light-grey;
    cursor: pointer;
  }
}

.search-suggest__searched-term-wrap--transparent {
  border: 0;
  background: transparent;
  margin-top: $space10;

  @include hover {
    background: transparent;
  }
}

.search-suggest__searched-term {
  @include text-style(strong);

  width: columns(7);
  margin-right: columns(1);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: uppercase;

  @include apply-only(large) {
    width: columns(12);
  }

  @include apply-only(medium) {
    width: columns(14);
  }

  @include apply-upto(small) {
    width: columns(34);
  }
}

.search-suggest__searched-terms-clear {
  @include link-underlined();
  @include text-style(common);
}

.search-suggest__recommendation {
  @include recommendation;

  padding: 0 $global-padding;
}

.search-suggest__recommendation--visible {
  margin: $space30 0;
}

.search-suggest__scroll {
  overflow-y: auto;
  max-height: calc(100vh - (#{$sticky-big-header-height} + #{$search-form-height}));
  max-height: calc(var(--vh) - (#{$sticky-big-header-height} + #{$search-form-height}));

  @include apply-upto(medium) {
    max-height: calc(100vh - (#{$sticky-small-header-height} + #{$search-form-height-small}));
    max-height: calc(var(--vh) - (#{$sticky-small-header-height} + #{$search-form-height-small}));
  }

  .wrapper--super-banner & {
    max-height: calc(100vh - (#{$sticky-big-header-height} + #{$search-form-height} + #{$superbanner-height}));
    max-height: calc(var(--vh) - (#{$sticky-big-header-height} + #{$search-form-height} + #{$superbanner-height}));

    @include apply-upto(medium) {
      max-height: calc(
        100vh - (#{$sticky-small-header-height} + #{$search-form-height-small} + #{$superbanner-height})
      );
      max-height: calc(
        var(--vh) - (#{$sticky-small-header-height} + #{$search-form-height-small} + #{$superbanner-height})
      );
    }
  }
}
</style>
