<script setup lang="ts">
import { stripHtmlTagsFromString, scrollToAnchor } from '@mop/shared/utils/util';
import { getElementUiObject, getHtmlFromRichText } from '@mop/cms/utils/utils';
import type { MopCmsAccordionSearchable, MopCmsAccordionGroup, MopCmsAccordionItem } from './AccordionSearchable';
import { cmsContentElementListModel } from '@/models/cms/cmsContentElementModel';
import type { CmsContentElementModel } from '@/types/cms';

defineOptions({
  name: 'MopCmsAccordionSearchable',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const data = props.data;
const route = useRoute();

const element: MopCmsAccordionSearchable = getElementUiObject(data, {
  headline: data.headline,
  text: data.text,
  closeButtonLabel: data.closeButtonLabel,
  closeButtonIsVisible: data.closeButtonIsVisible && data.closeButtonLabel,
  searchInputIsVisible: data.searchInputIsVisible,
  groups: data.groups.map((group: any) => {
    return getElementUiObject(group, {
      headline: group.headline,
      anchor: group.anchor,
      isVisible: true,
      items: group.items.map((item: any) => {
        return getElementUiObject(item, {
          headline: item.headline,
          anchor: item.anchor,
          richText: getHtmlFromRichText(item.richText),
          isVisible: true,
        } as MopCmsAccordionItem);
      }),
    } as MopCmsAccordionGroup);
  }),
});

const searchTermRef = ref('');
const searchTermLowercased = computed(() => searchTermRef.value.toLowerCase());
const activeAccordionTitleRef = ref<string[]>([]);
const isGroupShownRef = ref(false);
const filteredGroupsRef = ref<MopCmsAccordionGroup[]>(element.groups);

async function injectSizeTablesToSizesGroup() {
  const { getCmsStoryList, cmsStoryListModelRef } = useMopCms(route.path);
  await getCmsStoryList(constants.STORYBLOK.STORY_PATHS.SIZE_TABLES);

  const sizesGroup = filteredGroupsRef.value.find((group) => group.anchor === 'sizes')!;
  sizesGroup.items.push(
    ...cmsStoryListModelRef.value!.getStoryModelList().map((item: any) => {
      const content = item.getResponse().data.content;
      const headline = content.table[0].tableCaption ?? '';
      const _uid = headline.replace(/[^a-zA-Z]/g, '');
      const contentElements: CmsContentElementModel[] = [];
      contentElements.push(...cmsContentElementListModel({ data: content.table }).getCmsContentElementModelList());
      contentElements.push(
        ...cmsContentElementListModel({ data: content.measureDetails }).getCmsContentElementModelList(),
      );

      return {
        _editable: undefined,
        _uid,
        headline,
        anchor: _uid,
        contentElements,
        isVisible: sizesGroup.isVisible,
      } as any;
    }),
  );
}

function hideAllGroups(): void {
  showSingleGroup();
}

function showSingleGroup(uid?: string): void {
  isGroupShownRef.value = false;
  filteredGroupsRef.value.forEach((group) => {
    group.isVisible = group._uid === uid;
    group.items.forEach((item) => {
      item.isVisible = group.isVisible;
    });
    if (group.isVisible) {
      isGroupShownRef.value = true;
    }
  });
}

watch(searchTermLowercased, (newSearchTerm: string) => {
  // To not trigger this watcher if search input gets cleared via code.
  if (isGroupShownRef.value && !newSearchTerm) {
    return;
  }

  if (!newSearchTerm) {
    isGroupShownRef.value = true;
    return;
  }

  isGroupShownRef.value = false;
  activeAccordionTitleRef.value = [];

  filteredGroupsRef.value.forEach((group) => {
    group.isVisible = false;

    group.items.forEach((item) => {
      if (newSearchTerm) {
        item.isVisible = stripHtmlTagsFromString(item.headline + item.richText)
          .toLowerCase()
          .includes(newSearchTerm);
      } else {
        item.isVisible = false;
      }

      if (item.isVisible) {
        group.isVisible = true;
      }
    });
  });
});

useAsyncData(async () => {
  await injectSizeTablesToSizesGroup();
});

onMounted(() => {
  watch(
    () => route.hash,
    (newHash) => {
      const anchor = newHash.substring(1);
      if (!anchor && filteredGroupsRef.value.length > 1) {
        hideAllGroups();
        return;
      }
      let foundGroupUid = '';
      let foundItem: MopCmsAccordionItem;
      filteredGroupsRef.value.some((group) => {
        const isGroupTargeted = group.anchor === anchor;

        if (!isGroupTargeted && !group.items.find((item) => item.anchor === anchor)) {
          return false;
        }
        foundItem = group.items.find((item) => item.anchor === anchor)!;
        foundGroupUid = group._uid;
        return true;
      });

      if (!foundGroupUid) {
        return;
      }
      searchTermRef.value = '';
      showSingleGroup(foundGroupUid);
      if (foundItem! && !activeAccordionTitleRef.value.includes(foundItem.headline)) {
        activeAccordionTitleRef.value.push(foundItem.headline);
      }
      scrollToAnchor(newHash);
    },
    { immediate: true },
  );
});
</script>

<template>
  <div v-storyblok-editable="element" class="ui-cms-accordion-searchable">
    <div class="ui-cms-accordion-searchable__content">
      <h3 class="ui-cms-accordion-searchable__headline">
        {{ element.headline }}
      </h3>
      <p>{{ element.text }}</p>
      <div class="ui-cms-accordion-searchable__search-form">
        <MopSearchForm v-show="element.searchInputIsVisible" v-model="searchTermRef" is-small />

        <button
          v-show="isGroupShownRef && element.closeButtonIsVisible"
          class="ui-cms-accordion-searchable__close-button"
          @click="hideAllGroups"
        >
          <i class="icon icon--arrow-next arrow-icon--rotate ui-cms-accordion-searchable__close-button-icon" />
          {{ element.closeButtonLabel }}
        </button>
      </div>
    </div>
    <div
      v-for="group in filteredGroupsRef"
      v-show="group.isVisible"
      :key="group._uid"
      v-storyblok-editable="group"
      class="ui-cms-accordion-searchable__group"
    >
      <span :id="group.anchor" class="ui-cms-accordion-searchable__anchor" />
      <div v-show="!searchTermRef" class="ui-cms-accordion-searchable__content">
        <h3 class="ui-cms-accordion-searchable__headline">
          {{ group.headline }}
        </h3>
      </div>

      <UiAccordion
        v-for="(item, index) in group.items"
        v-show="item.isVisible"
        :key="`${item._uid}-${index}`"
        v-storyblok-editable="item"
        :anchor="item.anchor"
        :active-title="activeAccordionTitleRef"
        :is-last="index === group.items.length - 1"
        :title="item.headline"
        @pick-menu="activeAccordionTitleRef = $event"
      >
        <div v-if="item.richText" class="ui-cms-accordion-searchable__content" v-html="item.richText" />
        <MopCmsContentElementsSlot
          v-if="item.contentElements"
          :id="item._uid"
          class="ui-cms-accordion-searchable__content ui-cms-accordion-searchable__content--element"
          :elements="item.contentElements"
          no-padding
          lazy-load
        />
      </UiAccordion>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ui-cms-accordion-searchable__anchor {
  position: absolute;
  top: -$sticky-big-header-height;

  @include apply-upto(small) {
    top: -$sticky-small-header-height;
  }
}

.ui-cms-accordion-searchable__content {
  padding: 0 $global-padding;
  // For anchor positioning.
  position: relative;
}

.ui-cms-accordion-searchable__content--element {
  padding-bottom: $space30;
  padding-top: $space20;
}

.ui-cms-accordion-searchable__headline {
  @include text-style(headline);
}

.ui-cms-accordion-searchable__search-form {
  margin: $space30 0;
}

.ui-cms-accordion-searchable__close-button {
  @include link-not-underlined;

  margin-top: $space30;

  @include apply-upto(small) {
    @include link-not-underlined($space5);
  }
}

.ui-cms-accordion-searchable__close-button-icon {
  margin-right: $space15;
}

.ui-cms-accordion-searchable__group {
  overflow: hidden;
  // For anchor positioning.
  position: relative;
}
</style>
