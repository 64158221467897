import { securedWrap } from '@mop/shared/utils/securedWrap';
import type { CmsStoryModel } from '@/types/cms';

type SuperBannerStorage = {
  isOpen: Ref<boolean>;
  story: Ref<CmsStoryModel | null>;
};

export default function useMopSuperBannerClient() {
  const overlay = useMopOverlay();
  const storage = initStorage<SuperBannerStorage>('useSuperBanner');
  const isOpenRef = storage.get('isOpen') ?? storage.saveAndGet('isOpen', ref(false));
  const { cmsGlobalStoryListModelRef } = useMopCmsGlobalComponents();
  const cmsSuperBannerStoryModelRef =
    storage.get('story') ??
    storage.saveAndGet(
      'story',
      ref(
        cmsGlobalStoryListModelRef.value.getStoryModelByName(constants.STORYBLOK.GLOBAL_STORY_NAMES.SUPER_BANNER) ??
          null,
      ),
    );

  async function openSuperBannerOverlay(callbackFn?: Function) {
    isOpenRef.value = true;
    await overlay.open({
      type: 'top',
      componentName: 'MopSuperBannerOverlay',
      urlHash: OVERLAYS.SUPER_BANNER.URL_HASH,
      overrideParams: {
        lockScroll: false,
        showClose: false,
      },
      onClose: () => {
        isOpenRef.value = false;
        callbackFn && callbackFn();
      },
    });
  }

  async function closeSuperBannerOverlay() {
    await overlay.closeAll();
  }

  return securedWrap({
    openSuperBannerOverlay,
    closeSuperBannerOverlay,
    cmsSuperBannerStoryModelRef,
    isOpenRef,
  });
}
