<script setup lang="ts">
import { ref, computed } from 'vue';
import type { CmsMedia } from '@mop/cms/types';
import Ui2EcomPopularityFlag from '../Ui2EcomPopularityFlag/Ui2EcomPopularityFlag.vue';
import Ui2EcomCallout from '../Ui2EcomCallout/Ui2EcomCallout.vue';
import Ui2EcomPrice from '../Ui2EcomPrice/Ui2EcomPrice.vue';
import Ui2Image from '../Ui2Image/Ui2Image.vue';
import type { UiProductSwatch, UiPrice, UiEcomCalloutAppearances } from '@mop/ui2/types';
import { isNuxtContext, isTouchDevice } from '@mop/ui2/utils/utils';
import useUi2Config from '@mop/ui2/composables/useUi2Config';
const { cdnProductImageUrl } = useUi2Config();

defineOptions({
  name: 'Ui2EcomProductCard',
});

const emit = defineEmits([
  'click-wishlist',
  'mouse-enter',
  'mouse-leave',
  'swatch-mouse-enter',
  'swatch-mouse-leave',
  'click',
]);

const props = defineProps({
  price: {
    type: Object as PropType<UiPrice | undefined>,
    required: true,
  },
  title: {
    type: String,
    default: '',
  },
  url: {
    type: String,
    required: true,
  },
  imageType: {
    type: String,
    default: 'product-tile',
  },
  imageSrc: {
    type: String,
    default: '',
  },
  disableImageHoverChange: {
    type: Boolean,
    default: false,
  },
  imageHoverSrc: {
    type: String,
    default: '',
  },
  isHover: {
    type: Boolean,
    default: false,
  },
  isSwatchHover: {
    type: Boolean,
    default: false,
  },
  hasSustainableFlag: {
    type: Boolean,
    default: false,
  },
  isInWishlist: {
    type: null as unknown as PropType<boolean | null>,
    default: false,
  },
  isDenim: {
    type: Boolean,
    default: false,
  },
  newFlag: {
    type: String,
    default: '',
  },
  popularityFlag: {
    type: String,
    default: '',
  },
  calloutMessage: {
    type: String,
    default: '',
  },
  calloutMessageAppearance: {
    type: String as PropType<UiEcomCalloutAppearances>,
    default: '',
  },
  customFlag: {
    type: String,
    default: '',
  },
  hideText: {
    type: Boolean,
    default: false,
  },
  wishlistIcon: {
    type: String,
    default: 'heart',
  },
  swatches: {
    type: Array as PropType<UiProductSwatch[]>,
    default: () => [],
  },
  media: {
    type: Object as PropType<CmsMedia>,
    default: () => null,
  },
});

const isHoverRef = ref(props.isHover);
const isSwatchHoverRef = ref(props.isSwatchHover);

function handleMouseEnter() {
  emit('mouse-enter');
  isHoverRef.value = true;
}

function handleMouseLeave() {
  emit('mouse-leave');
  isHoverRef.value = false;
}

const swatchImageHoverSrcItemsRef = ref<string[]>([]);
const activeSwatchRef = computed(() => {
  return props.swatches.find((swatch) => {
    return !swatchImageHoverSrcItemsRef.value.length
      ? swatch.url === props.url
      : swatch.imageSrc === swatchImageHoverSrcItemsRef.value[swatchImageHoverSrcItemsRef.value.length - 1];
  });
});

function handleSwatchMouseEnter(swatch: UiProductSwatch) {
  emit('swatch-mouse-enter', swatch);
  const newImageSrcHover = swatch.imageSrc;
  const imageHoverScrs = swatchImageHoverSrcItemsRef.value.filter((src) => src !== newImageSrcHover);
  imageHoverScrs.push(newImageSrcHover);
  swatchImageHoverSrcItemsRef.value = imageHoverScrs;
  isSwatchHoverRef.value = true;
}

function handleSwatchMouseLeave() {
  emit('swatch-mouse-leave');
  swatchImageHoverSrcItemsRef.value = [];
  isSwatchHoverRef.value = false;
}

const uiIsInishlistRef: Ref<boolean | null> = ref(null);
function handleWishlistButtonClick() {
  uiIsInishlistRef.value = !props.isInWishlist;
  emit('click-wishlist');
}

function handleProductClick(event: MouseEvent, url: string) {
  emit('click', event, url);
}

const isLazy = isNuxtContext;
const MAX_SWATCHES_LENGTH = 5;
const WISHLIST_ICON_SIZE = 16;
</script>

<template>
  <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions vuejs-accessibility/mouse-events-have-key-events -->
  <div
    :class="[
      'product-tile',
      {
        'product-tile--image-hover': imageHoverSrc && isHoverRef,
        'product-tile--swatch-image-hover': swatchImageHoverSrcItemsRef.length && isSwatchHoverRef,
        'product-tile--sustainable': hasSustainableFlag,
      },
    ]"
    @mouseenter="() => !isTouchDevice() && handleMouseEnter()"
    @mouseleave="() => !isTouchDevice() && handleMouseLeave()"
  >
    <div class="product-tile__image-wrap">
      <Ui2EcomPopularityFlag v-if="popularityFlag" class="product-tile__popularity-flag" :text="popularityFlag" />
      <div v-if="isDenim" class="product-tile__logo">
        <Ui2Icon name="logo-mopd" color="ui-color-theme-denim-0" :width="24" :height="24" />
      </div>
      <ClientOnly>
        <div
          v-if="swatches && swatches.length > 0"
          aria-hidden="true"
          :class="[
            'product-tile__overlay',
            {
              'product-tile__overlay--visible': isHoverRef,
            },
          ]"
        >
          <div class="product-tile__swatches-wrapper">
            <!-- eslint-disable-next-line vuejs-accessibility/mouse-events-have-key-events vuejs-accessibility/no-static-element-interactions -->
            <div class="product-tile__swatches" @mouseleave.stop.prevent="handleSwatchMouseLeave">
              <!-- eslint-disable-next-line vuejs-accessibility/mouse-events-have-key-events vuejs-accessibility/anchor-has-content vuejs-accessibility/no-static-element-interactions -->
              <a
                v-for="swatch in swatches.slice(0, MAX_SWATCHES_LENGTH)"
                :key="swatch.url"
                :href="swatch.url"
                class="product-tile__swatch-link"
                no-prefetch
                tabindex="-1"
                @mouseenter.stop.prevent="handleSwatchMouseEnter(swatch)"
                @click="(event) => handleProductClick(event, swatch.url)"
              >
                <div
                  :class="[
                    'product-tile__swatch',
                    {
                      'product-tile__swatch--active': swatch.url === activeSwatchRef?.url,
                    },
                  ]"
                  :style="`background-image: url('${cdnProductImageUrl}${swatch.swatchImageSrc}?width=25')`"
                />
              </a>
              <!-- eslint-disable-next-line vuejs-accessibility/mouse-events-have-key-events vuejs-accessibility/no-static-element-interactions -->
              <span
                v-if="swatches.length - MAX_SWATCHES_LENGTH > 0"
                class="product-tile__swatches-plus"
                @mouseenter.stop.prevent="handleSwatchMouseLeave"
              >
                {{ `+${swatches.length - MAX_SWATCHES_LENGTH}` }}
              </span>
            </div>
          </div>
          <div class="product-tile__sizes-wrapper">
            <div class="product-tile__sizes">
              <span v-for="size in activeSwatchRef?.sizes" :key="`${size}`" class="product-tile__size">
                {{ size }}
              </span>
              <div class="product-tile__sizes-more">
                {{ $ui2Config.ui2T('components.producttile.more.multiple_sizes') }}
              </div>
            </div>
          </div>
        </div>
      </ClientOnly>

      <!-- eslint-disable-next-line vuejs-accessibility/anchor-has-content -->
      <a
        class="product-tile__link"
        :href="url"
        no-prefetch
        tabindex="-1"
        @click="(event) => handleProductClick(event, url)"
      >
        <UiCmsMedia
          v-if="media"
          :key="`${media._uid}-${imageType}`"
          :image-type="imageType"
          :data="media as any"
          lazy
        />
        <Ui2Image
          v-else
          :key="`${imageSrc}-${imageType}`"
          :type="imageType"
          :src="imageSrc"
          class="product-tile__image"
          :lazy="isLazy"
        />
        <ClientOnly>
          <div v-if="!disableImageHoverChange" class="product-tile__image-hover-wrapper">
            <Ui2Image
              v-if="imageHoverSrc && !media"
              :key="`${imageHoverSrc}-${imageType}`"
              :type="imageType"
              :src="imageHoverSrc"
              no-background
              class="product-tile__image-hover"
            />
          </div>
          <div v-if="!media" class="product-tile__swatch-image-hover-wrapper">
            <!-- Array to ensure a nice transition between images -->
            <Ui2Image
              v-for="src in swatchImageHoverSrcItemsRef"
              :key="`${src}-${imageType}`"
              :type="imageType"
              :src="src"
              no-background
              class="product-tile__image-hover product-tile__swatch-image-hover"
            />
          </div>
        </ClientOnly>
      </a>
    </div>
    <a
      :href="url"
      no-prefetch
      :class="[
        'product-tile__info-wrap',
        {
          'product-tile__info-wrap--hide-text': hideText,
        },
      ]"
      @click="(event) => handleProductClick(event, url)"
    >
      <div v-if="calloutMessage" class="product-tile__callout">
        <Ui2EcomCallout :text="calloutMessage" :appearance="calloutMessageAppearance" />
      </div>
      <h3 class="product-tile__title">
        {{ title }}
      </h3>
      <div
        v-if="newFlag || customFlag"
        :class="[
          'product-tile__custom-flag',
          {
            'product-tile__custom-flag--new-and-custom': newFlag && customFlag,
          },
        ]"
      >
        <span v-if="newFlag" class="product-tile__custom-flag-new">{{ newFlag }}</span
        ><span v-if="customFlag" class="product-tile__custom-flag-text">{{ customFlag }}</span>
      </div>
      <Ui2EcomPrice :price="price" show-saving show-price />
    </a>
    <div
      :class="[
        'product-tile__wishlist',
        {
          'product-tile__wishlist--loading': isInWishlist === null,
          'product-tile__wishlist--filled': isInWishlist || (isInWishlist === null && uiIsInishlistRef === true),
        },
      ]"
    >
      <button
        class="product-tile__wishlist-button"
        :aria-label="$ui2Config.ui2T('components.wishlist.tab_name_short')"
        :aria-pressed="Boolean(isInWishlist)"
        :disabled="isInWishlist === null"
        @click.stop="handleWishlistButtonClick"
      >
        <div v-if="wishlistIcon === 'x'">
          <Ui2Icon
            class="product-tile__wishlist-icon"
            name="x"
            :width="WISHLIST_ICON_SIZE"
            :height="WISHLIST_ICON_SIZE"
            focusable="false"
            aria-hidden="true"
          />
        </div>
        <div v-else>
          <Ui2Icon
            class="product-tile__wishlist-icon"
            name="heart"
            :width="WISHLIST_ICON_SIZE"
            :height="WISHLIST_ICON_SIZE"
            focusable="false"
            aria-hidden="true"
          />
          <Ui2Icon
            class="product-tile__wishlist-icon product-tile__wishlist-icon--filled"
            name="heart-filled"
            :width="WISHLIST_ICON_SIZE"
            :height="WISHLIST_ICON_SIZE"
            focusable="false"
            aria-hidden="true"
          />
        </div>
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.product-tile {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.product-tile__image-wrap {
  position: relative;
}

.product-tile__wishlist,
.product-tile__overlay,
.product-tile__logo,
.product-tile__popularity-flag {
  @include v2-z(global, content);

  position: absolute;
}

.product-tile__logo {
  transition: opacity $animation-duration-short $animation-type-standard;
  opacity: 1;
  width: 24px;
  height: 24px;
  left: $space-12;
  bottom: $space-12;
  pointer-events: none;

  @include v2-apply-upto(tablet) {
    left: $space-8;
    bottom: $space-8;
  }
}

.product-tile__popularity-flag {
  top: $space-12;
  left: $space-12;
  pointer-events: none;

  @include v2-apply-upto(tablet) {
    top: $space-8;
    left: $space-8;
  }
}

.product-tile__wishlist {
  right: $space-4;
  top: $space-4;

  @include v2-apply-upto(tablet) {
    right: $space-2;
    top: $space-2;
  }
}

.product-tile__wishlist-button {
  padding: $space-8;
  width: 36px;
  height: 36px;
  color: $color-text-body-primary;
  transition: all $animation-duration-medium $animation-type-standard;
  border: 0;
  outline: none;
  background: none;
  cursor: pointer;

  &::after {
    @include v2-z(global, content);
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    border: 2px solid $color-border-focus;
    border-radius: $border-radius-rounded-full;
    transition: all $animation-duration-medium $animation-type-standard;
    opacity: 0;
    content: '';
  }

  &:hover {
    scale: 1.08;
  }

  &:active {
    scale: 0.92;
  }

  &:focus-visible {
    &::after {
      opacity: 1;
    }
  }

  @include v2-apply-upto(tablet) {
    width: 32px;
    height: 32px;
  }
}

.product-tile__wishlist-icon {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: $space-8;
  opacity: 1;

  transition: all $animation-duration-medium $animation-type-standard;

  width: 20px !important;
  height: 20px !important;
  min-width: 20px !important;
  min-height: 20px !important;

  @include v2-apply-upto(tablet) {
    width: 16px !important;
    height: 16px !important;
    min-width: 16px !important;
    min-height: 16px !important;
  }
}

.product-tile__wishlist-icon--filled {
  color: $color-text-highlight;
  opacity: 0;
}

.product-tile__wishlist--filled {
  .product-tile__wishlist-icon--filled {
    opacity: 1;
  }
}

.product-tile__wishlist--loading {
  &.product-tile__wishlist--filled {
    .product-tile__wishlist-icon--filled {
      opacity: 0;
    }
  }

  &.product-tile__wishlist--filled {
    .product-tile__wishlist-icon--filled {
      opacity: 1;
    }
  }
}

.product-tile__title {
  @include v2-text-style(sm);
  margin: 0;
  color: $color-text-body-primary;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @include v2-apply-upto(tablet) {
    @include v2-text-style(xs);
  }
}

.product-tile__info-wrap {
  @include v2-text-style(sm);

  color: $color-text-body-primary;
  height: 100%;
  position: relative;
  padding: $space-12 $space-12 $space-8;
  display: flex;
  flex-direction: column;
  gap: $space-4;

  &:focus-visible {
    outline: 2px solid $color-border-focus;
  }

  @include v2-apply-upto(tablet) {
    @include v2-text-style(xs);
    padding: $space-8;
  }
}

.product-tile__info-wrap--hide-text {
  display: none;

  @include v2-apply-upto(mobile) {
    // Should be visible on mobile
    display: block;
  }
}

.product-tile__image-hover-wrapper,
.product-tile__swatch-image-hover-wrapper {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.product-tile__image-hover {
  display: block;
}

.product-tile--image-hover .product-tile__image-hover-wrapper,
.product-tile--swatch-image-hover .product-tile__swatch-image-hover-wrapper {
  opacity: 1;
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  animation-name: fadeInTile;
  animation-timing-function: ease-in;

  @include v2-apply-upto(mobile) {
    display: none;
  }
}

.product-tile--image-hover .product-tile__logo {
  opacity: 0;
}

.product-tile__swatch-image-hover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.product-tile__custom-flag {
  @include v2-text-style(xs);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product-tile__custom-flag-new {
  color: $color-text-body-secondary;
  text-transform: capitalize;
}

.product-tile__custom-flag--new-and-custom {
  .product-tile__custom-flag-new {
    &::after {
      content: '|';
      padding: 0 $space-4;
      color: inherit;
    }
  }
}

.product-tile__overlay {
  display: flex;
  transition: all ease $animation-duration-short;
  transform: scale(0.96);
  opacity: 0;
  flex-direction: column;
  gap: $space-8;
  bottom: $space-12;
  left: $space-12;
  right: $space-12;
  padding: $space-12 $space-16 $space-8;
  justify-content: space-between;
  border-radius: $border-radius-rounded-md;
  background: $color-surface-component-product-card-quick-info;
  backdrop-filter: $blur-base;

  @include v2-apply-upto(tablet) {
    bottom: $space-8;
    left: $space-8;
    right: $space-8;
    padding: $space-8 $space-12 $space-4;
  }

  @include v2-apply-upto(mobile) {
    display: none;
  }
}

.product-tile__overlay--visible {
  opacity: 1;
  animation: zoomAnimation $animation-duration-medium linear alternate;
  transform: scale(1);
}

$swatch-container-height: 28px;

.product-tile__swatches-wrapper {
  height: $swatch-container-height;
  margin-left: -4px;
  overflow: hidden;
}

.product-tile__swatches {
  @include v2-text-style(xs);
  max-height: calc($swatch-container-height * 2);
  color: $color-text-body-tertiary;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}

.product-tile__swatches-plus {
  color: $color-text-body-primary;
  padding-left: $space-2;
}

.product-tile__swatch-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $swatch-container-height;
  height: $swatch-container-height;
  padding: 2px;
  position: relative;
}

.product-tile__swatch {
  display: block;
  width: 100%;
  height: 100%;
  background-color: $color-border-secondary;
  border: 3px solid $color-border-secondary;
  border-radius: $border-radius-rounded-full;
  background-color: $color-border-secondary;
  transition: box-shadow $animation-duration-medium $animation-type-standard;
}

.product-tile__swatch--active {
  box-shadow: 0 0 0 1px $color-border-action;
}

$sizes-container-height: 18px;

.product-tile__sizes-wrapper {
  height: $sizes-container-height;
  overflow: hidden;
}

.product-tile__sizes {
  @include v2-text-style(xs);

  max-height: calc($sizes-container-height * 2);
  color: $color-text-body-primary;
  display: flex;
  flex-wrap: wrap;
  column-gap: $space-8;
  position: relative;
  top: -$sizes-container-height;
  transform: translateY(100%);
}

.product-tile__sizes-more {
  position: absolute;
  top: -$sizes-container-height;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow-x: hidden;
}

.product-tile__sizes--hovered {
  display: none;
}

.product-tile--swatch-image-hover {
  .product-tile__sizes--hovered {
    display: block;
    display: flex;
  }

  .product-tile__sizes--default {
    display: none;
  }
}

.product-tile__size {
  text-transform: uppercase;
}

.product-tile__custom-flag-text {
  color: $color-text-body-secondary;
}

.product-tile--sustainable {
  .product-tile__custom-flag-text {
    color: $color-text-sustainability;
  }
}

// Temporary fix to make popularity flags from ab test work
.product-tile .product-tile__image-wrap .product-tile__link {
  &::after {
    display: none !important;
  }
}

@keyframes fadeInTile {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes zoomAnimation {
  0% {
    transform: scale(0.96);
  }
  60% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}
</style>
