<script setup lang="ts">
import type { CmsUiGenderSwitchItem } from './uiCmsGenderSwitch';

defineOptions({
  name: 'UiCmsGenderSwitchItem',
});

const props = defineProps({
  item: {
    type: Object as PropType<CmsUiGenderSwitchItem>,
    required: true,
  },
});

const element: CmsUiGenderSwitchItem = props.item;

if (element.media) {
  element.media.imageType = 'cms-two-column-vertical-square';
}

const tileStyle = {
  color: element.buttonColor,
  backgroundColor: element.backgroundColor,
};
</script>

<template>
  <div v-storyblok-editable="element" class="ui-cms-gender-switch-item">
    <UiNuxtLink v-if="element.media" v-bind="element.link" class="ui-cms-gender-switch-item__image-wrapper">
      <UiCmsMedia :data="element.media" />
    </UiNuxtLink>

    <div class="ui-cms-gender-switch-item__bottom-bar" :style="tileStyle">
      <div class="ui-cms-gender-switch-item__button-wrapper">
        <UiNuxtLink
          v-if="element.buttonText"
          v-storyblok-editable="element.buttonText"
          v-bind="element.link"
          class="ui-cms-gender-switch-item__button button button--secondary button--arrow-next-disabled"
        >
          {{ element.buttonText }}

          <svg xmlns="http://www.w3.org/2000/svg" class="ui-cms-gender-switch-item__button-arrow-next">
            <path fill="currentColor" d="M10.712 4.99L5.679.02l-.703.712L8.792 4.5H.178v1H8.77L4.976 9.245l.703.712z" />
          </svg>
        </UiNuxtLink>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ui-cms-gender-switch-item {
  position: relative;
  width: columns(18);

  @include apply-upto(small) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.ui-cms-gender-switch-item__bottom-bar {
  display: flex;
  padding: $space20 0;

  @include apply-from(medium) {
    justify-content: flex-end;
    position: sticky;
    bottom: 0;
    width: 100%;
  }
}

.ui-cms-gender-switch-item__button-wrapper {
  margin: 0 columns-in-vw(1);

  @include apply-upto(small) {
    width: 100%;
  }
}

.ui-cms-gender-switch-item--right {
  .ui-cms-gender-switch-item__image-wrapper {
    @include apply-upto(medium) {
      order: 2;
    }
  }

  .ui-cms-gender-switch-item__bottom-bar {
    @include apply-from(medium) {
      justify-content: flex-start;
    }
  }
}

.ui-cms-gender-switch-item__button-arrow-next {
  display: none;
  top: 50%;
  right: $space25;
  width: $space10;
  height: $space10;
  margin-top: -$space5;
}

.ui-cms-gender-switch-item__button {
  color: currentColor;
  border-color: currentColor;
  background-color: transparent;

  @include hover {
    .ui-cms-gender-switch-item__button-arrow-next {
      display: inline-block;
      position: absolute;
    }
  }
}
</style>
