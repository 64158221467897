<script setup lang="ts">
import { isClient } from '@mop/shared/utils/util';
import type { ObserveVisibilityConfig } from '@mop/types';
import type { CmsMedia } from '@mop/cms/types';
import type { GtmProductListType, Gtm2EcommerceEventType } from '@/types/gtm';
import type { ProductModel, Price } from '@/types/product';

defineOptions({
  name: 'MopProductTile',
});

const props = defineProps({
  product: {
    type: Object as PropType<ProductModel>,
    required: true,
  },
  position: {
    type: Number,
    required: false,
    default: 0,
  },
  gtmListType: {
    type: String as PropType<GtmProductListType>,
    required: false,
    default: '',
  },
  gtmSearchTerm: {
    type: String,
    required: false,
    default: '',
  },
  media: {
    type: Object,
    default: null,
  },
  imageType: {
    type: String,
    default: 'product-tile',
  },
  imageIndex: {
    type: Number,
    default: PRODUCT_IMAGE_INDEX.STANDARD,
  },
  imageDivision: {
    type: Number,
    default: PRODUCT_IMAGE_DIVISION.STANDARD,
  },
  hideText: {
    type: Boolean,
    default: false,
  },
  disableImageHoverChange: {
    type: Boolean,
    default: false,
  },
  imageHoverIndex: {
    type: Number,
    default: PRODUCT_IMAGE_INDEX.DETAIL,
  },
  categoryPath: {
    type: String,
    default: '',
  },
  disableClick: {
    type: Boolean,
    default: false,
  },
  wishlistIcon: {
    type: String,
    default: 'heart',
  },
  enablePromotion: {
    type: Boolean,
    default: false,
  },
  enablePopularityFlag: {
    type: Boolean,
    default: false,
  },
  disableHover: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['click']);

const {
  $mopI18n,
  $breakpoint: { isMobileRef },
  $gtm2,
  $mopConfig,
} = useNuxtApp();
const router = useRouter();
const product: ProductModel = props.product as ProductModel;
const imageIndex: number = props.imageIndex;
let imageHoverIndex: number = props.imageHoverIndex;
if (imageIndex === imageHoverIndex) {
  imageHoverIndex =
    imageIndex === PRODUCT_IMAGE_INDEX.DETAIL ? PRODUCT_IMAGE_INDEX.STANDARD : PRODUCT_IMAGE_INDEX.DETAIL;
}
const { reportView, reportEngagement } = useMopTrackingClient();
const { getPromotionCallOutFlag } = useMopPromotions();
const { wishlistModelRef, addToWishlist, removeFromWishlist, loadingRef } = useMopWishlistClient();
const url = product.getUrl();
const imageSrc = product.getImageByIndex(imageIndex, props.imageDivision);
const imageHoverSrc = product.getImageByIndex(imageHoverIndex);
const hasSustainableFlag = product.hasSustainabilityFlag();
const customFlag = product.getCustomFlag();
const isHover = ref(false);

const popularityFlag = product.getPopularityFlag($mopConfig.isPopularityFlagEnabledForSaleProducts());
const popularityFlagText =
  props.enablePopularityFlag && popularityFlag
    ? $mopI18n.t(`product.popularity_flag.${popularityFlag?.type}.title`)
    : '';
const swatchesRef = ref<ProductModel[]>([]);
const price: Price = product.getPrice();

const calloutMessageRef = computed(() => {
  if (!isClient) {
    return;
  }
  return props.enablePromotion ? getPromotionCallOutFlag(product) : null;
});

const visibilityConfig: ObserveVisibilityConfig = {
  callback: (isVisible) => {
    if (!props.gtmListType || !isVisible) {
      return;
    }
    reportView({
      gtm: {
        type: 'ProductImpression',
        data: {
          product: {
            product,
            position: props.position + 1,
            list: props.gtmListType,
            category: props.categoryPath,
          },
        },
      },
    });
    trackEcommerce('view_item_list', true);
  },
  rootMargin: '0px 0px 0px 0px',
};

if (isClient && !props.disableHover) {
  const stopIsHoverWatch = watch(isHover, async (isHover) => {
    if (!isHover) {
      return;
    }
    const { productModelRef, searchProductByRefId } = useMopProduct();
    await searchProductByRefId(product.getMopId(), requestParameters.PRODUCT_TILE_HOVER, true);
    swatchesRef.value = productModelRef.value.getSwatches();
    stopIsHoverWatch();
  });
}

const isInWishlistRef = computed(() => {
  if (loadingRef.value.add || loadingRef.value.remove) {
    return null;
  }
  return wishlistModelRef.value.isProductInWishlist(product.getAyId());
});

async function handleWishlistClick() {
  if (isInWishlistRef.value) {
    await removeFromWishlist(product, props.gtmListType);
  } else {
    await addToWishlist(product, props.gtmListType);
  }
}

function handleMouseEnter() {
  if (!isMobileRef.value) {
    isHover.value = true;
  }
}

function handleMouseLeave() {
  if (!isMobileRef.value) {
    isHover.value = false;
  }
}

function trackEcommerce(event: Gtm2EcommerceEventType, enableBundling = false) {
  $gtm2.trackEcommerce({
    event,
    enableBundling,
    data: {
      products: [
        {
          product,
          position: props.position + 1,
          list: props.gtmListType,
          category: props.categoryPath,
        },
      ],
    },
  });
}

function handleProductClick(event: MouseEvent, url: string) {
  if (props.gtmListType) {
    reportEngagement({
      gtm: {
        type: 'ProductClick',
        data: {
          product: {
            product,
            position: props.position + 1,
            list: props.gtmListType,
            category: props.categoryPath,
          },
        },
      },
    });
    trackEcommerce('select_item');
  }
  if (props.gtmSearchTerm) {
    reportEngagement({
      gtm: {
        type: 'Click',
        data: {
          custom: {
            event: props.gtmSearchTerm,
            // Possibly incorrect parameters, ticket about tracking implementation: https://marc-o-polo.atlassian.net/browse/DI-9706
            // @ts-ignore
            target: 'site_search_click',
            label: document.location.origin + $mopI18n.localePath(url),
          },
        },
      },
    });
  }
  if (props.disableClick) {
    event.preventDefault();
    emit('click');
    return;
  }
  if (guardEvent(event)) {
    event.preventDefault();
    handleNavigateTo(url);
  }
}

function handleNavigateTo(url: string) {
  router.push(url);
  emit('click');
}
</script>

<template>
  <div v-observe-visibility:once="gtmListType ? visibilityConfig : undefined">
    <Ui2EcomProductCard
      :price="price"
      :title="product.getCompositeName()"
      :url="$mopI18n.localePath(url)"
      :image-type="imageType"
      :image-src="imageSrc"
      :disable-image-hover-change="disableImageHoverChange"
      :image-hover-src="imageHoverSrc"
      :is-hover="isHover"
      :has-sustainable-flag="hasSustainableFlag"
      :is-in-wishlist="isInWishlistRef"
      :is-denim="product.isDenim()"
      :new-flag="product.isNew() ? $mopI18n.t('product.badge.new').toLowerCase() : ''"
      :popularity-flag="popularityFlagText"
      :callout-message="calloutMessageRef?.message"
      :callout-message-appearance="calloutMessageRef?.appearance"
      :custom-flag="customFlag && $mopI18n.te(customFlag) ? $mopI18n.t(customFlag) : customFlag"
      :hide-text="hideText"
      :wishlist-icon="wishlistIcon === 'x' ? 'x' : 'heart'"
      :swatches="
        swatchesRef.map((swatch) => {
          return {
            url: $mopI18n.localePath(swatch.getUrl()),
            swatchImageSrc: swatch.getSwatchImage(),
            imageSrc: swatch.getImageByIndex(imageIndex),
            sizes: swatch.getSizes(),
          };
        })
      "
      :media="media as CmsMedia"
      @click="handleProductClick"
      @click-wishlist="handleWishlistClick"
      @mouse-enter="handleMouseEnter"
      @mouse-leave="handleMouseLeave"
    />
  </div>
</template>
