import type {
  StoreGeoCoordinates,
  StoreOpeningTimes,
  Address,
  Collection,
  StoreData,
  StoreModel,
  StoreListResponseData,
  Service,
} from '@/types/store';

export function storeModel(responseData: StoreData) {
  return {
    getId(): string {
      return responseData?.id;
    },

    getName(): string {
      return responseData?.name || '';
    },

    getCountry(): string {
      return responseData?.country;
    },

    getMail(): string {
      return responseData?.mail || '';
    },

    getAddress(): Address {
      return responseData?.address;
    },

    getOpeningTimes(): StoreOpeningTimes[] {
      return responseData?.openingTimes;
    },

    getStoreCoordinates(): StoreGeoCoordinates {
      return responseData?.storeGeolocation;
    },

    getPhoneNumber(): string {
      if (responseData?.phoneNumber === null) {
        return '';
      }

      return responseData?.phoneNumber;
    },

    getCollection(): Collection[] {
      return responseData?.collection;
    },

    isClickAndCollectEnabled() {
      return this.getServices().includes('click_and_collect');
    },

    isReserveAndCollectEnabled() {
      return this.getServices().includes('reserve_and_collect');
    },

    getServices(): Service[] {
      const services: Service[] = responseData?.services;

      if (!services) {
        return [];
      }

      const sortOrder: Service[] = [
        'bonus_points',
        'reserve_and_collect',
        'click_and_collect',
        'shopping_appointment',
        'voucher_redemption',
      ];

      return services.sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b));
    },

    getStockQuantity(): number | null {
      return responseData?.stockQuantity ?? null;
    },
  };
}

export function storeListModel(responseData: StoreListResponseData | null) {
  const response: StoreListResponseData = responseData ?? {};

  return {
    hasError(): boolean {
      return response.error !== undefined;
    },

    getStoreModelList(): StoreModel[] {
      if (response.data === undefined || response.data.list.length === 0) {
        return [];
      }

      // Remove stores without GeoCoordinates
      const filteredStores: StoreData[] = response.data.list.filter((storeData) => storeData.storeGeolocation);
      return filteredStores.map((storeData) => storeModel(storeData));
    },

    getPostalCode(): string | undefined {
      return response?.data?.postalCode ?? undefined;
    },

    isInitialized(): boolean {
      return responseData !== null;
    },
  };
}
