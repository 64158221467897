<script setup lang="ts">
import { scrollToAnchor } from '@mop/shared/utils/util';
import { getElementUiObject, getHtmlFromRichText, getHtmlFromTable } from '@mop/cms/utils/utils';
import type { CmsUiStaticNavigation } from './uiCmsStaticNavigation';

defineOptions({
  name: 'UiCmsStaticNavigation',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const data: any = props.data;

const element: CmsUiStaticNavigation = getElementUiObject(data, {
  items: data.items.map((navigationItem: any) => {
    return getElementUiObject(navigationItem, {
      headline: navigationItem.headline,
      anchor: navigationItem.anchor,
      items: navigationItem.items.map((navigationContentItem: any) => {
        return getElementUiObject(navigationContentItem, {
          headline: navigationContentItem.headline,
          htmlItems: navigationContentItem.items.map((item: any) => {
            let html = '';
            if (item.component === 'StaticNavigationItemContentRichText') {
              html = getHtmlFromRichText(item.richText);
            } else if (item.component === 'StaticNavigationItemContentTable') {
              html = getHtmlFromTable(item.table, item.headline);
            }
            return getElementUiObject(item, {
              value: html,
            });
          }),
        });
      }),
    });
  }),
});

function handleNavigationClick(event: MouseEvent) {
  scrollToAnchor((event.target as HTMLAnchorElement).href);
}
</script>

<template>
  <div v-storyblok-editable="element" class="ui-cms-static-navigation" @click="handleNavigationClick">
    <div class="ui-cms-static-navigation__table-of-contents rich-text">
      <div
        v-for="(navigationItem, i) in element.items"
        :key="`table-of-contents${i}`"
        class="ui-cms-static-navigation__table-of-contents-item"
      >
        <div class="ui-cms-static-navigation__table-of-contents-item-headline">
          {{ navigationItem.headline }}
        </div>
        <ol>
          <li v-for="(navigationItemContent, j) in navigationItem.items" :key="`table-of-contents${j}`">
            <NuxtLink :to="{ hash: `#${navigationItem.anchor}-${j + 1}` }" no-prefetch>
              {{ navigationItemContent.headline }}
            </NuxtLink>
          </li>
        </ol>
      </div>
    </div>
    <div class="ui-cms-static-navigation__contents">
      <div
        v-for="(navigationItem, i) in element.items"
        :key="i"
        v-storyblok-editable="navigationItem"
        class="ui-cms-static-navigation__item"
      >
        <span :id="navigationItem.anchor" />
        <h2 v-if="navigationItem.headline">
          {{ navigationItem.headline }}
        </h2>
        <div
          v-for="(navigationItemContent, j) in navigationItem.items"
          :key="j"
          v-storyblok-editable="navigationItemContent"
          class="ui-cms-static-navigation__item-content"
        >
          <span :id="`${navigationItem.anchor}-${j + 1}`" />
          <h3 v-if="navigationItemContent.headline">
            {{ navigationItemContent.headline }}
          </h3>
          <div
            v-for="(htmlItem, k) in navigationItemContent.htmlItems"
            :key="k"
            v-storyblok-editable="htmlItem"
            v-html="htmlItem.value"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
h2 {
  @include text-style(headline);
}

h3 {
  @include text-style(headline-2);
}

.ui-cms-static-navigation__item {
  position: relative;
  counter-reset: item;
  padding-bottom: $space30;

  @include apply-upto(medium) {
    padding-bottom: $space10;
  }

  > span {
    display: inline-block;
    position: absolute;
    top: -$sticky-big-header-height;
  }

  h2 {
    border-bottom: 1px solid $middle-grey;
    margin: 0 columns(2) $space50;
    padding-bottom: $space30;

    @include apply-upto(medium) {
      border-color: $dark-grey;
    }

    @include apply-upto(small) {
      padding: 0 columns(2) $space30;
      margin: 0 0 $space30;
    }
  }

  &:first-child {
    h2 {
      border: 0;
      padding-bottom: 0;
    }
  }

  h3 {
    position: relative;
    margin: 0 0 $space20;

    @include apply-upto(small) {
      padding-top: $space25;
    }

    &::before {
      @include text-style(strong-small);

      content: '0' counter(item);
      counter-increment: item;
      margin-top: 4px;
      border-bottom: 2px solid transparent;
      position: absolute;
      top: 0;
      left: -$space30;

      @include apply-upto(small) {
        left: 0;
        bottom: 100%;
      }
    }

    &:nth-child(n + 10)::before {
      content: counter(item);
    }
  }
}

.ui-cms-static-navigation__item-content {
  margin-bottom: $space40;
  margin-left: $space30;
  position: relative;
  padding: 0 $global-padding;

  @include apply-upto(small) {
    margin-bottom: $space20;
    margin-left: 0;
  }

  > span {
    display: inline-block;
    position: absolute;
    top: -$sticky-big-header-height;
  }

  > div {
    padding-bottom: $space10;
  }

  :deep(.table-headline) {
    @include text-style(headline-3);

    @include apply-from(tiny) {
      margin: 0;
    }
  }

  :deep(.table) {
    width: 100%;
    overflow: auto;
  }

  :deep(td) {
    max-width: 200px;
    min-width: 100px;
  }

  :deep(.table--columns-1),
  :deep(.table--columns-2),
  :deep(.table--columns-3) {
    td {
      max-width: none;
    }
  }
}

.ui-cms-static-navigation__table-of-contents {
  border-bottom: 1px solid $middle-grey;
  margin: 0 columns(2) $space70;

  @include apply-upto(small) {
    margin: 0 0 $space50;
    padding: 0 $global-padding;
  }

  @include apply-upto(medium) {
    border-color: $dark-grey;
  }
}

.ui-cms-static-navigation__table-of-contents-item-headline {
  @include text-style(headline);

  @include apply-from(tiny) {
    margin-bottom: $space30;
  }

  @include apply-upto(small) {
    margin-bottom: $space20;
  }
}

.ui-cms-static-navigation__table-of-contents-item {
  margin-bottom: $space50;

  @include apply-upto(small) {
    margin-bottom: $space30;
  }

  ol {
    column-count: 2;
    padding-left: 0;

    @include apply-upto(small) {
      column-count: 1;
    }
  }

  li {
    padding: 0;
    display: list-item;

    &::before {
      padding-top: 9px;
      margin-left: 0;
    }
  }

  a {
    @include link-not-underlined(7px, none);
    @include text-style(running);

    padding-left: $space30;
  }
}
</style>
