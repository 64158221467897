import type { LocaleObject, Country } from '@/types/locale';

export const cmsLivePreviewLanguageMappings: { [key: string]: string } = {
  de: 'de-de',
  fr: 'fr-fr',
  nl: 'nl-nl',
  'ge-ge': 'en-dk', // Global-e: Denmark is default
};

export const globalEShopId = 170;
export const globalEShopLocale = 'en-lv';

export const localeList: LocaleObject[] = [
  {
    code: 'de-de',
    cmsFallbackLanguage: 'de',
    lang: 'de',
    shopId: 10,
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'DEU',
  },
  {
    code: 'fr-fr',
    cmsFallbackLanguage: 'fr',
    lang: 'fr',
    shopId: 60,
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'FRA',
  },
  {
    code: 'de-at',
    cmsFallbackLanguage: 'de',
    lang: 'de',
    shopId: 30,
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'AUT',
  },
  {
    code: 'nl-nl',
    cmsFallbackLanguage: 'nl',
    lang: 'nl',
    shopId: 70,
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'NLD',
  },
  {
    code: 'en-se',
    lang: 'en',
    shopId: 20,
    geCurrency: 'SEK',
    currency: 'SEK',
    alpha3: 'SWE',
  },
  {
    code: 'nl-be',
    cmsFallbackLanguage: 'nl',
    lang: 'nl',
    shopId: 80,
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'BEL',
  },
  {
    code: 'fr-be',
    cmsFallbackLanguage: 'fr',
    lang: 'fr',
    shopId: 90,
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'BEL',
  },
  {
    code: 'de-ch',
    cmsFallbackLanguage: 'de',
    lang: 'de',
    shopId: 40,
    geCurrency: 'CHF',
    currency: 'CHF',
    alpha3: 'CHE',
  },
  {
    code: 'fr-ch',
    cmsFallbackLanguage: 'fr',
    lang: 'fr',
    shopId: 50,
    geCurrency: 'CHF',
    currency: 'CHF',
    alpha3: 'CHE',
  },
  {
    code: 'en-gb',
    lang: 'en',
    shopId: 100,
    geCurrency: 'GBP',
    currency: 'GBP',
    alpha3: 'GBR',
  },
  {
    code: 'en-fi',
    lang: 'en',
    shopId: 110,
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'FIN',
  },
  {
    code: 'es-es',
    lang: 'es',
    cmsFallbackLanguage: 'es',
    shopId: 120,
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'ESP',
  },
  {
    code: 'it-it',
    lang: 'it',
    cmsFallbackLanguage: 'it',
    shopId: 150,
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'ITA',
  },
  {
    code: 'en-lu',
    lang: 'en',
    shopId: 160,
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'LUX',
  },
  {
    code: 'en-lv',
    lang: 'en',
    shopId: 170,
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'LVA',
  },
  {
    code: 'en-lt',
    lang: 'en',
    shopId: 180,
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'LTU',
  },
  {
    code: 'en-sk',
    lang: 'en',
    shopId: 190,
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'SVK',
  },
  {
    code: 'en-si',
    lang: 'en',
    shopId: 200,
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'SVN',
  },
  {
    code: 'cs-cz',
    lang: 'cs',
    shopId: 210,
    geCurrency: 'CZK',
    currency: 'EUR',
    alpha3: 'CZE',
  },
  {
    code: 'pl-pl',
    lang: 'pl',
    shopId: 220,
    geCurrency: 'PLN',
    currency: 'EUR',
    alpha3: 'POL',
  },
  {
    code: 'en-ro',
    lang: 'en',
    shopId: 130,
    geCurrency: 'RON',
    currency: 'EUR',
    alpha3: 'ROU',
  },
  {
    code: 'en-hu',
    lang: 'en',
    shopId: 140,
    geCurrency: 'HUF',
    currency: 'EUR',
    alpha3: 'HUN',
  },
  {
    code: 'en-ee',
    lang: 'en',
    shopId: 230,
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'EST',
  },
  {
    code: 'en-ie',
    lang: 'en',
    shopId: 240,
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'IRL',
  },
  {
    code: 'en-dk',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    shopId: globalEShopId,
    geCurrency: 'DKK',
    currency: 'EUR',
    alpha3: 'DNK',
  },
  {
    code: 'en-gr',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    shopId: globalEShopId,
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'GRC',
  },
  {
    code: 'en-pt',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    shopId: globalEShopId,
    geCurrency: 'PTE',
    currency: 'EUR',
    alpha3: 'PRT',
  },
  {
    code: 'en-no',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    shopId: globalEShopId,
    geCurrency: 'NOK',
    currency: 'EUR',
    alpha3: 'NOR',
    isDisabled: true,
  },
  {
    code: 'en-is',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    shopId: globalEShopId,
    geCurrency: 'ISK',
    currency: 'EUR',
    alpha3: 'ISL',
    isDisabled: true,
  },
  {
    code: 'en-hr',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    shopId: globalEShopId,
    geCurrency: 'HRK',
    currency: 'EUR',
    alpha3: 'HRV',
  },
  {
    code: 'en-us',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    shopId: globalEShopId,
    geCurrency: 'USD',
    currency: 'EUR',
    alpha3: 'USA',
  },
  {
    code: 'en-ca',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    shopId: globalEShopId,
    geCurrency: 'CAD',
    currency: 'EUR',
    alpha3: 'CAN',
  },
  {
    code: 'en-rs',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    shopId: globalEShopId,
    geCurrency: 'RSD',
    currency: 'EUR',
    alpha3: 'SRB',
  },
  {
    code: 'en-ae',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    shopId: globalEShopId,
    geCurrency: 'AED',
    currency: 'EUR',
    alpha3: 'ARE',
  },
  {
    code: 'en-il',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    shopId: globalEShopId,
    geCurrency: 'ILS',
    currency: 'EUR',
    alpha3: 'ISR',
  },
  {
    code: 'en-qa',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    shopId: globalEShopId,
    geCurrency: 'QAR',
    currency: 'EUR',
    alpha3: 'QAT',
  },
  {
    code: 'en-nz',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    shopId: globalEShopId,
    geCurrency: 'NZD',
    currency: 'EUR',
    alpha3: 'NZL',
  },
  {
    code: 'en-in',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    shopId: globalEShopId,
    geCurrency: 'INR',
    currency: 'EUR',
    alpha3: 'IND',
  },
  {
    code: 'en-sg',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    shopId: globalEShopId,
    geCurrency: 'SGD',
    currency: 'EUR',
    alpha3: 'SGP',
  },
  {
    code: 'en-jp',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    shopId: globalEShopId,
    geCurrency: 'JPY',
    currency: 'EUR',
    alpha3: 'JPN',
  },
  {
    code: 'en-hk',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    shopId: globalEShopId,
    geCurrency: 'HKD',
    currency: 'EUR',
    alpha3: 'HKG',
  },
];

export function getLocalesFromCountry(localeList: LocaleObject[], country: Country): string[] {
  const list: string[] = [].slice.call(localeList);
  country = country.toLowerCase();
  return list.reduce((locales: string[], locale: any) => {
    if (locale !== undefined && locale.code.split('-')[1] === country) {
      locales.push(locale.code);
    }
    return locales;
  }, []);
}

export function extractCountriesFromLocaleList(filteredLocaleList: LocaleObject[]): string[] {
  const countryList: string[] = [];
  filteredLocaleList.forEach((locale: LocaleObject) => {
    const country: string = getCountryFromLocale(locale);
    if (countryList.includes(country)) {
      return;
    }
    countryList.push(country);
  });
  return countryList;
}

export function getCountryFromLocale(locale: LocaleObject | undefined): string {
  return locale ? locale.code.split('-')[1] : '';
}

export function getCountryFromAlpha3(alpha3: string) {
  return getCountryFromLocale(localeList.find((locale) => locale.alpha3 === alpha3));
}

export function getAlpha3FromCountry(country: string) {
  return localeList.find((locale) => getCountryFromLocale(locale) === country)?.alpha3 ?? '';
}
