import type { PriceData, Price, ReductionData } from '@/types/product';

export function getPrice(price: PriceData | undefined): Price {
  const reductionList: ReductionData[] = price?.appliedReductions ?? [];
  const salesPrice: number = (price?.withTax || 0) / 100;
  const salesPriceNet: number = (price?.withoutTax || 0) / 100;

  let basePrice: number = salesPrice;
  let basePriceNet: number = salesPriceNet;
  let discountPercentage = 0;

  reductionList.forEach((reduction) => {
    basePrice += reduction.amount.absoluteWithTax / 100;
    basePriceNet += (reduction.amount.absoluteWithTax - (price?.tax?.vat.amount || 0)) / 100;
    discountPercentage += Math.round(reduction.amount.relative * 100);
  });

  return {
    salesPrice,
    basePrice,
    basePriceNet,
    salesPriceNet,
    discountPercentage,
    currency: price?.currencyCode ?? '',
  };
}
