<script setup lang="ts">
import { logInfo } from '@mop/shared/utils/logger';

defineOptions({
  name: 'UiCmsElementNotFound',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

logInfo(`Content Element "${props.data.component}" not found: ${JSON.stringify(props.data)}`, true);
const displayError = props.data.isProductionBuild === false;
</script>

<template>
  <div>
    <span v-if="displayError">
      {{ `Module ${data.component} not found` }}
    </span>
  </div>
</template>
