import { isClient, parseAxiosErrorResponseUrl } from '@mop/shared/utils/util';
import StoryblokClient from 'storyblok-js-client';
import type { AxiosError, AxiosInstance } from 'axios';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { BapiClient } from '@aboutyou/backbone';
import { logInfo } from '@mop/shared/utils/logger';
import type {
  APIAwsSetupParams,
  APIAycSetupParams,
  APIBackboneSetupParams,
  APICmsSetupParams,
  APICrmMiddlewareSetupParams,
  APIEpoqSetupParams,
  APIWalletSetupParams,
} from '@/types/apiInit';
import type { ApiCmsInstance } from '@/types/cms';
import { initApiBackbone, initApiAyc } from '@/api/providers/ayc';
import { initApiEpoq } from '@/api/providers/epoq';
import { initApiWallet } from '@/api/providers/wallet';
import apiCms from '@/api/providers/cms';
import apiAws from '@/api/providers/aws';
import apiCrmMiddleware from '@/api/providers/crm';
import apiLocal from '@/api/providers/local';

const numberOfRetries: number = isClient ? 1 : 0;

// Max 1 connection
let apiLocalCache: AxiosInstance;
// Max 2 connections
const apiCmsCache: { [key: string]: StoryblokClient } = {};
// Max 24 connections
const apiCrmCache: { [key: string]: AxiosInstance } = {};
// Max 24 connections
const apiBackboneCache: { [key: string]: BapiClient } = {};
// Max 24 connections
const apiAwsCache: { [key: string]: AxiosInstance } = {};
// Max 24 connections
const apiAycCache: { [key: string]: AxiosInstance } = {};
// = Max 99 connections (LIMIT IS 128!)

function retryCondition(error: AxiosError) {
  logInfo(`Retrying: ${parseAxiosErrorResponseUrl(error)}`);
  return true;
}

function getLocalClient() {
  if (apiLocalCache) {
    return apiLocalCache;
  }
  apiLocalCache = axios.create({
    timeout: 10000,
    responseType: 'json',
  });

  axiosRetry(apiLocalCache, {
    retries: numberOfRetries,
    shouldResetTimeout: true,
    retryCondition,
  });

  return apiLocalCache;
}

function createApiLocal() {
  return apiLocal(getLocalClient());
}

function getCmsClient(setupParams: APICmsSetupParams) {
  if (!apiCmsCache[setupParams.cmsApiKey]) {
    const client: StoryblokClient = (apiCmsCache[setupParams.cmsApiKey] = new StoryblokClient({
      accessToken: setupParams.cmsApiKey,
      rateLimit: 1000,
      timeout: isClient ? 10000 : 5000,
      fetch: isClient ? window.fetch.bind(window) : fetch,
    }));
    apiCmsCache[setupParams.cmsApiKey] = client;
  }
  return apiCmsCache[setupParams.cmsApiKey];
}

function createApiCms(setupParams: APICmsSetupParams) {
  const cmsClient: ApiCmsInstance = {
    client: getCmsClient(setupParams),
    config: {
      cmsVersion: setupParams.cmsVersion,
      cmsLanguage: setupParams.cmsLanguage,
      cmsFallbackLanguage: setupParams.cmsFallbackLanguage,
      cmsRelease: setupParams.cmsRelease,
    },
  };

  return apiCms(cmsClient);
}

function getCrmClient(setupParams: APICrmMiddlewareSetupParams) {
  if (!apiCrmCache[setupParams.bapiShopId]) {
    const client = axios.create({
      baseURL: setupParams.url,
      headers: {
        'x-shop-id': setupParams.bapiShopId,
      },
      timeout: 60000,
      responseType: 'json',
    });
    axiosRetry(client, {
      retries: 0,
      shouldResetTimeout: true,
      retryCondition,
    });
    apiCrmCache[setupParams.bapiShopId] = client;
  }
  return apiCrmCache[setupParams.bapiShopId];
}

function createApiCrmMiddleware(setupParams: APICrmMiddlewareSetupParams) {
  return apiCrmMiddleware(getCrmClient(setupParams));
}

function getBackboneClient(setupParams: APIBackboneSetupParams) {
  if (!apiBackboneCache[setupParams.bapiShopId]) {
    apiBackboneCache[setupParams.bapiShopId] = apiBackboneCache[setupParams.bapiShopId] = new BapiClient({
      host: setupParams.url,
      shopId: setupParams.bapiShopId,
    });
  }

  return apiBackboneCache[setupParams.bapiShopId];
}

function createApiBackbone(setupParams: APIBackboneSetupParams) {
  return initApiBackbone(getBackboneClient(setupParams));
}

function getAwsClient(setupParams: APIAwsSetupParams) {
  if (!apiAwsCache[setupParams.bapiShopId]) {
    const client: AxiosInstance = axios.create({
      baseURL: setupParams.url,
      timeout: isClient ? 10000 : 5000,
      responseType: 'json',
    });
    axiosRetry(client, {
      retries: numberOfRetries,
      shouldResetTimeout: true,
      retryCondition,
    });
    client.prototype.shopId = setupParams.bapiShopId;
    apiAwsCache[setupParams.bapiShopId] = client;
  }
  return apiAwsCache[setupParams.bapiShopId];
}

function createApiAws(setupParams: APIAwsSetupParams) {
  return apiAws(getAwsClient(setupParams));
}

function getAycClient(setupParams: APIAycSetupParams) {
  if (!apiAycCache[setupParams.bapiShopId]) {
    const client = axios.create({
      baseURL: setupParams.url,
      headers: {
        'x-shop-id': setupParams.bapiShopId,
      },
      timeout: isClient ? 10000 : 4000,
      responseType: 'json',
    });
    axiosRetry(client, {
      retries: numberOfRetries,
      shouldResetTimeout: true,
      retryCondition,
    });
    apiAycCache[setupParams.bapiShopId] = client;
  }
  return apiAycCache[setupParams.bapiShopId];
}

function createApiAyc(setupParams: APIAycSetupParams) {
  return initApiAyc(getAycClient(setupParams));
}

function getEpoqClient(setupParams: APIEpoqSetupParams) {
  const client = axios.create({
    baseURL: setupParams.url,
    timeout: 10000,
    responseType: 'json',
  });
  axiosRetry(client, {
    retries: numberOfRetries,
    shouldResetTimeout: true,
    retryCondition,
  });
  return client;
}

function createApiEpoq(setupParams: APIEpoqSetupParams) {
  return initApiEpoq(getEpoqClient(setupParams));
}

function getWalletClient(setupParams: APIWalletSetupParams) {
  const client: AxiosInstance = axios.create({
    baseURL: setupParams.url,
    responseType: 'json',
  });
  return client;
}

function createApiWallet(setupParams: APIWalletSetupParams) {
  return initApiWallet(getWalletClient(setupParams));
}

export {
  createApiLocal,
  createApiCms,
  createApiCrmMiddleware,
  createApiBackbone,
  createApiAyc,
  createApiAws,
  createApiEpoq,
  createApiWallet,
};
