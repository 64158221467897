<script setup lang="ts">
import { getImage } from '@mop/cms/utils/utils';
import type { Benefits } from './UiCmsBenefit';

defineOptions({
  name: 'UiCmsBenefit',
});

const props = defineProps({
  data: {
    type: Object as PropType<Benefits>,
    required: true,
  },
});

const {
  $breakpoint: { isMobileRef },
} = useNuxtApp();
const benefitsData = computed(() => {
  return {
    list: props.data?.body?.map((benefitItem) => {
      return {
        ctaText: benefitItem.ctaText,
        descriptionDesktop: benefitItem.descriptionDesktop,
        descriptionMobile: benefitItem.descriptionMobile,
        image: getImage(benefitItem.image),
        id: benefitItem._uid,
        data: benefitItem,
      };
    }),
    backgroundColor: props.data?.backgroundColor,
  };
});
</script>

<template>
  <div
    v-if="benefitsData?.list?.length > 0"
    class="ui-cms-benefit"
    :style="`--background-color: ${data.backgroundColor}`"
  >
    <div
      v-for="benefit in benefitsData.list"
      :key="benefit.id"
      v-storyblok-editable="benefit.data"
      class="ui-cms-benefit__item"
    >
      <template v-if="isMobileRef">
        <div class="ui-cms-benefit__content ui-cms-benefit__content--mobile">
          <div class="ui-cms-benefit__headline">
            {{ benefit.ctaText }}
          </div>
          <p class="ui-cms-benefit__text">{{ benefit.descriptionMobile || benefit.descriptionDesktop }}</p>
        </div>
      </template>
      <template v-else>
        <UiImage v-storyblok-editable="benefit.data" :image="benefit.image" cover />
        <div v-show="benefit.ctaText" class="ui-cms-benefit__headline ui-cms-benefit__headline--visible">
          {{ benefit.ctaText }}
        </div>
        <div class="ui-cms-benefit__content">
          <div class="ui-cms-benefit__headline">
            {{ benefit.ctaText }}
          </div>
          <p class="ui-cms-benefit__text">{{ benefit.descriptionDesktop }}</p>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ui-cms-benefit {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  row-gap: $space-8;
  column-gap: $space-8;

  @include apply-upto(medium) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include apply-upto(small) {
    grid-template-columns: 1fr;
  }
}

.ui-cms-benefit__headline {
  @include text-style(strong);
  background: $black;
  color: $white;
  text-transform: uppercase;
  padding: $space5 $space-16;
  transition: opacity 0.3s ease;
  transform: translateY(1px);
  opacity: 1;
  width: 100%;
}

.ui-cms-benefit__headline--visible {
  position: absolute;
  bottom: 0;
}

.ui-cms-benefit__content {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  background: var(--background-color);
  transition: opacity 0.3s ease;
  transform: translateZ(0);
  color: $white;
  overflow-y: auto;
}

.ui-cms-benefit__text-headline {
  @include text-style(strong);

  text-transform: uppercase;
  padding-bottom: $space5;
}

.ui-cms-benefit__text {
  padding: $space-16;
  margin: 0;
}

.ui-cms-benefit__item {
  position: relative;

  &:hover {
    .ui-cms-benefit__content {
      opacity: 1;
    }

    .ui-cms-benefit__headline--visible {
      opacity: 0;
    }
  }
}

.ui-cms-benefit__content--mobile {
  position: relative;
  opacity: 1;
}
</style>
