<script setup lang="ts">
import useUiFormElement from '@mop/ui/composables/useUiFormElement';
import type { UiFormSelectItem } from '@mop/ui/types/uiComponents';

defineOptions({
  name: 'UiFormSelect',
  inheritAttrs: false,
});

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: null,
  },
  name: {
    type: String,
    default: null,
  },
  items: {
    type: Array as PropType<UiFormSelectItem[]>,
    default: null,
  },
  rules: {
    type: Array,
    default: null,
  },
  disabledDefaultText: {
    type: String,
    default: null,
  },
  hideErrors: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const { class: className, ...attributes } = useAttrs();
const { validationErrors, register, validate } = useUiFormElement(props);
defineExpose({ validate });

async function handleChange(event: Event) {
  const value = (event.target as HTMLSelectElement).value;
  emit('update:modelValue', value);
  await nextTick();
  await validate();
}

onBeforeMount(() => {
  register(getCurrentInstance());
});
</script>

<template>
  <div :class="`form-select ${className}`">
    <select
      v-bind="attributes"
      :name="name"
      :value="modelValue"
      class="form-input form-select__select"
      @change="handleChange($event)"
    >
      <option
        v-if="disabledDefaultText"
        class="form-select__select-option form-select__select-disabled"
        value=""
        disabled
        :selected="!items.map((item) => item.value).includes(modelValue)"
      >
        {{ disabledDefaultText }}
      </option>
      <option
        v-for="(item, index) in items"
        :key="index"
        class="form-select__select-option"
        :value="item.value"
        :selected="modelValue === item.value"
        v-bind="item.data"
      >
        {{ item.text }}
      </option>
    </select>

    <i class="form-select__icon icon icon--arrow-select" />

    <div v-if="!hideErrors && validationErrors.length" class="form-error form-error--select">
      {{ $mopI18n.t(validationErrors[0]) }}
    </div>
  </div>
</template>
