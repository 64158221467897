import { logWarning } from '@mop/shared/utils/logger';
import { localStorageGet, localStorageSet } from '@mop/shared/utils/localStorage';
import { variantListModel } from '@/models';
import type { Alternate } from '@/types/cms';
import { globalEShopLocale } from '@/i18n/localeList';
import { getPrice } from '@/models/utils/priceUtils';
import type {
  Badge,
  ProductModel,
  ProductListResponseData,
  VariantModel,
  ProductResponseData,
  ProductAttributes,
  ProductAdvancedAttributes,
  Price,
  ProductData,
  ProductImage,
  Availability,
  EditorNotes,
  ModelSize,
  SellingPointRange,
  DeviationType,
  ProductDataPopularityFlag,
} from '@/types/product';

const SUSTAINABILITY_FLAG_TRANSLATION_KEY = 'product.flag.sustainable';
const SOON_AVAILABLE_TRANSLATION_KEY = 'product.flag.soon_available';
const SOLD_OUT_TRANSLATION_KEY = 'product.stock.soldout';

export function productModel(responseData: ProductResponseData | null): ProductModel {
  const response: ProductResponseData = responseData ?? {};
  const attributes: ProductAttributes | undefined = response.data?.attributes;
  const advancedAttributes: ProductAdvancedAttributes | undefined = response.data?.advancedAttributes;

  const localCache: {
    breadcrumbs?: any;
    editorNotes?: EditorNotes;
    imageUrlsPDP?: ProductImage[];
    sizes?: any;
    soldOut?: boolean;
    soonAvailable?: boolean;
    isInTransit?: boolean;
    swatches?: ProductModel[];
    variants?: VariantModel[];
    flag?: string;
    alternates?: Alternate[];
  } = {
    sizes: [],
    breadcrumbs: {},
  };

  return {
    isInitialized(): boolean {
      // @ts-ignore
      return responseData !== null && response?.code === undefined;
    },

    hasError(): boolean {
      // @ts-ignore
      return response.error !== undefined || response?.code === 0;
    },

    hasErrorNotFound(): boolean {
      return response.error?.code === constants.ERROR_CODE.NOT_FOUND;
    },

    isPromoQualified(): boolean {
      return attributes?.promoQualifier === 'true';
    },

    getAyId(): number {
      return response.data?.id ?? 0;
    },

    getMopId(): string {
      return response.data?.referenceKey ?? '';
    },

    getMopMasterId(): string {
      return (response.data?.masterKey || this.getMopId().split('_')?.[0]) ?? '';
    },

    // Datasource: CMS -> global
    getVimeoId(): string {
      return response.data?.vimeoId ?? '';
    },

    // Datasource: CMS -> global
    getPopularityFlag(showOnSaleProducts = false): ProductDataPopularityFlag | undefined {
      if (this.isSoonAvailable() || this.isInTransit()) {
        return;
      }
      if (!showOnSaleProducts && this.hasDiscount()) {
        return;
      }
      return response.data?.popularityFlag;
    },

    getName(): string {
      return advancedAttributes?.displayName ?? '';
    },

    getShortDescription(): string {
      return advancedAttributes?.shortDescription ?? '';
    },

    getCompositeName(): string {
      return [this.getName(), this.getShortDescription()].join(' ');
    },

    isMen() {
      return this.getGender() === 'M';
    },

    isWomen() {
      return this.getGender() === 'W';
    },

    getGender() {
      return (attributes?.gender ?? '') as 'M' | 'W' | '';
    },

    getBrandFromId(): string {
      const brandId: number = parseInt(this.getBrandId());
      const brandMap: any = {
        200: 'DENIM',
        300: 'PURE',
        700: '(PURE)',
        400: 'MR.',
        500: 'JUNIOR',
        600: 'HOME',
      };
      return brandMap[brandId] ?? '';
    },

    getSimpleBrandName(): string {
      const subBrand: string | null = this.getBrandFromId();
      return subBrand || constants.BRAND_NAME_DEFAULT;
    },

    getBrandName(): string {
      const brand: string = constants.BRAND_NAME_DEFAULT;
      const subBrand: string | null = this.getBrandFromId();
      return subBrand === '' ? brand : `${brand} ${subBrand}`;
    },

    getShortBrandName(): string {
      const shortBrandName: string = constants.BRAND_NAME_DEFAULT_SHORT;
      const brand: string = constants.BRAND_NAME_DEFAULT;
      const subBrand: string | null = this.getBrandFromId();
      return subBrand === '' ? brand : `${shortBrandName} ${subBrand}`;
    },

    getBrandId(): string {
      return attributes?.brandId ?? '';
    },

    getCareInstructions(): string[] {
      return attributes?.careInstructionsId || [];
    },

    getMopCategoryIds(): string[] {
      return attributes?.mopShopcategoryId || [];
    },

    getPrimaryCategoryId(): string {
      return advancedAttributes?.primaryCategory || '';
    },

    isOutlet(): boolean {
      const legacyOutletCheck: boolean = /outlet/i.test(attributes?.badgeText ?? '');
      const outletCheck: boolean = this.getLifecycleFlag().toLocaleLowerCase() === 'outlet';
      return outletCheck || legacyOutletCheck;
    },

    getLifecycleFlag(): string {
      return attributes?.lifecycleFlag || '';
    },

    isNew(): boolean {
      return response.data?.isNew === true;
    },

    // tile v2
    getCustomFlag(): string {
      const { customProductLabel } = attributes ?? {};
      if (customProductLabel) {
        return customProductLabel;
      }
      return this.getSustainabilityFlag();
    },

    // tile v2
    getSustainabilityFlag(): string {
      // More granular sustaiability flags will be implemented here
      return this.getSustainabilityId() ? SUSTAINABILITY_FLAG_TRANSLATION_KEY : '';
    },

    // badge = lifecycle flag
    getBadge(): Badge | null {
      if (this.getLifecycleFlag()) {
        return {
          sale: false,
          text: `product.badge.${this.getLifecycleFlag().toLowerCase()}`,
        };
      }
      if (this.isNew()) {
        return {
          sale: false,
          text: 'product.badge.new',
        };
      }
      if (this.hasDiscount()) {
        return {
          sale: true,
          text: 'product.badge.sale',
        };
      }
      return null;
    },

    hasDiscount() {
      return (this.getPrice().discountPercentage || 0) > 0;
    },

    getPrice(): Price {
      return getPrice(response.data?.priceRange?.min);
    },

    isPriceRange(): boolean {
      return response.data?.priceRange?.min?.withTax !== response.data?.priceRange?.max?.withTax;
    },

    getSeason(): string {
      return attributes?.season ?? '';
    },

    isSoldOut(): boolean {
      if (localCache.soldOut !== undefined) {
        return localCache.soldOut;
      }
      localCache.soldOut = response.data?.isSoldOut === true;
      return localCache.soldOut;
    },

    isSoonAvailable(): boolean {
      if (localCache.soonAvailable !== undefined) {
        return localCache.soonAvailable;
      }
      const variants: VariantModel[] = this.getVariants();
      localCache.soonAvailable =
        variants.length > 0 && variants.every((variant) => variant.getAvailability().isComingSoon);
      return localCache.soonAvailable;
    },

    isInTransit(): boolean {
      if (localCache.isInTransit !== undefined) {
        return localCache.isInTransit;
      }
      const variants: VariantModel[] = this.getVariants();
      localCache.isInTransit =
        variants.length > 0 && variants.every((variant) => variant.getAvailability().isInTransit);
      return localCache.isInTransit;
    },

    getImageList(): ProductImage[] {
      // Hide images of type 4.7 && 5.1
      const images: ProductImage[] = (response.data?.images ?? []).filter((image) => {
        if (
          (image.division === PRODUCT_IMAGE_DIVISION.CASUAL && image.index === PRODUCT_IMAGE_INDEX.BUST) ||
          (image.division === PRODUCT_IMAGE_DIVISION.LICENSE && image.index === PRODUCT_IMAGE_INDEX.STANDARD)
        ) {
          return false;
        }
        return true;
      });
      return images;
    },

    getImageByIndex(index = PRODUCT_IMAGE_INDEX.STANDARD, division = PRODUCT_IMAGE_DIVISION.STANDARD): string {
      const imageList: ProductImage[] = this.getImageList();
      const fallbackIndex: number =
        index === PRODUCT_IMAGE_INDEX.STANDARD ? PRODUCT_IMAGE_INDEX.BACK : PRODUCT_IMAGE_INDEX.STANDARD;
      return (
        // exception to allow selecting from CMS 4.7 and 5.1
        ((response.data?.images ?? []) as ProductImage[]).find(
          (image: ProductImage) => image.index === index && image.division === division,
        )?.src ||
        imageList.find((image: ProductImage) => image.index === index)?.src ||
        imageList.find((image: ProductImage) => image.index === fallbackIndex)?.src ||
        imageList[0]?.src
      );
    },

    getStandardImage(): string {
      return this.getImageByIndex(PRODUCT_IMAGE_INDEX.STANDARD);
    },

    getImageIndexForRecos(isHover = false): number {
      const imageList: ProductImage[] = this.getImageList();
      if (imageList.findIndex((image) => image.division === PRODUCT_IMAGE_DIVISION.STANDARD) !== -1) {
        return isHover ? PRODUCT_IMAGE_INDEX.DETAIL : PRODUCT_IMAGE_INDEX.STANDARD;
      }
      return isHover ? PRODUCT_IMAGE_INDEX.STANDARD : PRODUCT_IMAGE_INDEX.BUST;
    },

    getImageListForPDP(): ProductImage[] {
      if (localCache.imageUrlsPDP !== undefined) {
        return localCache.imageUrlsPDP;
      }
      const imageList: ProductImage[] = this.getImageList();
      const sortedImageList: ProductImage[] = [...imageList];
      // rule is to swap index 2 and 4 places if both available
      const imageIndex4: number = sortedImageList.findIndex((image) => image.index === PRODUCT_IMAGE_INDEX.DETAIL);
      const imageIndex2: number = sortedImageList.findIndex((image) => image.index === PRODUCT_IMAGE_INDEX.BACK);
      if (imageIndex2 >= 0 && imageIndex4 >= 0) {
        sortedImageList.splice(imageIndex2, 1, imageList[imageIndex4]);
        sortedImageList.splice(imageIndex4, 1, imageList[imageIndex2]);
      }
      localCache.imageUrlsPDP = sortedImageList;
      return sortedImageList;
    },

    getSwatchImage(): string {
      return response.data?.swatchImage ?? '';
    },

    getLongDescription(): string {
      return advancedAttributes?.longDescription ?? '';
    },

    getFitting(): string {
      return attributes?.fitting ?? '';
    },

    getColorId(): string {
      return advancedAttributes?.colorId ?? '';
    },

    getColorName(): string {
      return advancedAttributes?.colorName ?? '';
    },

    getGiftCardColorFromId(): string {
      // 100 is general gift card with white bg
      return this.isGiftCard() && this.getColorId() === '100' ? 'white' : '';
    },

    getVariants(): VariantModel[] {
      if (localCache.variants) {
        return localCache.variants;
      }

      localCache.variants =
        response.data?.variants === undefined
          ? variantListModel(null).getVariantModelList()
          : variantListModel({
              data: response.data.variants,
            }).getVariantModelList();
      return localCache.variants;
    },

    getSizes(isComingSoon = false): string[] {
      const cacheKey: number = isComingSoon ? 1 : 0;
      if (localCache.sizes[cacheKey]) {
        return localCache.sizes[cacheKey];
      }
      const variantList: VariantModel[] = this.getVariants();
      const sizes: string[] = variantList.reduce((sizeList: string[], variant: VariantModel) => {
        const availability: Availability = variant.getAvailability();
        if (isComingSoon || availability.isInStock || availability.isComingSoon) {
          sizeList.push(variant.getSize());
        }
        return sizeList;
      }, []);
      sortSizes(sizes);
      localCache.sizes[cacheKey] = sizes;
      return sizes;
    },

    getSwatches(): ProductModel[] {
      if (localCache.swatches) {
        return localCache.swatches;
      }
      if (!response.data?.siblings) {
        localCache.swatches = [productModel(responseData)];
      } else {
        localCache.swatches = response.data.siblings
          .reduce(
            (list: ProductModel[], productData: ProductData) => {
              list.push(productModel({ data: productData }));
              return list;
            },
            [productModel(responseData)],
          )
          .sort((productA: ProductModel, productB: ProductModel) => {
            // Lowercase because of weird safari behaviour
            const colorNameA = `${productA.getColorName().toLowerCase()}-${productA.getAyId()}`;
            const colorNameB = `${productB.getColorName().toLowerCase()}-${productB.getAyId()}`;
            return colorNameA.localeCompare(colorNameB);
          });
      }
      return localCache.swatches;
    },

    getRefinementColorName(): string {
      return attributes?.refinementColor?.[0] ?? '';
    },

    getSlug(): string {
      return advancedAttributes?.slug ?? '';
    },

    getUrl(size?: string): string {
      let sizeHash = '';
      if (size !== undefined && size.length > 0) {
        sizeHash = `#size=${encodeURIComponent(size)}`;
      }
      let prefix = `/${constants.PDP_SLUG_PREFIX}`;
      if (this.isGiftCard()) {
        prefix = `/${URLS.GIFT_CARD}/`;
      }
      return `${prefix}${this.getSlug()}${decodeURIComponent(sizeHash.replace(/%20/g, ''))}`;
    },

    getAvailabilityFlag(showSoldOutFlag = true) {
      if (this.isSoonAvailable() || this.isInTransit()) {
        return SOON_AVAILABLE_TRANSLATION_KEY;
      }
      if (showSoldOutFlag && this.isSoldOut()) {
        return SOLD_OUT_TRANSLATION_KEY;
      }
    },

    // flag = custom flag
    getFlag(showSoldOutFlag = true): string | undefined {
      if (showSoldOutFlag && localCache.flag !== undefined) {
        return localCache.flag;
      }
      const availabilityFlag: string | undefined = this.getAvailabilityFlag(showSoldOutFlag);
      if (availabilityFlag) {
        localCache.flag = availabilityFlag;
        return localCache.flag;
      }
      localCache.flag = this.getFlagWithoutAvailability();
      return localCache.flag;
    },

    getFlagWithoutAvailability(): string | undefined {
      const { customCampaignLabel, customProductLabel } = attributes ?? {};
      if (customCampaignLabel) {
        return customCampaignLabel;
      }
      if (customProductLabel) {
        return customProductLabel;
      }
      if (this.getSustainabilityId()) {
        return SUSTAINABILITY_FLAG_TRANSLATION_KEY;
      }
    },

    hasSustainabilityFlag() {
      return this.getFlag() === SUSTAINABILITY_FLAG_TRANSLATION_KEY;
    },

    hasSustainabilityFlagWithoutAvailability() {
      return this.getFlagWithoutAvailability() === SUSTAINABILITY_FLAG_TRANSLATION_KEY;
    },

    getBreadcrumbPath(mopId = '.', checkCategoryCb?: Function): string {
      // gtm tracker calls without category id and cache gets wrong when navigating from plp
      let cache: string | undefined = localCache.breadcrumbs[mopId];
      if (cache) {
        return cache;
      }
      const categories: string[] = this.getMopCategoryIds();
      if (!categories || categories.length === 0) {
        return '';
      }
      // try to find breadcrumb for given category
      if (mopId !== '.') {
        cache = categories.find((id) => mopId === id);
      }

      cache ??= categories.reduce((longestPath: string, category: string) => {
        if (
          /unisex|sale|outlet|new|seo|test/gi.test(category) ||
          category.split('-').length <= longestPath.split('-').length
        ) {
          return longestPath;
        }
        if (checkCategoryCb) {
          return checkCategoryCb(category) ? category : longestPath;
        }
        return category;
      }, '');
      localCache.breadcrumbs[mopId] = cache;
      return cache;
    },

    getLongestDenimCategory(): string {
      const categories: string[] = this.getMopCategoryIds();
      if (!categories || categories.length === 0) {
        return '';
      }

      return categories.reduce((longestPath: string, category: string) => {
        if (category.includes(MOP_CATEGORY_IDS.DENIM) && category.split('-').length >= longestPath.split('-').length) {
          return category;
        }
        return longestPath;
      }, '');
    },

    getAlternates(): Alternate[] {
      if (localCache.alternates) {
        return localCache.alternates;
      }
      const slugs: string[] = advancedAttributes?.alternate ?? [];
      if (!slugs || slugs.length === 0) {
        return [];
      }

      const defaultGlobalEAlternate: string | undefined = slugs.find(
        (slug) => slug.split(':')[0].toLowerCase() === globalEShopLocale,
      );
      // appending forced entries to slugs
      if (defaultGlobalEAlternate) {
        constants.FORCED_ALTERNATE_SLUGS.forEach((forceEntry) => {
          slugs.push(`${forceEntry}:${defaultGlobalEAlternate.split(':')[1]}`);
        });
      }

      localCache.alternates = slugs.reduce((list: Alternate[], slug: string) => {
        const [locale, href]: string[] = slug.split(':');
        if (!locale || !href) {
          logWarning(`Product alternate "${slug}" on product ${this.getMopId()} is broken.`);
          return list;
        }
        const lang: string = locale.toLocaleLowerCase();
        const langSplit: string[] = lang?.split('-') || [];
        if (langSplit.length !== 2) {
          return list;
        }
        list.push({
          href: `/${constants.PDP_SLUG_PREFIX}${href}`,
          lang,
        });
        return list;
      }, []);

      return localCache.alternates;
    },

    getSellingPoints(range: SellingPointRange): string[] {
      const ranges: any = {
        material: {
          from: 0,
          to: 7,
        },
        sizeFit: {
          from: 8,
          to: 13,
        },
        deviatingFit: {
          from: 14,
          to: 14,
        },
      };
      const { from, to } = ranges[range];
      if (!advancedAttributes) {
        return [];
      }
      const matchSellingPointsRegex = /^(sellingPoints)([1-9]|1[0-4])$/;
      const sellingPoints: string[] = Object.keys(advancedAttributes)
        .sort()
        .reduce((sellingPointList: string[], key) => {
          const attributeKey = key as keyof ProductAdvancedAttributes;
          const attributeValue = advancedAttributes[attributeKey] as string;
          const matchSellingPointsAttribute: string[] | null = matchSellingPointsRegex.exec(attributeKey);
          const sellingPointsAttributeIndex: number = parseInt(matchSellingPointsAttribute?.[2] ?? '-1');
          if (
            attributeValue &&
            sellingPointsAttributeIndex >= from &&
            sellingPointsAttributeIndex <= to &&
            !sellingPointList.includes(attributeValue)
          ) {
            sellingPointList.push(attributeValue);
          }
          return sellingPointList;
        }, []);

      return sellingPoints;
    },
    getDeviatingFit(): DeviationType | null {
      const deviatingFit: number = parseInt(this.getSellingPoints('deviatingFit')?.[0]);
      if (deviatingFit === -1) {
        return 'small';
      }
      if (deviatingFit === 1) {
        return 'large';
      }
      return null;
    },
    getSustainabilityId(): string {
      const { sustainability } = attributes ?? {};
      return sustainability?.find((key) => key !== '00') || '';
    },

    getSustainableCertificatePercentage(): string {
      return advancedAttributes?.sustainableCertificatePercentage ?? '';
    },

    getSustainableCertificateName(): string {
      return advancedAttributes?.sustainableCertificateName ?? '';
    },

    getDebugData(): ProductData | undefined {
      return response.data;
    },

    getEditorNotes(): EditorNotes | undefined {
      if (localCache.editorNotes !== undefined) {
        return localCache.editorNotes;
      }
      const { marketingHeadline, marketingSubline, marketingText } = advancedAttributes ?? {};
      if (!marketingHeadline && !marketingHeadline && !marketingText) {
        return;
      }
      localCache.editorNotes = {
        headline: marketingHeadline ?? null,
        subline: marketingSubline ?? null,
        text: marketingText ?? null,
      };
      return localCache.editorNotes;
    },

    getModelSize(): ModelSize | null {
      const { modelSize, patternSize } = advancedAttributes ?? {};

      if (!modelSize || !patternSize) {
        return null;
      }

      return {
        modelSize,
        patternSize,
      } as ModelSize;
    },

    getModelAlsoWearsProductIds(colorId: string): string[] {
      type ProductModelAlsoWearsData = {
        [key: string]: string[];
      };
      const modelAlsoWearsString: string = advancedAttributes?.modelAlsoWears ?? '';
      let modelAlsoWears: ProductModelAlsoWearsData;
      if (modelAlsoWearsString.length === 0) {
        return [];
      }
      try {
        modelAlsoWears = JSON.parse(modelAlsoWearsString);
      } catch (error) {
        logWarning(`Attribute 'modelAlsoWears' on product ${this.getMopId()} is not a valid JSON.`);
        return [];
      }
      const getKeyValue: any =
        <T extends object, U extends keyof T>(obj: T) =>
        (key: U) =>
          obj[key];
      const productIdList: string[] = getKeyValue(modelAlsoWears)(colorId);
      return productIdList ?? [];
    },

    isProduct(): boolean {
      return !this.isGiftCard() && !this.isCorrectionGlasses();
    },

    isGiftCard(): boolean {
      return attributes?.giftCard === 'true';
    },

    isCorrectionGlasses(): boolean {
      const ids = ['women-accessories-correction-glasses', 'men-accessories-correction-glasses'];
      return this.getMopCategoryIds().some((id) => ids.includes(id)) || ids.includes(this.getPrimaryCategoryId());
    },

    isFragrance(): boolean {
      return this.getMopCategoryIds().some((id) => id.includes('fragrance'));
    },

    isDenim(): boolean {
      return this.getBrandId() === '200';
    },

    storeProductAsTriedWithVirtualTryOn() {
      const id = this.getVirtualTryOnSku();
      const availableProducts = JSON.parse(localStorageGet(constants.LOCAL_STORAGE.VIRTUAL_TRY_ON) || '[]');
      if (availableProducts.includes(id)) {
        return;
      }
      availableProducts.push(id);
      localStorageSet(constants.LOCAL_STORAGE.VIRTUAL_TRY_ON, JSON.stringify(availableProducts));
    },

    getVirtualTryOnSku(): string {
      return `${this.getMopMasterId()}_${this.getColorId()}`;
    },

    isProductTriedWithVirtualTryOn(): boolean {
      const id = this.getVirtualTryOnSku();
      const availableProducts = JSON.parse(localStorageGet(constants.LOCAL_STORAGE.VIRTUAL_TRY_ON) || '[]');
      return availableProducts.includes(id);
    },

    isVirtualTryOnEnabled(): boolean {
      return attributes?.virtualTryOn?.toLocaleLowerCase() === 'true';
    },

    isDesignedForCircularity(): boolean {
      return attributes?.designedforCircularity?.toLocaleLowerCase() === 'true';
    },
  };
}

export function productListModel(responseData: ProductListResponseData | null) {
  const response: ProductListResponseData = responseData ?? {};
  let list: ProductModel[] = [];
  return {
    isInitialized(): boolean {
      return responseData !== null;
    },

    hasError(): boolean {
      return response.error !== undefined;
    },

    getProductModelList(): ProductModel[] {
      if (response.data === undefined || list.length > 0) {
        return list;
      }
      list = response.data.map((product) => productModel({ data: product }));
      return list;
    },

    getDebugData(): ProductListResponseData {
      return response;
    },
  };
}
