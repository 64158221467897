<script setup lang="ts">
import { getElementUiObject, getHtmlFromTable } from '@mop/cms/utils/utils';
import type { CmsUiSizeTable } from './uiCmsHtml';

defineOptions({
  name: 'UiCmsTable',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const data: any = props.data;

const element: CmsUiSizeTable = getElementUiObject(data, {
  html: getHtmlFromTable(data.table, data.headline),
});
</script>

<template>
  <div v-if="element.html" v-storyblok-editable="element" class="ui-cms-padding" v-html="element.html" />
</template>

<style lang="scss" scoped>
.ui-cms-padding {
  padding: 0 $global-padding;
}
</style>
