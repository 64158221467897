<script setup lang="ts">
import { getElementUiObject, getCard } from '@mop/cms/utils/utils';
import type { CmsUiTripleCard } from './uiCmsCard';

defineOptions({
  name: 'UiCmsTripleCard',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  lazyLoad: {
    type: Boolean,
    default: false,
  },
});

const data: any = props.data;

const imageType = 'cms-three-column-no-ratio';
const element: CmsUiTripleCard = getElementUiObject(data, {
  left: getCard(data.cards?.[0]),
  center: getCard(data.cards?.[1]),
  right: getCard(data.cards?.[2]),
});
if (element.left) {
  element.left.imageType = imageType;
  element.left.headline.level ??= 2;
}
if (element.center) {
  element.center.imageType = imageType;
  element.center.headline.level ??= 2;
}
if (element.right) {
  element.right.imageType = imageType;
  element.right.headline.level ??= 2;
}
</script>

<template>
  <div v-if="element.left && element.center && element.right" v-storyblok-editable="element" class="ui-cms-triple-card">
    <div class="ui-cms-triple-card__item">
      <UiCmsCardItem
        :data="element.left"
        :lazy-load="lazyLoad"
        :data-track-element-item="`${element.left.media?.trackingId}|1`"
      />
    </div>
    <div class="ui-cms-triple-card__item">
      <UiCmsCardItem
        :data="element.center"
        :lazy-load="lazyLoad"
        :data-track-element-item="`${element.center.media?.trackingId}|2`"
      />
    </div>
    <div class="ui-cms-triple-card__item">
      <UiCmsCardItem
        :data="element.right"
        :lazy-load="lazyLoad"
        :data-track-element-item="`${element.right.media?.trackingId}|3`"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ui-cms-triple-card {
  display: flex;
  flex-wrap: wrap;
  background-color: $white;
}

.ui-cms-triple-card__item {
  flex-basis: columns(12);
  width: columns(12);

  @include apply-upto(small) {
    flex-basis: 100%;
    width: 100%;
  }

  :deep(.ui-cms-card-item__headline--serif-condensed) {
    @include apply-upto(medium) {
      font-size: 22px;
    }
  }
}
</style>
