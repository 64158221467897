/**
 * Detailed redirect and country selector logic described here:
 * https://marc-o-polo.atlassian.net/wiki/spaces/AYC/pages/1374888220/Global+Redirect+Handling
 */
import { localStorageSet, localStorageGet } from '@mop/shared/utils/localStorage';
import { securedWrap } from '@mop/shared/utils/securedWrap';
import type { ServerHeaderResponseData } from '@/types/header';

export default function useMopCountrySelector() {
  const nuxtApp = useNuxtApp();
  async function enforceCountrySelect() {
    if (nuxtApp.$storyblokLivePreview.isEnabled) {
      return false;
    }

    const selectedCountry = getSelectedCountry();
    let headerCountry = getLocalisationCountry();
    if (selectedCountry && headerCountry) {
      return false;
    }

    const headers: ServerHeaderResponseData = await nuxtApp.$apiLocal.getHeaders();
    headerCountry = headers.data?.country?.toLowerCase();
    if (!headerCountry) {
      return false;
    }

    localStorageSet(constants.LOCAL_STORAGE.COUNTRY_LOCALISATION, headerCountry);
    const isAvalableCountry: boolean = nuxtApp.$mopI18n.availableCountries.includes(headerCountry);
    if (!isAvalableCountry) {
      return false;
    }

    return headerCountry !== nuxtApp.$mopI18n.country;
  }

  function openCountrySelector(callbackFn?: Function) {
    return useMopOverlay().open({
      type: 'centered',
      componentName: 'MopCountrySelector',
      urlHash: OVERLAYS.COUNTRY.URL_HASH,
      onClose: () => {
        callbackFn && callbackFn();
      },
    });
  }

  function storeSelectedCountry(country?: string) {
    const selectedCountry: string = country || nuxtApp.$mopI18n.country;
    localStorageSet(constants.LOCAL_STORAGE.COUNTRY_SELECTED, selectedCountry);
  }

  function getSelectedCountry(): string | undefined {
    return getCountryFromLocalStorage(constants.LOCAL_STORAGE.COUNTRY_SELECTED);
  }

  function getLocalisationCountry(): string | undefined {
    return getCountryFromLocalStorage(constants.LOCAL_STORAGE.COUNTRY_LOCALISATION);
  }

  function getCountryFromLocalStorage(name: string): string | undefined {
    const country = localStorageGet(name)?.toLowerCase();
    if (!country || !nuxtApp.$mopI18n.availableCountries.includes(country)) {
      return;
    }
    return country;
  }

  return securedWrap({
    openCountrySelector,
    enforceCountrySelect,
    storeSelectedCountry,
    getSelectedCountry,
    getLocalisationCountry,
  });
}
