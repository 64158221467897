<script setup lang="ts">
import type { CmsPromoTile } from '@/types/cms';

defineOptions({
  name: 'MopCmsTextPromotionItem',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const element = getCmsUiPromoTextTile(props.data) as CmsPromoTile;
</script>

<template>
  <MopTile :data="element" />
</template>
