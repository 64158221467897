import type { AxiosError, AxiosInstance } from 'axios';
import { handleAxiosError, errorHandler } from '@/api/utils';
import type {
  CustomerResponseData,
  UpdateEmailPayload,
  UpdatePasswordPayload,
  UpdatePersonalDataPayload,
  UpdateCustomData,
} from '@/types/customer';
import type { AddressResponseData, UpdateAddressData, CreateAddressData } from '@/types/address';
const API_NAME = 'AYC - customer';

export function getCustomer(apiInstance: AxiosInstance, accessToken: string): Promise<CustomerResponseData> {
  return errorHandler<CustomerResponseData>(async () => ({
    data: (
      await apiInstance.get('oauth/me', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getCustomer',
      accessToken,
    }),
  }));
}

export function getAddresses(
  apiInstance: AxiosInstance,
  accessToken: string,
  shopId: number,
  perPage = 10,
): Promise<AddressResponseData> {
  return errorHandler<AddressResponseData>(async () => ({
    data: (
      await apiInstance.get('oauth/customer/addresses', {
        headers: {
          'Content-Type': 'application/json',
          'x-shop-id': shopId,
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          perPage,
        },
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getAddresses',
      accessToken,
    }),
  }));
}

export function updatePersonalData(
  apiInstance: AxiosInstance,
  accessToken: string,
  payload: UpdatePersonalDataPayload,
): Promise<CustomerResponseData> {
  return errorHandler<CustomerResponseData>(async () => ({
    data: (
      await apiInstance.patch('customer/personal', payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'updatePersonalData',
      accessToken,
    }),
  }));
}

export function updatePassword(
  apiInstance: AxiosInstance,
  accessToken: string,
  payload: UpdatePasswordPayload,
): Promise<CustomerResponseData> {
  return errorHandler<CustomerResponseData>(async () => ({
    data: (
      await apiInstance.put('oauth/customer/password', payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'updatePassword',
      accessToken,
    }),
  }));
}

export function updateEmail(
  apiInstance: AxiosInstance,
  accessToken: string,
  payload: UpdateEmailPayload,
): Promise<CustomerResponseData> {
  return errorHandler<CustomerResponseData>(async () => ({
    data: (
      await apiInstance.patch('oauth/customer/email', payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'updateEmail',
      accessToken,
    }),
  }));
}

export function updateCustomData(
  apiInstance: AxiosInstance,
  accessToken: string,
  payload: UpdateCustomData,
): Promise<CustomerResponseData> {
  return errorHandler<CustomerResponseData>(async () => ({
    data: (
      await apiInstance.patch('customer/custom-data', payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'updateCustomData',
      accessToken,
    }),
  }));
}

export function updateAddress(
  apiInstance: AxiosInstance,
  accessToken: string,
  addressId: number,
  shopId: number,
  payload: UpdateAddressData,
): Promise<AddressResponseData> {
  return errorHandler<AddressResponseData>(async () => ({
    data: (
      await apiInstance.patch(`customer/address/${addressId}`, payload, {
        headers: {
          'Content-Type': 'application/json',
          'x-shop-id': shopId,
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'updateAddress',
      accessToken,
    }),
  }));
}

export function createAddress(
  apiInstance: AxiosInstance,
  accessToken: string,
  shopId: number,
  payload: CreateAddressData,
): Promise<AddressResponseData> {
  return errorHandler<AddressResponseData>(async () => ({
    data: (
      await apiInstance.post(`oauth/customer/address`, payload, {
        headers: {
          'Content-Type': 'application/json',
          'x-shop-id': shopId,
          Authorization: `Bearer ${accessToken}`,
        },
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'createAddress',
      accessToken,
    }),
  }));
}
