import type { CategoryData, CategoryDataProperties, BapiCategoryData } from '@/types/category';

export const defaultCategoryProperies: any = {
  includeNameInSearchSuggest: 'false',
  showInMobileMenu: 'true',
  showProductCount: 'false',
  categoryFlag: '',
  isLandingPage: 'false',
  mopId: '',
  alternate: '',
  redirect: '',
  customName: '',
  textColor: '',
  globalEBlacklist: [],
};

export const defaultPropertyNames: string[] = [
  'includeNameInSearchSuggest',
  'showInMobileMenu',
  'showProductCount',
  'categoryFlag',
  'isLandingPage',
  'globalEBlacklist',
  'mopId',
  'redirect',
  'customName',
  'textColor',
];

export function transformBapiCategoriesResponse(bapiCategories: any[]): CategoryData[] {
  return bapiCategories.map(function (bapiCategory: BapiCategoryData) {
    const category: CategoryData = {
      // To shrink dom size
      data: `${bapiCategory.id}|${bapiCategory.path}|${bapiCategory.name}|${bapiCategory.depth}|`,
    };
    if (bapiCategory.isHidden) {
      category.hidden = 1;
    }

    bapiCategory.properties?.forEach((prop: any) => {
      const propertyName: keyof CategoryDataProperties = prop.name;
      const defaultValue: string = defaultCategoryProperies[propertyName];
      if (defaultValue === prop.value) {
        return;
      }
      if (propertyName === 'mopId') {
        category.data += prop.value;
      } else if (prop.value === 'true') {
        category[propertyName] = 1;
      } else if (prop.value === 'false') {
        category[propertyName] = 0;
      } else {
        category[propertyName] = prop.value;
      }
    });

    if (bapiCategory.children?.length) {
      category.children = transformBapiCategoriesResponse(bapiCategory.children);
    }
    return category;
  });
}
