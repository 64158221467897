import type { GetWishlistParams } from '@/types/wishlist';
import type { GetCartParams } from '@/types/cart';
import type { ProductsSearchParameter, VariantWith } from '@/types/product';

const PRODUCT_DETAIL_FULL: ProductsSearchParameter = {
  with: {
    attributes: {
      withKey: [
        'brandId',
        'careInstructionsId',
        'gender',
        'mopShopcategoryId',
        'refinementColor',
        'season',
        'sustainability',
        'customCampaignLabel',
        'customProductLabel',
        'lifecycleFlag',
        'badgeText',
        'promoQualifier',
        'giftCard',
        'virtualTryOn',
        'designedforCircularity',
      ],
    },
    advancedAttributes: {
      withKey: [
        'alternate',
        'colorName',
        'colorId',
        'displayName',
        'shortDescription',
        'longDescription',
        'marketingHeadline',
        'marketingSubline',
        'marketingText',
        'modelAlsoWears',
        'patternSize',
        'sellingPoints1',
        'sellingPoints2',
        'sellingPoints3',
        'sellingPoints4',
        'sellingPoints5',
        'sellingPoints6',
        'sellingPoints7',
        'sellingPoints8',
        'sellingPoints9',
        'sellingPoints10',
        'sellingPoints11',
        'sellingPoints12',
        'sellingPoints13',
        'sellingPoints14',
        'slug',
        'modelSize',
        'primaryCategory',
        'sustainableCertificatePercentage',
        'sustainableCertificateName',
      ],
    },
    priceRange: true,
    variants: {
      advancedAttributes: 'all',
      attributes: 'all',
      lowestPriorPrice: true,
    },
    siblings: {
      advancedAttributes: {
        withKey: ['colorName', 'slug', 'colorId'],
      },
      attributes: {
        withKey: ['giftCard', 'virtualTryOn'],
      },
      images: {
        attributes: {
          withKey: ['viewType', 'division', 'index'],
        },
      },
    },
  },
  includeSoldOut: true,
  campaignKey: 'px',
};

const PURCHASE_TRACKING: ProductsSearchParameter = {
  with: {
    attributes: {
      withKey: ['brandId', 'gender', 'sustainability', 'season', 'virtualTryOn'],
    },
    advancedAttributes: {
      withKey: ['colorId', 'displayName', 'primaryCategory'],
    },
    priceRange: true,
    variants: {
      attributes: {
        withKey: ['size'],
      },
    },
  },
  includeSoldOut: true,
  campaignKey: 'px',
};

const PRODUCT_TILE: ProductsSearchParameter = {
  with: {
    advancedAttributes: {
      withKey: ['displayName', 'shortDescription', 'colorId', 'colorName', 'slug', 'primaryCategory'],
    },
    attributes: {
      withKey: [
        'brandId',
        'colorId',
        'refinementColor',
        'gender',
        'season',
        'sustainability',
        'customCampaignLabel',
        'customProductLabel',
        'lifecycleFlag',
        'mopShopcategoryId',
        'badgeText',
        'promoQualifier',
        'giftCard',
        'virtualTryOn',
      ],
    },
    priceRange: true,
  },
  campaignKey: 'px',
};

const PRODUCT_TILE_HOVER: ProductsSearchParameter = {
  with: {
    attributes: {
      withKey: ['giftCard'],
    },
    advancedAttributes: {
      withKey: ['colorName', 'colorId', 'slug'],
    },
    variants: {
      attributes: {
        withKey: ['size'],
      },
    },
    siblings: {
      advancedAttributes: {
        withKey: ['colorName', 'colorId', 'slug'],
      },
      attributes: {
        withKey: ['giftCard', 'refinementColor'],
      },
      variants: {
        attributes: {
          withKey: ['size'],
        },
      },
    },
  },
  campaignKey: 'px',
};

const PRODUCT_STOCK: ProductsSearchParameter = {
  with: {
    variants: {
      attributes: {
        withKey: ['size'],
      },
      lowestPriorPrice: true,
    },
  },
  includeSoldOut: true,
  campaignKey: 'px',
};

// this one is used to calculate total products, therefore as min data as possible
const FILTER_COUNT: ProductsSearchParameter = {
  pagination: {
    perPage: 1,
    page: 999999,
  },
  campaignKey: 'px',
};

const PRODUCT_TILE_GIVEAWAY: ProductsSearchParameter = {
  with: {
    advancedAttributes: {
      withKey: ['displayName', 'shortDescription', 'colorId', 'colorName', 'slug', 'primaryCategory'],
    },
    attributes: {
      withKey: [
        'brandId',
        'colorId',
        'refinementColor',
        'images',
        'colorDetail',
        'gender',
        'season',
        'mopShopcategoryId',
        'promoQualifier',
        'giftCard',
        'sustainability',
        'virtualTryOn',
      ],
    },
    variants: {
      attributes: {
        withKey: ['size', 'isSellableForFree', 'appliedPricePromotionKey'],
      },
    },
  },
  campaignKey: 'px',
  includeSellableForFree: true,
};

const PRODUCT_TILE_RESERVATION: ProductsSearchParameter = {
  with: {
    advancedAttributes: {
      withKey: ['displayName', 'shortDescription', 'colorName', 'slug', 'primaryCategory'],
    },
    priceRange: true,
    variants: {
      attributes: {
        withKey: ['size'],
      },
    },
  },
  campaignKey: 'px',
};

const BOOSTED_PRODUCTS: ProductsSearchParameter = {
  ...PRODUCT_TILE,
  pagination: {
    perPage: 500,
  },
};

const CART_PRODUCTS: GetCartParams = {
  with: {
    items: {
      product: {
        priceRange: true,
        attributes: {
          ...(PRODUCT_TILE_GIVEAWAY.with!.attributes as any),
        },
        advancedAttributes: {
          ...(PRODUCT_TILE_GIVEAWAY.with!.advancedAttributes as any),
        },
        variants: {
          attributes: {
            withKey: ['size'],
          },
        },
      },
      variant: {
        ...(PRODUCT_TILE_GIVEAWAY.with!.variants as VariantWith),
      },
    },
  },
  campaignKey: 'px',
  skipAvailabilityCheck: false,
};

const WISHLIST_PRODUCTS: GetWishlistParams = {
  with: {
    items: {
      product: PRODUCT_TILE?.with,
    },
  },
  campaignKey: 'px',
};

export default {
  PRODUCT_DETAIL_FULL,
  PRODUCT_TILE,
  PRODUCT_TILE_HOVER,
  PRODUCT_STOCK,
  PRODUCT_TILE_RESERVATION,
  PRODUCT_TILE_GIVEAWAY,
  FILTER_COUNT,
  BOOSTED_PRODUCTS,
  CART_PRODUCTS,
  PURCHASE_TRACKING,
  WISHLIST_PRODUCTS,
};
