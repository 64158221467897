import type {
  ProductDataResponse,
  VariantDataResponse,
  AdvancesAttributeValues,
  SimpleAttributeValue,
  ProductAttributes,
  ProductAdvancedAttributes,
  VariantAttributes,
  VariantAdvancedAttributes,
  ProductAttributesResponse,
  VariantAttributesResponse,
  ProductAdvancedAttributesResponse,
  VariantAdvancedAttributesResponse,
  ProductData,
  VariantData,
  Image,
  ProductImage,
} from '@/types/product';

function mapSimpleAttributes<T, V extends object>(attributes: V): T {
  const result: any = {};
  Object.keys(attributes).forEach((attribute) => {
    // @ts-ignore
    const attributeValue = attributes[attribute];
    if (Array.isArray(attributeValue.values)) {
      result[attribute] = attributeValue.values.map((value: SimpleAttributeValue) => value.label);
    } else {
      result[attribute] = attributeValue.values.label;
    }
  });
  return result;
}
function mapAdvancedAttributes<T, V extends object>(attributes: V, defaultValues: T): T {
  const result: any = {};
  Object.keys(attributes).forEach((attribute) => {
    // @ts-ignore
    const attributeValue: AdvancesAttributeValues = attributes[attribute];
    if (attributeValue === undefined || attributeValue.values[0].fieldSet[0] === undefined) {
      return;
    }

    // @ts-ignore
    if (Array.isArray(defaultValues[attribute])) {
      result[attribute] = attributeValue.values[0].fieldSet.map((obj) => obj[0]?.value).filter(Boolean);
      return;
    }
    if (!attributeValue.values[0].fieldSet[0]?.[0]?.value) {
      return;
    }
    result[attribute] = attributeValue.values[0].fieldSet[0][0].value;
  });
  return result;
}

function getProductAttributes(response: ProductDataResponse | undefined) {
  const attributes: ProductAttributesResponse | undefined = response?.attributes;
  if (attributes === undefined) {
    return {};
  }
  return mapSimpleAttributes<ProductAttributes, ProductAttributesResponse>(attributes);
}

function getVariantAttributes(response: VariantDataResponse | undefined) {
  const attributes: VariantAttributesResponse | undefined = response?.attributes;
  if (attributes === undefined) {
    return {};
  }
  return mapSimpleAttributes<VariantAttributes, VariantAttributesResponse>(attributes);
}

function getProductAdvancedAttributes(response: ProductDataResponse | undefined): ProductAdvancedAttributes {
  const attributes: ProductAdvancedAttributesResponse | undefined = response?.advancedAttributes;
  const defaultValues: ProductAdvancedAttributes = {
    displayName: '',
    shortDescription: '',
    longDescription: '',
    features: '',
    modelAlsoWears: '',
    alternate: [],
    slug: '',
    colorId: '',
    colorName: '',
    primaryCategory: '',
  };
  if (attributes === undefined) {
    return {};
  }
  return mapAdvancedAttributes<ProductAdvancedAttributes, ProductAdvancedAttributesResponse>(attributes, defaultValues);
}

function getVariantAdvancedAttributes(response: VariantDataResponse | undefined): VariantAdvancedAttributes {
  const attributes: VariantAdvancedAttributesResponse | undefined = response?.advancedAttributes;
  const defaultValues: VariantAdvancedAttributes = {};
  if (attributes === undefined) {
    return defaultValues;
  }
  return mapAdvancedAttributes<VariantAdvancedAttributes, VariantAdvancedAttributesResponse>(attributes, defaultValues);
}

function getProductImages(data: ProductDataResponse): ProductImage[] {
  return data.images
    .reduce((list: ProductImage[], image: Image) => {
      if (image.attributes?.viewType?.values?.value === 'hires') {
        const [division, index] = image.attributes?.index?.values.label.split('.') || [
          PRODUCT_IMAGE_DIVISION.STANDARD,
          PRODUCT_IMAGE_INDEX.STANDARD,
        ];
        list.push({
          index: parseInt(index),
          division: parseInt(division),
          src: image.hash,
        });
      }
      return list;
    }, [])
    .sort((imageA: ProductImage, imageB: ProductImage) => {
      if (imageA.index < imageB.index) {
        return -1;
      }
      if (imageA.index > imageB.index) {
        return 1;
      }
      return 0;
    });
}

function getSwatchImage(data: ProductDataResponse) {
  return data.images.find((image: Image) => image.attributes?.viewType?.values?.value === 'swatch')?.hash ?? '';
}

export function mapProductAttributes(data: ProductDataResponse): void {
  (data as unknown as ProductData).attributes = getProductAttributes(data);
  (data as unknown as ProductData).advancedAttributes = getProductAdvancedAttributes(data);
  (data as unknown as ProductData).swatchImage = getSwatchImage(data);
  (data as unknown as ProductData).images = getProductImages(data);
}

export function mapVariantAttributes(data: VariantDataResponse): void {
  (data as unknown as VariantData).attributes = getVariantAttributes(data);
  (data as unknown as VariantData).advancedAttributes = getVariantAdvancedAttributes(data);
}
