<script setup lang="ts">
import {
  getCta,
  getElementUiObject,
  getHeadlineWithAllOptions,
  getLink,
  getMedia,
  getHtmlFromRichText,
} from '@mop/cms/utils/utils';
import type { CmsUiImageText } from './uiCmsImageText';

defineOptions({
  name: 'UiCmsImageText',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  lazyLoad: {
    type: Boolean,
    default: false,
  },
});

const data = props.data;

const element: CmsUiImageText = getElementUiObject(data, {
  headline: getHeadlineWithAllOptions(data),
  headlineFontStyle: data.headlineFontStyle,
  headlineSize: data.headlineSize,
  subline: data.subline,
  text: getHtmlFromRichText(data.text),
  cta: getCta(data.cta?.[0]),
  link: getLink(data.link),
  textColor: data.textColor?.color,
  backgroundColor: data.backgroundColor?.color,
  textHorizontalAlignment: data.textHorizontalAlignment,
  textVerticalAlignment: data.textVerticalAlignment,
  showTextElementFirst: data.showTextElementFirst,
  media: getMedia(data.media?.[0], true),
});

if (element.media) {
  element.media.imageType = 'cms-two-column-no-ratio';
}

if (element.cta && ['buttonPrimary', 'buttonSecondary'].includes(element.cta.type || '')) {
  element.cta.type = 'arrowRight';
}

const headlineFontMap: Record<string, string> = {
  default: 'ui-cms-image-text__content-headline--default',
  serifCondensed: 'ui-cms-image-text__content-headline--serif-condensed',
};

const uiHeadlineFontClass = headlineFontMap[element.headlineFontStyle || 'default'];

const uiStyle = {
  '--color': element.textColor,
  backgroundColor: element.backgroundColor,
};
</script>

<template>
  <UiNuxtLink
    v-storyblok-editable="element"
    v-bind="element.link"
    :class="[
      'ui-cms-image-text',
      {
        'ui-cms-image-text--text-first': element.showTextElementFirst,
      },
    ]"
    :data-track-element-item="element.media?.trackingId"
  >
    <div class="ui-cms-image-text__image">
      <UiCmsMedia v-if="element.media" :data="element.media" :lazy="lazyLoad" />
    </div>
    <div class="ui-cms-image-text__content-wrapper">
      <div
        :class="[
          'ui-cms-image-text__content',
          element.textHorizontalAlignment ? `ui-cms-image-text__content--align-${element.textHorizontalAlignment}` : '',
          element.textVerticalAlignment
            ? `ui-cms-image-text__content--vertical-align-${element.textVerticalAlignment}`
            : '',
        ]"
        :style="uiStyle"
      >
        <div>
          <div v-if="element.subline" class="ui-cms-image-text__content-subline">
            {{ element.subline }}
          </div>
          <UiHeading
            v-if="element.headline.text"
            :class="[
              'ui-cms-image-text__content-headline',
              uiHeadlineFontClass,
              element.headlineSize ? `ui-cms-image-text__content-headline--${element.headlineSize}` : '',
              {
                'ui-cms-image-text__content-headline--uppercase': element.headline.isUppercase,
              },
            ]"
            :level="element.headline.level ?? 2"
          >
            {{ element.headline.text }}
          </UiHeading>

          <div v-if="element.text" class="ui-cms-image-text__content-text" v-html="element.text" />

          <UiCmsCta v-if="element.cta" :data="element.cta" no-padding />
        </div>
      </div>
    </div>
  </UiNuxtLink>
</template>

<style lang="scss" scoped>
.ui-cms-image-text {
  display: flex;
  flex-wrap: wrap;
  background-color: $white;
}

.ui-cms-image-text__image,
.ui-cms-image-text__content-wrapper {
  flex-basis: 50%;
  width: 50%;

  @include apply-upto(small) {
    flex-basis: 100%;
    width: 100%;
  }
}

.ui-cms-image-text--text-first {
  .ui-cms-image-text__content-wrapper {
    order: 1;
  }

  .ui-cms-image-text__image {
    order: 2;

    @include apply-upto(small) {
      order: 1;
    }
  }
}

.ui-cms-image-text__content {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: $global-padding;
  padding-left: $global-padding;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  @include apply-upto(small) {
    padding-top: $space10;
  }
}

.ui-cms-image-text__content--align-right {
  @include apply-from(large) {
    padding-right: columns(1, 100);
    padding-left: columns(1, 100);
  }
}

.ui-cms-image-text__content--vertical-align-top {
  align-items: flex-start;
}

.ui-cms-image-text__content--vertical-align-bottom {
  align-items: flex-end;
}

.ui-cms-image-text-content {
  width: 100%;
}

.ui-cms-image-text__content-subline {
  @include text-style(common);
}

.ui-cms-image-text__content-headline {
  @include font-smoothing(strong);
  @include text-style(headline, true);
  margin-top: 0;
  margin-bottom: 0;
}

.ui-cms-image-text__content-headline--small {
  @include text-style(headline-small, true);
}

.ui-cms-image-text__content-headline--uppercase {
  text-transform: uppercase;
}

.ui-cms-image-text__content-headline--serif-condensed {
  @include text-style(headline-serif-condensed, true);
}

.ui-cms-image-text__content-text {
  @include text-style(common);

  line-height: 17px;
  letter-spacing: 0.3px;

  :deep(p) {
    @include text-style(common);

    line-height: 17px;
    letter-spacing: 0.3px;
    margin: 0;
  }
}
</style>
