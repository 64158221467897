<script setup lang="ts">
import { getElementUiObject, getHtmlFromRichText } from '@mop/cms/utils/utils';
import type { CmsUiImprintText } from './uiCmsImprintText';

defineOptions({
  name: 'UiCmsImprintText',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const data: any = props.data;

const element: CmsUiImprintText = getElementUiObject(data, {
  richText: getHtmlFromRichText(data.richText),
  headline: data.headline,
});
</script>

<template>
  <div
    v-if="element.headline || element.richText"
    v-storyblok-editable="element"
    class="ui-cms-imprint-text ui-cms-padding"
  >
    <div v-if="element.headline" class="ui-cms-imprint-text__headline">
      {{ element.headline }}
    </div>
    <div v-if="element.richText" v-html="element.richText" />
  </div>
</template>

<style lang="scss" scoped>
.ui-cms-imprint-text {
  display: flex;
  flex-direction: column;
  margin-bottom: $space30;
}

.ui-cms-padding {
  padding: 0 $global-padding;
}

.ui-cms-imprint-text__headline {
  @include text-style(headline-3);
}
</style>
