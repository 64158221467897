import type { AxiosInstance } from 'axios';
import { getGooglePass, getApplePassBlob } from './apiWallet';

export function initApiWallet(apiInstance: AxiosInstance) {
  return {
    getGooglePass: (customerReferenceKey: string, customerPublicKey: string) =>
      getGooglePass(apiInstance, customerReferenceKey, customerPublicKey),
    getApplePassBlob: (customerReferenceKey: string, customerPublicKey: string) =>
      getApplePassBlob(apiInstance, customerReferenceKey, customerPublicKey),
  };
}

export type ApiWallet = ReturnType<typeof initApiWallet>;
