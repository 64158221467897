import type { AxiosError, AxiosInstance } from 'axios';
import { handleAxiosError, errorHandler } from '@/api/utils';
import type { StoreListResponseData, StoreSearchParams, OpticalStoreSearchParams } from '@/types/store';
const API_NAME = 'AWS - store finder';

export function getStores(apiInstance: AxiosInstance, params: StoreSearchParams): Promise<StoreListResponseData> {
  return errorHandler<StoreListResponseData>(async () => ({
    data: (
      await apiInstance.get('/stores', {
        params,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getStores',
      params,
    }),
  }));
}

export function getOpticalStores(
  apiInstance: AxiosInstance,
  params: OpticalStoreSearchParams,
): Promise<StoreListResponseData> {
  return errorHandler<StoreListResponseData>(async () => ({
    data: (
      await apiInstance.get('/optical-stores', {
        params,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getOpticalStores',
      params,
    }),
  }));
}
