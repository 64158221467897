import { createApp } from 'vue';

import UiNuxtLink from '@mop/ui/components/global/UiNuxtLink.vue';
import MopPageLoad from '@/components/mop/PageLoad.vue';
import MopCmsContentElementsSlot from '@/components/mop/cms/ContentElementsSlot.vue';

const app = createApp({});

const components: any = {
  MopPageLoad,
  UiNuxtLink,
  MopCmsContentElementsSlot,
};

Object.entries(components).forEach(([name, component]) => {
  app.component(name, component as Component);
});

export default defineNuxtPlugin(() => {});
