export function getCmsImageUrlBySize(src: string, width: string): string | null {
  if (!src) {
    return null;
  }
  if (src?.endsWith('svg')) {
    return src;
  }

  return `${src}/m/${width}x0/filters:quality(80)`;
}
