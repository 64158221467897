import type { NewsletterResponseData, Theme } from '@/types/newsletter';
import { getThemes } from '@/models/utils/newsletterUtils';

export function newsletterModel(responseData: NewsletterResponseData | null) {
  const response: NewsletterResponseData = responseData ?? {};

  return {
    hasError(): boolean {
      return response.error !== undefined;
    },

    hasSubscription(): boolean {
      return response.error?.code === constants.ERROR_CODE.NEWSLETTER.ALREADY_EXISTS;
    },

    isInitialized(): boolean {
      return responseData !== null;
    },

    hasInterestInMen(): boolean {
      return response.data?.isInterestedInMen === true;
    },

    hasInterestInWomen(): boolean {
      return response.data?.isInterestedInWomen === true;
    },

    isOptInMail(): boolean {
      return response.data?.isOptInMail === true;
    },

    isOptInPost(): boolean {
      return response.data?.isOptInPost === true;
    },

    getOptInThemes(filterOutNonGlobalEThemes: boolean): Array<Theme> {
      let themes: Array<Theme> = [];
      const responseData: any = response.data;

      if (responseData) {
        themes = getThemes(responseData);
      }

      if (filterOutNonGlobalEThemes) {
        themes = themes.filter((theme) => !constants.NEWSLETTER_GLOBAL_E_DISABLED_THEME_IDS.includes(theme.id));
      }

      return themes;
    },

    // Email is already opt-in and now is joined (i.e. to raffle or adventscalender)
    isAdded(): boolean {
      const addedResponses: string[] = [
        constants.ERROR_MESSAGE.NEWSLETTER.RAFFLE_ADDED,
        constants.ERROR_MESSAGE.NEWSLETTER.ADVENTCALENDERTHEME_ADDED,
      ];
      return addedResponses.includes(response?.error?.message || '');
    },

    // Email is already opt-in and also already joined (i.e. to raffle or adventscalender)
    isAlreadySet(): boolean {
      const alreadySetResponses: string[] = [
        constants.ERROR_MESSAGE.NEWSLETTER.RAFFLE_ALREADY_SET,
        constants.ERROR_MESSAGE.NEWSLETTER.ADVENTCALENDERTHEME_ALREADY_SET,
      ];
      return alreadySetResponses.includes(response?.error?.message || '');
    },

    isDOIMailSent(): boolean {
      return response.data?.isDOIMailSent === true;
    },

    getDebugData() {
      return response.data;
    },
  };
}
