import { isClient } from '@mop/shared/utils/util';
import { extractCountriesFromLocaleList, getLocalesFromCountry } from '@/i18n/localeList';
import type { LocaleObject, AvailableCountries } from '@/types/locale';

// Example
// Input: accept-language: en-GB,en;q=0.9,en-US;q=0.8,de;q=0.7,it;q=0.6,ru;q=0.5
// Return: ["en-GB", "en", "en-US", "de", "it", "ru"]
function parseAcceptLanguage(input: string): string[] {
  return input.split(',').map((tag) => tag.split(';')[0]);
}

function getBrowserLocales(headers: any): string[] {
  if (isClient && typeof navigator !== 'undefined' && navigator.languages) {
    return navigator.languages as string[];
  } else if (headers['accept-language']) {
    return parseAcceptLanguage(headers['accept-language']);
  }
  return [];
}

// Find locale code that best matches provided list of browser locales.
export function getBestMatchingBrowserLocale(
  headers: any,
  filteredLocaleList: LocaleObject[],
  defaultLocale: string,
  country?: string,
): string | undefined {
  let browserLocales: string[] = getBrowserLocales(headers).map((browserLocale) => browserLocale.toLowerCase());
  if (!country) {
    country = (headers['cloudfront-viewer-country'] as string)?.toLowerCase();
  }

  const availableCountries: AvailableCountries = extractCountriesFromLocaleList(filteredLocaleList);

  // 1: check from provided country
  if (country && availableCountries.includes(country)) {
    browserLocales = browserLocales.map((browserLocale) =>
      browserLocale.includes('-') ? browserLocale : `${browserLocale}-${country}`,
    );
    const possibleLocales: string[] = getLocalesFromCountry(filteredLocaleList, country);
    const foundMatchingLocale: string | undefined = possibleLocales.find((possibleLocale) =>
      browserLocales.includes(possibleLocale),
    );
    if (foundMatchingLocale) {
      return foundMatchingLocale;
    }

    return possibleLocales[0] || defaultLocale;
  }

  // 2: check exact browser locale
  const possibleLocales: string[] = filteredLocaleList.map((locale) => locale.code);
  const foundMatchingLocale: string | undefined = browserLocales.find((browserLocale) => {
    return possibleLocales.includes(browserLocale);
  });
  if (foundMatchingLocale) {
    return foundMatchingLocale;
  }

  // 3: check country part of browser locale
  const browserCountries: string[] = browserLocales.reduce((list: string[], browserLocale: string) => {
    const country: string | undefined = browserLocale.split('-')[1];
    if (country) {
      list.push(country);
    }
    return list;
  }, []);
  const possibleCountries: string[] = possibleLocales.map((possibleLocale) => possibleLocale.split('-')[1]);
  const matchingCountry: string | undefined = browserCountries.find((browserCountry) =>
    possibleCountries.includes(browserCountry),
  );
  if (matchingCountry) {
    const possibleLocales: string[] = getLocalesFromCountry(filteredLocaleList, matchingCountry);
    return possibleLocales[0] || defaultLocale;
  }

  // 4: check language part of browser locale
  const browserLanguages: string[] = browserLocales.map((browserLocale) => browserLocale.split('-')[0]);
  const possibleLanguages: string[] = possibleLocales.map((possibleLocale) => possibleLocale.split('-')[0]);
  const matchingLanguage: string | undefined = browserLanguages.find((browserLanguage) =>
    possibleLanguages.includes(browserLanguage),
  );
  if (matchingLanguage) {
    const foundMatchingLocale: string | undefined = possibleLocales.find(
      (possibleLocale) => possibleLocale.split('-')[0] === matchingLanguage,
    );
    return foundMatchingLocale;
  }
}
