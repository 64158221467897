<script setup lang="ts">
import { ref, computed } from 'vue';
import type { PropType } from 'vue';
import type { UiNotificationTypes, UiNotificationStatuses } from '@mop/ui2/types';

defineOptions({
  name: 'Ui2Notification',
});

const props = defineProps({
  type: {
    type: String as PropType<UiNotificationTypes>,
    default: 'primary',
  },
  status: {
    type: String as PropType<UiNotificationStatuses>,
    default: 'default',
  },
  title: {
    type: String,
    default: '',
  },
  showIcon: {
    type: Boolean,
    default: false,
  },
  showCloseButton: {
    type: Boolean,
    default: false,
  },
});

const isVisible = ref(true);
const iconSize = computed<number>(() => (props.type === 'inline' ? 16 : 20));
</script>

<template>
  <div v-if="isVisible" :class="['ui-notification', `ui-notification--${type}`, `ui-notification--${status}`]">
    <div v-if="showIcon" class="ui-notification__icon-wrap">
      <Ui2Icon
        class="ui-notification__icon ui-notification__icon--error"
        name="alert-circle"
        :width="iconSize"
        :height="iconSize"
      />
      <Ui2Icon
        class="ui-notification__icon ui-notification__icon--warning"
        name="alert-triangle"
        :width="iconSize"
        :height="iconSize"
      />
      <Ui2Icon
        class="ui-notification__icon ui-notification__icon--success"
        name="check-circle"
        :width="iconSize"
        :height="iconSize"
      />
      <Ui2Icon
        class="ui-notification__icon ui-notification__icon--default"
        name="info-circle"
        :width="iconSize"
        :height="iconSize"
      />
    </div>
    <div v-if="title" class="ui-notification__title">
      {{ title }}
    </div>
    <div v-if="showCloseButton" class="ui-notification__close">
      <Ui2Button type="close" @click="isVisible = false" />
    </div>
    <div v-if="$slots.default" class="ui-notification__content">
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
.ui-notification {
  --ui2-notification-background-color: var(--color-surface-component-alert-neutral);
  --ui2-notification-border-color: var(--color-border-secondary);
  --ui2-notification-text-color: var(--color-text-body-secondary);
}

.ui-notification--info {
  --ui2-notification-background-color: var(--color-surface-component-alert-information);
  --ui2-notification-border-color: var(--color-border-information);
  --ui2-notification-text-color: var(--color-text-information);
}

.ui-notification--error {
  --ui2-notification-background-color: var(--color-surface-component-alert-error);
  --ui2-notification-border-color: var(--color-border-error);
  --ui2-notification-text-color: var(--color-text-error);
}

.ui-notification--warning {
  --ui2-notification-background-color: var(--color-surface-component-alert-warning);
  --ui2-notification-border-color: var(--color-border-warning);
  --ui2-notification-text-color: var(--color-text-warning);
}

.ui-notification--success {
  --ui2-notification-background-color: var(--color-surface-component-alert-success);
  --ui2-notification-border-color: var(--color-border-success);
  --ui2-notification-text-color: var(--color-text-success);
}

.ui-notification__icon {
  display: none;
}

.ui-notification--error {
  .ui-notification__icon--error {
    display: block;
  }
}

.ui-notification--success {
  .ui-notification__icon--success {
    display: block;
  }
}
.ui-notification--warning {
  .ui-notification__icon--warning {
    display: block;
  }
}
.ui-notification--default,
.ui-notification--info {
  .ui-notification__icon--default {
    display: block;
  }
}

.ui-notification--primary,
.ui-notification--secondary {
  border-radius: $border-radius-rounded-lg;
  border: 1px solid transparent;
  padding: $space-12;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas:
    'icon title close'
    'icon content close';

  background-color: var(--ui2-notification-background-color);
  border-color: var(--ui2-notification-border-color);

  .ui-notification__icon-wrap {
    grid-area: icon;
    padding: 2px 12px 0 4px;
    color: var(--ui2-notification-text-color);
  }
  .ui-notification__title {
    @include v2-text-style(sm, regular);
    text-align: left;
    grid-area: title;
  }
  .ui-notification__close {
    grid-area: close;
    color: $color-text-action;
    text-align: right;
  }
  .ui-notification__content {
    @include v2-text-style(sm, regular);
    color: $color-text-body-secondary;
    grid-area: content;
    margin-top: $space-4;
  }
}

.ui-notification--secondary {
  background-color: $color-surface-primary;
}

.ui-notification--inline {
  display: grid;
  grid-template-areas: 'icon content';
  grid-template-columns: auto 1fr;
  grid-gap: $space-8;
  color: var(--ui2-notification-text-color);

  .ui-notification__content {
    @include v2-text-style(sm, regular);
  }
  .ui-notification__icon-wrap {
    padding: 2px 0;
  }
  .ui-notification__title,
  .ui-notification__close {
    display: none;
  }
}

@include v2-apply-upto(sm) {
  .ui-notification--primary,
  .ui-notification--secondary {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'icon close'
      'title title'
      'content content';

    .ui-notification__icon-wrap {
      padding-left: 0;
    }
  }
}
</style>
