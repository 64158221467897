import type { Price } from '@/types/product';
import type { ReservationProductData } from '@/types/reservation';

export function reservationProductModel(reservationProduct: ReservationProductData) {
  return {
    getEan(): string {
      return reservationProduct.variantId;
    },

    getUrl(): string {
      return reservationProduct.productSrc;
    },

    getImage(): string {
      return reservationProduct.imageSrc;
    },

    getPrice(): Price {
      return reservationProduct.price;
    },

    getSize(): string {
      return reservationProduct.size;
    },

    getColor(): string {
      return reservationProduct.color;
    },

    getName(): string {
      return reservationProduct.name;
    },

    getShortDescription(): string {
      return reservationProduct.shortDescription;
    },
  };
}
