import { securedWrap } from '@mop/shared/utils/securedWrap';
import { ssrCache } from '@/api/utils/ssrCache';
import { categoryListModel } from '@/models';
import type { CategoryListModel, CategoryData, CategoryListResponseData, CategoryCountData } from '@/types/category';

type UseRootCategories = {
  rootCategoryListRef: Ref<CategoryListModel>;
  searchRootCategories: () => Promise<void>;
};

type RootCategoryComposableStorage = {
  rootCategoryListRef: Ref<CategoryListModel>;
};

function getRootCategoriesCountObject(countDataItems: CategoryCountData[] = []) {
  const countObject: any = {};
  countDataItems?.forEach((countDataItem) => {
    countObject[countDataItem.id] = countDataItem.productCount;
  });
  return countObject;
}

export default function useMopRootCategories(): UseRootCategories {
  const nuxtApp = useNuxtApp();
  const storage = initStorage<RootCategoryComposableStorage>('useRootCategory');
  const cacheId = `root-categories-${nuxtApp.$mopI18n.locale}`;
  const redisCache = ssrCache();
  const responseRef = useMopSSR<CategoryListResponseData | null>(cacheId, null);
  const rootCategoryListRef =
    storage.get('rootCategoryListRef') ??
    storage.saveAndGet('rootCategoryListRef', ref(categoryListModel(responseRef.value)));

  /**
   * Called on app init.
   * Do not init elsewhere.
   */
  async function searchRootCategories() {
    responseRef.value ??= await redisCache.get<CategoryListResponseData>(cacheId, getEnrichedRootCategories);
    rootCategoryListRef.value = categoryListModel(responseRef.value);
  }

  async function getEnrichedRootCategories() {
    const [rootCategoriesTreeData, rootCategoriesCountData, rootSaleCategoriesCountData] = await Promise.all([
      nuxtApp.$apiBackbone.getAllCategories(),
      nuxtApp.$apiBackbone.getCategoriesCount(),
      nuxtApp.$apiBackbone.getSaleCategoriesCount(),
    ]);

    if (
      rootCategoriesTreeData.error !== undefined ||
      rootCategoriesTreeData.data === undefined ||
      rootCategoriesTreeData.data.length === 0
    ) {
      return;
    }

    const rootCategoriesCountObject: any = getRootCategoriesCountObject(rootCategoriesCountData.data);
    const rootSaleCategoriesCountObject: any = getRootCategoriesCountObject(rootSaleCategoriesCountData.data);

    // Filter empty categories, add productCount to category
    function getMergedProductCountCategoryData(categoryData: CategoryData[]): CategoryData[] {
      const country: string = nuxtApp.$mopI18n.country;

      return categoryData.filter(function (filteredDataItem) {
        const emptyCategorySlugs = ['members-sale'];
        const isEmptyCategoryVisible = emptyCategorySlugs.some((emptyCategorySlug) =>
          filteredDataItem.data.includes(emptyCategorySlug),
        );
        const checkForSaleCount: boolean = /[-/](sale|outlet)/gi.test(filteredDataItem.data) && !isEmptyCategoryVisible;

        if (filteredDataItem.children?.length) {
          filteredDataItem.children = getMergedProductCountCategoryData(filteredDataItem.children);
        }
        const filteredDataItemId: number = parseInt(filteredDataItem.data.split('|')[0]);
        const categoryCount: number =
          (checkForSaleCount ? rootSaleCategoriesCountObject : rootCategoriesCountObject)[filteredDataItemId] ?? 0;
        const isEmpty: boolean = !isEmptyCategoryVisible && categoryCount === 0;

        let data = '';
        if (!isProductionBuild || filteredDataItem.showProductCount) {
          if (isProductionBuild && filteredDataItem.showProductCount) {
            delete filteredDataItem.showProductCount;
          }
          data = `|${String(categoryCount)}`;
        }
        if (isEmpty) {
          data += (!data ? '|' : '') + '|1';
        }
        filteredDataItem.data += data;
        filteredDataItem.hidden =
          (isProductionBuild === true && isEmpty) ||
          filteredDataItem.hidden ||
          filteredDataItem.globalEBlacklist?.includes(country)
            ? 1
            : 0;
        return filteredDataItem;
      });
    }

    rootCategoriesTreeData.data = getMergedProductCountCategoryData(rootCategoriesTreeData.data);
    return rootCategoriesTreeData;
  }

  return securedWrap({
    rootCategoryListRef,
    searchRootCategories,
  });
}
