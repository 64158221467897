import { securedWrap } from '@mop/shared/utils/securedWrap';
import { isClient } from '@mop/shared/utils/util';
import type { CmsContentElementModel } from '@/types/cms';
import type { ProductModel, ProductsSearchParameter } from '@/types/product';

type PromotionProductsStorage = {
  productModelsRef: Ref<ProductModel[]>;
};

export default function useMopPromotionProducts() {
  const storage = initStorage<PromotionProductsStorage>('promotionProducts');

  const productModelsRef: Ref<ProductModel[]> =
    storage.get('productModelsRef') ?? storage.saveAndGet('productModelsRef', ref([]));

  function setPromotionProducts(products: ProductModel[]) {
    productModelsRef.value = storage.saveAndGet('productModelsRef', ref(products)).value;
  }

  function getPromotionProductRef(productId: string) {
    return computed(() => {
      return productModelsRef.value.find((productModel) => productModel.getMopId() === productId) || null;
    });
  }

  function initPromotionProducts(elements: CmsContentElementModel[]) {
    if (!isClient || !elements?.length) {
      return;
    }

    const isEnabledIframe = useNuxtApp().$storyblokLivePreview.isEnabledInIframe;
    const { productModelListRef, searchProductsByIds } = useMopProducts('promotion-products');
    const mopProductIdsRef = computed(() => extractProductIds(elements));

    watch(
      mopProductIdsRef,
      async (mopProductIds, oldMopProductIds) => {
        if (
          !mopProductIds.length ||
          (oldMopProductIds && mopProductIds.sort().join(',') === oldMopProductIds.sort().join(','))
        ) {
          return;
        }
        const searchParams: ProductsSearchParameter = {
          ...requestParameters.PRODUCT_TILE,
          includeSellableForFree: true,
          includeSoldOut: true,
          pagination: {
            perPage: 999,
          },
        };
        await searchProductsByIds(mopProductIds, searchParams, isEnabledIframe);
        setPromotionProducts(productModelListRef.value.getProductModelList());
      },
      { immediate: true },
    );
  }

  function getMopProductIdsFromMediaHotspot(elementData: any) {
    const hotspotsData: [] = elementData.media?.[0]?.hotspots;
    if (!hotspotsData?.length) {
      return [];
    }
    const productIds: string[] = [];
    hotspotsData.forEach((hotspot: any) => {
      if (!hotspot.productId) {
        return;
      }
      productIds.push(hotspot.productId);
    });
    return productIds;
  }

  function getMopProductIdsFromElement(element: CmsContentElementModel): string[] {
    const mopProductIds: string[] = [];
    const data: any = element.getData();
    const type: string = element.getType();
    if (['ProductGrid', 'ProductRow', 'HeroCard', 'ProductCarousel', 'ShopTheLook'].includes(type)) {
      data.items.forEach((item: any) => {
        if (!item.productId) {
          return;
        }
        mopProductIds.push(item.productId);
      });
    }
    if (type === 'HeroCard') {
      if (data.heroItem?.[0]?.productId) {
        mopProductIds.push(data.heroItem[0].productId);
      }
    }
    if (type === 'ProductCarousel') {
      data.heroItems.forEach((heroItem: any) => {
        if (!heroItem.productId) {
          return;
        }
        mopProductIds.push(heroItem.productId);
      });
    }
    if (type === 'PromotionItem') {
      if (data.productId) {
        mopProductIds.push(data.productId);
      }
    }

    // Search for product ids in media hotspots
    const keys = Object.keys(data);
    const hotspotProductIds = getMopProductIdsFromMediaHotspot(data);
    keys.forEach((key) => {
      const dataArray = data[key];
      if (Array.isArray(dataArray)) {
        dataArray.forEach((dataArrayItem) => {
          hotspotProductIds.push(...getMopProductIdsFromMediaHotspot(dataArrayItem));
        });
      }
    });

    if (hotspotProductIds) {
      mopProductIds.push(...hotspotProductIds);
    }

    return mopProductIds;
  }

  function extractProductIds(elements: CmsContentElementModel[]): string[] {
    if (!elements?.length) {
      return [];
    }
    return [
      ...new Set(
        elements.reduce((elementMopProductIds: string[], element) => {
          const elementMopProductIdsNew: string[] = getMopProductIdsFromElement(element);
          if (elementMopProductIdsNew.length) {
            elementMopProductIds = [...elementMopProductIds, ...elementMopProductIdsNew];
          }
          return elementMopProductIds;
        }, []),
      ),
    ];
  }

  return securedWrap({
    productModelsRef,
    setPromotionProducts,
    getPromotionProductRef,
    initPromotionProducts,
  });
}
