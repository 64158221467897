import type { Pagination } from '@/types/product';

export function paginationModel(responseData: Pagination | null) {
  const response: Pagination = responseData ?? {
    current: 0,
    first: 0,
    last: 0,
    next: 0,
    page: 0,
    perPage: 0,
    prev: 0,
    total: 0,
  };

  return {
    getCurrentPage(): number {
      return response.page;
    },
    getFirstPage(): number {
      return response.first;
    },
    getLastPage(): number {
      return response.last;
    },
    getNextPage(): number {
      return response.next;
    },
    getPreviousPage(): number {
      return response.prev;
    },
    getTotal(): number {
      return response.total;
    },
    isFirstPage(): boolean {
      return this.getCurrentPage() === 1;
    },
    isLastPage(): boolean {
      return this.getCurrentPage() === this.getLastPage();
    },
  };
}
