import { isClient } from '@mop/shared/utils/util';
import { securedWrap } from '@mop/shared/utils/securedWrap';
import { wishlistModel } from '@/models';
import type { ProductModel } from '@/types/product';
import type { GtmWishListTarget } from '@/types/gtm';
import type {
  WishlistModelRef,
  WishlistResponseData,
  AddWishlistItemParams,
  GetWishlistParams,
  WishlistItem,
} from '@/types/wishlist';

type LoadingState = {
  init: boolean;
  add: boolean;
  remove: boolean;
  loading: boolean;
};

type CartComposableStorage = {
  wishlist: WishlistModelRef;
};

export default function useMopWishlistClient() {
  const { reportEngagement } = useMopTrackingClient();
  const storage = initStorage<CartComposableStorage>('useWishlist');
  const loadingRef: Ref<LoadingState> = ref({
    init: false,
    add: false,
    remove: false,
    loading: computed(() => isLoading(loadingRef)),
  });
  const { getSalePromotionKey, showSaleCallOutMessage, isSaleEnabled } = useMopPromotions();
  const { $apiBackbone, $cookie, $mopConfig, $gtm2 } = useNuxtApp();
  const wishlistModelRef: WishlistModelRef =
    storage.get('wishlist') ?? storage.saveAndGet('wishlist', ref(getWishlistModel(null)));
  const wishlistKey: string = isClient ? getUuidAndSaveToCookie($cookie, constants.COOKIE.WISHLIST) : '';

  /**
   * Called on layout level.
   * Do not init elsewhere.
   */
  async function initWishlist(): Promise<void> {
    if (!isClient) {
      return;
    }
    loadingRef.value.init = true;
    const params: GetWishlistParams = requestParameters.WISHLIST_PRODUCTS;
    if (params && isSaleEnabled('promoQualifier') && showSaleCallOutMessage()) {
      params.pricePromotionKey = getSalePromotionKey();
    }
    const wishlistResult: WishlistResponseData = await $apiBackbone.getWishlist(wishlistKey, params);
    wishlistModelRef.value = getWishlistModel(wishlistResult);
    loadingRef.value.init = false;
  }

  function getWishlistModel(responseData: WishlistResponseData | null) {
    responseData?.data?.items.forEach((item: WishlistItem) => {
      if (item.product) {
        enrichProductResponseWithCmsData(item.product, $mopConfig);
      }
    });
    return wishlistModel(responseData);
  }

  // adding by variant
  async function addToWishlist(product: ProductModel, gtmTarget: GtmWishListTarget): Promise<void> {
    const productId: number = product.getAyId();

    reportEngagement({
      gtm: {
        type: 'Click',
        data: {
          custom: {
            event: 'AddToWishlist',
            category: getGtmTarget(gtmTarget).toLowerCase(),
            label: product.getMopId(),
          },
        },
      },
    });

    $gtm2.trackEcommerce({
      event: 'add_to_wishlist',
      data: {
        products: [
          {
            product,
            category: getGtmTarget(gtmTarget).toLowerCase(),
          },
        ],
      },
    });

    const itemExist: boolean = wishlistModelRef.value.isProductInWishlist(productId);
    if (itemExist) {
      return;
    }
    const addToWishlistParams: AddWishlistItemParams = {
      ...requestParameters.WISHLIST_PRODUCTS,
      wishlistKey,
      item: {
        productId,
      },
      skipAvailabilityCheck: true,
    };
    loadingRef.value.add = true;
    const wishlistResult: WishlistResponseData = await $apiBackbone.addWishlistItem(
      wishlistKey,
      productId,
      addToWishlistParams,
    );
    wishlistModelRef.value = getWishlistModel(wishlistResult);
    loadingRef.value.add = false;
  }

  async function removeFromWishlist(product: ProductModel, gtmTarget: GtmWishListTarget): Promise<void> {
    reportEngagement({
      gtm: {
        type: 'Click',
        data: {
          custom: {
            event: 'RemoveFromWishlist',
            category: getGtmTarget(gtmTarget).toLowerCase(),
            label: product.getMopId(),
          },
        },
      },
    });

    const wishlistItemKey: string = wishlistModelRef.value.getKeyByProductId(product.getAyId());
    if (wishlistItemKey === '') {
      return;
    }
    loadingRef.value.remove = true;
    const wishlistResult: WishlistResponseData = await $apiBackbone.removeWishlistItem(
      wishlistKey,
      wishlistItemKey,
      requestParameters.WISHLIST_PRODUCTS,
    );
    wishlistModelRef.value = getWishlistModel(wishlistResult);
    loadingRef.value.remove = false;
  }

  return securedWrap({
    wishlistModelRef,
    initWishlist,
    addToWishlist,
    removeFromWishlist,
    loadingRef,
  });
}

function getGtmTarget(gtmTarget: GtmWishListTarget): string {
  if (/^(detail|wishlist)/gi.test(gtmTarget)) {
    return gtmTarget;
  }
  if (/^(recommendation)/gi.test(gtmTarget)) {
    return 'Recommendation';
  }
  return 'Category';
}
