<script setup lang="ts">
import { getElementUiObject, getMedia, getLink, getCta } from '@mop/cms/utils/utils';
import type { CmsUiTripleTeaser, CmsUiTripleTeaserBig, CmsUiTripleTeaserSmall } from './uiCmsTripleTeaser';

defineOptions({
  name: 'UiCmsTripleTeaser',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  lazyLoad: {
    type: Boolean,
    default: false,
  },
});

const data = props.data;

function getCmsUiTripleTeaserBig(object: any = {}): CmsUiTripleTeaserBig | undefined {
  return getElementUiObject(object, {
    headline: object.headline,
    subline: object.subline,
    text: object.text,
    cta: getCta(object.cta?.[0]),
    link: getLink(object.link),
    backgroundColor: object.backgroundColor?.color,
    textColor: object.textColor?.color,
    media: getMedia(object.media?.[0]),
  });
}

function getCmsUiTripleTeaserSmall(object: any = {}): CmsUiTripleTeaserSmall | undefined {
  return getElementUiObject(object, {
    headline: object.headline,
    text: object.text,
    cta: getCta(object.cta?.[0]),
    link: getLink(object.link),
    media: getMedia(object.media?.[0]),
  });
}

const element: CmsUiTripleTeaser = getElementUiObject(data, {
  big: getCmsUiTripleTeaserBig(data.big?.[0]),
  smallTop: getCmsUiTripleTeaserSmall(data.smallTop?.[0]),
  smallBottom: getCmsUiTripleTeaserSmall(data.smallBottom?.[0]),
});
</script>

<template>
  <div v-storyblok-editable="element" class="ui-cms-triple-teaser">
    <UiCmsTripleTeaserItemBig
      class="ui-cms-triple-teaser__big"
      :data="element.big"
      :lazy-load="lazyLoad"
      :data-track-element-item="`${element.big.media?.trackingId}|1`"
    />
    <div class="ui-cms-triple-teaser__small">
      <UiCmsTripleTeaserItemSmall
        class="ui-cms-triple-teaser__small-top"
        :data="element.smallTop"
        :lazy-load="lazyLoad"
        :data-track-element-item="`${element.smallTop.media?.trackingId}|2`"
      />
      <UiCmsTripleTeaserItemSmall
        class="ui-cms-triple-teaser__small-bottom"
        :data="element.smallBottom"
        :lazy-load="lazyLoad"
        :data-track-element-item="`${element.smallBottom.media?.trackingId}|3`"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ui-cms-triple-teaser {
  display: flex;
  align-items: flex-start;

  @include apply-upto(medium) {
    flex-direction: column;
  }
}

.ui-cms-triple-teaser__big {
  position: relative;
  width: columns(23);

  @include apply-from(extraextralarge) {
    width: columns(21);
  }

  @include apply-only(extralarge) {
    width: columns(22);
  }

  @include apply-upto(medium) {
    width: columns(36);
  }

  @include apply-only(medium) {
    display: flex;
    flex-direction: row-reverse;
  }
}

.ui-cms-triple-teaser__small {
  width: columns(13);

  @include apply-from(extraextralarge) {
    width: columns(16);
  }

  @include apply-only(extralarge) {
    width: columns(14);
  }

  @include apply-upto(medium) {
    display: flex;
    width: columns(36);
  }

  @include apply-upto(small) {
    flex-direction: column;
  }
}

.ui-cms-triple-teaser__small-top {
  margin-left: columns(2, 13);

  @include apply-from(extraextralarge) {
    margin-left: columns(4, 16);
  }

  @include apply-only(extralarge) {
    margin-left: columns(2, 14);
  }

  @include apply-upto(medium) {
    margin: 0;
  }

  :deep(.ui-cms-triple-teaser-item-small__textbox) {
    @include apply-from(extralarge) {
      padding-right: columns(1, 12);
    }

    @include apply-only(large) {
      padding-right: columns(1, 11);
    }
  }
}

.ui-cms-triple-teaser__small-bottom {
  margin-top: $space30;
  margin-right: columns(2, 13);

  @include apply-from(extraextralarge) {
    margin-right: columns(4, 16);
  }

  @include apply-only(extralarge) {
    margin-right: columns(2, 14);
  }

  @include apply-upto(medium) {
    margin: 0;
  }

  :deep(.ui-cms-triple-teaser-item-small__textbox) {
    @include apply-from(extralarge) {
      padding-left: columns(1, 12);
    }

    @include apply-only(large) {
      padding-left: columns(1, 11);
    }
  }
}
</style>
