import type { OrderItemData, OrderItemStatus, PackageData } from '@/types/orders';
import type { GiftCard } from '@/types/product';

// 'bought' is not in this sequence, as it's special case for offline orders and should not be reflected on shipping status
const statusSequence: OrderItemStatus[] = [
  'placed',
  'waiting',
  'inProgress',
  'completed',
  'canceled',
  'shipped',
  'delivered',
  'returned',
];

export function orderProductModel(responseData: OrderItemData | null) {
  const response: OrderItemData = responseData ?? {};

  return {
    getEan(): string {
      return response.ean ?? '';
    },

    getPosition(): number {
      return response?.position ?? 0;
    },

    getPackageId(): number {
      return response?.packageId ?? 0;
    },

    getQuantity(): number {
      return response?.quantity ?? 0;
    },

    getReturnedQuantity(): number {
      return response?.returnedQuantity ?? 0;
    },

    isShipFromStoreOrder(): boolean {
      return ![constants.ORDER.MOP_WAREHOUSE_GLN, constants.ORDER.ARVATO_WAREHOUSE_GLN].includes(
        response.storeGln ?? '',
      );
    },

    isBackOrder(): boolean {
      return response.storeGln === constants.ORDER.MOP_WAREHOUSE_GLN;
    },

    isReturned(): boolean {
      return response.orderStatus?.status === 'returned';
    },

    getStatus(): OrderItemStatus | undefined {
      return response?.orderStatus?.status;
    },

    getTrackingNumber(): string | undefined {
      return response?.orderStatus?.trackingNumber;
    },

    getTotalPrice(): number {
      return this.getQuantity() * this.getUnitPrice();
    },

    getTotalRefundPrice(): number {
      return this.getReturnedQuantity() * this.getUnitOriginalPrice();
    },

    getTotalNetPrice(): number {
      return this.getQuantity() * this.getUnitNetPrice();
    },

    getTotalOriginalPrice(): number {
      return this.getQuantity() * this.getUnitOriginalPrice();
    },

    getUnitPrice(): number {
      return response.price?.grossPrice ?? 0;
    },

    getUnitNetPrice(): number {
      return response.price?.netPrice ?? 0;
    },

    getUnitOriginalPrice(): number {
      return response.price?.originalPrice ?? 0;
    },

    getFlag(): string {
      return response.attributes?.flag ?? '';
    },

    isNew(): boolean {
      return response.attributes?.isNew === true;
    },

    getProductName(): string {
      return response.attributes?.productName ?? '';
    },

    getMopId(): string {
      return `${response.attributes?.masterId}_${response.attributes?.colorId}`;
    },

    getColorId(): string {
      return response.attributes?.colorId ?? '';
    },

    getColorName(): string {
      return response.attributes?.colorName ?? '';
    },

    getSize(): string {
      return response.attributes?.size ?? '';
    },

    getMopProductId(): string {
      return response.attributes?.masterId ?? '';
    },

    getGiftMessage(): GiftCard {
      const message: GiftCard | undefined = response?.giftCard;
      return {
        from: decodeURIComponent(message?.from ?? ''),
        to: decodeURIComponent(message?.to ?? ''),
        message: decodeURIComponent(message?.message ?? ''),
      };
    },

    isGiftCard(): boolean {
      return response?.giftCard !== undefined;
    },

    isGiveaway(): boolean {
      return response.attributes?.isGiveaway === true;
    },

    isShipped(shipments: PackageData[]): boolean {
      if (!this.getStatus()) {
        return false;
      }
      const shipment = shipments.find(({ id }) => id === this.getPackageId());
      if (shipment?.status) {
        return ['shipped', 'delivered', 'outForDelivery'].includes(shipment.status);
      }
      // backward compatibility, orders before June 2023 don't have shipment status
      return statusSequence.indexOf(this.getStatus() as OrderItemStatus) >= statusSequence.indexOf('shipped');
    },

    isDelivered(shipments: PackageData[], isOrderDelivered: boolean): boolean {
      if (!this.getStatus()) {
        return false;
      }
      const shipment = shipments.find(({ id }) => id === this.getPackageId());
      if (shipment?.status) {
        return shipment.status === 'delivered';
      }
      // backward compatibility, orders before June 2023 don't have shipment status
      return (
        statusSequence.indexOf(this.getStatus() as OrderItemStatus) >= statusSequence.indexOf('shipped') &&
        isOrderDelivered
      );
    },

    getImage(): string {
      return response.attributes?.image ?? constants.ORDER.FALLBACK_IMAGE;
    },
    getRedirectUrl(): string {
      const size: string = this.getSize();
      const sizeHash: string = size ? `#size=${encodeURIComponent(size)}` : '';
      const prefix: string = this.isGiftCard()
        ? `/${URLS.GIFT_CARD}/`
        : `/${constants.REDIRECT_PATHS.PRODUCT_REDIRECT}/`;

      return `${prefix}${this.getMopProductId()}_${this.getColorId()}${sizeHash}`;
    },
  };
}
