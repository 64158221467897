<script lang="ts">
export default defineNuxtComponent({
  name: 'UiHeading',
  props: {
    level: {
      type: Number as PropType<0 | 1 | 2 | 3 | 4 | 5 | 6>,
      required: true,
    },
  },
  render() {
    /**
     * There's a bunch of invalid input (-1, 7, undefined, null, NaN, …) that could come in (e.g. from a CMS). That's
     * why the valid heading levels are checked first.
     */
    const element = [1, 2, 3, 4, 5, 6].includes(this.level) ? 'h' + this.level : 'div';
    return h(element, this.$slots.default?.());
  },
});
</script>
