<script setup lang="ts">
import { scrollToErrorElement } from '@mop/shared/utils/util';

defineOptions({
  name: 'UiForm',
});

defineProps({
  short: {
    type: Boolean,
    default: false,
  },
  long: {
    type: Boolean,
    default: false,
  },
});

defineExpose({ validate });

const emit = defineEmits(['submit']);

const elements = ref<any[]>([]);

function register(element: any) {
  elements.value.push(element);
}

provide('register', register);

async function validate() {
  let isValid = true;
  let firstErrorElement: HTMLElement | null = null;
  for (const element of elements.value) {
    if (!(await element.exposed.validate())) {
      if (firstErrorElement === null) {
        firstErrorElement = element.vnode.el;
      }
      isValid = false;
    }
  }
  if (firstErrorElement) {
    scrollToErrorElement(firstErrorElement);
  }
  return isValid;
}

async function handleSubmit(event: Event) {
  const isValid = await validate();
  if (isValid) {
    emit('submit', event);
  }
  return isValid;
}
</script>

<template>
  <form
    :class="[
      'form',
      {
        'form--short': short,
        'form--long': long,
      },
    ]"
    @submit.prevent.stop="handleSubmit"
  >
    <slot />
  </form>
</template>

<style lang="scss">
@import '@mop/ui/scss/forms/index';
</style>
