import { securedWrap } from '@mop/shared/utils/securedWrap';
import type { ProductModel, VariantModel, Price } from '@/types/product';
import type {
  GtmProductTracking,
  GtmPageViewEvent,
  GtmPageViewParams,
  GtmAddToCartEvent,
  GtmProductDetailEvent,
  GtmProductImpressionEvent,
  GtmProductClickEvent,
  GtmProductTrackingParams,
  GtmContentTracking,
  GtmContentViewEvent,
  GtmContentClickEvent,
  GtmRemoveFromCartEngagement,
  GtmCartContentEvent,
  GtmPurchaseEvent,
  GtmPurchaseEventParams,
  GtmCartContentUpdateEvent,
  GtmCheckoutEvent,
  GtmCheckoutEventParams,
  GtmAyCheckoutEventParams,
  GtmAyCheckoutEvent,
} from '@/types/gtm';

function getProductTrackingObject(productTrackingParams: GtmProductTrackingParams): GtmProductTracking {
  const product: ProductModel = productTrackingParams.product;
  const variant: VariantModel | undefined = productTrackingParams.variant;
  const priceInformation: Price = variant ? variant.getPrice() : product.getPrice();
  return {
    id: product.getMopMasterId(),
    brand: product.getSimpleBrandName(),
    gender: product.getGender(),
    product_variation_id: variant?.getEan() || '',
    product_id: product.getMopId(),
    category: getCategoryPath(productTrackingParams),
    product_category: product.getPrimaryCategoryId(),
    list: productTrackingParams.list || 'na',
    quantity: productTrackingParams.quantity,
    sale: priceInformation.salesPrice !== priceInformation.basePrice,
    position: productTrackingParams.position || 1,
    name: product.getName(),
    price:
      productTrackingParams.price || (variant ? variant.getPrice().salesPriceNet : priceInformation.salesPriceNet) || 0,
    salepercent: priceInformation.discountPercentage ?? 0,
    saletotal: priceInformation.basePrice * ((priceInformation.discountPercentage ?? 0) / 100),
    stock: variant?.getAvailability()?.quantity,
    size: variant?.getSize() || '',
    season: product.getSeason(),
    variant: product.getColorId(),
    shipFromStoreProduct: variant?.isShipFromStore() || false,
    pdpVideo: Boolean(productTrackingParams.product.getVimeoId()),
    sustainablematerial: product.getSustainabilityId(),
    correctionGlasses: product.isCorrectionGlasses(),
    virtualTryOnPotential: product.isVirtualTryOnEnabled(),
    virtualTryOnDone: product.isProductTriedWithVirtualTryOn(),
  };
}

function getContentViewEvent(contentTrackingParams: GtmContentTracking): GtmContentViewEvent {
  return {
    event: 'promoImpression',
    ecommerce: {
      promoView: {
        promotions: [contentTrackingParams],
      },
    },
  };
}

function getContentClickEvent(contentTrackingParams: GtmContentTracking): GtmContentClickEvent {
  return {
    event: 'promoClick',
    ecommerce: {
      promoClick: {
        promotions: [contentTrackingParams],
      },
    },
  };
}

function getProductDetailEvent(productTrackingParams: GtmProductTrackingParams): GtmProductDetailEvent {
  return {
    event: 'productDetail',
    ecommerce: {
      detail: {
        actionField: {
          list: productTrackingParams.list || 'na',
          action: 'detail',
        },
        products: [getProductTrackingObject(productTrackingParams)],
      },
      currencyCode: productTrackingParams.variant?.getPrice().currency,
    },
  };
}

function getPageViewEvent(pageViewParams: GtmPageViewParams): GtmPageViewEvent {
  return {
    Landingpage: pageViewParams.landingPage || false,
    UserId: pageViewParams.userId || '',
    PageCategoryID: pageViewParams.pageCategoryId || '',
    PageCountryCode: pageViewParams.pageCountryCode || '',
    PageDivision: pageViewParams.pageCategoryId ? getDivision(pageViewParams.pageCategoryId) : '',
    PageLanguageCode: pageViewParams.pageLanguageCode || '',
    PageType: pageViewParams.pageType,
    PageTitle: pageViewParams.pageTitle || '',

    Privacy_OptOut_Performance: pageViewParams.privacyOptOutPerformance || 0,
    errorCode: pageViewParams.errorCode || '',
    paymentMethode: pageViewParams.paymentMethod || '',
    paymentProvider: pageViewParams.paymentProvider || '',
    shippingProvider: pageViewParams.shippingProvider || '',
    GlobalE: pageViewParams.globalE || false,

    MopTestName: pageViewParams.abTestName || 'na',
    MopTestVariant: pageViewParams.abTestVariant || 'na',
  };
}

function getDivision(categoryMopId: string) {
  if (constants.VIRTUAL_TOP_CATEGORIES.includes(categoryMopId)) {
    return categoryMopId.split('-')[1];
  }
  return categoryMopId.split('-')[0] || '';
}

function getProductClickEngagement(productTrackingParams: GtmProductTrackingParams): GtmProductClickEvent {
  return {
    event: 'productClick',
    ecommerce: {
      click: {
        actionField: {
          list: productTrackingParams.list || 'na',
        },
        products: [getProductTrackingObject(productTrackingParams)],
      },
      currencyCode: productTrackingParams.product.getPrice().currency,
    },
  };
}

function getAddToCartEngagement(productTrackingParams: GtmProductTrackingParams): GtmAddToCartEvent {
  return {
    event: 'addToCart',
    ecommerce: {
      add: {
        products: [getProductTrackingObject(productTrackingParams)],
      },
      currencyCode: productTrackingParams.variant?.getPrice().currency,
    },
  };
}

function getRemoveFromCartEngagement(productTrackingParams: GtmProductTrackingParams): GtmRemoveFromCartEngagement {
  return {
    event: 'removeFromCart',
    ecommerce: {
      remove: {
        products: [getProductTrackingObject(productTrackingParams)],
      },
      currencyCode: productTrackingParams.variant?.getPrice().currency,
    },
  };
}

function getAyCheckoutEngagement(
  productTrackingParams: GtmAyCheckoutEventParams,
  currencyCode?: string,
): GtmAyCheckoutEvent {
  const products = productTrackingParams.products.map((productTrackingParam: GtmProductTrackingParams) => {
    return getProductTrackingObject(productTrackingParam);
  });
  const attributeName = productTrackingParams.eventName === 'finish_checkout' ? 'finish' : 'start';
  return {
    event: productTrackingParams.eventName,
    ecommerce: {
      [attributeName]: {
        products,
      },
      currencyCode,
    },
  };
}

function getCartContentEvent(
  productTrackingParams: GtmProductTrackingParams[],
  currencyCode?: string,
): GtmCartContentEvent {
  const cartContent = productTrackingParams.map((productTrackingParam: GtmProductTrackingParams) => {
    return getProductTrackingObject(productTrackingParam);
  });

  return {
    event: 'cartContent',
    currencyCode,
    cartContent,
  };
}

function getPurchaseEvent(purchaseEventParams: GtmPurchaseEventParams): GtmPurchaseEvent {
  return {
    event: 'purchase',
    ecommerce: {
      purchase: {
        actionField: {
          id: purchaseEventParams.id,
          affiliation: 'Online Store',
          revenue: purchaseEventParams.revenue,
          revenueEshop: purchaseEventParams.revenueEshop,
          revenueShipFromStore: purchaseEventParams.revenueShipFromStore,
          currency: purchaseEventParams.currency,
          tax: purchaseEventParams.tax,
          shipping: purchaseEventParams.shipping,
          coupon: purchaseEventParams.coupon,
          vouchervalue: purchaseEventParams.vouchervalue,
          customerrelation: purchaseEventParams.customerrelation,
          shipFromStoreOrder: purchaseEventParams.isShipFromStoreOrder,
          parcelSplit: purchaseEventParams.isParcelSplit,
          numberOfParcels: purchaseEventParams.numberOfParcels,
          cookieswitch: purchaseEventParams.cookieswitch,
        },
        products: purchaseEventParams.products.map((orderProduct: GtmProductTrackingParams) => {
          return getProductTrackingObject(orderProduct);
        }),
      },
    },
  };
}

function getCheckoutEvent(checkoutEventParams: GtmCheckoutEventParams): GtmCheckoutEvent {
  const actionField: { step: number; option?: string } = {
    step: checkoutEventParams.step,
  };
  if (checkoutEventParams.option) {
    actionField.option = checkoutEventParams.option;
  }
  return {
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField,
        products: checkoutEventParams.products.map((orderProduct: GtmProductTrackingParams) => {
          return getProductTrackingObject(orderProduct);
        }),
      },
    },
  };
}

function getProductImpressionEvent(productTrackingParams: GtmProductTrackingParams): GtmProductImpressionEvent {
  return {
    event: 'productImpression',
    ecommerce: {
      impressions: [getProductTrackingObject(productTrackingParams)],
      currencyCode: productTrackingParams.product.getPrice().currency,
    },
  };
}

function getCategoryPath(productTrackingParams: GtmProductTrackingParams): string {
  let categoryPath = '';
  const product: ProductModel = productTrackingParams.product;

  if (!productTrackingParams.category) {
    categoryPath = [product.getBreadcrumbPath().split('-').join('/'), product.getMopId()].join('/');
  } else {
    const category: string = productTrackingParams.category;
    categoryPath = category.includes(product.getMopId()) ? category : [category, product.getMopId()].join('/');
  }
  return categoryPath.replace(/^\//, '');
}

function getCartContentClearEvent(): GtmCartContentUpdateEvent {
  return {
    cartContent: undefined,
  };
}

export const GtmUtils = securedWrap({
  getProductDetailEvent,
  getAddToCartEngagement,
  getRemoveFromCartEngagement,
  getCartContentEvent,
  getCartContentClearEvent,
  getProductImpressionEvent,
  getProductClickEngagement,
  getPageViewEvent,
  getPurchaseEvent,
  getContentViewEvent,
  getContentClickEvent,
  getCheckoutEvent,
  getAyCheckoutEngagement,
});
