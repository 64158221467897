<script setup lang="ts">
import { getImage, getHtmlFromRichText } from '@mop/cms/utils/utils';
import type { EditableCmsComponent } from '@/types/cms';

type MemberBenefit = EditableCmsComponent & {
  headline: string;
  description: string;
  cta: string;
  icon: Object;
  id: string;
  details: Object;
  data: Object;
};

export type MemberBenefits = {
  items: Array<MemberBenefit>;
};

defineOptions({
  name: 'MopCmsMemberBenefit',
});

const props = defineProps({
  data: {
    type: Object as PropType<MemberBenefits>,
    required: true,
  },
});

const overlay = useMopOverlay();

const memberBenefitsDataRef = computed(() => {
  return {
    list: props.data.items?.map((memberBenefitItem: any) => {
      const icon = getImage(memberBenefitItem.icon);
      return {
        cta: memberBenefitItem.cta,
        headline: memberBenefitItem.headline,
        description: getHtmlFromRichText(memberBenefitItem.description),
        icon,
        details: {
          ...memberBenefitItem.details[0],
          ...{ icon },
        },
        id: memberBenefitItem._uid,
        data: memberBenefitItem,
      };
    }),
  };
});

function toggleMemberBenefitItemOverlay(benefit: any) {
  const { details } = benefit;
  overlay.open({
    type: 'centered',
    componentName: 'MopMemberBenefitItemOverlay',
    props: {
      data: details,
    },
  });
}
</script>

<template>
  <div v-if="memberBenefitsDataRef?.list?.length > 0" class="member-benefit">
    <div
      v-for="benefit in memberBenefitsDataRef.list"
      :key="benefit.id"
      :class="[
        'member-benefit__item',
        {
          'member-benefit__item--align-bottom': !benefit.icon,
        },
      ]"
    >
      <div class="member-benefit__icon-wrapper">
        <UiImage
          v-show="benefit.icon"
          v-storyblok-editable="benefit.data"
          :image="benefit.icon"
          class="member-benefit__icon"
          no-background
        />
      </div>
      <div class="member-benefit__content">
        <div class="member-benefit__title">
          {{ benefit.headline }}
        </div>
        <div class="member-benefit__description" v-html="benefit.description" />
        <button
          v-show="benefit.cta"
          class="member-benefit__button button button--primary"
          @click="toggleMemberBenefitItemOverlay(benefit)"
        >
          {{ benefit.cta }}
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.member-benefit {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  row-gap: 16px;
  column-gap: 16px;
  padding: 0 $global-padding;

  @include apply-upto(large) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include apply-upto(medium) {
    grid-template-columns: 1fr;
  }
}

.member-benefit__item {
  width: auto;
  min-height: 204px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: $space20;
  border-radius: 2px;
  border: 1px solid $panes-light-grey;
  background: $white;
  box-sizing: border-box;
}

.member-benefit__item--align-bottom {
  justify-content: flex-end;
}

.member-benefit__icon-wrapper {
  display: block;
  width: 56px;
  height: 56px;
  margin-bottom: 26px;
}

.member-benefit__icon {
  width: 100%;
  height: 100%;
}

.member-benefit__title {
  @include text-style(strong);
  font-size: 18px;
  line-height: 18px;
  color: $black;
  text-transform: uppercase;
  margin-bottom: $space10;
}

.member-benefit__description {
  :deep(.rich-text *) {
    @include text-style(paragraph);
    color: $dark-grey;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.28px;
  }
}
.member-benefit__button {
  padding-top: 14px;
  padding-bottom: 14px;
  text-transform: uppercase;

  @include apply-upto(medium) {
    width: auto;
  }
}
</style>
