import type { AxiosInstance } from 'axios';
import { getRecommendation } from './apiRecommendation';
import { postFeedback } from './apiFeedback';
import type { EpoqParams, EpoqFeedbackParams } from '@/types/recommendation';

export function initApiEpoq(apiInstance: AxiosInstance) {
  return {
    getRecommendation: (params: EpoqParams) => getRecommendation(apiInstance, params),
    postFeedback: (params: EpoqFeedbackParams) => postFeedback(apiInstance, params),
  };
}

export type ApiEpoq = ReturnType<typeof initApiEpoq>;
