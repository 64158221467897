import type { Theme, ThemeMode } from '@/types/newsletter';

/**
 * the list of current newsletter opt-in themes with the mode
 *
 * to provide proper labels, use the theme naming convention:
 * e.g.: 'themeLifecycle' => 'theme_lifecycle'
 * Note: The key mapping will be automatically done
 */
export const themeList: ThemeMode[] = [
  {
    id: 'themeLoyalty',
    mode: 'activateAndDeactivate',
  },
  {
    id: 'themeLifecycle',
    mode: 'activateAndDeactivate',
  },
  {
    id: 'themeTrade',
    mode: 'activateAndDeactivate',
  },
  {
    id: 'themeAdventcalendar',
    mode: 'activateAndDeactivate',
  },
  {
    id: 'themeJunior',
    mode: 'activateAndDeactivate',
  },
];

export function getThemeTranslation(themeId: string) {
  const matchUpperCaseLettersRegularExpression = /([A-Z]{1})/g;
  return themeId.replace(matchUpperCaseLettersRegularExpression, '_$1').toLowerCase();
}

export function getThemes(source: any): Array<Theme> {
  const themes: Array<Theme> = [];
  if (!source || source.length === 0) {
    return themes;
  }
  return themeList.reduce((themes: Theme[], optinTopic) => {
    if (
      (!source[optinTopic.id] && optinTopic.mode === 'deactivateOnly') ||
      (source[optinTopic.id] && optinTopic.mode === 'activateOnly')
    ) {
      return themes;
    }
    themes.push({
      id: optinTopic.id,
      value: Boolean(source[optinTopic.id]),
      label: getThemeTranslation(optinTopic.id),
    });
    return themes;
  }, []);
}
