<script setup lang="ts">
import type { Placement, StrictModifiers } from '@popperjs/core';
import type { CmsLink } from '@mop/cms/types';
import { createPopper } from '@popperjs/core';

defineOptions({
  name: 'UiTooltip',
});

const props = defineProps({
  centerContent: {
    type: Boolean,
    value: false,
  },
  nowrap: {
    type: Boolean,
    value: false,
  },
  direction: {
    type: String as PropType<Placement>,
    default: 'top',
    validator(value: string) {
      return !value || ['top', 'bottom', 'left', 'right'].includes(value);
    },
  },
  trigger: {
    type: String,
    default: 'mouseover',
    validator(value: string) {
      return !value || ['mouseover', 'click', 'focus', 'none'].includes(value);
    },
  },
  isVisible: {
    type: Boolean,
    default: false,
  },
  link: {
    type: Object as PropType<CmsLink>,
    default: () => {},
  },
  noCursor: {
    type: Boolean,
    default: false,
  },
  allowPropagation: {
    type: Boolean,
    default: false,
  },
  allowClick: {
    type: Boolean,
    default: false,
  },
  hideArrow: {
    type: Boolean,
    default: false,
  },
  popperModifiers: {
    type: Array as PropType<StrictModifiers[]>,
    default: () => null,
  },
});

const emit = defineEmits(['click']);

const rootRef = ref<HTMLElement>();
const tooltipRef = ref<HTMLElement>();
const arrowRef = ref<HTMLElement>();
const mounted = ref(false);
const isActiveRef = ref(false);
const isVisibleByTrigger = ref();
let popper: any = null;

function show() {
  popper.update();
  isVisibleByTrigger.value = true;
}

function hide() {
  isVisibleByTrigger.value = false;
}

function handleTouch() {
  isActiveRef.value = !isActiveRef.value;
  isActiveRef.value ? show() : hide();
}

onMounted(() => {
  mounted.value = true;
  setTimeout(() => {
    popper = createPopper(rootRef.value!, tooltipRef.value!, {
      placement: props.direction,
      modifiers: props.popperModifiers || [
        {
          name: 'arrow',
          options: {
            element: arrowRef.value,
            padding: 5,
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['top', 'bottom'],
            padding: {
              top: 100,
              bottom: 10,
            },
          },
        },
        {
          name: 'preventOverflow',
          options: {
            padding: 10,
          },
        },
      ],
    });
    if (props.trigger !== 'none') {
      rootRef.value?.addEventListener(props.trigger, show);
      rootRef.value?.addEventListener('mouseleave', hide);
      rootRef.value?.addEventListener('touchstart', handleTouch);
    }
  }, 100);
});
onUnmounted(() => {
  popper && popper.destroy();
  if (props.trigger !== 'none') {
    rootRef.value?.removeEventListener(props.trigger, show);
    rootRef.value?.removeEventListener('mouseleave', hide);
    rootRef.value?.removeEventListener('touchstart', handleTouch);
  }
});
</script>

<template>
  <span
    ref="rootRef"
    role="tooltip"
    class="ui-mop-tooltip"
    :class="[
      'ui-mop-tooltip',
      {
        'ui-mop-tooltip--cursor': !noCursor,
      },
    ]"
    @click="($event) => (allowPropagation ? null : $event.stopPropagation())"
  >
    <UiNuxtLink v-if="mounted" v-bind="link">
      <div
        ref="tooltipRef"
        :class="[
          'tooltip',
          {
            'tooltip--centered': centerContent,
            'tooltip--nowrap': nowrap,
            'tooltip--visible': isVisible || isVisibleByTrigger,
          },
        ]"
        @click="($event) => (allowClick ? emit('click') : $event.preventDefault())"
      >
        <span v-if="!hideArrow" ref="arrowRef" class="tooltip__arrow" data-popper-arrow />
        <span class="tooltip__content">
          <slot name="contentSlot" />
        </span>
      </div>
    </UiNuxtLink>
    <slot />
  </span>
</template>

<style lang="scss" scoped>
.ui-mop-tooltip {
  display: inline-block;
}

.ui-mop-tooltip--cursor {
  cursor: help;
}

.tooltip {
  @include z(global, tooltip);

  pointer-events: none;
  background-color: $white;
  border: 1px solid $black;
  padding: $space15 $space10;
  border-radius: 4px;
  max-width: 800px;
  transition: opacity ease 0.3s;
  opacity: 0;
  position: absolute;

  @include apply-upto(tiny) {
    max-width: 300px;
  }

  @include apply-only(small) {
    max-width: 400px;
  }

  @include apply-only(medium) {
    max-width: 500px;
  }
}

.tooltip--visible {
  opacity: 1;
  pointer-events: all;
}

.tooltip--centered {
  .tooltip__content {
    text-align: center;
  }
}

.tooltip--nowrap {
  .tooltip__content {
    white-space: nowrap;
  }
}

.tooltip__arrow,
.tooltip__arrow::before {
  width: 7px;
  height: 7px;
}

.tooltip__arrow::before {
  content: '';
  position: absolute;
  border-bottom: 1px solid $black;
  border-right: 1px solid $black;
  background: $white;
  left: 0;
}

.tooltip[data-popper-placement^='top'] {
  .tooltip__arrow {
    bottom: -4px;
  }

  .tooltip__arrow::before {
    transform: rotate(45deg);
  }
}

.tooltip[data-popper-placement^='bottom'] {
  .tooltip__arrow {
    top: -4px;
  }

  .tooltip__arrow::before {
    transform: rotate(225deg);
  }
}

.tooltip[data-popper-placement^='left'] {
  .tooltip__arrow {
    right: -4px;
  }

  .tooltip__arrow::before {
    transform: rotate(-45deg);
  }
}

.tooltip[data-popper-placement^='right'] {
  .tooltip__arrow {
    left: -4px;
  }

  .tooltip__arrow::before {
    transform: rotate(135deg);
  }
}
</style>
