/**
 * Storyblok bridge plugin for live preview
 */

import { loadScript, isClient } from '@mop/shared/utils/util';
import { securedWrap } from '@mop/shared/utils/securedWrap';
import { cmsStoryModel } from '@/models';
import type { MopStoryblokLivePreview, CmsStoryModel } from '@/types/cms';

export default defineNuxtPlugin((nuxtApp) => {
  let scriptLoaded = false;
  let storyblok: any;
  const route = useRoute();
  const isStoryblokLivePreview = Boolean(route.query._storyblok_lang);
  const isStoryblokLivePreviewIframe = isClient ? window.top !== window.self : false;
  const cmsStoryModelRefs: any = {};
  const storyModelIdRef = ref(0);

  useRouter().beforeEach(() => {
    storyModelIdRef.value = 0;
  });

  function loadStoryblokJs(callback: () => void) {
    if (!isStoryblokLivePreview) {
      return;
    }
    if (scriptLoaded) {
      // @ts-ignore
      if (!window.StoryblokBridge) {
        return;
      }
      callback();
      return;
    }
    scriptLoaded = true;
    loadScript({
      source: 'https://app.storyblok.com/f/storyblok-v2-latest.js',
      callback,
      triggerCallbackIfExists: true,
    });
  }

  if (isClient) {
    loadStoryblokJs(() => {
      // @ts-ignore
      storyblok = new StoryblokBridge();

      storyblok.on('input', (event: any) => {
        const keys: string[] = Object.keys(cmsStoryModelRefs);
        // Update modfied cmsStoryModelRefs, that are listening
        keys.forEach((key) => {
          const cmsStoryModelRef: Ref<CmsStoryModel> = cmsStoryModelRefs[key];
          if (event.story.id === cmsStoryModelRef.value?.getId()) {
            cmsStoryModelRef.value = cmsStoryModel({ data: event.story });
          }
        });
      });
    });
  }

  const storyblokLivePreview: MopStoryblokLivePreview = securedWrap({
    initStoryListener(cacheKey, cmsStoryModelRef, showStoryblokWidget) {
      onMounted(() => {
        if (isStoryblokLivePreview) {
          cmsStoryModelRefs[cacheKey] = cmsStoryModelRef;
          return;
        }
        if (showStoryblokWidget) {
          watch(
            cmsStoryModelRef,
            (cmsStoryModel) => {
              storyModelIdRef.value = cmsStoryModel?.getId() || 0;
            },
            { immediate: true },
          );
        }
      });
      onUnmounted(() => {
        if (isStoryblokLivePreview) {
          delete cmsStoryModelRefs[cacheKey];
        }
      });
    },
    isScriptLoaded() {
      return storyblok !== undefined;
    },
    isEnabled: isStoryblokLivePreview,
    isEnabledInIframe: isStoryblokLivePreviewIframe,
    currentStoryModelIdRef: storyModelIdRef,
  });

  nuxtApp.payload.data.storyblokLivePreviewEnabled = isStoryblokLivePreviewIframe;
  nuxtApp.provide('storyblokLivePreview', storyblokLivePreview);
});
