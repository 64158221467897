<script setup lang="ts">
import { getElementUiObject, getHeadlineWithUppercaseOption, getLink, getImage, getMedia } from '@mop/cms/utils/utils';
import type { CmsAccountOverviewCards } from '@/types/cms';

defineOptions({
  name: 'MopCmsAccountOverviewCards',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  lazyLoad: {
    type: Boolean,
    default: false,
  },
});

const data: any = props.data;

const element: CmsAccountOverviewCards = getElementUiObject(data, {
  cards: data.cards.map((item: any) => {
    return getElementUiObject(item, {
      headline: getHeadlineWithUppercaseOption(item),
      hasBorder: item.hasBorder,
      subline: item.subline,
      description: item.description,
      link: getLink(item.link),
      icon: getImage(item.icon),
      backgroundColor: item.backgroundColor?.color,
      media: getMedia(item.media?.[0]),
      hideMobile: item.hideMobile,
      hideForNoLoyalty: item.hideForNoLoyalty,
      textColor: item.textColor?.color,
    });
  }),
});
</script>

<template>
  <div v-storyblok-editable="data" class="mop-cms-account-overview-cards">
    <MopCmsAccountOverviewCard
      v-for="cardData of element.cards"
      :key="cardData._uid"
      :data="cardData"
      :lazy-load="lazyLoad"
    />
  </div>
</template>

<style lang="scss" scoped>
.mop-cms-account-overview-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: $space-8;
  column-gap: $space-8;

  @include apply-upto(medium) {
    grid-template-columns: 1fr;
    row-gap: $space15;
    column-gap: $space15;
  }
}
</style>
