import { getPassiveEventOption } from '@mop/shared/utils/util';
import type { Timer } from '@mop/types';

const uiSlideActiveClass = 'ui-mop-slide--active';
const uiSliderBulletActiveClass = 'ui-mop-slider__bullet--active';
const uiSliderNoSmoothScrollClass = 'ui-mop-slider--no-smooth-scroll';
const uiSliderBulletClass = 'ui-mop-slider__bullet';
const uiSliderStartClass = 'ui-mop-slider--start';
const uiSliderEndClass = 'ui-mop-slider--end';
const uiSliderNoSnapClass = 'ui-mop-slider-no-snap';
const pauseAutoPlayEvents: string[] = ['mouseenter', 'touchstart'];
const minOffsetForVisibleArrow = 20;

export default class UiSlider {
  sliderElement!: HTMLElement;
  sliderContainerElement!: HTMLElement;
  slides!: HTMLElement[];
  bulletContainerElement!: HTMLElement;
  bulletElements!: HTMLElement[];
  hasAutoPlay: boolean | number = false;
  hasAutoPlayOnHold = true;
  autoPlaySpeed = 3000;
  hasInfiniteLoop = false;
  isFocus = true;
  slideFullPage = false;
  currentIndex = 0;

  scrollingTimer!: Timer | null;
  autoplayTimer!: Timer | null;
  slideResizeObserver!: ResizeObserver | null;

  constructor(sliderElement: HTMLElement, autoPlaySpeed: number) {
    if (!sliderElement) {
      return;
    }
    const sliderContainerElement: HTMLElement = sliderElement.getElementsByClassName(
      'ui-mop-slider__container',
    )[0] as HTMLElement;
    if (!sliderContainerElement) {
      return;
    }
    this.autoPlaySpeed = autoPlaySpeed || 3000;
    this.handleBulletClick = this.handleBulletClick.bind(this);
    this.debouncedHandleOnScroll = this.debouncedHandleOnScroll.bind(this);
    this.handleArrowClick = this.handleArrowClick.bind(this);
    this.handleEndScroll = this.handleEndScroll.bind(this);
    this.initAutoPlay = this.initAutoPlay.bind(this);
    this.pauseAutoPlay = this.pauseAutoPlay.bind(this);
    this.stopAutoPlay = this.stopAutoPlay.bind(this);
    this.startAutoPlay = this.startAutoPlay.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);

    this.sliderElement = sliderElement as HTMLElement;
    this.sliderContainerElement = sliderContainerElement as HTMLElement;
    this.slides = [].slice.call(this.sliderContainerElement.getElementsByClassName('ui-mop-slide'));
    this.bulletContainerElement = this.sliderElement.getElementsByClassName('ui-mop-slider__bullets')[0] as HTMLElement;
    if (this.getSlideCount() <= 1) {
      this.sliderElement.classList.add('ui-mop-slider--single');
      return;
    }

    this.slideResizeObserver = new ResizeObserver((entries) => {
      if (entries[0].contentRect.width > 0) {
        this.initSlider();
        this.slideResizeObserver?.disconnect();
      }
    });
    this.slideResizeObserver.observe(this.slides[0]);
  }

  destroy() {
    this.destroyTimeouts();
    this.destroyListeners();
    this.destroyElements();
  }

  destroyTimeouts() {
    this.scrollingTimer && clearTimeout(this.scrollingTimer);
    this.autoplayTimer && clearInterval(this.autoplayTimer);

    this.scrollingTimer = null;
    this.autoplayTimer = null;
  }

  destroyListeners() {
    this.bulletContainerElement?.removeEventListener('click', this.handleBulletClick);
    this.removeScrollListener();
    this.slideResizeObserver?.disconnect();

    [].slice
      .call(this.sliderElement?.getElementsByClassName('ui-mop-slider__arrow') || [])
      .forEach((arrow: HTMLElement) => {
        arrow.removeEventListener('click', this.handleArrowClick);
      });

    pauseAutoPlayEvents.forEach((event) => {
      this.sliderElement?.removeEventListener(event, this.pauseAutoPlay);
    });
    this.sliderElement?.removeEventListener('mouseleave', this.startAutoPlay);

    window.removeEventListener('blur', this.handleBlur);
    window.removeEventListener('focus', this.handleFocus);
  }

  destroyElements() {
    // @ts-ignore
    this.sliderElement = null;
    // @ts-ignore
    this.sliderContainerElement = null;
    this.slides = [];
    this.bulletContainerElement = {} as HTMLElement;
    this.bulletElements = [];
  }

  initSlider() {
    const sliderElementClassList: DOMTokenList = this.sliderElement.classList;
    this.hasAutoPlay = sliderElementClassList.contains('ui-mop-slider--auto-play');
    this.slideFullPage = sliderElementClassList.contains('ui-mop-slider--slide-full-page');
    this.hasInfiniteLoop = sliderElementClassList.contains('ui-mop-slider--infinite-loop');

    this.initSlides();
    this.initBullets();
    this.initArrows();

    if (this.hasAutoPlay) {
      this.initAutoPlay();
      window.addEventListener('blur', this.handleBlur);
      window.addEventListener('focus', this.handleFocus);
    }
  }

  addScrollListener() {
    this.sliderContainerElement.addEventListener('scroll', this.debouncedHandleOnScroll, getPassiveEventOption());
  }

  removeScrollListener() {
    this.sliderContainerElement?.removeEventListener('scroll', this.debouncedHandleOnScroll);
  }

  handleBlur() {
    this.pauseAutoPlay();
    this.isFocus = false;
  }

  handleFocus() {
    this.isFocus = true;
    this.startAutoPlay();
  }

  initArrows() {
    [].slice.call(this.sliderElement.getElementsByClassName('ui-mop-slider__arrow')).forEach((arrow: HTMLElement) => {
      arrow.addEventListener('click', this.handleArrowClick, { passive: true });
    });
    this.handleArrowsVisibility();
  }

  handleArrowClick(event: Event) {
    const showNext: boolean = (event.currentTarget as HTMLElement).classList.contains('ui-mop-slider__arrow--next');
    this.slide(showNext);
  }

  initSlides() {
    this.sliderElement.classList.add('ui-mop-slider--initialized');
    this.addScrollListener();

    if (this.hasInfiniteLoop) {
      // scroll to next slide when clones are present
      this.setScrollLeft(this.getScrollStepWidth());
      this.sliderElement.classList.remove('ui-mop-slider--loading');
    }
  }

  getSlideCount() {
    return this.hasInfiniteLoop ? this.slides.length - 2 : this.slides.length;
  }

  initBullets() {
    // Always init Bullets, even not visible. Needed for calculation reasons on sliding
    for (let i = 0; i < this.getSlideCount(); i++) {
      const bulletElement: HTMLElement = document.createElement('button') as HTMLElement;
      bulletElement.classList.add(uiSliderBulletClass);
      if (i === 0) {
        bulletElement.classList.add(uiSliderBulletActiveClass);
      }
      this.bulletContainerElement.appendChild(bulletElement);
    }
    this.bulletContainerElement.addEventListener('click', this.handleBulletClick);
    this.bulletElements = [].slice.call(this.bulletContainerElement.children || []) as HTMLElement[];
  }

  pauseAutoPlay() {
    if (!this.isFocus) {
      return;
    }
    this.autoplayTimer && clearInterval(this.autoplayTimer);
  }

  stopAutoPlay() {
    this.autoplayTimer && clearInterval(this.autoplayTimer);
    this.hasAutoPlay = false;
  }

  startAutoPlay() {
    if (!this.hasAutoPlay || !this.isFocus) {
      return;
    }
    this.autoplayTimer = setInterval(() => {
      if (!this.hasAutoPlay) {
        return;
      }
      this.hasAutoPlayOnHold = true;
      this.slide();
    }, this.autoPlaySpeed);
  }

  initAutoPlay() {
    this.startAutoPlay();
    pauseAutoPlayEvents.forEach((event) => {
      this.sliderElement.addEventListener(event, this.pauseAutoPlay, {
        passive: true,
      });
    });
    this.sliderElement.addEventListener('mouseleave', this.startAutoPlay, {
      passive: true,
    });
  }

  setScrollLeft(scrollLeft: number) {
    this.sliderElement.classList.add(uiSliderNoSmoothScrollClass);
    this.sliderContainerElement.scrollLeft = scrollLeft;
    this.sliderElement.classList.remove(uiSliderNoSmoothScrollClass);
  }

  getScrollStepWidth(): number {
    return this.slides[0].offsetWidth + this.getGapWidth();
  }

  getGapWidth(): number {
    return parseInt(window.getComputedStyle(this.sliderContainerElement).columnGap) || 0;
  }

  scroll(scrollLeftPosition: number, behavior: ScrollBehavior | 'instant' = 'smooth') {
    this.sliderContainerElement.scroll({
      left: scrollLeftPosition,
      // @ts-ignore
      behavior,
    });
  }

  getCurrentSlideIndex() {
    const slideCount: number = this.slides.length;
    const scrollStep: number = this.getScrollStepWidth();
    const containerScrollLeft: number = this.sliderContainerElement.scrollLeft;
    return Math.round(slideCount - (slideCount * scrollStep - containerScrollLeft) / scrollStep);
  }

  slide(showNext = true) {
    if (!this.sliderElement) {
      return;
    }

    const currentSlide: number = this.getCurrentSlideIndex();
    let relativeSlideIndex: number = showNext ? currentSlide + 1 : currentSlide - 1;
    if (this.hasInfiniteLoop) {
      // adjusting for clones
      relativeSlideIndex = showNext ? currentSlide : currentSlide - 2;
    }
    this.slideToIndex(relativeSlideIndex);
  }

  handleBulletClick(event: MouseEvent) {
    const eventTarget: HTMLElement = event.target as HTMLElement;
    if (!eventTarget.classList.contains(uiSliderBulletClass)) {
      return;
    }
    const bulletIndex: number = Array.from(eventTarget.parentElement?.children ?? []).indexOf(eventTarget);
    const bulletCount: number = eventTarget.parentElement?.children.length ?? 0;
    const slideCount: number = this.getSlideCount();
    const relativeSlideIndex: number = (slideCount / bulletCount) * bulletIndex;
    this.slideToIndex(relativeSlideIndex);
  }

  slideToIndex(slideIndex: number, behavior: ScrollBehavior | 'instant' = 'smooth') {
    if (this.hasInfiniteLoop) {
      slideIndex++;
    }
    const scrollStep: number = this.getScrollStepWidth();
    this.sliderContainerElement.classList.add(uiSliderNoSnapClass);

    if (behavior === 'smooth') {
      this.scroll(scrollStep * slideIndex, behavior);
    } else {
      setTimeout(() => this.scroll(scrollStep * slideIndex, behavior), 0);
    }
  }

  debouncedHandleOnScroll() {
    this.scrollingTimer && clearTimeout(this.scrollingTimer);
    this.scrollingTimer = setTimeout(this.handleEndScroll, 100);
  }

  handleEndScroll() {
    if (!this.sliderElement) {
      return;
    }
    this.sliderContainerElement.classList.remove(uiSliderNoSnapClass);
    const currentSlide: number = this.getCurrentSlideIndex();
    const isFirstClone: boolean = currentSlide === 0;
    const isLastClone: boolean = currentSlide === this.slides.length - 1;
    if (this.hasInfiniteLoop) {
      // reached end of slider, reset to start
      if (isLastClone) {
        this.setScrollLeft(this.getScrollStepWidth());
      } else if (isFirstClone) {
        // went back, reset to end of slider
        this.setScrollLeft(this.getScrollStepWidth() * this.getSlideCount());
      }
    }

    if (!this.hasAutoPlayOnHold && this.hasAutoPlay) {
      this.stopAutoPlay();
    }

    if (this.hasInfiniteLoop && (isFirstClone || isLastClone)) {
      return;
    }
    if (this.hasInfiniteLoop) {
      // adjusting for clones
      this.handleSlideChanged(currentSlide - 1);
    } else {
      this.handleSlideChanged(currentSlide);
    }
  }

  handleSlideChanged(activeIndex: number) {
    if (this.currentIndex === activeIndex) {
      return;
    }
    const event: CustomEvent = new CustomEvent('slide-change', {
      detail: {
        activeIndex,
        wasAutoScroll: this.hasAutoPlay,
      },
    });
    this.currentIndex = activeIndex;
    this.sliderElement.dispatchEvent(event);

    if (this.hasAutoPlayOnHold) {
      this.hasAutoPlayOnHold = false;
    }

    this.handleArrowsVisibility();

    this.toggleActiveSlides(activeIndex);
  }

  handleArrowsVisibility() {
    if (!this.hasInfiniteLoop) {
      const { scrollWidth, scrollLeft, offsetWidth } = this.sliderContainerElement;
      if (scrollLeft < minOffsetForVisibleArrow) {
        this.sliderElement.classList.add(uiSliderStartClass);
      } else {
        this.sliderElement.classList.remove(uiSliderStartClass);
      }

      if (scrollWidth - scrollLeft - minOffsetForVisibleArrow <= offsetWidth) {
        this.sliderElement.classList.add(uiSliderEndClass);
      } else {
        this.sliderElement.classList.remove(uiSliderEndClass);
      }
    }
  }

  toggleActiveSlides(activeIndex: number) {
    this.slides.forEach((slide) => slide.classList.remove(uiSlideActiveClass));
    if (this.hasInfiniteLoop) {
      this.slides[activeIndex + 1].classList.add(uiSlideActiveClass);
    } else {
      this.slides[activeIndex].classList.add(uiSlideActiveClass);
    }

    this.bulletElements.forEach((bulletElement) => bulletElement.classList.remove(uiSliderBulletActiveClass));
    this.bulletElements[activeIndex].classList.add(uiSliderBulletActiveClass);
  }
}
