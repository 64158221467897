import type { AxiosError, AxiosInstance } from 'axios';
import { handleAxiosError, errorHandler } from '@/api/utils';
import type {
  ReservationListResponseData,
  ReservationResponseData,
  CreateReservationData,
  ReservationStatus,
} from '@/types/reservation';
const API_NAME = 'AWS - reservation';

export function getReservations(apiInstance: AxiosInstance, authToken: string): Promise<ReservationListResponseData> {
  return errorHandler<ReservationListResponseData>(async () => ({
    data: (
      await apiInstance.get('/reservations', {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'x-ayc-shop-id': apiInstance.prototype.shopId,
        },
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getReservations',
      authToken,
      shopId: apiInstance.prototype.shopId,
    }),
  }));
}

export function getReservationById(
  apiInstance: AxiosInstance,
  authToken: string,
  reservationId: string,
): Promise<ReservationResponseData> {
  return errorHandler<ReservationResponseData>(async () => ({
    data: (
      await apiInstance.get(`/reservations/${reservationId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'x-ayc-shop-id': apiInstance.prototype.shopId,
        },
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getReservationById',
      reservationId,
      authToken,
      shopId: apiInstance.prototype.shopId,
    }),
  }));
}

export function createReservation(
  apiInstance: AxiosInstance,
  authToken: string,
  reservationData: CreateReservationData,
): Promise<ReservationResponseData> {
  return errorHandler<ReservationResponseData>(async () => ({
    data: (
      await apiInstance.post('/reservations', reservationData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'x-ayc-shop-id': apiInstance.prototype.shopId,
        },
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'createReservation',
      authToken,
      shopId: apiInstance.prototype.shopId,
      reservationData,
    }),
  }));
}

export function requestReservation(
  apiInstance: AxiosInstance,
  authToken: string,
  reservationStatus: ReservationStatus,
  reservationId: string,
): Promise<ReservationResponseData> {
  return errorHandler<ReservationResponseData>(async () => ({
    data: (
      await apiInstance.patch(
        `/reservations/${reservationId}`,
        {
          reservationStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'x-ayc-shop-id': apiInstance.prototype.shopId,
          },
        },
      )
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'requestReservation',
      authToken,
      shopId: apiInstance.prototype.shopId,
      reservationId,
      reservationStatus,
    }),
  }));
}

export function deleteReservation(
  apiInstance: AxiosInstance,
  authToken: string,
  reservationId: string,
): Promise<ReservationResponseData> {
  return errorHandler<ReservationResponseData>(async () => ({
    data: (
      await apiInstance.delete(`/reservations/${reservationId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'x-ayc-shop-id': apiInstance.prototype.shopId,
        },
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'deleteReservation',
      authToken,
      shopId: apiInstance.prototype.shopId,
      reservationId,
    }),
  }));
}
