import { logWarning } from '@mop/shared/utils/logger';
import type { AnonymousReservation } from '@/types/reservation';
export function anonymousReservationModel(reservation: string | null) {
  let parsedReservation: AnonymousReservation | null;

  try {
    parsedReservation = reservation ? JSON.parse(reservation) : null;
  } catch (error) {
    logWarning(`Anonymous reservation value "${reservation}" is not a valid JSON.`);
    parsedReservation = null;
  }

  return {
    hasReservation(): boolean {
      return parsedReservation !== null;
    },

    getEan(): string {
      return parsedReservation?.ean ?? '';
    },

    getMopId(): string {
      return parsedReservation?.mopId ?? '';
    },

    getStoreId(): string {
      return parsedReservation?.storeId ?? '';
    },
  };
}
