import { isClient } from '@mop/shared/utils/util';
import { securedWrap } from '@mop/shared/utils/securedWrap';
import { promotionListModel } from '@/models';
import type { ProductModel } from '@/types/product';
import type { CartModel } from '@/types/cart';
import type { CustomerGroup } from '@/types/customer';
import type { CmsVisibility, CmsStoryResponseData } from '@/types/cms';
import type {
  PromotionBasedOn,
  PromotionConfig,
  PromotionModel,
  PromotionConfigFlag,
  PromotionModelListRef,
} from '@/types/promotion';

const availablePromotions = [
  constants.STORYBLOK.GLOBAL_STORY_NAMES.SALE_PREFERENCES,
  constants.STORYBLOK.GLOBAL_STORY_NAMES.FREE_SHIPPING_PREFERENCES,
  constants.STORYBLOK.GLOBAL_STORY_NAMES.GIVEAWAY_PREFERENCES,
  constants.STORYBLOK.GLOBAL_STORY_NAMES.PROMOTION_PREFERENCES,
];

type LoadingState = {
  initPromotions: boolean;
  loading: boolean;
};

type PromotionsComposableStorage = {
  [key: string]: any;
  promotions: PromotionModelListRef;
  saleCache: {
    enabled?: boolean;
  };
  freeShippingCache: {
    enabled?: boolean;
    expiration?: number;
    [key: string]: any;
  };
  giveawayCache: {
    enabled?: boolean;
    expiration?: number;
    [key: string]: any;
  };
};

type PromotionName = keyof PromotionsComposableStorage;

export type CmsCategory = {
  bapiCategoryId: number;
  mopCategoryId: string;
};

export default function useMopPromotions() {
  const { $mopConfig, $abTestIsFreeShippingAbTestActive } = useNuxtApp();
  const { customerModelRef } = useMopCustomer();
  const customerGroups: CustomerGroup[] = customerModelRef.value.getCustomerGroups();
  const storage = initStorage<PromotionsComposableStorage>('usePromotions');
  const promotionModelListRef: PromotionModelListRef =
    storage.get('promotions') || storage.saveAndGet('promotions', ref(promotionListModel(null, customerGroups)));

  const loadingRef: Ref<LoadingState> = ref({
    initPromotions: false,
    loading: computed(() => isLoading(loadingRef)),
  });

  /**
   * Called in app init.
   * Do not init elsewhere.
   */
  function initPromotions() {
    loadingRef.value.initPromotions = true;
    const { cmsGlobalStoryListModelRef } = useMopCmsGlobalComponents();
    const promotionConfigs = availablePromotions.reduce((configList: PromotionConfig[], promotionName: string) => {
      const promotionResponse = cmsGlobalStoryListModelRef.value
        .getStoryModelByName(promotionName)
        ?.getResponse() as CmsStoryResponseData;
      if (!promotionResponse) {
        return configList;
      }
      const cmsPromotionContent: any = promotionResponse.data?.content;
      const categories: CmsCategory[] = cmsPromotionContent.categories ? cmsPromotionContent.categories : [];
      const visibilityGroups: CmsVisibility[] = cmsPromotionContent.customerGroups;
      const flags: PromotionConfigFlag[] = cmsPromotionContent.flags || [];
      configList.push({
        component: cmsPromotionContent.component,
        validFrom: cmsPromotionContent.validFrom,
        validTo: cmsPromotionContent.validTo,
        isActive: cmsPromotionContent.isActive,
        basedOn: cmsPromotionContent.basedOn,
        showCallOutOnPdpPlp: cmsPromotionContent.showCallOutOnPdpPlp,
        visibilityGroups,
        categoryIds: categories.map((category: CmsCategory) => category.mopCategoryId),
        callOutMessage: cmsPromotionContent.callOutMessage,
        giveawayProduct: cmsPromotionContent.giveawayProduct,
        minOrderValue: cmsPromotionContent.minOrderValue,
        promotionKey: cmsPromotionContent.promotionKey,
        requiredProductCount: cmsPromotionContent.requiredProductCount,
        flags,
      } as PromotionConfig);
      return configList;
    }, []);

    promotionModelListRef.value = promotionListModel(promotionConfigs, customerGroups);
    loadingRef.value.initPromotions = false;
  }

  function isFreeShippingEnabled(cart?: CartModel, mopCategoryIds?: string[]): boolean {
    if (!isClient) {
      return false;
    }
    const promotion = getPromotion(constants.PROMOTIONS.FREE_SHIPPING_PREFERENCES);

    if (cart) {
      return promotion.isValidCart(cart);
    }

    const storageKey: keyof PromotionsComposableStorage = 'freeShippingCache';
    const freeShippingCache = storage.get(storageKey) || storage.saveAndGet(storageKey, { enabled: undefined });

    const now = Date.now();
    const cacheId = mopCategoryIds?.join(' ') || '';

    if (freeShippingCache.enabled === false) {
      return false;
    }

    if (now < (freeShippingCache?.expiration || 0) && cacheId in freeShippingCache) {
      return freeShippingCache[cacheId];
    }

    if (!promotion.isEnabled()) {
      return storage.saveAndGet(storageKey, { enabled: false }).enabled === true;
    }

    freeShippingCache.enabled = promotion.isEnabled();
    freeShippingCache.expiration = promotion.getExpirationDate();
    freeShippingCache[cacheId] = mopCategoryIds ? promotion.isValidProduct(mopCategoryIds) : undefined;
    return storage.saveAndGet(storageKey, freeShippingCache)[cacheId];
  }

  function getFreeShippingCallOutMessage() {
    if (!isClient) {
      return '';
    }
    return getPromotion(constants.PROMOTIONS.FREE_SHIPPING_PREFERENCES).getCallOutMessage();
  }

  function isPromotionEnabled(mopCategoryIds?: string[]): boolean {
    if (!isClient) {
      return false;
    }
    const promotion = getPromotion(constants.PROMOTIONS.PROMOTION_PREFERENCES);

    const storageKey: keyof PromotionsComposableStorage = 'promotionCache';
    const promotionCache = storage.get(storageKey) || storage.saveAndGet(storageKey, { enabled: undefined });

    const now = Date.now();
    const cacheId = mopCategoryIds?.join(' ') || '';

    if (promotionCache.enabled === false) {
      return false;
    }

    if (now < (promotionCache?.expiration || 0) && cacheId in promotionCache) {
      return promotionCache[cacheId];
    }

    if (!promotion.isEnabled()) {
      return storage.saveAndGet(storageKey, { enabled: false }).enabled === true;
    }

    promotionCache.enabled = promotion.isEnabled();
    promotionCache.expiration = promotion.getExpirationDate();
    let isEnabled: boolean | undefined = false;
    if (promotion.getBasedOn() !== 'category') {
      isEnabled = true;
    } else {
      isEnabled = mopCategoryIds ? promotion.isValidProduct(mopCategoryIds) : undefined;
    }
    promotionCache[cacheId] = isEnabled;
    return storage.saveAndGet(storageKey, promotionCache)[cacheId];
  }

  function getPromotionCallOutFlag(product: ProductModel) {
    if (!isClient) {
      return;
    }
    if (product.isGiftCard()) {
      return;
    }
    const promotion = getPromotion(constants.PROMOTIONS.PROMOTION_PREFERENCES);
    const basedOn = promotion.getBasedOn();
    if (basedOn === 'promoQualifier') {
      if (!product.isPromoQualified()) {
        return;
      }
    } else if (basedOn === 'sale') {
      if ((product.getPrice().discountPercentage ?? 0) <= 0) {
        return;
      }
    }

    return promotion.getPromotionFlag();
  }

  function getShippingCost(cart: CartModel) {
    if (cart.isGiftCardOnlyOrder()) {
      return 0.0;
    }

    const isFreeShippingAbTestActive =
      $abTestIsFreeShippingAbTestActive() &&
      $mopConfig.getFreeShippingMinAmount() > 0 &&
      cart.getTotal() > $mopConfig.getFreeShippingMinAmount();

    const isFreeShippingPromotionActive = isFreeShippingEnabled(cart);

    return isFreeShippingAbTestActive || isFreeShippingPromotionActive ? 0.0 : $mopConfig.getShippingInfo().cost;
  }

  function isSaleEnabled(promotionBasedOn?: PromotionBasedOn) {
    if (!isClient) {
      return false;
    }
    const promotion: PromotionModel = getPromotion(constants.PROMOTIONS.SALE_PREFERENCES);
    const storageKey = 'saleCache';
    const localCache = storage.get(storageKey) || storage.saveAndGet(storageKey, { enabled: undefined });

    const basedOn = promotion.getBasedOn();
    if (!promotion.isEnabled() || (promotionBasedOn && basedOn !== promotionBasedOn)) {
      return storage.saveAndGet(storageKey, { enabled: undefined }).enabled === true;
    }

    localCache.enabled = promotion.isEnabled() && promotion.isValidTimeFrame(new Date());
    return storage.saveAndGet(storageKey, localCache).enabled === true;
  }

  function isProductQualified(product: ProductModel) {
    if (!isClient) {
      return false;
    }
    if (product.isGiftCard()) {
      return false;
    }
    const promotion = getPromotion(constants.PROMOTIONS.SALE_PREFERENCES);
    const basedOn = promotion.getBasedOn();
    if (basedOn === 'promoQualifier') {
      return product.isPromoQualified();
    }
    if (basedOn === 'sale') {
      return (product.getPrice().discountPercentage ?? 0) > 0;
    }
    return false;
  }

  function getSaleCallOutMessage() {
    if (!isSaleEnabled()) {
      return '';
    }

    return getPromotion(constants.PROMOTIONS.SALE_PREFERENCES).getCallOutMessage();
  }

  function getSalePromotionKey() {
    if (!isSaleEnabled()) {
      return '';
    }

    const promotion = getPromotion(constants.PROMOTIONS.SALE_PREFERENCES);
    return promotion.isValidCustomerGroup() ? promotion.getPromotionKey() : '';
  }

  function isGiveawayEnabled(cart?: CartModel, mopCategoryIds?: string[]) {
    if (!isClient) {
      return false;
    }
    const promotion = getPromotion(constants.PROMOTIONS.GIVEAWAY_PREFERENCES);

    if (!promotion.isEnabled()) {
      return false;
    }
    if (cart) {
      return promotion.isValidCart(cart);
    }

    const storageKey = 'giveawayCache';
    const giveawayCache = storage.get(storageKey) || storage.saveAndGet(storageKey, { enabled: undefined });

    const now = Date.now();
    const cacheId = mopCategoryIds?.join(' ') || '';

    if (giveawayCache.enabled === false) {
      return false;
    }

    if (now < (giveawayCache.expiration ?? 0) && cacheId in giveawayCache) {
      return giveawayCache[cacheId] === true;
    }

    if (!promotion.isEnabled()) {
      return storage.saveAndGet(storageKey, { enabled: false }).enabled === true;
    }

    giveawayCache.enabled = promotion.isEnabled();
    giveawayCache.expiration = promotion.getExpirationDate();
    giveawayCache[cacheId] = mopCategoryIds ? promotion.isValidProduct(mopCategoryIds) : undefined;
    return storage.saveAndGet(storageKey, giveawayCache)[cacheId] === true;
  }

  function getGiveawayCallOutMessage() {
    if (!isClient) {
      return '';
    }
    return getPromotion(constants.PROMOTIONS.GIVEAWAY_PREFERENCES).getCallOutMessage();
  }

  function showSaleCallOutMessage() {
    if (!isClient) {
      return false;
    }
    const promotion = getPromotion(constants.PROMOTIONS.SALE_PREFERENCES);
    return promotion.showCallOutMessage();
  }

  function getPromotion(promotionName: PromotionName): PromotionModel {
    return (
      storage.get(promotionName) ||
      storage.saveAndGet(promotionName, promotionModelListRef.value.getPromotionModelByName(promotionName as string))
    );
  }

  return securedWrap({
    promotionModelListRef,
    initPromotions,
    isFreeShippingEnabled,
    isSaleEnabled,
    isProductQualified,
    isGiveawayEnabled,
    isPromotionEnabled,
    getFreeShippingCallOutMessage,
    getSaleCallOutMessage,
    getGiveawayCallOutMessage,
    // @todo in extra CR: replace all get callout messages with getPromotionCallOutFlag
    getPromotionCallOutFlag,
    getShippingCost,
    getSalePromotionKey,
    showSaleCallOutMessage,
    loadingRef,
  });
}
