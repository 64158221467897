import type { ISbStoryParams, ISbStoriesParams } from 'storyblok-js-client';
import { getStory, getStories, getGlobalStories } from './apiCms';
import type { ApiCmsInstance } from '@/types/cms';
export default function initApiCms(apiInstance: ApiCmsInstance) {
  return {
    getStory: (slug: string, params?: ISbStoryParams, contentOnly?: boolean) =>
      getStory(apiInstance, slug, params, contentOnly),
    getStories: (slug?: string, params?: ISbStoriesParams, contentOnly?: boolean) =>
      getStories(apiInstance, slug, params, contentOnly),
    getGlobalStories: (slug: string, params?: ISbStoriesParams) => getGlobalStories(apiInstance, slug, params),
  };
}

export type ApiCms = ReturnType<typeof initApiCms>;
