import { isClient } from '@mop/shared/utils/util';
export const isNuxtContext = typeof useNuxtApp !== 'undefined';

export function getCmsImageUrlBySize(src: string, width: string): string | null {
  if (!src) {
    return null;
  }
  if (src?.endsWith('svg')) {
    return src;
  }

  return `${src}/m/${width}x0/filters:quality(80)`;
}

export function isTouchDevice() {
  if (!isClient) {
    return false;
  }
  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    // @ts-ignore
    navigator.msMaxTouchPoints > 0
  );
}
