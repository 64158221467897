import type { AxiosError, AxiosInstance } from 'axios';
import { errorHandler, handleAxiosError } from '@/api/utils';
import type { StatusResponseData } from '@/types/status';
import type { CreateAddressDataPayload } from '@/types/customer';
const API_NAME = 'CRM - address';

export function createAddress(
  apiInstance: AxiosInstance,
  accessToken: string,
  payload: CreateAddressDataPayload,
): Promise<StatusResponseData> {
  return errorHandler<StatusResponseData>(async () => {
    await apiInstance.post('/api/Address', payload, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return {
      data: '',
    };
  }).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'createAddress',
      accessToken,
    }),
  }));
}
