import type { AxiosError } from 'axios';
import type { BapiClient } from '@aboutyou/backbone';
import { handleAxiosError, errorHandler } from '@/api/utils';
import { transformBapiCategoriesResponse } from '@/api/utils/category';
import type {
  SearchSuggestionResponseData,
  SearchSuggestionParams,
  SearchSuggestionData,
} from '@/types/searchSuggestions';
const API_NAME = 'AYC - search suggestion';

export function getSearchSuggestions(
  apiInstance: BapiClient,
  term: string,
  params?: SearchSuggestionParams,
): Promise<SearchSuggestionResponseData> {
  return errorHandler<SearchSuggestionResponseData>(async () => {
    const result: any = await apiInstance.typeahead.suggestions(term, params);
    const suggestions: SearchSuggestionData[] = (result?.suggestions as SearchSuggestionData[]).reduce(
      (list: SearchSuggestionData[], suggestion: SearchSuggestionData) => {
        if (suggestion.type === 'brandOrCategory' && suggestion.brandOrCategorySuggestion.category) {
          suggestion.brandOrCategorySuggestion.category = transformBapiCategoriesResponse([
            suggestion.brandOrCategorySuggestion.category,
          ])[0];
        }
        list.push(suggestion);
        return list;
      },
      [],
    );

    return {
      data: {
        suggestions,
      },
    };
  }).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getSearchSuggestions',
      term,
      params,
    }),
  }));
}
