import type { AxiosError } from 'axios';
import type { BapiClient } from '@aboutyou/backbone';
import { handleAxiosError, errorHandler } from '@/api/utils';
import { defaultPropertyNames, transformBapiCategoriesResponse } from '@/api/utils/category';
import type {
  CategoryResponseData,
  CategoryCountResponseData,
  CategoryListResponseData,
  CategoryData,
  SearchCategoryParams,
  SearchCategoryCountParams,
} from '@/types/category';
const API_NAME = 'AYC - category';

export function getSaleCategoriesCount(apiInstance: BapiClient): Promise<CategoryCountResponseData> {
  const saleCategoryCountParams: SearchCategoryCountParams = {
    where: {
      attributes: [
        {
          type: 'boolean',
          key: 'sale' as any,
          value: true,
        },
      ],
    },
  };

  return getCategoriesCount(apiInstance, saleCategoryCountParams);
}

export function getCategoriesCount(
  apiInstance: BapiClient,
  params: SearchCategoryCountParams = {},
): Promise<CategoryCountResponseData> {
  return errorHandler<CategoryCountResponseData>(async () => ({
    data: await apiInstance.filters.getValues('CategoryIds', params),
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getCategoriesCount',
      params,
    }),
  }));
}

function transformBapiCategoryResponse(bapiCategory: any): CategoryData {
  return transformBapiCategoriesResponse([bapiCategory])[0];
}

export function getCategoryById(
  apiInstance: BapiClient,
  id: number,
  params: SearchCategoryParams = {},
): Promise<CategoryResponseData> {
  return errorHandler<CategoryResponseData>(async () => ({
    data: transformBapiCategoryResponse(await apiInstance.categories.getById(id, params)),
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getCategoryById',
      id,
      params,
    }),
  }));
}

export function getCategoryListByIds(apiInstance: BapiClient, ids: number[]): Promise<CategoryListResponseData> {
  return errorHandler<CategoryListResponseData>(async () => ({
    data: transformBapiCategoryResponse(await apiInstance.categories.getByIds(ids)),
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: getCategoryListByIds,
      ids,
    }),
  }));
}

export function getCategoryByPath(apiInstance: BapiClient, path: string[]): Promise<CategoryResponseData> {
  return errorHandler<CategoryResponseData>(async () => ({
    data: transformBapiCategoryResponse(
      await apiInstance.categories.getByPath(path, {
        with: {
          properties: {
            withName: [...defaultPropertyNames, 'alternate'],
          },
        },
      }),
    ),
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getCategoryByPath',
      path,
    }),
  }));
}

export function getAllCategories(apiInstance: BapiClient): Promise<CategoryListResponseData> {
  return errorHandler<CategoryListResponseData>(async () => ({
    data: transformBapiCategoriesResponse(
      await apiInstance.categories.getRoots({
        with: {
          children: 3,
          properties: {
            withName: defaultPropertyNames,
          },
        },
        includeHidden: true,
      }),
    ),
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getAllCategories',
    }),
  }));
}
