import { customerModel, orderProductModel } from '@/models';
import type { CustomerModel } from '@/types/customer';
import type {
  OrderData,
  OrderModel,
  OrderResponseData,
  OrderListResponseData,
  OrderStatus,
  AddressData,
  OrderItemData,
  OrderProductModel,
  ReductionData,
  ReductionType,
  PackageData,
  PaymentItemData,
} from '@/types/orders';

function getAddress(address?: AddressData): AddressData | null {
  if (!address) {
    return null;
  }
  return {
    zip: address?.zip ?? '',
    firstName: address?.firstName ?? '',
    lastName: address?.lastName ?? '',
    gender: address?.gender ?? '',
    city: address?.city ?? '',
    street: address?.street ?? '',
    houseNumber: address?.houseNumber ?? '',
    countryCode: address?.countryCode.toLowerCase() ?? '',
    phoneNumber: address?.phoneNumber ?? '',
    additional: address?.additional ?? '',
    collectionPoint: address?.collectionPoint ?? null,
  };
}

const statusSequence: OrderStatus[] = [
  'created',
  'pending',
  'confirmed',
  'exported',
  'waiting',
  'inProgress',
  'completed',
  'canceled',
  'shipped',
  'outForDelivery',
  'delayed',
  'delivered',
  'returned',
];

export function orderModel(responseData: OrderResponseData | null) {
  const response: OrderResponseData = responseData ?? {};

  const localCache: any = {};

  return {
    hasError(): boolean {
      return response.error !== undefined;
    },

    isInitialized(): boolean {
      return responseData !== null;
    },

    isPlacedSuccessfully(): boolean {
      return response.data?.orderStatus !== 'canceled';
    },

    getId(): string {
      return response.data?.orderId ?? '';
    },

    getExtId(): string {
      return response.data?.extOrderNo ?? '';
    },

    getVisibleId(): string {
      return this.isGlobalEOrder() ? this.getExtId() : this.getId();
    },

    isGlobalEOrder(): boolean {
      return response.data?.orderType === 'globalE';
    },

    isOffline(): boolean {
      return response.data?.orderType === 'offline';
    },

    isDelivered(): boolean {
      if (!response.data?.orderStatus) {
        return false;
      }
      return statusSequence.indexOf(response.data.orderStatus) >= statusSequence.indexOf('delivered');
    },

    isShipped(): boolean {
      if (!response.data?.orderStatus) {
        return false;
      }
      return statusSequence.indexOf(response.data.orderStatus) >= statusSequence.indexOf('shipped');
    },

    isCanceled(): boolean {
      return response.data?.orderStatus === 'canceled';
    },

    isPayed(): boolean {
      return this.getOrderBalance() <= 0;
    },

    getOrderDate(): string {
      return response.data?.orderDate ?? '';
    },

    getCurrency(): string {
      return response.data?.payment.currency ?? '';
    },

    getLocale(): string {
      return (response.data?.locale ?? '').toLowerCase();
    },

    getShippingAddress(): AddressData | null {
      return getAddress(response.data?.addresses?.shipping);
    },

    getBillingAddress(): AddressData | null {
      return getAddress(response.data?.addresses?.billing);
    },

    getPackages(): PackageData[] {
      return response.data?.packages ?? [];
    },

    getShippingMethod(): string {
      return response.data?.packages[0]?.carrier?.toLowerCase() ?? '';
    },

    getPayment(): { method: string; provider: string } {
      const paymentItemData: PaymentItemData | null =
        response.data?.payment.payments.find((p: any) => p?.method?.toLowerCase() !== 'gift_card') || null;
      if (!paymentItemData) {
        return { method: '', provider: '' };
      }
      const method: string = paymentItemData?.method?.toLowerCase() ?? '';
      const provider: string = method === 'credit_card' ? paymentItemData?.cardType?.toLowerCase() ?? '' : method;
      return { method, provider };
    },

    getCustomer(): CustomerModel {
      return customerModel({
        data: {
          birthDate: '',
          country: '',
          createdAt: '',
          customData: {},
          date: '',
          email: response.data?.customer.email || '',
          firstName: response.data?.customer.firstName || '',
          gender: response.data?.customer.gender || '',
          groups: [],
          id: response.data?.customer.aycId || 0,
          lastName: response.data?.customer.lastName || '',
          publicKey: response.data?.customer.crmCustomerCardNumber || '',
          referenceKey: response.data?.customer.crmContactNo || '',
          registrationDate: '',
          score: '',
          status: {
            isGuestCustomer: Boolean(response.data?.customer.isGuest),
            isActive: true,
            isTestCustomer: false,
          },
          title: '',
          updatedAt: '',
        },
      });
    },

    getOrderItems(): OrderProductModel[] {
      if (localCache.orderItems !== undefined) {
        return localCache.orderItems;
      }
      const orderItems: OrderItemData[] = response.data?.items ?? [];

      localCache.orderItems = orderItems
        .reduce((productList: OrderItemData[], productData: OrderItemData) => {
          let product: OrderItemData | undefined = productList.find((item) => item.ean === productData.ean);
          const isReturned = productData.orderStatus?.status === 'returned';

          if (product) {
            product.quantity = (product.quantity ??= 0) + 1;
          } else {
            product = productData;
            product.quantity = 1;
            productList.push(product);
          }
          if (isReturned) {
            product.returnedQuantity = (product.returnedQuantity ??= 0) + 1;
          }
          return productList;
        }, [])
        .map(orderProductModel);

      return localCache.orderItems;
    },

    getShipFromStoreOrderItems(): OrderProductModel[] {
      return this.getOrderItems().filter((orderProduct) => orderProduct.isShipFromStoreOrder());
    },

    getOnlineOrderItems(): OrderProductModel[] {
      return this.getOrderItems().filter((orderProduct) => !orderProduct.isShipFromStoreOrder());
    },

    hasMultipleShipments(): boolean {
      return this.getOnlineOrderItems().length > 0 && this.getShipFromStoreOrderItems().length > 0;
    },

    isBackOrder(): boolean {
      return this.getOrderItems().some((orderProduct) => orderProduct.isBackOrder()) ?? false;
    },

    getSubtotalPrice(): number {
      return this.getOrderItems().reduce((total, orderItem) => total + orderItem.getTotalOriginalPrice(), 0);
    },

    getShippingPrice(): number {
      return response.data?.fees?.find((fee) => fee.type === 'shipping')?.totalGrossPrice ?? 0;
    },

    getShippingNetPrice(): number {
      return response.data?.fees?.find((fee) => fee.type === 'shipping')?.totalNetPrice ?? 0;
    },

    getPaymentPrice(): number {
      return response.data?.fees?.find((fee) => fee.type === 'payment')?.totalGrossPrice ?? 0;
    },

    getPaymentNetPrice(): number {
      return response.data?.fees?.find((fee) => fee.type === 'payment')?.totalNetPrice ?? 0;
    },

    getTotalDiscount(): number {
      return this.getTotalItemLevelDiscount() + this.getTotalOrderLevelDiscount();
    },

    getTotalItemLevelDiscount(): number {
      return -(
        this.getSubtotalPrice() -
        this.getOrderItems().reduce((total, orderItem) => total + orderItem.getTotalPrice(), 0)
      );
    },

    getReturnedValue(): number {
      return response.data?.transaction?.returnedValue || 0;
    },

    getOrderBalance(): number {
      let balance: number = Math.abs(response.data?.transaction?.orderBalance || 0);
      if (balance) {
        balance = balance - this.getReturnedValue();
      }
      return balance;
    },

    getTotalOrderLevelDiscount(): number {
      return response.data?.reductions?.reduce((total, reduction) => total + reduction.amount, 0) ?? 0;
    },

    getVoucherValue(): number {
      const voucherValue = this.getReductionByType('voucher')?.amount;
      return voucherValue ? -1 * voucherValue : 0;
    },

    getVoucherCode(): string {
      return this.getReductionByType('voucher')?.couponCode ?? '';
    },

    getReductionByType(reductionType: ReductionType): ReductionData | undefined {
      const orderLevelReduction = response.data?.reductions?.find((reduction) => reduction.category === reductionType);
      if (orderLevelReduction) {
        return orderLevelReduction;
      }
      return response.data?.items.reduce((summedReduction: ReductionData | undefined, item) => {
        const reduction = item?.price?.reductions?.find((reduction) => reduction.category === reductionType);
        if (!reduction) {
          return summedReduction;
        }
        return {
          category: reduction.category,
          amount: (summedReduction?.amount || 0) + reduction.amount,
          couponCode: reduction.couponCode,
        };
      }, undefined);
    },

    getTotalPrice(): number {
      return (response.data?.totals.grossPrice ?? 0) - this.getReturnedValue();
    },

    getTotalNetPrice(): number {
      return response.data?.totals.netPrice ?? 0;
    },

    getTotalTax(): number {
      return response.data?.totals.tax ?? 0;
    },

    getTotalOrderCount(): number {
      return response.data?.totalOrderCount ?? 0;
    },

    getDownloadInvoiceUrlList(): string[] {
      return response.data?.invoices ?? [];
    },

    getDownloadReturnLabelUrlList(): string[] {
      return response.data?.returnLabels ?? [];
    },

    getTrackingLink(): string {
      return response.data?.trackingPath ?? '';
    },
  };
}

export function orderListModel(responseData: OrderListResponseData | null) {
  const response: OrderListResponseData = responseData ?? {};

  return {
    hasError(): boolean {
      return response.error !== undefined;
    },

    isInitialized(): boolean {
      return responseData !== null;
    },

    getCount(): number {
      return response.data?.length ?? 0;
    },

    getOrderModelList(): OrderModel[] {
      if (response.data === undefined) {
        return [];
      }
      return response.data
        .map((order: OrderData) => orderModel({ data: order }))
        .sort((orderA, orderB) => {
          const a: number = new Date(orderA.getOrderDate()).getTime();
          const b: number = new Date(orderB.getOrderDate()).getTime();

          if (a < b) {
            return 1;
          }
          if (a > b) {
            return -1;
          }
          return 0;
        });
    },
  };
}
