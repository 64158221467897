import type { AddressResponseData, AddressData } from '@/types/address';
import { getCountryFromAlpha3, getAlpha3FromCountry } from '@/i18n/localeList';

export function addressModel(responseData: AddressResponseData | null, countryCode = '') {
  const countryAlpha3 = getAlpha3FromCountry(countryCode);
  const addressData =
    responseData?.data?.entities?.find(
      (address) => address.isDefault.billing && address.countryCode === countryAlpha3,
    ) ?? ({} as AddressData);

  return {
    hasError(): boolean {
      return responseData?.error !== undefined;
    },

    getId() {
      return addressData.id;
    },

    getStreet() {
      return addressData.street ?? '';
    },

    getAdditional() {
      return addressData.additional ?? '';
    },

    getHouseNumber() {
      return addressData.houseNumber ?? '';
    },

    getCity() {
      return addressData.city ?? '';
    },

    getZip() {
      return addressData.zipCode ?? '';
    },

    getCountryCode() {
      return getCountryFromAlpha3(addressData.countryCode);
    },
  };
}
