import type { AxiosError, AxiosInstance } from 'axios';
import { handleAxiosError, errorHandler } from '@/api/utils';
import type { ProductStockResponse, ProductStockResponseData, StockResponseParams } from '@/types/product';
const API_NAME = 'AWS - get stock';
export function getStockData(apiInstance: AxiosInstance, params: StockResponseParams): ProductStockResponse {
  return errorHandler<ProductStockResponseData>(async () => ({
    data: (
      await apiInstance.post('/getstock', {
        ...params,
        glns: [constants.ORDER.ARVATO_WAREHOUSE_GLN],
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getStockData',
      params,
    }),
  }));
}
