<script setup lang="ts">
import { throttle } from '@mop/shared/utils/util';
import type PlayerInstance from '@vimeo/player';
import type { Options as PlayerOptions } from 'vimeo__player';
import type { ObserveVisibilityConfig, Timer } from '@mop/types';
import type { UiImageSettingsType, CmsUiVideoVimeo, UiCmsVideoState } from '@mop/cms/types';
import imageSettings from '@mop/cms/imageSettings';
import SVGPlay from '@mop/ui/icons/video/play.svg?component';
import SVGStop from '@mop/ui/icons/video/stop.svg?component';
import SVGVolumeOn from '@mop/ui/icons/video/volume-on.svg?component';
import SVGVolumeOff from '@mop/ui/icons/video/volume-off.svg?component';
import SVGFullscreen from '@mop/ui/icons/video/fullscreen.svg?component';
import SVGFullscreenClose from '@mop/ui/icons/video/fullscreen-close.svg?component';

defineOptions({
  name: 'UiVideoVimeo',
});

const props = defineProps({
  data: {
    type: Object as PropType<CmsUiVideoVimeo>,
    required: true,
  },
  lazyLoad: {
    type: Boolean,
    default: false,
  },
  scaleFullWidth: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['loaded', 'started', 'visible']);

const element: CmsUiVideoVimeo = props.data;
const { $breakpoint, $resize } = useNuxtApp();
const VIMEO_BASE_URL = 'https://player.vimeo.com/video/';
const DEFAULT_VOLUME = 50;
const MOUSE_OVER_DELAY = 1000;
const VOLUME_STYLE_HEIGHT = 70;
const VIDEO_HEIGHT = 300;
const scaleFullWidth = props.scaleFullWidth;
// @ts-ignore
const typeConfig: UiImageSettingsType =
  // @ts-ignore
  imageSettings.types[element.imageType || 'default'] || imageSettings.types.default;
const hasBeenClickedRef = ref(false);
let hasStartedEventBeenEmitted = false;
const videoWrapperRef = ref<HTMLElement>();
const volume = ref<HTMLElement>();
const playerRef = ref<HTMLElement>();
const vimeoPlayerRef = ref<HTMLElement>();
const isVisibleRef = ref(false);
const isPlayerLoadedRef = ref(false);
const isComponentUnmounted = ref(false);
const videoStateRef = ref<UiCmsVideoState>({
  isInitialized: false,
  isLoading: true,
  isFullScreen: false,
  isPlaying: false,
  showControlBar: false,
  showVolume: false,
  showFallbackImage: true,
  embeddedPadding: 0,
  duration: 0,
  timelineRangeValue: 0,
  volumeRangeValue: 0,
  volumeProgress: 0,
  settings: $breakpoint.isMobileRef.value ? element.mobile : element.desktop,
  aspectRatioName:
    $breakpoint.isMobileRef.value && typeConfig.mobile?.aspectRatio
      ? typeConfig.mobile.aspectRatio
      : typeConfig.desktop.aspectRatio,
  uiStyle: {},
  url: undefined,
} as UiCmsVideoState);

let videoWrapperElement: HTMLElement;
let vimeoPlayer: HTMLElement | undefined;
let playerElement: HTMLElement | undefined;
// @ts-ignore
let vimeoPlayerClass;
let hideControlsTimer: Timer;
let showFallbackImageTimer: Timer;
let volumeTimer: Timer;
let previousVolumeValue = videoStateRef.value.volumeRangeValue;
let player: PlayerInstance;
let vimeoOptions = getVimeoOptions();
const showPlayer = ref(Boolean(videoStateRef.value.settings.vimeoId || element.desktop.vimeoId));
const isPlayingWhenVisibleRef = ref(Boolean(vimeoOptions.autoplay));

const throttleHandler = throttle(() => {
  calculateDimensions();
}, 100);

onMounted(() => {
  watch(
    [$resize.viewportWidthRef, $resize.viewportHeightRef],
    () => {
      throttleHandler();
    },
    { immediate: true },
  );

  watch(
    $breakpoint.isMobileRef,
    () => {
      videoStateRef.value.settings = element.desktop;
      if (element.desktop.aspectRatioWidth && element.desktop.aspectRatioHeight) {
        videoStateRef.value.uiStyle = {
          paddingTop: `${(element.desktop.aspectRatioHeight / element.desktop.aspectRatioWidth) * 100}%`,
        };
      }

      if ($breakpoint.isMobileRef.value && (element.mobile?.vimeoId || element.mobile?.coverImage)) {
        videoStateRef.value.settings = element.mobile;

        if (element.mobile.aspectRatioWidth && element.mobile.aspectRatioHeight) {
          videoStateRef.value.uiStyle = {
            paddingTop: `${(element.mobile.aspectRatioHeight / element.mobile.aspectRatioWidth) * 100}%`,
          };
        }
      }

      showPlayer.value = Boolean(videoStateRef.value.settings.vimeoId);
      vimeoOptions = getVimeoOptions();
      createPlayer();
    },
    { immediate: true },
  );
  window.addEventListener('blur', handleWindowBlur);
  window.addEventListener('focus', handleWindowFocus);
});

function play(): void {
  // To prevent error due to race condtion with "pause()" (https://stackoverflow.com/a/36898221/12682316).
  if (isComponentUnmounted.value || !player || videoStateRef.value.isPlaying) {
    return;
  }
  player.play();
}

function pause(): void {
  // To prevent error due to race condtion with "play()" (https://stackoverflow.com/a/36898221/12682316).
  if (isComponentUnmounted.value || !player || !videoStateRef.value.isPlaying) {
    return;
  }
  player.pause();
}

function handleWindowBlur() {
  pause();
}

function handleWindowFocus() {
  if (!isVisibleRef.value || !isPlayingWhenVisibleRef.value) {
    return;
  }
  play();
  handleHideControls();
}

onUnmounted(() => {
  window.removeEventListener('blur', handleWindowBlur);
  window.removeEventListener('focus', handleWindowFocus);
  isComponentUnmounted.value = true;
  player && player.destroy();
});

const getPlayerClass = new Promise((resolve) => {
  // @ts-ignore
  if (!vimeoPlayerClass) {
    import(/* webpackChunkName: "vimeoPlayer" */ '@vimeo/player').then((module) => {
      vimeoPlayerClass = module.default;
      resolve(vimeoPlayerClass);
    });
  } else {
    resolve(vimeoPlayerClass);
  }
});

const visibilityChanged: ObserveVisibilityConfig = {
  callback: (isVisible: boolean) => {
    if (isVisible !== isVisibleRef.value && player) {
      if (isVisible && vimeoOptions.autoplay && isPlayingWhenVisibleRef.value) {
        play();
      } else {
        pause();
      }
    }
    if (isVisible) {
      emit('visible');
    }
    isVisibleRef.value = isVisible;
  },
  rootMargin: '-1px -1px -1px -1px',
};

function createPlayer() {
  nextTick(async () => {
    if (player) {
      await player.destroy();
      // @ts-ignore
      player = null;
    }
    if (!showPlayer.value) {
      return;
    }

    videoWrapperElement = videoWrapperRef.value || ({} as HTMLElement);
    playerElement = playerRef.value;
    vimeoPlayer = vimeoPlayerRef.value;

    createVimeoUrl();

    const PlayerClass = await getPlayerClass;
    // @ts-ignore
    player = new PlayerClass(playerElement);
    await player.ready();
    isPlayerLoadedRef.value = true;
    initEvents();
    initVideo();
    // force video to pause if autoplay is false (regarding fullscreen background layer Vimeo bug)
    player.setAutopause(true).then(function forceVideoPauseState() {
      // IE Edge regarding video embeding time, sometimes rejects the setAutopause promise and starts over.
      setTimeout(function videoEmbedingTime() {
        if (isVisibleRef.value && vimeoOptions.autoplay) {
          isPlayingWhenVisibleRef.value = true;
          play();
        } else {
          pause();
        }
      }, 1000);
    });
  });
}

function createVimeoUrl() {
  let url = VIMEO_BASE_URL + videoStateRef.value.settings.vimeoId;
  Object.keys(vimeoOptions).forEach((option, index) => {
    const separator = index > 0 ? '&' : '?';
    url += `${separator}${option}=${vimeoOptions[option as keyof PlayerOptions]}`;
  });
  videoStateRef.value.url = url;
}

function initEvents() {
  player.on('loaded', () => {
    videoStateRef.value.isInitialized = true;
    emit('loaded');
  });
  player.on('timeupdate', handleTimeUpdate);
  player.on('durationchange', handleDurationChange);
  player.on('pause', handlePause);
  player.on('play', handlePlay);
  player.on('playing', handlePlay);
  player.on('bufferstart', () => {
    videoStateRef.value.isLoading = true;
  });
  player.on('bufferend', () => {
    videoStateRef.value.isLoading = false;
  });
}

function getVimeoOptions(): PlayerOptions {
  return {
    autoplay: videoStateRef.value.settings.config?.autoPlay,
    loop: videoStateRef.value.settings.config?.loop,
    muted: videoStateRef.value.settings.config?.mute,
    controls: false,
    dnt: true, // dnt = do not track
  };
}

function getRatio(width: number, height: number): number {
  return (height * 100) / width;
}

function handlePause() {
  videoStateRef.value.isPlaying = false;
  handleShowControls();
}

function handlePlay() {
  videoStateRef.value.isLoading = false;
  videoStateRef.value.isPlaying = true;
  handleHideControls();
  if (!hasStartedEventBeenEmitted) {
    emit('started');
    hasStartedEventBeenEmitted = true;
  }
  // partially resolves mobile issue where video is flickering shortly after it starts by
  // keeping the image above video
  clearTimeout(showFallbackImageTimer);
  showFallbackImageTimer = setTimeout(
    () => {
      videoStateRef.value.showFallbackImage = false;
    },
    $breakpoint.isMobileRef.value ? 500 : 0,
  );
}

function initVideo() {
  calculateDimensions();
  // reset styles previously set before loading from fallback image
  if (videoStateRef.value.settings.coverImage) {
    videoStateRef.value.uiStyle = {};
  }
  videoStateRef.value.showFallbackImage = true;
  videoStateRef.value.isLoading = false;
  videoStateRef.value.isPlaying = false;
  player.getMuted().then((muted: boolean) => {
    videoStateRef.value.volumeRangeValue = muted ? 0 : DEFAULT_VOLUME;
    handleDurationChange();
    handleVolume();
    setFallbackImageState();
  });
}

function setFallbackImageState(): boolean {
  const settings = videoStateRef.value.settings;
  let showFallbackImage = false;
  if (settings.coverImage && settings.coverImage.url) {
    showFallbackImage = true;
  }
  return showFallbackImage;
}

function scaleToFull(width: number, height: number) {
  // If no aspect ratio is provided, calculate video stylings based on parent element
  if (videoStateRef.value.aspectRatioName || videoStateRef.value.uiStyle.paddingTop) {
    return;
  }

  const wrapperElement = vimeoPlayer?.parentElement?.parentElement;

  if (!wrapperElement) {
    return;
  }

  const { width: containerWidth, height: containerHeight } = wrapperElement.getBoundingClientRect();
  const widthPortion = containerWidth / window.innerWidth;
  let scaleWidth = (((containerHeight / containerWidth) * width) / height) * 100;
  if (scaleFullWidth) {
    scaleWidth = scaleWidth > 100 ? 100 : scaleWidth;
    videoWrapperElement.style.paddingTop = `${(height / width) * 100}%`;
  } else {
    scaleWidth = scaleWidth < 100 ? 100 : scaleWidth;
    const left = ((100 - scaleWidth) / 2) * widthPortion;
    const translateY = (VIDEO_HEIGHT - (videoStateRef.value.embeddedPadding * scaleWidth) / 100) / (VIDEO_HEIGHT / 50);
    videoWrapperElement.style.transform = `translateY(${-translateY}%)`;
    videoWrapperElement.style.left = `${left}vw`;
    videoWrapperElement.style.paddingTop = `${VIDEO_HEIGHT}%`;
    videoWrapperElement.style.width = `${scaleWidth * widthPortion}vw`;
  }
}

function calculateDimensions() {
  if (!player) {
    return;
  }
  Promise.allSettled([player.getVideoWidth(), player.getVideoHeight()]).then((dimensions) => {
    if (dimensions[0].status === 'fulfilled' && dimensions[1].status === 'fulfilled') {
      const width = dimensions[0].value;
      const height = dimensions[1].value;
      videoStateRef.value.embeddedPadding = getRatio(width, height);
      scaleToFull(width, height);
    }
  });
}

function handleToggleVolumeMute() {
  if (videoStateRef.value.volumeRangeValue > 0) {
    previousVolumeValue = videoStateRef.value.volumeRangeValue;
    videoStateRef.value.volumeRangeValue = 0;
  } else {
    videoStateRef.value.volumeRangeValue = previousVolumeValue > 0 ? previousVolumeValue : DEFAULT_VOLUME;
  }

  handleVolume();
  // forces repaint for safari, only interested in mute/unmute action
  const sliderElement: HTMLElement | undefined = volume.value;
  if (sliderElement) {
    sliderElement.style.display = 'none';
    // eslint-disable-next-line
        sliderElement?.offsetHeight;
    sliderElement.style.display = 'block';
  }
}

function handleDurationChange() {
  player.getDuration().then((duration: number) => {
    videoStateRef.value.duration = duration;
  });
}

function handleProgress() {
  player.setCurrentTime(parseInt(String(videoStateRef.value.timelineRangeValue))).then(() => {
    handleHideControls();
  });
}

function handleShowVolume() {
  clearTimeout(volumeTimer);
  videoStateRef.value.showVolume = true;
}

function handleHideVolume() {
  clearTimeout(volumeTimer);
  volumeTimer = setTimeout(() => {
    videoStateRef.value.showVolume = false;
  }, MOUSE_OVER_DELAY);
}

function handleVolume() {
  player.getMuted().then((muted: boolean) => {
    if (muted && videoStateRef.value.volumeRangeValue === 0) {
      videoStateRef.value.volumeProgress = 0;
      videoStateRef.value.volumeRangeValue = 0;
      player.setVolume(0);
    } else {
      videoStateRef.value.volumeProgress = (VOLUME_STYLE_HEIGHT * videoStateRef.value.volumeRangeValue) / 100;
      player.setVolume(videoStateRef.value.volumeRangeValue / 100);
    }
  });
}

function handleTimeUpdate() {
  player.getCurrentTime().then((seconds: number) => {
    videoStateRef.value.timelineRangeValue = seconds;
  });
}

function handleFullScreenToggle() {
  videoStateRef.value.isFullScreen ? closeFullScreen() : openFullScreen();
}

function openFullScreen() {
  if (vimeoPlayer?.requestFullscreen) {
    vimeoPlayer.requestFullscreen();
    // @ts-ignore mozRequestFullScreen does not exist on HTMLElement
  } else if (vimeoPlayer.mozRequestFullScreen) {
    /* Firefox */
    // @ts-ignore mozRequestFullScreen does not exist on HTMLElement
    vimeoPlayer.mozRequestFullScreen();
    // @ts-ignore webkitRequestFullscreen does not exist on HTMLElement
  } else if (vimeoPlayer.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    // @ts-ignore webkitRequestFullscreen does not exist on HTMLElement
    vimeoPlayer.webkitRequestFullscreen();
    // @ts-ignore webkitEnterFullscreen does not exist on HTMLElement
  } else if (playerElement.webkitEnterFullscreen) {
    // @ts-ignore webkitEnterFullscreen does not exist on HTMLElement
    playerElement.webkitEnterFullscreen(); /* IOS safari fallback, works for ipad too but then it's actual fullscreen without styled controls */
  }
}

function closeFullScreen() {
  try {
    if (document.exitFullscreen) {
      document.exitFullscreen();
      // @ts-ignore mozCancelFullScreen does not exist on document
    } else if (document.mozCancelFullScreen) {
      // @ts-ignore mozCancelFullScreen does not exist on document
      document.mozExitFullscreen();
      // @ts-ignore webkitExitFullscreen does not exist on document
    } else if (document.webkitExitFullscreen) {
      // @ts-ignore webkitExitFullscreen does not exist on document
      document.webkitExitFullscreen();
    }
  } catch (error) {
    // do nothing, closed with esc
  }
}

function handleTogglePlay(event: Event) {
  event && event.preventDefault();
  event && event.stopPropagation();
  hasBeenClickedRef.value = true;
  player.getPaused().then((paused: boolean) => {
    if (paused) {
      isPlayingWhenVisibleRef.value = true;
      play();
    } else {
      isPlayingWhenVisibleRef.value = false;
      pause();
    }
  });
}

function handleShowControls() {
  videoStateRef.value.showControlBar = true;
  handleHideControls();
}

function handleHideControls() {
  clearTimeout(hideControlsTimer);
  if (!videoStateRef.value.isPlaying) {
    return;
  }
  hideControlsTimer = setTimeout(() => {
    videoStateRef.value.showControlBar = false;
  }, MOUSE_OVER_DELAY);
}

function fallbackImageLoaded(event: Event) {
  // if no aspect ratio defined - use aspect ratio from fallback image first
  if (!videoStateRef.value.aspectRatioName) {
    videoStateRef.value.uiStyle = {
      paddingTop: `${
        ((event.target as HTMLImageElement).naturalHeight / (event.target as HTMLImageElement).naturalWidth) * 100
      }%`,
    };
  }
}

const isMobileRef = $breakpoint.isMobileRef;
</script>

<template>
  <div class="vimeo-player__container">
    <ClientOnly>
      <div
        v-if="showPlayer"
        ref="vimeoPlayerRef"
        :key="`player-${isMobileRef}`"
        v-observe-visibility="visibilityChanged"
        :class="[
          'vimeo-player',
          {
            'video-player--full-screen': videoStateRef.isFullScreen,
            'video-player--playing': videoStateRef.isPlaying,
            'video-player--muted': videoStateRef.volumeRangeValue === 0,
            'video-player--hidden-control-bar': !videoStateRef.showControlBar,
            'video-player--portrait': videoStateRef.embeddedPadding > 100,
          },
        ]"
        @togglePlay="handleTogglePlay"
        @toggleMute="handleToggleVolumeMute"
      >
        <div
          class="video-player__overlay"
          @click="
            videoStateRef.settings?.config?.allowInteraction ||
            videoStateRef.settings?.config?.showControls ||
            !videoStateRef.settings?.config?.autoPlay
              ? handleTogglePlay($event)
              : null
          "
          @mousemove="handleShowControls"
          @mouseleave="handleHideControls"
        >
          <div class="video-player__icon-wrapper">
            <i v-if="videoStateRef.isLoading" class="video-player__loading-icon icon icon--loading-asset" />
            <SVGPlay
              v-else-if="!videoStateRef.isPlaying"
              class="video-player__icon video-player__icon--big video-player__icon--animated"
            />
          </div>
          <div
            v-if="videoStateRef.settings?.config?.showControls"
            class="video-player__control-bar"
            @click.stop.prevent
          >
            <SVGStop
              v-if="videoStateRef.isPlaying"
              class="video-player__icon video-player__icon--play-pause"
              @click="handleTogglePlay"
            />
            <SVGPlay v-else class="video-player__icon video-player__icon--play-pause" @click="handleTogglePlay" />
            <div class="video-player__timeline-slider-wrapper">
              <input
                v-model="videoStateRef.timelineRangeValue"
                class="video-player__timeline-slider"
                type="range"
                min="1"
                step="1"
                :max="videoStateRef.duration"
                @input="handleProgress"
              />
            </div>
            <div
              :class="[
                'video-player__volume-slider-wrapper',
                {
                  'video-player__volume-slider-wrapper--slider-shown': videoStateRef.showVolume,
                },
              ]"
              @mouseenter="handleShowVolume"
              @mouseleave="handleHideVolume"
            >
              <div
                class="video-player__volume-background"
                :style="`border-left-width: ${videoStateRef.volumeProgress}px; width: ${VOLUME_STYLE_HEIGHT}px;`"
              />
              <input
                ref="volume"
                v-model="videoStateRef.volumeRangeValue"
                class="video-player__volume-slider"
                type="range"
                min="0"
                step="1"
                max="100"
                :style="`width: ${VOLUME_STYLE_HEIGHT}px;`"
                @input="handleVolume"
              />
              <SVGVolumeOn
                v-if="videoStateRef.volumeRangeValue"
                class="video-player__icon video-player__icon--volume"
                @click="handleToggleVolumeMute"
              />
              <SVGVolumeOff
                v-else
                class="video-player__icon video-player__icon--volume"
                @click="handleToggleVolumeMute"
              />
            </div>
            <SVGFullscreen
              v-if="!videoStateRef.isFullScreen"
              class="video-player__icon video-player__icon--fullscreen"
              @click="handleFullScreenToggle"
            />
            <SVGFullscreenClose
              v-else
              class="video-player__icon video-player__icon--fullscreen"
              @click="handleFullScreenToggle"
            />
          </div>
          <UiImage
            v-if="videoStateRef.showFallbackImage && videoStateRef.settings.coverImage"
            :key="videoStateRef.settings.coverImage.id"
            class="video-player__fallback-image"
            :image="videoStateRef.settings.coverImage"
            :type="element.imageType"
            :config="element.imageConfig"
            :lazy="lazyLoad"
            @loaded="fallbackImageLoaded"
          />
        </div>
        <div
          ref="videoWrapperRef"
          :class="[
            'video-player__wrapper',
            videoStateRef.aspectRatioName
              ? `img--${videoStateRef.aspectRatioName}`
              : 'video-player__wrapper--no-aspect-ratio',
          ]"
          :style="videoStateRef.uiStyle"
        >
          <iframe
            v-show="videoStateRef.isInitialized && isPlayerLoadedRef"
            ref="playerRef"
            :class="[
              'video-player__video-element',
              {
                'video-player__video-element--has-been-clicked': hasBeenClickedRef,
              },
            ]"
            :src="videoStateRef.url"
            frameborder="0"
            webkitAllowFullScreen
            mozallowfullscreen
            allowfullscreen
            allow="autoplay; encrypted-media; fullscreen; picture-in-picture"
          />
        </div>
      </div>
      <div v-else v-storyblok-editable="element" class="vimeo-no-player">
        <UiImage
          v-if="videoStateRef.settings.coverImage"
          :key="videoStateRef.settings.coverImage.id"
          class="video-player__fallback-image"
          :image="videoStateRef.settings.coverImage"
          :type="element.imageType"
          :config="element.imageConfig"
          :lazy="lazyLoad"
        />
      </div>
    </ClientOnly>
  </div>
</template>

<style lang="scss" scoped>
$big-icon-scale: 1.7;

@mixin range-slider-thumb {
  width: 2px;
  height: 13px;
  outline: 0;
  border: none;
  background-color: $white;
  cursor: pointer;
  pointer-events: all;
}

.vimeo-player__container {
  height: 100%;
}

.vimeo-player {
  position: relative;
  overflow: hidden;

  .video-player__fallback-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

.video-player__wrapper {
  outline: none;
  position: relative;
  background-color: $white;
}

.video-player__wrapper--no-aspect-ratio {
  padding-top: calc((9 / 16 * 100%));
}

.video-player__video-element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  pointer-events: none;
  user-select: none;

  &::-webkit-media-controls {
    /* stylelint-disable-next-line */
    display: none !important;
  }
}

.video-player__control-bar {
  width: 100%;
  position: absolute;
  display: flex;
  padding: 0 columns(1) $space15;
  align-items: center;
  bottom: 0;

  @include apply-upto(medium) {
    display: none;
  }
}

.video-player__overlay {
  @include z(global, content);

  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.video-player__icon {
  cursor: pointer;
}

.video-player__icon--fullscreen {
  margin-left: $space25;
}

.video-player__icon-wrapper {
  @include z(global, content);

  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 70px;
  top: calc(50% - 35px);
}

.video-player__timeline-slider-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin: 0 $space25;
}

.video-player__timeline-slider {
  position: relative;
  width: 100%;
  height: 4px;
  border-radius: 0;
  cursor: pointer;
  overflow: hidden;
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;

  @include apply-only-safari {
    -webkit-appearance: slider-horizontal;
  }

  &::-webkit-slider-runnable-track {
    background-color: $dark-grey;
  }

  &::-webkit-slider-thumb {
    width: 0;
    height: 4px;
    box-shadow: -100vw 0 0 100vw $white;
    pointer-events: none;
    -webkit-appearance: none;
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &:focus {
    outline: none;
  }

  &::-moz-range-track {
    background-color: $dark-grey;
    height: 4px;
    outline: none;
  }

  &::-moz-range-thumb {
    width: 0;
    height: 4px;
    border: 0;
    border: none;
    background: transparent;
    box-shadow: -100vw 0 0 100vw $white;
    cursor: pointer;
  }
}

.video-player__volume-slider-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
}

.video-player__volume-background,
.video-player__volume-slider {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  bottom: $space80;
  transform: rotate(270deg);
  transition: opacity 0.3s ease-in-out;
}

.video-player__volume-background {
  height: 2px;
  bottom: 86px;
  background-color: $dark-grey;
  border-left-color: $white;
  border-left-style: solid;
}

.video-player__volume-slider {
  height: 14px;
  cursor: pointer;
  overflow: hidden;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;

  &::-webkit-slider-runnable-track,
  &::-moz-range-track {
    background-color: $dark-grey;
  }

  &::-webkit-slider-thumb {
    @include range-slider-thumb;

    -webkit-appearance: none;
  }

  &::-webkit-slider-runnable-track {
    width: 14px;
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &:focus {
    outline: none;
  }

  &::-moz-range-thumb {
    @include range-slider-thumb;

    -moz-appearance: none;
  }
}

.video-player__volume-slider-wrapper--slider-shown {
  .video-player__volume-background,
  .video-player__volume-slider {
    opacity: 1;
    visibility: visible;

    @include apply-only-touch-device {
      display: none;
      opacity: 0;
      visibility: hidden;
    }
  }
}

.video-player__loading-icon,
.video-player__icon--big {
  transform: scale($big-icon-scale);
}

.video-player__icon--animated {
  animation: blurControls 0.5s ease-out;
}

.video-player--full-screen {
  .video-player__wrapper {
    background: $black;
    /* stylelint-disable declaration-no-important */
    padding-top: 0 !important;
    width: 100% !important;
    height: 100% !important;
    transform: none !important;
    left: 0 !important;
    /* stylelint-enable declaration-no-important */
  }
}

.video-player--playing {
  .video-player__control-bar {
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
  }
}

.video-player--hidden-control-bar {
  .video-player__control-bar {
    opacity: 0;
  }
}

@keyframes blurControls {
  0% {
    transform: scale($big-icon-scale);
    opacity: 1;
    filter: blur(0);
  }

  50% {
    transform: scale(2.7);
  }

  100% {
    transform: scale($big-icon-scale);
    opacity: 0.7;
    filter: blur(2px);
  }
}
</style>
