import { logError } from '@mop/shared/utils/logger';
import axios from 'axios';
import type { ApiError } from '@/types/error';

export function handleAxiosError(error: any, debugData: any, disableLog = false): ApiError {
  // prevents logging request cancelations
  if (!disableLog && !axios.isCancel(error)) {
    const originalError: any = error?.toJSON ? error.toJSON() : debugData || error;
    let apiError: any = originalError;
    // transform timedout requests into grouped mode
    if ((originalError?.message as string)?.includes('timeout')) {
      apiError = {
        message: `${debugData?.api}: ${debugData?.method} [${originalError?.config?.timeout}ms]`,
        name: 'Timeout',
      };
    }

    logError(apiError);
  }
  return {
    code: error.response?.status ?? 0,
    message: error.message,
  };
}

export function errorHandler<T>(callbackFn: Function): Promise<T> {
  return new Promise((resolve, reject) => {
    try {
      return resolve(callbackFn());
    } catch (error) {
      logError(error);
      reject(error);
    }
  });
}
