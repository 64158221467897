import { isLocalhostOrIsDebugCookieEnabled } from '@mop/shared/utils/logger';

/**
 * Similar functionality like it was for ssrRef with nuxt composition api.
 * Should be only setting data for server side, and client should not be allowed to mutate the data
 */
export function useMopSSR<T>(key: string, initialValue: any) {
  const { ssrContext, payload, hooks } = useNuxtApp();
  const storage = initStorage<any>('useMopSSR');
  // delete path to avoid redirects on client side: https://github.com/nuxt/nuxt/pull/21408
  delete payload.path;
  key = `$${key}`;
  if (ssrContext) {
    const val = storage.get(key) ?? storage.saveAndGet(key, ref<T>(initialValue));
    hooks.hook('app:rendered', () => {
      // val.value this value is indirectly set in the context where it's being used
      payload[key] = val.value;
    });
    return val;
  }
  const val = ref(window.__NUXT__?.[key] ?? null);
  if (!isLocalhostOrIsDebugCookieEnabled) {
    delete window.__NUXT__?.[key];
    delete payload[key];
  }
  return val;
}
