import { securedWrap } from '@mop/shared/utils/securedWrap';
import type { ProductModel } from '@/types/product';

export default function useMopCartOverlayClient() {
  const overlay = useMopOverlay();
  const { resetCustomCartData } = useMopCartClient();
  const {
    $breakpoint,
    $breakpoint: { isMobileRef },
  } = useNuxtApp();
  const componentName = 'MopCartOverlay';

  function openCartOverlay() {
    const closeOnMouseLeave: boolean =
      !$breakpoint.isTinyRef.value && !$breakpoint.isSmallRef.value && !$breakpoint.isMediumRef.value;

    return overlay.open({
      type: 'right',
      componentName,
      overrideParams: {
        lockScroll: isMobileRef.value || '.cart-overlay-items',
        forceRender: true,
        closeOnMouseLeave,
        mouseLeaveTimeout: 2500,
      },
      onClose: resetCustomCartData,
    });
  }

  function openGiftCardPromptOverlay(
    caseId: 'pdp_giftcard' | 'click_collect' | 'cart' | 'pdp',
    product?: ProductModel,
  ) {
    return overlay.open({
      type: 'centered',
      componentName: 'MopGiftCardPromptOverlay',
      props: {
        caseId,
        product,
      },
      overrideParams: {
        lockScroll: true,
        forceRender: true,
      },
    });
  }

  return securedWrap({
    componentName,
    openCartOverlay,
    openGiftCardPromptOverlay,
  });
}
