import type { AxiosError, AxiosInstance } from 'axios';
import { handleAxiosError, errorHandler } from '@/api/utils';
import type { GooglePassResponseData } from '@/types/wallet';
const API_NAME = 'AWS - wallet';

export function getGooglePass(
  apiInstance: AxiosInstance,
  customerReferenceKey: string,
  customerPublicKey: string,
): Promise<GooglePassResponseData> {
  return errorHandler<GooglePassResponseData>(
    async () =>
      await apiInstance.head('/google-pass', {
        params: {
          customerReferenceKey,
          customerPublicKey,
        },
      }),
  ).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getGooglePass',
      customerReferenceKey,
      customerPublicKey,
    }),
  }));
}

export function getApplePassBlob(
  apiInstance: AxiosInstance,
  customerReferenceKey: string,
  customerPublicKey: string,
): Promise<Blob | void> {
  return errorHandler<Blob>(
    async () =>
      (
        await apiInstance.get('/apple-pass', {
          responseType: 'blob',
          params: {
            customerReferenceKey,
            customerPublicKey,
          },
        })
      ).data,
  ).catch((error: AxiosError) => {
    handleAxiosError(error, {
      api: API_NAME,
      method: 'getApplePassBlob',
      customerReferenceKey,
      customerPublicKey,
    });
  });
}
