import { securedWrap } from '@mop/shared/utils/securedWrap';
import { searchSuggestionModel } from '@/models';
import type { SearchSuggestionParams } from '@/types/searchSuggestions';

export default function useMopSearchSuggestionClient() {
  const searchSuggestionModelRef = ref(searchSuggestionModel(null));
  const loading = ref(false);

  async function searchSuggestions(term: string, params?: SearchSuggestionParams) {
    loading.value = true;
    const searchSuggestionsResponse = await useNuxtApp().$apiBackbone.getSearchSuggestions(term, params);
    searchSuggestionModelRef.value = searchSuggestionModel(searchSuggestionsResponse);
    loading.value = false;
  }

  return securedWrap({
    searchSuggestionModelRef,
    searchSuggestions,
    loading,
  });
}
