import { getDeviceVisibility } from '@mop/cms/utils/utils';
import type {
  CmsContentElementResponseData,
  CmsContentElementListResponseData,
  CmsContentElementModel,
  CmsVisibility,
} from '@/types/cms';

export function cmsContentElementModel(responseData: CmsContentElementResponseData | null) {
  const response: CmsContentElementResponseData = responseData ?? {};

  return {
    hasError(): boolean {
      return response.error !== undefined;
    },

    getType(): string {
      // TODO: Remove after benefits module cleanup
      if (response.data?.component === 'CompanyBenefit') {
        return 'Benefit';
      }
      return response.data?.component ?? '';
    },

    getVueComponentName() {
      const type = this.getType();
      const isMopCmsComponent = [
        'ProductRow',
        'ProductCarousel',
        'ProductGrid',
        'HeroCard',
        'PromotionItem',
        'TextPromotionItem',
        'ShopTheLook',
        'AccordionSearchable',
        'AccountOverviewCards',
        'TabNavigation',
      ].includes(type);

      return isMopCmsComponent ? `MopCms${type}` : `UiCms${type}`;
    },

    getEditableData(): string {
      return response.data?._editable ?? '';
    },

    getCustomId(): string {
      return response.data?.customId ?? '';
    },

    getUid(object?: any): string {
      const data: any = object || response.data;
      return data?._uid ?? '';
    },

    getData(): any {
      return response.data;
    },

    getVisibility(): CmsVisibility | undefined {
      return response.data?.visibility;
    },

    getDeviceVisibility() {
      return getDeviceVisibility(response.data);
    },

    getContentElements(attribute = 'body'): any[] {
      if (response.data === undefined || this.getData()[attribute] === undefined) {
        return cmsContentElementListModel(null).getCmsContentElementModelList();
      }
      return cmsContentElementListModel({
        data: this.getData()[attribute],
      }).getCmsContentElementModelList();
    },
  };
}

export function cmsContentElementListModel(responseData: CmsContentElementListResponseData | null) {
  const response: CmsContentElementListResponseData = responseData ?? {};

  return {
    hasError(): boolean {
      return response.error !== undefined;
    },

    getCmsContentElementModelList(): CmsContentElementModel[] {
      if (response.data === undefined) {
        return [];
      }
      return response.data.map((cmsContentElement) => cmsContentElementModel({ data: cmsContentElement }));
    },
  };
}
