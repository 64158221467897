import { securedWrap } from '@mop/shared/utils/securedWrap';
import type {
  GtmProductTrackingParams,
  GtmPageViewParams,
  GtmPurchaseEventParams,
  GtmCheckoutEventParams,
  GtmAyCheckoutEventParams,
  EventParams,
} from '@/types/gtm';
import type { CartLineItemModel } from '@/types/cart';

export default function useMopGtm() {
  const { $gtm, $mopI18n } = useNuxtApp();
  const ignoreBundling = true;

  function trackProduct(productTrackingParams: GtmProductTrackingParams) {
    if (!$gtm?.isEnabled()) {
      return;
    }
    $gtm.trackEvent(GtmUtils.getProductDetailEvent(productTrackingParams), true);
  }

  function trackProductImpression(productTrackingParams: GtmProductTrackingParams) {
    $gtm.trackEvent(GtmUtils.getProductImpressionEvent(productTrackingParams));
  }

  function trackView(path: string) {
    $gtm.trackView(path);
  }

  function trackPageView(pageViewParams: GtmPageViewParams) {
    $gtm.trackPageView(GtmUtils.getPageViewEvent(pageViewParams));
  }

  function trackProductClick(productTrackingParams: GtmProductTrackingParams) {
    $gtm.trackEvent(GtmUtils.getProductClickEngagement(productTrackingParams));
  }

  function trackAddToCartClick(productTrackingParams: GtmProductTrackingParams) {
    $gtm.clearCartContent();
    $gtm.trackEvent(GtmUtils.getAddToCartEngagement(productTrackingParams), ignoreBundling);
  }

  function trackRemoveFromCartClick(productTrackingParams: GtmProductTrackingParams) {
    $gtm.clearCartContent();
    $gtm.trackEvent(GtmUtils.getRemoveFromCartEngagement(productTrackingParams), ignoreBundling);
  }

  function trackAyCheckout(ayCheckoutTrackingParams: GtmAyCheckoutEventParams) {
    $gtm.trackEvent(GtmUtils.getAyCheckoutEngagement(ayCheckoutTrackingParams, $mopI18n.currency), ignoreBundling);
  }

  function trackClick(clickTrackingParams: EventParams) {
    $gtm.trackEvent(clickTrackingParams, ignoreBundling);
  }

  function trackCartContent(currentCartLineItems: CartLineItemModel[]) {
    $gtm.updateCartContent(currentCartLineItems);
  }

  function trackPurchase(purchaseParams: GtmPurchaseEventParams) {
    $gtm.trackEvent(GtmUtils.getPurchaseEvent(purchaseParams), ignoreBundling);
  }

  function trackCheckout(checkoutParams: GtmCheckoutEventParams) {
    $gtm.trackCheckout(GtmUtils.getCheckoutEvent(checkoutParams));
  }

  return securedWrap({
    trackProduct,
    trackPurchase,
    trackCheckout,
    trackProductImpression,
    trackAddToCartClick,
    trackCartContent,
    trackRemoveFromCartClick,
    trackProductClick,
    trackView,
    trackPageView,
    trackClick,
    trackAyCheckout,
  });
}
