import { logInfo } from '@mop/shared/utils/logger';
export default defineNuxtPlugin((nuxtApp) => {
  // @ts-ignore
  const isDebugCookieEnabled = Boolean(nuxtApp.$cookie.get(constants.COOKIE.DEBUG));

  function log(value: string) {
    if (!isDebugCookieEnabled) {
      return;
    }
    logInfo(`DEBUG: ${value}`, true);
  }

  nuxtApp.provide('debug', { log });
});
