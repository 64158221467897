<script setup lang="ts">
import { getElementUiObject, getHtmlFromRichText } from '@mop/cms/utils/utils';
import type { EditableCmsComponent, StoryblokComponentData } from '@/types/cms';
import { cmsContentElementListModel } from '@/models/cms/cmsContentElementModel';

export type CmsUiSuperBannerOverlayFlexible = EditableCmsComponent & {
  elements: StoryblokComponentData[];
  bottomText?: string;
};

defineOptions({
  name: 'MopCmsSuperBannerOverlayFlexible',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const data = props.data;

const element: CmsUiSuperBannerOverlayFlexible = getElementUiObject(data, {
  elements: cmsContentElementListModel({
    data: data.elements,
  }).getCmsContentElementModelList(),
  bottomText: getHtmlFromRichText(data.bottomText),
});
</script>

<template>
  <div v-storyblok-editable="element" class="superbanner-overlay-flexible">
    <div class="superbanner-overlay__top">
      <MopCmsContentElementsSlot id="superbanner-overlay-flexible" :elements="element.elements as any" no-padding />
    </div>
    <div class="superbanner-overlay__bottom" v-html="element.bottomText" />
  </div>
</template>

<style scoped lang="scss">
.superbanner-overlay-flexible {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $space10;
  height: 100%;

  :deep(.ui-cms-headline) {
    padding-top: 0;
    padding-bottom: $space10;
  }

  &.element-count-1 {
    @include apply-from(medium) {
      width: columns(24);
    }
  }
}

.superbanner-overlay__top {
  @include apply-from(medium) {
    padding-bottom: $space25;
  }
}

.superbanner-overlay__bottom {
  @include text-style(small);

  display: flex;

  :deep(p) {
    @include text-style(small);

    margin: 0;
  }

  &::before {
    content: '*';
    margin-right: $space5;
  }
}
</style>
