<script setup lang="ts">
import { getElementUiObject, getHeadlineWithAllOptions, getHtmlFromRichText } from '@mop/cms/utils/utils';
import type { CmsUiSeoTeaser } from './uiCmsSeoTeaser';

defineOptions({
  name: 'UiCmsSeoTeaser',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const data = props.data;
const readMoreToggleRef = ref(false);
const seoTeaserRef = ref<HTMLElement>();

const element: CmsUiSeoTeaser = getElementUiObject(data, {
  headline: getHeadlineWithAllOptions(data),
  teaser: getHtmlFromRichText(data.teaser),
  items: data.items,
});

const hasOverflowRef = computed(
  () => (seoTeaserRef.value?.scrollHeight || 0) > (seoTeaserRef!.value?.clientHeight || 0),
);

const trackingId = element.headline.text || '';
</script>

<template>
  <div
    ref="seoTeaserRef"
    v-storyblok-editable="element"
    :class="[
      'ui-cms-seo-teaser',
      'ui-cms-padding',
      {
        'ui-cms-seo-teaser--uppercase-headlines': element.headline.isUppercase,
        'ui-cms-seo-teaser--is-toggle-active': readMoreToggleRef,
        'ui-cms-seo-teaser--has-no-overflow': !hasOverflowRef,
      },
    ]"
    :data-track-element-item="`${trackingId}`"
  >
    <UiHeading v-if="element.headline.text" class="ui-cms-seo-teaser__headline" :level="element.headline.level ?? 2">
      {{ element.headline.text }}
    </UiHeading>
    <div class="ui-cms-seo-teaser__teaser">
      <div class="ui-cms-seo-teaser__teaser-text" v-html="element.teaser" />
      <div class="ui-cms-seo-teaser__read-more-wrapper">
        <a class="ui-cms-seo-teaser__read-more" @click="readMoreToggleRef = !readMoreToggleRef">
          {{ readMoreToggleRef ? $mopI18n.t('common.global.readless') : $mopI18n.t('common.global.readmore') }}
        </a>
      </div>
    </div>
    <div
      v-for="(item, index) in element.items"
      v-show="!element.isFolding || readMoreToggleRef"
      :key="index"
      class="ui-cms-seo-teaser__item"
    >
      <h2 class="ui-cms-seo-teaser__item-headline">
        {{ item.headline }}
      </h2>
      <p class="ui-cms-seo-teaser__item-text">
        {{ item.text }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$wrapper-height: $space50;
.ui-cms-seo-teaser {
  padding: $space20 $global-padding;

  @include apply-upto(small) {
    margin-bottom: $space70;
    padding-top: $space15;
    padding-bottom: 0;
  }

  :deep() {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @include apply-upto(infinite) {
        margin-bottom: $space10;
      }
    }
  }
}

.ui-cms-seo-teaser__headline {
  @include text-style(headline-2);

  @include apply-upto(infinite) {
    margin-bottom: $space10;
  }

  @include apply-upto(tiny) {
    margin-bottom: $space20;
  }
}

.ui-cms-seo-teaser__teaser-text {
  @include text-style(running);

  padding-right: $space10;
}

.ui-cms-seo-teaser__read-more-wrapper {
  display: none;

  @include apply-upto(small) {
    display: block;
    margin: 0;
    padding-top: $space10;
  }
}

.ui-cms-seo-teaser__read-more {
  @include link-underlined($space5);
}

.ui-cms-seo-teaser__item {
  margin-top: $space20;

  @include apply-upto(s) {
    margin-top: $space25;
  }
}

.ui-cms-seo-teaser__item-headline {
  @include text-style(headline-3);

  margin-bottom: $space5;

  &:not(:first-child) {
    margin-top: $space20;
  }
}

.ui-cms-seo-teaser__item-text {
  @include text-style(running-small);
}

.ui-cms-seo-teaser--uppercase-headlines {
  .ui-cms-seo-teaser__headline,
  .ui-cms-seo-teaser__item-headline {
    text-transform: uppercase;
  }

  :deep() {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-transform: uppercase;
    }
  }
}

.ui-cms-seo-teaser:not(.ui-cms-seo-teaser--is-toggle-active) {
  @include apply-upto(small) {
    padding-bottom: 0;
    margin-bottom: $space40;
    line-break: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 250px;

    .ui-cms-seo-teaser__read-more-wrapper {
      display: block;
      padding-top: 0;
      position: absolute;
      left: $global-padding;
      right: $global-padding;
      height: $wrapper-height;
      border-bottom: $space10 solid $white;
      top: 100%;
      margin-top: calc(-#{$wrapper-height} + 1px);
      background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0), rgb(255, 255, 255));
    }

    .ui-cms-seo-teaser__read-more {
      position: absolute;
      bottom: -$space40;
    }
  }
}

.ui-cms-seo-teaser--has-no-overflow {
  @include apply-upto(small) {
    padding: $space20 $global-padding;
    margin-bottom: 0;
  }

  .ui-cms-seo-teaser__read-more-wrapper {
    display: none;
  }
}
</style>
