import { logError } from '@mop/shared/utils/logger';

export function securedWrap<T>(item: T): T {
  // @ts-ignore
  Object.keys(item).forEach((key: string) => {
    // @ts-ignore
    const callbackFn: (...args: any) => T = item[key];
    // skipping modules, and other computed values
    if (typeof callbackFn !== 'function') {
      return;
    }

    // @ts-ignore
    item[key] = function securedCallback(...args: any) {
      try {
        const value: any = callbackFn.apply(item, args);
        if (value instanceof Promise) {
          return Promise.resolve(value).catch(logError);
        }
        return value;
      } catch (error) {
        logError(error);
      }
    };
  });

  return item;
}
