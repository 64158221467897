<script setup lang="ts">
import type {
  CmsMedia,
  CmsUiVideoVimeo,
  CmsMediaImage,
  CmsMediaImageCarousel,
  UiImageSettingsTypeKey,
} from '@mop/cms/types';

defineOptions({
  name: 'UiCmsMedia',
});

const props = defineProps({
  data: {
    type: Object as PropType<CmsMedia>,
    required: true,
  },
  lazy: {
    type: Boolean,
    default: false,
  },
  cover: {
    type: Boolean,
    default: false,
  },
  scaleFullWidthVideo: {
    type: Boolean,
    default: false,
  },
  imageType: {
    type: String as PropType<UiImageSettingsTypeKey>,
    default: '',
  },
});

const emit = defineEmits(['loaded', 'started', 'visible']);

const isLoadedRef = ref(false);
const { type: mediaType } = props.data;
const lazyLoading: boolean = props.lazy;
let vimeoData: CmsUiVideoVimeo | undefined;
let imageData: CmsMediaImage | undefined;
let imageCarouselData: CmsMediaImageCarousel | undefined;
if (mediaType === 'vimeo') {
  vimeoData = props.data as CmsUiVideoVimeo;
  if (props.imageType) {
    // Default image type can be overwritten by prop
    vimeoData.imageType = props.imageType;
  }
} else if (mediaType === 'image' && (props.data as CmsMediaImage).image) {
  imageData = props.data as CmsMediaImage;
  if (props.imageType) {
    // Default image type can be overwritten by prop
    imageData.imageType = props.imageType;
  }
} else if (mediaType === 'imageCarousel' && (props.data as CmsMediaImageCarousel).images?.length) {
  imageCarouselData = props.data as CmsMediaImageCarousel;
  if (props.imageType) {
    // Default image type can be overwritten by prop
    imageCarouselData.imageType = props.imageType;
  }
}

function handleLoaded() {
  setTimeout(() => {
    isLoadedRef.value = true;
  }, 50);
  emit('loaded');
}
</script>

<template>
  <div
    v-storyblok-editable="data"
    :class="[
      'ui-cms-media-wrapper',
      {
        'ui-cms-media-wrapper--cover': cover,
      },
    ]"
  >
    <UiVideoVimeo
      v-if="mediaType === 'vimeo'"
      :data="vimeoData!"
      :lazy="lazyLoading"
      :scale-full-width="scaleFullWidthVideo"
      @loaded="emit('loaded')"
      @started="emit('started')"
      @visible="emit('visible')"
    />

    <UiImage
      v-if="imageData && mediaType === 'image' && imageData.image"
      :key="`${imageData.image.url}-${String(imageData.imageType)}`"
      :image="imageData.image"
      :image-mobile="imageData.imageMobile"
      :type="imageData.imageType"
      :config="imageData.imageConfig"
      :lazy="lazyLoading"
      :cover="cover"
      @loaded="handleLoaded"
    />
    <UiImageCarousel
      v-if="imageCarouselData && mediaType === 'imageCarousel' && imageCarouselData.images?.length"
      :key="`${imageCarouselData.images.length}-${String(imageCarouselData.imageType)}`"
      :images="imageCarouselData.images"
      :images-mobile="imageCarouselData.imagesMobile"
      :image-type="imageCarouselData.imageType"
      :speed="imageCarouselData.speed"
      :lazy="lazyLoading"
      :cover="cover"
      @loaded="handleLoaded"
    />
    <UiSlot v-if="isLoadedRef" v-bind="$props" slot-component-name="SlotUiCmsMediaBottom" />
  </div>
</template>

<style lang="scss">
.ui-cms-media-wrapper {
  position: relative;
}

.ui-cms-media-wrapper--cover {
  height: 100%;
}
</style>
