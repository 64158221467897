import type { TransitionProps } from 'vue';

export const TRANSITION_ENTER_CLASS = 'enter';
export const TRANSITION_LEAVE_CLASS = 'leave';
export const TRANSITION_LOADING_CLASS = 'loading';
export const TRANSITION_DEFAULT_CLASS = 'transition';
export const TRANSITION_BODY_LOADING_CLASS = 'transition-loading';

export function getPageTransition(page: string = TRANSITION_DEFAULT_CLASS): TransitionProps {
  return {
    css: false,
    onLeave: (el, done) => {
      el.classList.add(`${page}--${TRANSITION_LEAVE_CLASS}`);
      setTimeout(done, constants.TRANSITION_TIME.LEAVE);
    },
  };
}
