<script setup lang="ts">
import SVGSearch from '@mop/shared/images/misc/search.svg?component';

defineOptions({
  name: 'MopSearchForm',
});

defineProps({
  isSmall: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['update:modelValue', 'submit']);

const { $breakpoint, $mopI18n } = useNuxtApp();
const searchInputPlaceholderTextRef = computed(() => {
  return $breakpoint.isMobileRef.value
    ? $mopI18n.t('components.suggest_overlay.search_placeholder.short')
    : $mopI18n.t('components.suggest_overlay.search_placeholder.long');
});

function handleSubmit(event: Event) {
  const value: string = ((event.target as any)[1] as HTMLInputElement).value;
  emit('submit', value);
}

function handleInput(event: Event) {
  const value: string = (event.target as HTMLInputElement).value;
  emit('update:modelValue', value);
}
</script>

<template>
  <form
    :class="[
      'search-form',
      {
        'search-form--small': isSmall,
      },
    ]"
    @submit.prevent.stop="handleSubmit"
  >
    <button type="submit" class="search-form__button">
      <SVGSearch width="25" height="16" />
    </button>
    <input
      :value="modelValue"
      class="search-form__input"
      autocomplete="off"
      spellcheck="false"
      :placeholder="searchInputPlaceholderTextRef"
      @input="handleInput"
    />
  </form>
</template>

<style lang="scss" scoped>
.search-form {
  display: flex;
  background: $light-grey;
  padding: $space15 $space30;
}

.search-form__button {
  border: 0;
  padding: 0;
  margin-right: $space30;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.search-form__input {
  @include text-style(common);

  background: transparent;
  flex-grow: 1;
  margin: 0;
  border: 0;
  font-size: 16px; // prevent zoom in Search input for iOS
}

.search-form__input::-ms-clear {
  display: none;
}

.search-form__input::placeholder {
  color: $dark-grey;
  opacity: 1;
}

.search-form__input:focus {
  outline: none;
}

.search-form--small {
  &.search-form {
    padding: {
      bottom: $space10;
      top: $space10;
    }
  }

  .search-form__button {
    cursor: default;
  }

  .search-form__input {
    font-size: inherit;
  }
}
</style>
