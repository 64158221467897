import { asyncForEach } from '@mop/shared/utils/util';
import { securedWrap } from '@mop/shared/utils/securedWrap';
import { localStorageGet } from '@mop/shared/utils/localStorage';
import type {
  CartModelRef,
  CartResponseData,
  CartLineItemResponseData,
  CartModel,
  CartLineItemModel,
  AddItemParams,
} from '@/types/cart';
import type { Price, ProductModel, VariantModel, GiftCard } from '@/types/product';
import type { PromotionModel } from '@/types/promotion';
import { cartModel } from '@/models';

type LoadingState = {
  initCart: boolean;
  addVariantToCart: boolean;
  updateCartItem: boolean;
  removeCartItem: boolean;
  loading: boolean;
};

type CartComposableStorage = {
  cart: CartModelRef;
  isInitialized: Ref<boolean>;
  loading: Ref<LoadingState>;
  isAddVariantToCartRef: Ref<boolean>;
  maxQuantityAddedToCartRef: Ref<number>;
};

export default function useMopCartClient() {
  const storage = initStorage<CartComposableStorage>('useCart');
  const nuxtApp = useNuxtApp();
  const { $cookie, $apiBackbone, $mopI18n, $mopConfig } = nuxtApp;
  const { reportEvent } = useMopTrackingClient();
  const { isGiveawayEnabled, promotionModelListRef, getSalePromotionKey, isSaleEnabled, isProductQualified } =
    useMopPromotions();
  const { searchProductByRefId, productModelRef } = useMopProduct();
  let pricePromotionKey: string;
  const isInitialized: Ref<boolean> =
    storage.get('isInitialized') ??
    storage.saveAndGet('isInitialized', ref($cookie.get(constants.COOKIE.BASKET) !== ''));
  const cartModelRef: CartModelRef = storage.get('cart') ?? storage.saveAndGet('cart', ref(getCartModel(null)));
  const isAddVariantToCartRef: Ref<boolean> =
    storage.get('isAddVariantToCartRef') ?? storage.saveAndGet('isAddVariantToCartRef', ref(false));
  const maxQuantityAddedToCartRef: Ref<number> =
    storage.get('maxQuantityAddedToCartRef') ?? storage.saveAndGet('maxQuantityAddedToCartRef', ref(0));

  const loadingRef: Ref<LoadingState> =
    storage.get('loading') ??
    storage.saveAndGet(
      'loading',
      ref({
        initCart: false,
        addVariantToCart: false,
        updateCartItem: false,
        removeCartItem: false,
        loading: computed(() => isLoading(loadingRef)),
      }),
    );

  function getBasketKey(): string {
    if (isInitialized.value) {
      return $cookie.get(constants.COOKIE.BASKET);
    }
    isInitialized.value = true;
    return getUuidAndSaveToCookie($cookie, constants.COOKIE.BASKET);
  }

  /**
   * Called on layout level.
   * Do not init elsewhere.
   */
  async function initCart() {
    loadingRef.value.initCart = true;
    if (isInitialized.value) {
      cartModelRef.value = getCartModel(await $apiBackbone.getCart(getBasketKey(), requestParameters.CART_PRODUCTS));
      await handleSale();
      await handleGiveaways();
      handleGtmCartContent();
      addCartDataToDOM();
    }
    loadingRef.value.initCart = false;
  }

  // Needed for AB test cart overlay: https://marc-o-polo.atlassian.net/wiki/spaces/CRO/pages/2389672162/Cart+Abandoner+Note+Pop-Up
  function addCartDataToDOM() {
    const cartData: any = cartModelRef.value.getLineItems().map((lineItem) => {
      const product = lineItem.getProduct();
      const imageSrc = product.getImageByIndex(7);
      const variant = lineItem.getVariant();
      const unitPrice = lineItem.getUnitPrice();
      const availability = variant.getAvailability();
      const size = variant.getSize();
      const productUrl = product.getUrl(size);
      const salesPrice = unitPrice.salesPrice;
      const basePrice = unitPrice.basePrice;
      const discountPercentage = unitPrice.discountPercentage;
      const currency = unitPrice.currency;

      return {
        url: $mopI18n.localePath(productUrl),
        name: product.getName(),
        image: imageSrc,
        quantity: lineItem.getQuantity(),
        size,
        salesPrice,
        formattedSalesPrice: $mopI18n.formatPrice({
          price: salesPrice,
          currency,
        }),
        basePrice,
        formattedBasePrice: $mopI18n.formatPrice({
          price: basePrice,
          currency,
        }),
        isSoldOut: !availability.isInStock,
        discountPercentage,
      };
    });

    // @ts-ignore
    if (window.__NUXT__) {
      // @ts-ignore
      window.__NUXT__['cart-init-data'] = cartData;
    }
  }

  function getCartModel(responseData: CartResponseData | null) {
    responseData?.data?.items.forEach((item: CartLineItemResponseData) => {
      enrichProductResponseWithCmsData(item.product, $mopConfig);
    });
    return cartModel(responseData);
  }

  function getCustomData(item?: CartLineItemModel, data?: { [key: string]: any }) {
    return {
      ...item?.getCustomData(),
      ...data,
      lastAddedTimestamp: data?.lastAddedTimestamp || Date.now(),
    };
  }

  async function addVariantToCart(product: ProductModel, variant: VariantModel, quantity = 1, customData?: any) {
    loadingRef.value.addVariantToCart = true;

    const existingLineItem: CartLineItemModel | undefined = cartModelRef.value.getLineItemByAyId(variant.getId());
    let cart: CartModel;
    let newQuantity: number;
    let maxQuanitityAddedToCart = 0;

    if (existingLineItem) {
      const updateParams: AddItemParams = {
        ...requestParameters.CART_PRODUCTS,
        customData: getCustomData(existingLineItem, customData),
      };

      const desiredNewQuantity: number = existingLineItem.getQuantity() + quantity;
      newQuantity = Math.min(
        desiredNewQuantity,
        existingLineItem.getVariant().getAvailability().quantity,
        constants.INVENTORY_CALL_THRESHOLD,
      );

      // Always update line item to ensure lastAddedTimestamp update
      cart = getCartModel(
        await $apiBackbone.updateCartItem(getBasketKey(), existingLineItem.getKey(), newQuantity, updateParams),
      );
      const canBeAddedToCart: boolean = desiredNewQuantity <= newQuantity;
      if (!canBeAddedToCart) {
        maxQuanitityAddedToCart = newQuantity;
      }
    } else {
      const price: Price = variant.getPrice();

      const createParams: AddItemParams = {
        ...requestParameters.CART_PRODUCTS,
        displayData: {
          'attribute-1': {
            key: 'attribute-1',
            value: variant.getSize(),
            label: product.getColorName(),
          },
          'attribute-2': {
            key: 'attribute-2',
            value: $mopI18n.formatPrice({
              price: price.salesPrice,
              currency: price.currency,
            }),
            label: ' ',
          },
        },
        customData: getCustomData(undefined, customData),
      };

      newQuantity = Math.min(quantity, constants.INVENTORY_CALL_THRESHOLD);

      cart = getCartModel(await $apiBackbone.addCartItem(getBasketKey(), variant.getId(), newQuantity, createParams));
    }

    if (!maxQuanitityAddedToCart) {
      const addedItem: CartLineItemModel | undefined = cart.getLineItemByAyId(variant.getId());
      if (!cart.hasError() && addedItem) {
        reportEvent({
          gtm: {
            data: {
              cartContent: {
                items: cart.getLineItems(),
              },
            },
          },
        });
      }
      isAddVariantToCartRef.value = !cart.hasError() && Boolean(addedItem);
    } else {
      maxQuantityAddedToCartRef.value = maxQuanitityAddedToCart;
    }
    cartModelRef.value = cart;
    await handleSale();
    await handleGiveaways();
    loadingRef.value.addVariantToCart = false;
  }

  async function addGiftCardToCart(_product: ProductModel, variant: VariantModel, messageParam: GiftCard) {
    loadingRef.value.addVariantToCart = true;
    const giftCardParams: AddItemParams = {
      ...requestParameters.CART_PRODUCTS,
      customData: getCustomData(undefined, { isGiftCard: true }),
      displayData: {},
    };

    if (messageParam && Object.values(messageParam).some((val) => val.length > 0)) {
      updateGiftCardParams(giftCardParams, messageParam);
    }

    const cartCheck: CartModel = getCartModel(
      await $apiBackbone.getCart(getBasketKey(), requestParameters.CART_PRODUCTS),
    );
    const existingLineItem: CartLineItemModel | undefined = cartCheck.getGiftCard();
    if (existingLineItem) {
      // bapi doesn't allow to update custom attributes, therefore have to remove and add again
      await $apiBackbone.removeCartItem(getBasketKey(), existingLineItem.getKey());
    }
    const cart: CartModel = getCartModel(
      await $apiBackbone.addCartItem(getBasketKey(), variant.getId(), 1, giftCardParams),
    );
    const addedItem: CartLineItemModel | undefined = cart.getLineItemByAyId(variant.getId());
    if (!cart.hasError() && !existingLineItem && addedItem) {
      reportEvent({
        gtm: {
          data: {
            cartContent: {
              items: cart.getLineItems(),
            },
          },
        },
      });
    }
    isAddVariantToCartRef.value = !cart.hasError() && !existingLineItem && Boolean(addedItem);
    cartModelRef.value = cart;
    loadingRef.value.addVariantToCart = false;
  }

  function updateGiftCardParams(params: AddItemParams, messageParam: GiftCard) {
    const deliveryMethod: string = messageParam?.deliveryMethod ?? 'postal';
    params.customData ??= {};
    params.customData.giftCard = {
      from: encodeURIComponent(String(messageParam?.from ?? '').toLocaleUpperCase()),
      to: encodeURIComponent(String(messageParam?.to ?? '').toLocaleUpperCase()),
      message: encodeURIComponent(String(messageParam?.message ?? '').toLocaleUpperCase()),
      deliveryMethod: encodeURIComponent(String(deliveryMethod)),
    };

    params.displayData = {};

    if (messageParam.from && messageParam.to) {
      params.displayData['attribute-1'] = {
        key: 'name',
        value: `${$mopI18n.t('product.giftcard.to')} ${messageParam.to}, ${$mopI18n.t('product.giftcard.from')} ${
          messageParam.from
        }`.toLocaleUpperCase(),
        label: ' ',
      };
    }

    params.displayData['attribute-2'] = {
      key: 'name',
      value: $mopI18n.t(`product.giftcard.delivery_${deliveryMethod}`).toLocaleUpperCase(),
      label: ' ',
    };

    if (messageParam.message) {
      params.displayData['attribute-3'] = {
        key: 'name',
        value: messageParam.message.toLocaleUpperCase(),
        label: ' ',
      };
    }
  }

  async function updateCartItem(item: CartLineItemModel, quantity: number, customData?: any) {
    loadingRef.value.updateCartItem = true;

    cartModelRef.value = getCartModel(
      await $apiBackbone.updateCartItem(getBasketKey(), item.getKey(), quantity, {
        ...requestParameters.CART_PRODUCTS,
        customData: getCustomData(item, customData),
      }),
    );

    await handleSale();
    await handleGiveaways();
    handleGtmCartContent();
    loadingRef.value.updateCartItem = false;
  }

  async function removeCartItem(itemKey: string) {
    if (!itemKey) {
      return;
    }
    loadingRef.value.removeCartItem = true;
    resetCustomCartData();

    cartModelRef.value = getCartModel(
      await $apiBackbone.removeCartItem(getBasketKey(), itemKey, requestParameters.CART_PRODUCTS),
    );
    await handleSale();
    await handleGiveaways();
    handleGtmCartContent();

    loadingRef.value.removeCartItem = false;
  }

  async function handleSale() {
    const cartLineItems: CartLineItemModel[] = cartModelRef.value.getLineItems();
    let refetchCart = false;
    if (!isSaleEnabled()) {
      await asyncForEach(cartLineItems, async (item: CartLineItemModel) => {
        const variant: VariantModel = item.getVariant();

        if (!variant.containsPricePromotionKey()) {
          return;
        }
        const customData = getCustomData(item);
        // @ts-ignore
        delete customData.pricePromotionKey;
        refetchCart = true;
        await $apiBackbone.updateCartItem(getBasketKey(), item.getKey(), item.getQuantity(), {
          ...requestParameters.CART_PRODUCTS,
          customData,
        });
      });
    } else {
      pricePromotionKey = getSalePromotionKey() || '-';
      if (!hasQualifiedAmountOfItemsForSale()) {
        pricePromotionKey = '-';
      }

      await asyncForEach(cartLineItems, async (item: CartLineItemModel) => {
        const variant: VariantModel = item.getVariant();
        const product: ProductModel = item.getProduct();

        if (!isProductQualified(product) || variant.hasAppliedPricePromotionKey(pricePromotionKey)) {
          return;
        }

        refetchCart = true;
        await $apiBackbone.updateCartItem(getBasketKey(), item.getKey(), item.getQuantity(), {
          ...requestParameters.CART_PRODUCTS,
          pricePromotionKey,
          customData: getCustomData(item),
        });
      });
    }

    if (refetchCart) {
      cartModelRef.value = getCartModel(await $apiBackbone.getCart(getBasketKey(), requestParameters.CART_PRODUCTS));
    }
  }

  function getCartGiveAway(promotionMopId: string) {
    return cartModelRef.value.getLineItems().find((lineItem: CartLineItemModel) => {
      return lineItem.isGiveaway() || lineItem.getProduct().getMopMasterId() === promotionMopId;
    });
  }

  async function handleGiveaways() {
    const isActive: boolean = isGiveawayEnabled(cartModelRef.value);
    const giveawayPromotion: PromotionModel = promotionModelListRef.value.getPromotionModelByName(
      constants.PROMOTIONS.GIVEAWAY_PREFERENCES,
    );
    const promotionMopId: string = giveawayPromotion.getGiveawayProductReferenceKey();
    if (!isActive) {
      // cleanup of exising giveaway if present
      const giveAwayProduct: CartLineItemModel | undefined = getCartGiveAway(promotionMopId);
      removeCartItem(giveAwayProduct?.getKey() || '');
      return;
    }

    // giveaway removed from basket by customer, no longer should be added
    const disabledGiveaway: string = localStorageGet(constants.LOCAL_STORAGE.DISABLED_GIVEAWAY) || '';

    // Get the giveaway product
    if (disabledGiveaway === promotionMopId || getCartGiveAway(promotionMopId)) {
      return;
    }

    // Fetch giveaway product
    await searchProductByRefId(promotionMopId, requestParameters.PRODUCT_TILE_GIVEAWAY, true);
    const variant: VariantModel | undefined = productModelRef.value?.getVariants()[0];
    if (productModelRef.value && variant?.getAvailability()?.isInStock) {
      addVariantToCart(productModelRef.value, variant, 1, { isGiveaway: true });
    }
  }

  function handleGtmCartContent() {
    reportEvent({
      gtm: {
        data: {
          cartContent: {
            items: cartModelRef.value.getLineItems(),
          },
        },
      },
    });
  }

  function getCart() {
    let giveaway: CartLineItemModel | undefined;
    const cart: CartModel = cartModelRef.value;

    let lastAddedItem: CartLineItemModel | undefined;

    const items: CartLineItemModel[] = cart.getLineItems().filter((item: CartLineItemModel) => {
      if (item.getVariant().isGiveaway()) {
        giveaway = item;
        return false;
      }
      if (!lastAddedItem || item.getLastAddedTimestamp() > lastAddedItem.getLastAddedTimestamp()) {
        lastAddedItem = item;
      }
      return true;
    });

    const shipFromStoreItems: CartLineItemModel[] = [];
    const onlineItems: CartLineItemModel[] = [];
    const soldOutItems: CartLineItemModel[] = [];

    items.forEach((item) => {
      if (!item.getVariant().getAvailability().isInStock) {
        soldOutItems.push(item);
      } else if (item.getVariant().isShipFromStore()) {
        shipFromStoreItems.push(item);
      } else {
        onlineItems.push(item);
      }
    });

    const hasMultipleShipments: boolean = shipFromStoreItems.length > 0 && onlineItems.length > 0;

    return {
      count: cart.getCount(),
      items,
      shipFromStoreItems,
      onlineItems,
      soldOutItems,
      giveaway,
      hasMultipleShipments,
      lastAddedItem,
    };
  }

  function hasQualifiedAmountOfItemsForSale() {
    if (!isSaleEnabled()) {
      return false;
    }

    const promotionModel: PromotionModel = promotionModelListRef.value.getPromotionModelByName(
      constants.PROMOTIONS.SALE_PREFERENCES,
    );
    const requiredAmount: number = promotionModel.getRequiredProductCount();

    const amount: number = cartModelRef.value
      .getLineItems()
      .reduce((currentAmount: number, lineItem: CartLineItemModel) => {
        if (isProductQualified(lineItem.getProduct())) {
          currentAmount += lineItem.getQuantity();
        }

        return currentAmount;
      }, 0);

    return amount >= Number(requiredAmount);
  }

  function resetCustomCartData() {
    isAddVariantToCartRef.value = false;
    maxQuantityAddedToCartRef.value = 0;
  }

  function canAddProductToCart() {
    if (cartModelRef.value.getCount(false) > 0 && cartModelRef.value.hasPrintGiftCard()) {
      return false;
    }

    if (cartModelRef.value.hasMixOfPrintGiftCardAndProducts()) {
      return false;
    }

    return true;
  }

  function canAddGiftCardToCart() {
    if (cartModelRef.value.getCount(false) > 0 && !cartModelRef.value.hasPrintGiftCard()) {
      return false;
    }

    if (cartModelRef.value.hasMixOfPrintGiftCardAndProducts()) {
      return false;
    }

    return true;
  }

  return securedWrap({
    cartModelRef,
    initCart,
    getCart,
    addVariantToCart,
    updateCartItem,
    removeCartItem,
    addGiftCardToCart,
    getBasketKey,
    loadingRef,
    isAddVariantToCartRef,
    maxQuantityAddedToCartRef,
    resetCustomCartData,
    canAddProductToCart,
    canAddGiftCardToCart,
  });
}
