import { getPrice } from '@/models/utils/priceUtils';
import type {
  VariantListResponseData,
  VariantModel,
  Availability,
  VariantResponseData,
  Stock,
  VariantAttributes,
  VariantAdvancedAttributes,
  Price,
  LowestPriorPrice,
  VariantData,
} from '@/types/product';

export function variantModel(responseData: VariantResponseData | null) {
  const response: VariantResponseData = responseData ?? {};
  const attributes: VariantAttributes | undefined = response.data?.attributes;
  const advancedAttributes: VariantAdvancedAttributes | undefined = response.data?.advancedAttributes;

  return {
    getId(): number {
      return response.data?.id ?? 0;
    },

    getPrice(): Price {
      return getPrice(response.data?.price);
    },

    getLowestPriorPrice(): LowestPriorPrice | undefined {
      if (!response.data?.lowestPriorPrice?.withTax) {
        return;
      }
      return {
        price: (response.data.lowestPriorPrice.withTax || 0) / 100,
        relativeDifferenceToPrice: Math.round((response.data.lowestPriorPrice.relativeDifferenceToPrice || 0) * 100),
      };
    },

    getSize(): string {
      return attributes?.size ?? '';
    },

    getEan(): string {
      return response.data?.referenceKey ?? '';
    },

    isGiveaway(): boolean {
      return attributes?.isSellableForFree === 'true';
    },

    getMaterialComposition(): string[] {
      let materialCompositionResult: string[] = [];

      if (!attributes || !advancedAttributes) {
        return materialCompositionResult;
      }
      const materialNames: string[] = Object.keys(attributes)
        .sort()
        .reduce((materialNameList: string[], key) => {
          const attributeKey = key as keyof VariantAttributes;
          if (attributeKey.startsWith('materialCompositionName')) {
            const attributeValue = attributes[attributeKey] as string;
            materialNameList.push(attributeValue);
          }
          return materialNameList;
        }, []);
      const materialPercentage: string[] = Object.keys(advancedAttributes)
        .sort()
        .reduce((materialPercentageList: string[], key) => {
          const attributeKey = key as keyof VariantAdvancedAttributes;
          if (attributeKey.startsWith('materialCompositionFraction')) {
            const attributeValue = advancedAttributes[attributeKey] as string;
            materialPercentageList.push(attributeValue);
          }
          return materialPercentageList;
        }, []);

      if (materialNames.length === materialPercentage.length) {
        materialCompositionResult = materialNames.map(function (entry, index) {
          return `${materialPercentage[index]} ${entry}`;
        });
      }
      return materialCompositionResult;
    },

    getAvailability(stockInTransit = false): Availability {
      const stockData: Stock = response.data?.stock as Stock;
      const quantity: number = stockData.quantity;
      const warehouseId: number = stockData.warehouseId;
      const isLowStock: boolean = quantity < constants.INVENTORY_CALL_THRESHOLD;
      const isComingSoon: boolean = quantity === 0 && stockData.isSellableWithoutStock;
      const isInStock: boolean = quantity > 0;
      const isInTransit: boolean = quantity === 0 && stockInTransit;
      let stockText = 'product.stock.exists';

      if (isLowStock) {
        stockText = 'product.stock.low';
      }
      if (!isInStock) {
        stockText = 'product.stock.soldout';
      }
      if (isInTransit) {
        stockText = 'product.flag.soon_available';
      }
      return {
        isComingSoon,
        isInStock,
        isLowStock,
        isBackOrder: stockData.supplierId !== constants.ARVATO_SUPPLIER_ID,
        isInTransit,
        quantity,
        stockText,
        backOrderText: 'product.backorder',
        deliveryText: 'product.delivery',
        warehouseId,
      };
    },

    isShipFromStore(): boolean {
      return this.getAvailability().warehouseId === getAyWarehouseId('SHIP_FROM_STORE');
    },

    getDebugData(): VariantData | undefined {
      return response.data;
    },

    hasAppliedPricePromotionKey(pricePromotionKey: string): boolean {
      return pricePromotionKey === response.data?.customData.pricePromotionKey;
    },

    containsPricePromotionKey(): boolean {
      return response.data?.customData?.pricePromotionKey !== undefined;
    },
  };
}

export function variantListModel(responseData: VariantListResponseData | null) {
  const response: VariantListResponseData = responseData ?? {};

  return {
    hasError(): boolean {
      return response.error !== undefined;
    },

    getVariantModelList(): VariantModel[] {
      if (response.data === undefined) {
        return [];
      }
      return response.data.map((variantData: VariantData) => variantModel({ data: variantData }));
    },
  };
}
