<script setup lang="ts">
import { getElementUiObject, getLink, getImage } from '@mop/cms/utils/utils';
import type { CmsUiFaqImageTile, CmsUiFaqImageTiles } from './uiCmsFaq';

defineOptions({
  name: 'UiCmsFaqImageTiles',
});

const props = defineProps({
  data: {
    type: Object as PropType<CmsUiFaqImageTiles>,
    required: true,
  },
});

const data = props.data;

const element: CmsUiFaqImageTiles = getElementUiObject(data, {
  headline: data.headline,
  text: data.text,
  items: data.items.reduce((list: CmsUiFaqImageTile[], item: CmsUiFaqImageTile) => {
    if (!item.text.trim()) {
      return list;
    }
    list.push(
      getElementUiObject(item, {
        link: getLink(item.link),
        text: item.text,
        image: getImage(item.image),
      }),
    );
    return list;
  }, []),
});
</script>

<template>
  <div v-storyblok-editable="element" class="ui-cms-faq-image-tiles">
    <h2 v-if="element.headline" class="ui-cms-faq-image-tiles__headline">
      {{ element.headline }}
    </h2>
    <p v-if="element.text">
      {{ element.text }}
    </p>
    <ul class="ui-cms-faq-image-tiles__list">
      <li
        v-for="item in element.items"
        :key="item._uid"
        v-storyblok-editable="item"
        class="ui-cms-faq-image-tiles__item"
      >
        <UiNuxtLink v-bind="item.link" class="ui-cms-faq-image-tiles__link">
          <div class="ui-cms-faq-image-tiles__image-wrapper">
            <UiImage :image="item.image" class="ui-cms-faq-image-tiles__image" lazy />
          </div>
          <div class="ui-cms-faq-image-tiles__text">
            {{ item.text }}
          </div>
        </UiNuxtLink>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
$accordion-searchable-icon-size: 130px;

.ui-cms-faq-image-tiles {
  padding: 0 $global-padding;
}

.ui-cms-faq-image-tiles__headline {
  @include text-style(headline);

  @include apply-upto(infinite) {
    margin-bottom: 0;
  }
}

.ui-cms-faq-image-tiles__list {
  display: flex;
  flex-wrap: wrap;
  width: columns(15);

  @include apply-upto(large) {
    width: columns(18);
  }

  @include apply-upto(medium) {
    width: columns(27);
  }

  @include apply-upto(small) {
    width: columns(36);
  }
}

.ui-cms-faq-image-tiles__item {
  flex: 0 0 columns(12);
  margin-bottom: columns(2);

  @include apply-upto(s) {
    flex-basis: columns(18);
  }
}

.ui-cms-faq-image-tiles__item:nth-child(3n - 1) {
  text-align: center;

  @include apply-upto(s) {
    text-align: left;
  }
}

.ui-cms-faq-image-tiles__item:nth-child(3n) {
  text-align: right;

  @include apply-upto(s) {
    text-align: left;
  }
}

.ui-cms-faq-image-tiles__link {
  display: inline-block;
  text-align: center;
  width: $accordion-searchable-icon-size;
}

.ui-cms-faq-image-tiles__image-wrapper {
  display: inline-block;
}

.ui-cms-faq-image-tiles__image {
  width: $accordion-searchable-icon-size;
}

.ui-cms-faq-image-tiles__text {
  @include text-style(strong);
}
</style>
