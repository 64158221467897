<script setup lang="ts">
import type { VNodeArrayChildren } from 'vue';
// @ts-ignore
import SVGLeftLong from '@mop/shared/images/arrow/left-long.svg?component';
// @ts-ignore
import SVGRightLong from '@mop/shared/images/arrow/right-long.svg?component';
import { isFirefox, asyncTimeout } from '@mop/shared/utils/util';
import UiSliderSlideNew from '@mop/ui/components/global/UiSliderSlideNew.vue';
import UiSlider from '@mop/ui/utils/uiSlider';

defineOptions({
  name: 'UiSliderNew',
});

const emit = defineEmits(['slide-change', 'mounted', 'before-slide-change']);

const slots = useSlots();

const props = defineProps({
  showArrows: {
    type: Boolean,
    default: false,
  },
  showArrowsMobile: {
    type: Boolean,
    default: false,
  },
  showBullets: {
    type: Boolean,
    default: false,
  },
  slideFullPage: {
    type: Boolean,
    default: false,
  },
  slideFullHeight: {
    type: Boolean,
    default: false,
  },
  hasInfiniteLoop: {
    type: Boolean,
    default: false,
  },
  autoPlay: {
    type: [Boolean, Number],
    default: false,
  },
  noGap: {
    type: Boolean,
    default: false,
  },
  showPeek: {
    type: Boolean,
    default: false,
  },
  isProductTiles: {
    type: Boolean,
    default: false,
  },
  slideToIndex: {
    type: Number,
    default: 0,
  },
  slideBehavior: {
    type: String as PropType<ScrollBehavior | 'instant'>,
    default: 'smooth',
  },
  maxSlidesCount: {
    type: Number,
    default: 1,
  },
  slideClassname: {
    type: String,
    default: '',
  },
  keepAspectRatio: {
    type: Boolean,
    default: false,
  },
});

const { $resize } = useNuxtApp();
const sliderElementRef: Ref<HTMLElement | null> = ref(null);
let uiSlider: UiSlider | null = null;

onMounted(async () => {
  if (!sliderElementRef.value) {
    return;
  }
  uiSlider = new UiSlider(sliderElementRef.value, props.autoPlay as number);
  uiSlider.sliderElement.addEventListener(
    'slide-change',
    function (event) {
      emit('slide-change', (event as CustomEvent).detail);
    },
    false,
  );
  emit('mounted', true);
  uiSlider.slideToIndex(props.slideToIndex, props.slideBehavior);

  await asyncTimeout(500);

  if (!sliderElementRef.value) {
    return;
  }
  sliderElementRef.value.style.visibility = 'visible';

  if (isFirefox()) {
    watch([$resize.viewportWidthRef, $resize.viewportHeightRef], debounceResize);
  }
});

function debounceResize() {
  // scroll-snap is not working for firefox while window is resizing
  // do slide to current slide as workaround
  uiSlider?.slideToIndex(uiSlider.getCurrentSlideIndex() - 1);
}

watch(
  () => props.slideToIndex,
  (index) => {
    uiSlider?.slideToIndex(index);
  },
);

onUnmounted(() => {
  uiSlider && uiSlider.destroy();
  uiSlider = null;
  sliderElementRef.value = null;
});

const render = () => {
  const slides = slots.default?.()[0]?.children as VNodeArrayChildren;
  if (!slides?.length) {
    return;
  }

  const items = slides.map((slide) => {
    return h(UiSliderSlideNew, { class: props.slideClassname }, () => slide);
  });

  // @ts-ignore
  const lastSlide = slots.default()[0].children[slides.length - 1];
  // @ts-ignore
  const firstSlide = slots.default()[0].children[0];

  if (props.hasInfiniteLoop) {
    items.unshift(
      h(
        UiSliderSlideNew,
        {
          class: ['ui-mop-slide--clone ui-mop-slide--last', props.slideClassname],
        },
        () => lastSlide,
      ),
    );

    items.push(
      h(
        UiSliderSlideNew,
        {
          class: ['ui-mop-slide--clone ui-mop-slide--first', props.slideClassname],
        },
        () => firstSlide,
      ),
    );
  }

  return h(
    'div',
    {
      class: [
        'ui-mop-slider',
        props.hasInfiniteLoop ? 'ui-mop-slider--loading' : '',
        props.maxSlidesCount > 1 ? `ui-mop-slider--show${props.maxSlidesCount}` : '',
        {
          'ui-mop-slider--bullets': props.showBullets,
          'ui-mop-slider--infinite-loop': props.hasInfiniteLoop,
          'ui-mop-slider--auto-play': props.autoPlay,
          'ui-mop-slider--slide-full-page': props.slideFullPage,
          'ui-mop-slider--slide-full-height': props.slideFullHeight,
          'ui-mop-slider--keep-ratio': props.keepAspectRatio,
          'ui-mop-slider--nogap': props.noGap,
          'ui-mop-slider--peek': props.showPeek,
          'ui-mop-slider--product-tiles': props.isProductTiles,
          'ui-mop-slider--arrows-mobile': props.showArrows && props.showArrowsMobile,
          'ui-mop-slider--instant-behavior': props.slideBehavior === 'instant',
        },
      ],
    },
    [
      h('ul', { class: 'ui-mop-slider__container' }, items),
      props.showArrows
        ? h('div', { class: 'ui-mop-slider__arrow ui-mop-slider__arrow--prev' }, [
            h(SVGLeftLong, {
              class: 'ui-mop-slider__arrow-svg',
              width: '26',
              height: '16',
            }),
          ])
        : null,
      props.showArrows
        ? h('div', { class: 'ui-mop-slider__arrow ui-mop-slider__arrow--next' }, [
            h(SVGRightLong, {
              class: 'ui-mop-slider__arrow-svg',
              width: '26',
              height: '16',
            }),
          ])
        : null,
      h('div', { class: 'ui-mop-slider__bullets' }),
    ],
  );
};

defineExpose({
  sliderElementRef,
});
</script>

<template>
  <render ref="sliderElementRef" />
</template>

<style lang="scss">
@import '../../scss/uiSlider';
</style>
