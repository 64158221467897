<script setup lang="ts">
import type { WatchStopHandle } from 'vue';
import type { ObserveVisibilityConfig } from '@mop/types';
import { getElementUiObject, getLink, getHeadlineWithAllOptions, getMedia, getCta } from '@mop/cms/utils/utils';
import type { CmsUiFullWidthBanner } from './uiCmsFullWidthBanner';
import type UiCmsMedia from './UiCmsMedia.vue';
import type UiNuxtLink from '@mop/ui/components/global/UiNuxtLink.vue';

type NuxtLinkType = typeof UiNuxtLink;
type CmsMediaType = typeof UiCmsMedia;

defineOptions({
  name: 'UiCmsFullWidthBanner',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  lazyLoad: {
    type: Boolean,
    default: false,
  },
});

const data = props.data;
const { $scroll, $breakpoint } = useNuxtApp();

const element: CmsUiFullWidthBanner = getElementUiObject(data, {
  headline: getHeadlineWithAllOptions(data),
  headlineFontStyle: data.headlineFontStyle,
  subline: data.subline,
  isSublineAboveHeadline: data.isSublineAboveHeadline ?? false,
  cta: getCta(data.cta?.[0]),
  link: getLink(data.link),
  textColor: data.textColor?.color || '#000',
  media: getMedia(data.media?.[0], true),
  useParallaxEffect: data.useParallaxEffect,
});

const headlineFontMap: Record<string, string> = {
  default: 'ui-cms-full-width-banner__textbox-headline--default',
  serifCondensed: 'ui-cms-full-width-banner__textbox-headline--serif-condensed',
};

const uiHeadlineFontClass = headlineFontMap[element.headlineFontStyle || 'default'];

if (element.media) {
  element.media.imageType = 'background';
}

const containerRef = ref<NuxtLinkType>();
const imageContainerRef = ref<CmsMediaType>();

const useParallaxEffect = element.media?.type !== 'image' ? false : data.useParallaxEffect ?? false;
const doesContentExist = Boolean(element.headline.text || element.subline || element.textColor || element.cta);
const parallaxEffectRef = ref<string>();
const imageStyleRef = computed(() => (!useParallaxEffect && $breakpoint.isTinyRef ? '' : parallaxEffectRef.value));

let stopScrollWatch: WatchStopHandle;
const visibilityConfig: ObserveVisibilityConfig = {
  callback: (isVisible) => {
    if (isVisible) {
      stopScrollWatch = watch($scroll.offsetTopRef, handleScroll);
    } else {
      stopScrollWatch && stopScrollWatch();
    }
  },
  rootMargin: '0px 0px 0px 0px',
};

onUnmounted(() => {
  stopScrollWatch && stopScrollWatch();
});

function handleLoaded() {
  if (useParallaxEffect) {
    nextTick(handleScroll);
  }
}

function handleScroll() {
  if (!containerRef?.value) {
    return;
  }
  const elementContainer = containerRef.value.$el || containerRef.value;
  const isDomElement: boolean = elementContainer instanceof HTMLElement;

  // It needs to be checked, if the container is a DOM element
  const element: Element = isDomElement ? (elementContainer as Element) : (elementContainer as NuxtLinkType).$el;
  const imageElement = imageContainerRef.value;

  const offsetTop = $scroll.offsetTopRef.value;
  const windowHeight = window.innerHeight;

  const elementPosition = element.getBoundingClientRect().top + offsetTop;
  const elementHeight = element.clientHeight;

  const windowStartScrollPosition = elementPosition - windowHeight;
  const windowStopScrollPosition = windowStartScrollPosition + windowHeight + elementHeight;
  const percentageScrolled =
    (offsetTop - windowStartScrollPosition) / (windowStopScrollPosition - windowStartScrollPosition);

  const scrollableHeight = imageElement?.$el.clientHeight - elementHeight;
  const newElementScrollTop = Math.round(percentageScrolled * scrollableHeight);

  if (newElementScrollTop < 0) {
    return;
  }

  parallaxEffectRef.value = `transform: translate3d(0, -${newElementScrollTop}px, 0);`;
}
</script>

<template>
  <UiNuxtLink
    v-bind="element.link"
    ref="containerRef"
    v-observe-visibility="useParallaxEffect ? visibilityConfig : undefined"
    v-storyblok-editable="element"
    :class="['ui-cms-full-width-banner', { 'ui-cms-full-width-banner--parallax': useParallaxEffect }]"
    :data-track-element-item="`${element.media?.trackingId}`"
  >
    <div v-if="element.media" class="ui-cms-full-width-banner__background">
      <UiCmsMedia
        ref="imageContainerRef"
        :style="imageStyleRef"
        :data="element.media"
        :lazy="lazyLoad"
        scale-full-width-video
        @loaded="handleLoaded"
      />
    </div>

    <div
      v-if="doesContentExist"
      class="ui-cms-full-width-banner__textbox-wrapper"
      :style="`--color: ${element.textColor};`"
    >
      <div class="ui-cms-full-width-banner__textbox">
        <UiHeading
          v-if="element.headline.text"
          :class="[
            'ui-cms-full-width-banner__textbox-headline',
            uiHeadlineFontClass,
            { 'ui-cms-full-width-banner__textbox-headline--uppercase': element.headline.isUppercase },
          ]"
          :level="element.headline.level ?? 1"
        >
          {{ element.headline.text }}
        </UiHeading>
        <div
          v-if="element.subline"
          :class="[
            'ui-cms-full-width-banner__textbox-subline',
            { 'ui-cms-full-width-banner__textbox-subline--first': element.isSublineAboveHeadline },
          ]"
        >
          {{ element.subline }}
        </div>

        <UiCmsCta v-if="element.cta" :data="element.cta" no-padding />
      </div>
    </div>
  </UiNuxtLink>
</template>

<style lang="scss" scoped>
.ui-cms-full-width-banner {
  position: relative;
  display: block;

  a {
    cursor: pointer;
  }
}

.ui-cms-full-width-banner__textbox-wrapper {
  @include z(global, content);

  position: absolute;
  max-height: calc(100% - #{$space60});
  bottom: 0;
  left: 0;
  width: 100%;
}

.ui-cms-full-width-banner__textbox {
  display: flex;
  flex-direction: column;
  padding: $space15 $space-16;

  @include apply-upto(small) {
    padding: $space5 $space-16;
  }

  &:deep(.ui-cms-cta__element) {
    padding: $space15 0;
    margin-bottom: -#{$space-16};
  }
}

.ui-cms-full-width-banner__textbox-subline {
  @include text-style(sub-headline);

  text-transform: uppercase;
}

.ui-cms-full-width-banner__textbox-subline--first {
  order: -1;
}

.ui-cms-full-width-banner__textbox-headline {
  @include font-smoothing(strong);
  @include text-style(headline);

  @include apply-upto(medium) {
    margin-bottom: 0;
    font-size: 40px;
    line-height: 40px;
  }

  @include apply-upto(small) {
    margin-bottom: 0;
    font-size: 22px;
    line-height: 22px;
  }

  @include apply-from(large) {
    margin-bottom: 0;
    font-size: 40px;
    line-height: 40px;
  }
}

.ui-cms-full-width-banner__textbox-headline--serif-condensed {
  @include text-style(headline-serif-condensed);

  @include apply-upto(medium) {
    margin-bottom: 0;
    font-size: 40px;
    line-height: 120%;
  }

  @include apply-upto(small) {
    margin-bottom: 0;
    font-size: 22px;
    line-height: 120%;
  }

  @include apply-from(large) {
    margin-bottom: 0;
    font-size: 40px;
    line-height: 120%;
  }
}

.ui-cms-full-width-banner__textbox-headline--uppercase {
  text-transform: uppercase;
}

.ui-cms-full-width-banner--parallax {
  @include apply-only(medium) {
    padding: 200px 0;
  }

  @include apply-only(large) {
    padding: 215px 0;
  }

  @include apply-from(extralarge) {
    padding: 240px 0;
  }

  .ui-cms-full-width-banner__background {
    overflow: hidden;

    @include apply-from(medium) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
</style>
