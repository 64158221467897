<script setup lang="ts">
import type { CmsMedia, CmsMediaImage } from '@mop/cms/types';
import { getElementUiObject, getMedia, getLink, getHeadlineWithUppercaseOption } from '@mop/cms/utils/utils';
import type { CmsUiShopTheLook, CmsUiShopTheLookTile } from './ShopTheLook';

defineOptions({
  name: 'MopCmsShopTheLook',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const data = props.data;

function getCmsUiShopTheLookTile(object: any): CmsUiShopTheLookTile[] {
  const media: CmsMedia | undefined = getMedia(object.media?.[0]);
  const imageType = 'cms-shop-the-look';
  if (media) {
    media.imageType = imageType;
  }
  const hasMobileMedia: boolean = (media as CmsMediaImage)?.imageMobile !== undefined;

  return getElementUiObject(object, {
    productId: object.productId || '',
    media,
    link: getLink(object.link),
    isProductTile: true,
    hasMobileMedia,
    imageType,
    productImageIndex: PRODUCT_IMAGE_INDEX.SHOP_THE_LOOK,
  });
}

const element: CmsUiShopTheLook = getElementUiObject(data, {
  headline: getHeadlineWithUppercaseOption(data),
  text: data.text,
  link: getLink(data.link),
  showHeroImageFirst: data.showHeroImageFirst || false,
  heroImage: getMedia(data.heroImage?.[0]),
  tiles: data.items?.slice(0, 4)?.map((item: any) => getCmsUiShopTheLookTile(item)),
});

if (element.heroImage) {
  element.heroImage.imageType = 'cms-shop-the-look-hero';
}
</script>

<template>
  <div
    v-storyblok-editable="element"
    :class="['ui-cms-shop-the-look', { 'ui-cms-shop-the-look--hero-first': element.showHeroImageFirst }]"
  >
    <div class="ui-cms-shop-the-look__content">
      <div
        v-if="element.headline.text"
        :class="[
          'ui-cms-shop-the-look__headline',
          { 'ui-cms-shop-the-look__headline--uppercase': element.headline.isUppercase },
        ]"
      >
        {{ element.headline.text }}
      </div>
      <div v-if="element.text" class="ui-cms-shop-the-look__text">
        {{ element.text }}
      </div>
    </div>
    <div class="ui-cms-shop-the-look__gallery">
      <div class="ui-cms-shop-the-look__grid">
        <MopTile
          v-for="(promoElement, index) in element.tiles"
          :key="`${index}-${promoElement._uid}`"
          class="ui-cms-shop-the-look__tile"
          :data="promoElement as any"
          :image-type="promoElement.imageType"
          disable-image-hover-change
          :track-position="(index + 1).toString()"
        />
      </div>
      <UiNuxtLink class="ui-cms-shop-the-look__hero" v-bind="element.link">
        <UiCmsMedia v-if="element.heroImage" :data="element.heroImage" />
      </UiNuxtLink>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ui-cms-shop-the-look__headline {
  @include text-style(headline);

  padding: $space20 0 $space30;
}

.ui-cms-shop-the-look__headline--uppercase {
  text-transform: uppercase;
}

.ui-cms-shop-the-look__content {
  padding: 0 $global-padding;

  .ui-cms-shop-the-look__headline {
    margin: 0;
  }
}

.ui-cms-shop-the-look__text {
  @include text-style(running);

  padding-bottom: $space30;
}

.ui-cms-shop-the-look__gallery {
  display: flex;
  padding-right: columns(2);

  @include apply-upto(small) {
    flex-direction: column-reverse;
    padding-right: 0;
  }
}

.ui-cms-shop-the-look__hero {
  // it fits
  flex-basis: 71%;
  width: 71%;
  min-width: 0.1vh; /* hack to force reflow */

  @include apply-upto(small) {
    width: 100%;
  }
}

.ui-cms-shop-the-look__grid {
  // it fits
  flex-basis: 58%;
  width: 58%;
  min-width: 0.1vh; /* hack to force reflow */
  display: flex;
  flex-wrap: wrap;

  @include apply-upto(small) {
    width: 100%;
  }
}

.ui-cms-shop-the-look__tile {
  flex-basis: 50%;
  width: 50%;
  border-style: solid;
  border-color: $white;
  border-width: 0;

  &:nth-of-type(1) {
    border-width: 0 1px 1px 0;
  }

  &:nth-of-type(2) {
    border-width: 0 0 1px 1px;
  }

  &:nth-of-type(3) {
    border-width: 1px 1px 0 0;
  }

  &:nth-of-type(4) {
    border-width: 1px 0 0 1px;
  }

  :deep() {
    .product-tile__info,
    .product-tile__hover-wrapper {
      padding: 0 $space15;

      @include apply-only(medium) {
        padding: 0 $space5 0 $space10;
      }

      @include apply-upto(small) {
        padding-bottom: $space10;
      }
    }

    .product-tile__block--brand {
      @include apply-only(medium) {
        display: none;
      }
    }

    .product-tile__wishlist {
      right: $space15;

      @include apply-upto(small) {
        right: $space5;
      }
    }
  }
}

.ui-cms-shop-the-look--hero-first {
  .ui-cms-shop-the-look__gallery {
    flex-direction: row-reverse;
    padding-right: 0;
    padding-left: $global-padding;

    @include apply-upto(small) {
      flex-direction: column-reverse;
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.ui-cms-shop-the-look__failed-list {
  display: flex;
}

.ui-cms-shop-the-look__failed-element {
  @include text-style(small);

  background: $signal-alert;
  color: $white;
  padding: $space5;
  margin: $space5;
}
</style>
