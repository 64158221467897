import type { AxiosError, AxiosInstance } from 'axios';
import type { CustomerBonusVoucherStepsResponseData } from '@/types/customer';
import { errorHandler, handleAxiosError } from '@/api/utils';
import type { StatusResponseData } from '@/types/status';
const API_NAME = 'CRM - customer';

export function deleteCustomer(
  apiInstance: AxiosInstance,
  authToken: string,
  customerEmail: string,
): Promise<StatusResponseData> {
  return errorHandler<StatusResponseData>(async () => {
    await apiInstance.delete(`/api/Customer/${customerEmail}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return {
      data: '',
    };
  }).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'deleteCustomer',
      authToken,
    }),
  }));
}
export function customerExists(
  apiInstance: AxiosInstance,
  authToken: string,
  customerEmail: string,
): Promise<StatusResponseData> {
  return errorHandler<StatusResponseData>(async () => {
    await apiInstance.get(`/api/Customer/${customerEmail}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return {
      data: '',
    };
  }).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'customerExists',
      authToken,
    }),
  }));
}
export function validatePrecard(
  apiInstance: AxiosInstance,
  authToken: string,
  precardNumber: string,
): Promise<StatusResponseData> {
  return errorHandler<StatusResponseData>(async () => {
    await apiInstance.get(`/api/Precard/${precardNumber}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return {
      data: '',
    };
  }).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'validatePrecard',
      authToken,
    }),
  }));
}

export function getBonusVoucherSteps(
  apiInstance: AxiosInstance,
  authToken: string,
): Promise<CustomerBonusVoucherStepsResponseData> {
  return errorHandler<CustomerBonusVoucherStepsResponseData>(async () => {
    return {
      data: (
        await apiInstance.get('/api/BonusVoucherSteps', {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
      ).data,
    };
  }).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'BonusVoucherSteps',
      authToken,
    }),
  }));
}
