import { securedWrap } from '@mop/shared/utils/securedWrap';
// Direct import to avoid circular dependency
import { productListModel } from '@/models/productModel';
import { paginationModel } from '@/models';
import type {
  ProductListResponseData,
  ProductData,
  ProductsSearchResponseData,
  ProductsSearchParameter,
  ProductModelListRef,
  PaginationModelRef,
  PaginationParams,
} from '@/types/product';

type LoadingState = {
  searchProductsByIds: boolean;
  searchProducts: boolean;
  loading: boolean;
};

export default function useMopProducts(cacheId?: string) {
  const nuxtApp = useNuxtApp();
  const mopRouter = useMopRouter();
  const { getCachedResponse } = useClientCache();
  const loadingRef: Ref<LoadingState> = ref({
    searchProductsByIds: false,
    searchProducts: false,
    loading: computed(() => isLoading(loadingRef)),
  });
  const defaultPagination: PaginationParams = {
    page: 1,
    perPage: nuxtApp.$mopConfig.getMaxProductsPerPage(),
  };
  const { $apiBackbone, $mopConfig } = nuxtApp;

  const appliedPaginationRef: Ref<PaginationParams | null> = ref(null);
  let currentProductQuery: ProductsSearchParameter = {
    pagination: defaultPagination,
    ...requestParameters.PRODUCT_TILE,
  };
  const { isSaleEnabled, getSalePromotionKey, showSaleCallOutMessage } = useMopPromotions();

  const CACHE_PRODUCT_LIST = `productsByIds-${cacheId}`;
  const CACHE_PRODUCT_SEARCH = `productsQuery-${currentProductQuery.pagination?.page}-${currentProductQuery.pagination?.perPage}-${cacheId}`;
  const productListResponseRef = useMopSSR<ProductListResponseData | null>(CACHE_PRODUCT_LIST, null);
  const productSearchResponseRef = useMopSSR<ProductsSearchResponseData | null>(CACHE_PRODUCT_SEARCH, null);

  let productModelListRef: ProductModelListRef | Ref<null>;
  let paginationModelRef: PaginationModelRef | Ref<null>;
  if (productListResponseRef.value) {
    productModelListRef = ref(productListModel(productListResponseRef.value));
    paginationModelRef = ref(paginationModel(null));
  } else if (productSearchResponseRef.value) {
    productModelListRef = ref(
      productListModel({
        data: productSearchResponseRef.value?.data?.entities,
      }),
    );
    paginationModelRef = ref(paginationModel(productSearchResponseRef.value?.data?.pagination ?? null));
  } else {
    productModelListRef = ref(productListModel(null));
    paginationModelRef = ref(paginationModel(null));
  }

  function enrichProductWithCmsData(productSearchResponse: ProductsSearchResponseData): ProductsSearchResponseData {
    productSearchResponse.data?.entities?.forEach((productData: ProductData) => {
      enrichProductResponseWithCmsData(productData, $mopConfig);
    });
    return productSearchResponse;
  }

  async function searchProductsByIds(
    mopProductIds: string[],
    searchParams: ProductsSearchParameter = requestParameters.PRODUCT_TILE,
    forceClientRefetch = false,
  ) {
    if (!mopProductIds || mopProductIds.length === 0) {
      return;
    }
    loadingRef.value.searchProductsByIds = true;
    const params: ProductsSearchParameter = {
      ...searchParams,
    };
    params.where = params.where || {
      attributes: [
        {
          type: 'attributes',
          key: 'referenceKey' as any,
          values: mopProductIds,
        },
      ],
    };
    if (isSaleEnabled('promoQualifier') && showSaleCallOutMessage()) {
      params.pricePromotionKey = getSalePromotionKey();
    }
    if (forceClientRefetch || params.pricePromotionKey) {
      productSearchResponseRef.value = null;
    }
    productSearchResponseRef.value ??= enrichProductWithCmsData(await $apiBackbone.queryProducts(params));

    productModelListRef.value = productListModel({
      data: productSearchResponseRef.value?.data?.entities,
    });
    loadingRef.value.searchProductsByIds = false;
  }

  async function searchProductsByEans(
    eans: string[],
    searchParams: ProductsSearchParameter = requestParameters.PRODUCT_TILE,
    forceClientRefetch = false,
  ) {
    await searchProductsByIds(
      [''],
      {
        ...searchParams,
        ...{
          where: {
            attributes: [
              {
                type: 'attributes',
                key: 'ean' as any,
                values: eans,
              },
            ],
          },
          includeSoldOut: true,
        },
      },
      forceClientRefetch,
    );
  }

  async function searchProducts(
    searchParams: ProductsSearchParameter,
    {
      forceClientRefetch = false,
      cacheClienResponse = false,
    }: { forceClientRefetch?: boolean; cacheClienResponse?: boolean },
  ) {
    loadingRef.value.searchProducts = true;
    currentProductQuery = {
      ...searchParams,
    };

    if (isSaleEnabled('promoQualifier') && showSaleCallOutMessage()) {
      currentProductQuery.pricePromotionKey = getSalePromotionKey();
    }

    if (forceClientRefetch || currentProductQuery.pricePromotionKey) {
      productSearchResponseRef.value = null;
    }

    const searchTerm: string | undefined = currentProductQuery.where?.term;
    // do partial search using *
    if (searchTerm && searchTerm.includes('*')) {
      currentProductQuery.where = {
        ...currentProductQuery.where,
        containsSearch: true,
        disableFuzziness: true,
        term: searchTerm.replace(/\*/gi, ''),
      };
    } else if (searchTerm && isProductId(searchTerm)) {
      currentProductQuery.where = {
        ...currentProductQuery.where,
        disableFuzziness: true,
      };
    }

    if (cacheClienResponse) {
      productSearchResponseRef.value ??= enrichProductWithCmsData(
        await getCachedResponse(currentProductQuery, () => $apiBackbone.queryProducts(currentProductQuery)),
      );
    } else {
      productSearchResponseRef.value ??= enrichProductWithCmsData(
        await $apiBackbone.queryProducts(currentProductQuery),
      );
    }

    paginationModelRef.value = paginationModel(productSearchResponseRef.value.data?.pagination ?? null);
    productModelListRef.value = productListModel({
      data: productSearchResponseRef.value?.data?.entities,
    });

    loadingRef.value.searchProducts = false;
  }

  function setPaginationFromUrl() {
    const currentPage: number = parseInt(mopRouter.getQueryParamValue(constants.QUERY_PARAMETERS.PAGE_QUERY) as string);
    if (isNaN(currentPage)) {
      appliedPaginationRef.value = defaultPagination;
      return;
    }
    appliedPaginationRef.value = {
      perPage: defaultPagination.perPage,
      page: currentPage,
    };
  }

  function getPaginationParams() {
    return appliedPaginationRef.value ?? defaultPagination;
  }

  function isProductId(input: string): boolean {
    /*
    Covering cases such as:
      B0126542701103_790
      B0126542701103
      730285-102_464
      730285-102
      00126542701103_790
      000823102003_D01
      000823102003
    */
    return /^(\d|\w{1}\d|\d+-\d)+(_.+)?$/gi.test(input);
  }

  return securedWrap({
    productModelListRef,
    loadingRef,
    paginationModelRef,
    searchProducts,
    searchProductsByIds,
    searchProductsByEans,
    setPaginationFromUrl,
    getPaginationParams,
  });
}
