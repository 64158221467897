import type { BapiClient } from '@aboutyou/backbone';
import type { AxiosError } from 'axios';
import { mapProductAttributes, mapVariantAttributes } from '@/models/utils/productUtils';
import { handleAxiosError, errorHandler } from '@/api/utils';
import type {
  CartResponseData,
  GetCartParams,
  AddItemParams,
  UpdateItemParams,
  RemoveItemParams,
  CartLineItemResponseData,
} from '@/types/cart';
const API_NAME = 'AYC - cart';

function mapCartProductResponse(items: CartLineItemResponseData[]) {
  items.forEach((item: any) => {
    mapProductAttributes(item.product);
    mapVariantAttributes(item.variant);
  });
}

export function getCart(apiInstance: BapiClient, basketKey: string, params?: GetCartParams): Promise<CartResponseData> {
  return errorHandler<CartResponseData>(async () => {
    const data: any = (await apiInstance.basket.get(basketKey, params)).basket;
    mapCartProductResponse(data.items);
    return {
      data,
    };
  }).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getCart',
      basketKey,
      params,
    }),
  }));
}

export function addCartItem(
  apiInstance: BapiClient,
  basketKey: string,
  variantId: number,
  quantity?: number,
  params?: AddItemParams,
): Promise<CartResponseData> {
  return errorHandler<CartResponseData>(async () => {
    const result = await apiInstance.basket.addItem(basketKey, variantId, quantity, params);
    mapCartProductResponse(result.basket.items as any);
    if (result.type === 'failure') {
      return {
        data: result.basket,
        error: {
          code: constants.ERROR_CODE.CART.ITEM_NOT_ADDED,
        },
      };
    }

    return {
      data: result.basket,
    };
  }).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'addCartItem',
      basketKey,
      variantId,
      quantity,
      params,
    }),
  }));
}

export function updateCartItem(
  apiInstance: BapiClient,
  basketKey: string,
  itemKey: string,
  quantity: number,
  params?: UpdateItemParams,
): Promise<CartResponseData> {
  return errorHandler<CartResponseData>(async () => {
    const data: any = (await apiInstance.basket.updateItem(basketKey, itemKey, quantity, params)).basket;
    mapCartProductResponse(data.items);
    return {
      data,
    };
  }).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'updateCartItem',
      basketKey,
      itemKey,
      quantity,
      params,
    }),
  }));
}

export function removeCartItem(
  apiInstance: BapiClient,
  basketKey: string,
  itemKey: string,
  params?: RemoveItemParams,
): Promise<CartResponseData> {
  return errorHandler<CartResponseData>(async () => {
    const data: any = await apiInstance.basket.deleteItem(basketKey, itemKey, params);
    mapCartProductResponse(data.items);
    return {
      data,
    };
  }).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'removeCartItem',
      basketKey,
      itemKey,
      params,
    }),
  }));
}
