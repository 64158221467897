export default defineNuxtPlugin(() => {
  useRouter().beforeEach((to, from, next) => {
    if (
      to.path === from.path &&
      Object.keys(from.query || {}).length > 0 &&
      Object.keys(to.query || {}).length === 0 &&
      to.hash
    ) {
      // Make sure to keep the get params on anchor link click
      next({ path: to.path, query: from.query, hash: to.hash });
    }
    next();
  });
});
