<script setup lang="ts">
import { getElementUiObject, getLink, getMedia } from '@mop/cms/utils/utils';
import type { CmsUiGenderSwitch, CmsUiGenderSwitchItem } from './uiCmsGenderSwitch';

defineOptions({
  name: 'UiCmsGenderSwitch',
});

const props = defineProps({
  data: {
    type: Object as PropType<CmsUiGenderSwitch>,
    required: true,
  },
});

const data: any = props.data;

function getGenderSwitchItem(object: any): CmsUiGenderSwitchItem | undefined {
  if (!object) {
    return;
  }
  return getElementUiObject(object, {
    buttonText: object.buttonText,
    link: getLink(object.link),
    buttonColor: object.buttonColor?.color,
    backgroundColor: object.backgroundColor?.color,
    media: getMedia(object.media?.[0]),
  });
}

const element: CmsUiGenderSwitch = getElementUiObject(data, {
  left: getGenderSwitchItem(data.cards?.[0]),
  right: getGenderSwitchItem(data.cards?.[1]),
});
</script>

<template>
  <div v-if="element.left && element.right" class="ui-cms-gender-switch">
    <UiCmsGenderSwitchItem :item="element.left" :data-track-element-item="`${element.left.media?.trackingId}|1`" />

    <UiCmsGenderSwitchItem
      :item="element.right"
      class="ui-cms-gender-switch-item--right"
      :data-track-element-item="`${element.right.media?.trackingId}|2`"
    />
  </div>
</template>

<style lang="scss" scoped>
.ui-cms-gender-switch {
  display: flex;
  flex-wrap: wrap;
}
</style>
