import type { AxiosError, AxiosInstance } from 'axios';
import { handleAxiosError, errorHandler } from '@/api/utils';
import type { CustomerFeedbackParams } from '@/types/customer';
import type { StatusResponseData } from '@/types/status';
const API_NAME = 'AWS - deleted customer feedback';

export function createCustomerFeedback(
  apiInstance: AxiosInstance,
  params: CustomerFeedbackParams,
): Promise<StatusResponseData> {
  return errorHandler<StatusResponseData>(async () => ({
    data: (await apiInstance.post('/deletedcustomerfeedback', params)).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'createCustomerFeedback',
      payload: params,
    }),
  }));
}
