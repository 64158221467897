import { getThemes } from '@/models/utils/newsletterUtils';
import type {
  Birthday,
  CustomData,
  CustomerResponseData,
  ScoreData,
  CustomerGroup,
  CustomerBonusVoucherStep,
} from '@/types/customer';
import type { Theme } from '@/types/newsletter';

const localCache: {
  customerGroups?: CustomerGroup[];
} = {
  customerGroups: [],
};

export function customerModel(responseData: CustomerResponseData | null) {
  const response: CustomerResponseData = responseData ?? {};

  return {
    hasError(): boolean {
      return response.error !== undefined;
    },

    isTokenInvalid(): boolean {
      return response.error?.code === 403;
    },

    isInitialized(): boolean {
      return responseData !== null;
    },

    isRegistered(): boolean {
      return this.getReferenceKey() !== '';
    },

    isRegisterFinished(checkForBonusFlag = false): boolean {
      // Registration might still not complete finshed when reference key is available.
      // MemberSinceDate for example is still not set at the point of time.
      // Any other field might placed later then referenceKey, can be added here.
      // This function is only needed for new register, in other case, getReferenceKey is sufficient.
      return this.isRegistered() && this.getMemberSinceDate() !== '' && (!checkForBonusFlag || this.hasBonus());
    },

    getEmail(): string {
      return response.data?.email ?? '';
    },

    getTitle(): string {
      return response.data?.title ?? '';
    },

    getCustomData(): CustomData {
      return response.data?.customData ?? {};
    },

    // TODO: Add custom field 'country' for user in AYC
    getCountry(): string {
      return response.data?.country ?? '';
    },

    getCurrentBonusPoints(): number {
      return response.data?.customData?.currentBonusPoints || 0;
    },

    getMemberSinceDate(): string {
      return response.data?.customData?.memberSinceDate || '';
    },

    getRegistrationDate(): string {
      return response.data?.createdAt ?? '';
    },

    // CRM - bonus account number
    getPublicKey(): string {
      return response.data?.publicKey ?? '';
    },

    getBirthday(): Birthday {
      const birthday: string = response.data?.birthDate ?? '';
      let year = '';
      let month = '';
      let day = '';

      if (birthday !== '') {
        [year, month, day] = birthday.split('-');
        return {
          year,
          month: parseInt(month).toString(),
          day: parseInt(day).toString(),
        };
      }

      return {
        year,
        month,
        day,
      };
    },

    getFirstName(): string {
      return response.data?.firstName ?? '';
    },

    getFavoriteStoreId(): string {
      return response.data?.customData?.favoriteStoreId ?? '';
    },

    getScore(): ScoreData {
      return (
        response.data?.customData?.score || {
          arvatoResultCode: '',
          methods: [],
          result: '',
        }
      );
    },

    getId(): number | undefined {
      return response.data?.id ?? undefined;
    },

    getLastName(): string {
      return response.data?.lastName ?? '';
    },

    getFullName(): string {
      return [response.data?.firstName ?? '', response.data?.lastName ?? ''].join(' ');
    },

    getGender() {
      return (response.data?.gender ?? '') as 'm' | 'f' | '';
    },

    isGuest(): boolean {
      return !response.data || response.data?.status.isGuestCustomer;
    },

    getCustomerGroups(): CustomerGroup[] {
      if (localCache.customerGroups?.length) {
        return localCache.customerGroups;
      }

      const groups: CustomerGroup[] = [];

      if (this.hasError() || this.isGuest()) {
        return [constants.CUSTOMER_GROUPS.GUEST as CustomerGroup];
      }
      groups.push(constants.CUSTOMER_GROUPS.MEMBER as CustomerGroup);

      const hasBonus: boolean = this.hasBonus();
      const currentBonusPoints: number = this.getCurrentBonusPoints();

      // Hint: do not combine conditions, otherwise it will be hard to compare with business/concepts
      if (hasBonus && !this.isOptInMail()) {
        groups.push(constants.CUSTOMER_GROUPS.MEMBER_NO_MAIL_OPT_IN as CustomerGroup);
      }

      if (hasBonus && currentBonusPoints > 0 && !this.isOptInPost()) {
        groups.push(constants.CUSTOMER_GROUPS.MEMBER_NO_POST_OPT_IN as CustomerGroup);
      }

      if (hasBonus && currentBonusPoints > 0 && this.isOptInPost()) {
        groups.push(constants.CUSTOMER_GROUPS.MEMBER_WITH_POST_OPT_IN as CustomerGroup);
      }

      const daysAgo = 30;
      const compareTime: number = Date.now() - daysAgo * 24 * 60 * 60 * 1000;
      const hadRecentBonusPointsRedemption: boolean =
        new Date(this.getLastBonusRedemptionDate() || Date.now()).getTime() > compareTime;
      if (hasBonus && currentBonusPoints <= 0 && hadRecentBonusPointsRedemption) {
        groups.push(constants.CUSTOMER_GROUPS.MEMBER_BONUSPOINT_RESET as CustomerGroup);
      }

      if (!this.getFavoriteStoreId()) {
        groups.push(constants.CUSTOMER_GROUPS.MEMBER_NO_FAVORITE_STORE as CustomerGroup);
      }

      if (new Date(this.getRegistrationDate()) > new Date('2019-03-19')) {
        if (hasBonus) {
          groups.push(constants.CUSTOMER_GROUPS.MEMBER_WITH_BONUS_MIGRATION as CustomerGroup);
        } else {
          groups.push(constants.CUSTOMER_GROUPS.MEMBER_NO_BONUS_MIGRATION as CustomerGroup);
        }
      }

      if (this.hasFreeShipping()) {
        groups.push(constants.CUSTOMER_GROUPS.MEMBER_WITH_FREE_SHIPPING as CustomerGroup);
      }

      localCache.customerGroups = groups;
      return localCache.customerGroups;
    },

    getLastBonusRedemptionDate(): string {
      return response.data?.customData?.lastBonusPointsRedemptionDate ?? '';
    },

    hasInterestInMen(): boolean {
      return response.data?.customData?.isInterestedInMen === true;
    },

    hasInterestInWomen(): boolean {
      return response.data?.customData?.isInterestedInWomen === true;
    },

    isOptInMail(): boolean {
      return response.data?.customData?.isOptInMail === true;
    },

    isOptInPost(): boolean {
      return response.data?.customData?.isOptInPost === true;
    },

    hasFreeShipping(): boolean {
      // VKF = Versandkostenfrei = Free Shipping
      return response.data?.customData?.vkf === true;
    },

    getOptInThemes(filterOutNonGlobalEThemes: boolean): Array<Theme> {
      let themes: Array<Theme> = [];
      const responseData: any = response.data;

      if (responseData) {
        themes = getThemes(responseData.customData);
      }

      if (filterOutNonGlobalEThemes) {
        themes = themes.filter((theme) => !constants.NEWSLETTER_GLOBAL_E_DISABLED_THEME_IDS.includes(theme.id));
      }

      return themes;
    },

    getBasketKey(): string {
      return response.data?.customData?.basketKey ?? '';
    },

    getWishlistKey(): string {
      return response.data?.customData?.wishlistKey ?? '';
    },

    hasBonus(): boolean {
      return response.data?.customData?.hasBonus === true;
    },

    getRaffles(): string[] {
      return response.data?.customData?.raffles || [];
    },

    // use for production to suppress specific prod process or add additional logging
    isTest(): boolean {
      return Boolean(response.data?.status.isTestCustomer);
    },

    isActive(): boolean {
      return Boolean(response.data?.status.isActive);
    },

    // CRM id - (contact number)
    getReferenceKey(): string {
      return response.data?.referenceKey || '';
    },

    getPointsToNextStep(pointSteps: CustomerBonusVoucherStep[]): number {
      const customerPoints = this.getCurrentBonusPoints();
      const pointsNext = pointSteps.reduce((previous: number, current: CustomerBonusVoucherStep) => {
        return customerPoints >= previous && customerPoints < current.minBonuspoints
          ? current.minBonuspoints
          : previous;
      }, 0);
      return pointsNext > 0 ? pointsNext - customerPoints : 0;
    },
  };
}
