import type { Ref } from 'vue';
import { securedWrap } from '@mop/shared/utils/securedWrap';
import type { SeoBreadcrumb } from '@/types/cms';

type BreadcrumbsRef = Ref<SeoBreadcrumb[]>;

type HeaderStorage = {
  breadcrumbsRef: BreadcrumbsRef;
};

export function useMopHeader() {
  const storage = initStorage<HeaderStorage>('useHeader');

  const breadcrumbsRef: BreadcrumbsRef =
    storage.get('breadcrumbsRef') ?? storage.saveAndGet('breadcrumbsRef', useMopSSR('breadcrumbs', []));

  function setHeaderBreadcrumbs(breadcrumbs: SeoBreadcrumb[] = []) {
    breadcrumbsRef.value = breadcrumbs;
  }

  return securedWrap({
    breadcrumbsRef,
    setHeaderBreadcrumbs,
  });
}
