import type { Ref } from 'vue';
import { securedWrap } from '@mop/shared/utils/securedWrap';
import { productListModel, productModel } from '@/models';
import type {
  ProductSearchParameter,
  ProductData,
  BackInStockNotificationParameters,
  ProductResponseData,
  ProductModel,
  ProductModelList,
  ProductsSearchParameter,
  ProductsSearchResponseData,
  StockResponseParams,
} from '@/types/product';

type LoadingState = {
  searchProductById: boolean;
  loading: boolean;
};

function getProductFromList(response: ProductsSearchResponseData | null): ProductModel {
  if (!response) {
    return productModel(null);
  }
  const list: ProductModelList = productListModel({
    data: response.data?.entities,
  });
  return list.getProductModelList()[0] || productModel({ error: { code: constants.ERROR_CODE.NOT_FOUND } });
}
export default function useMopProduct(cacheId?: string) {
  const loadingRef: Ref<LoadingState> = ref({
    searchProductById: false,
    loading: computed(() => isLoading(loadingRef)),
  });
  const { $apiAws, $apiBackbone, $mopConfig } = useNuxtApp();
  const { isSaleEnabled, getSalePromotionKey, showSaleCallOutMessage } = useMopPromotions();
  const productSearchResponseRef = useMopSSR<ProductsSearchResponseData | null>(`product-search-${cacheId}`, null);
  const productResponseRef = useMopSSR<ProductResponseData | null>(`product-${cacheId}`, null);
  const productModelRef = ref(
    productResponseRef.value
      ? productModel(productResponseRef.value)
      : getProductFromList(productSearchResponseRef.value),
  );

  function registerBackInStockNotification(payload: BackInStockNotificationParameters) {
    return $apiAws.registerBackInStockNotification(payload);
  }

  function getProductStock(payload: StockResponseParams) {
    return $apiAws.getStockData(payload);
  }

  function enrichProductWithCmsData(productResponse: ProductResponseData): ProductResponseData {
    if (productResponse.data) {
      enrichProductResponseWithCmsData(productResponse.data, $mopConfig);
    }
    return productResponse;
  }

  function enrichProductSearchWithCmsData(
    productSearchResponse: ProductsSearchResponseData,
  ): ProductsSearchResponseData {
    productSearchResponse.data?.entities?.forEach((productData: ProductData) => {
      enrichProductResponseWithCmsData(productData, $mopConfig);
    });
    return productSearchResponse;
  }
  async function searchProductByKey(
    mopProductId: string,
    searchParams: ProductsSearchParameter,
    forceClientRefetch = false,
    idKey = 'referenceKey',
  ) {
    if (!mopProductId) {
      return;
    }
    loadingRef.value.searchProductById = true;
    const params: ProductsSearchParameter = {
      ...searchParams,
    };
    params.where = params.where || {
      attributes: [
        {
          type: 'attributes',
          key: idKey as any,
          values: [mopProductId],
        },
      ],
    };
    if (searchParams?.pricePromotionKey === undefined && isSaleEnabled('promoQualifier') && showSaleCallOutMessage()) {
      params.pricePromotionKey = getSalePromotionKey();
    }
    if (forceClientRefetch || params.pricePromotionKey) {
      productSearchResponseRef.value = null;
    }
    productSearchResponseRef.value ??= enrichProductSearchWithCmsData(await $apiBackbone.queryProducts(params));
    productModelRef.value = getProductFromList(productSearchResponseRef.value);
    loadingRef.value.searchProductById = false;
  }

  async function searchProductById(id: string, searchParams: ProductsSearchParameter, forceClientRefetch = false) {
    await searchProductByKey(id, searchParams, forceClientRefetch);
  }
  async function searchProductByEan(ean: string, searchParams: ProductsSearchParameter, forceClientRefetch = false) {
    await searchProductByKey(ean, searchParams, forceClientRefetch, 'ean');
  }

  /**
   * Faster way to fetch product
   */
  async function searchProductByRefId(
    mopProductId: string,
    searchParams: ProductSearchParameter,
    forceClientRefetch = false,
  ) {
    if (!mopProductId) {
      return;
    }
    loadingRef.value.searchProductById = true;
    const params: ProductSearchParameter = {
      ...searchParams,
    };
    if (searchParams?.pricePromotionKey === undefined && isSaleEnabled('promoQualifier') && showSaleCallOutMessage()) {
      params.pricePromotionKey = getSalePromotionKey();
    }
    if (forceClientRefetch || params.pricePromotionKey) {
      productResponseRef.value = null;
    }
    productResponseRef.value ??= enrichProductWithCmsData(await $apiBackbone.getProduct(mopProductId, params));
    // Sometimes bapi returns multiple products for single ref key and backbone SDK throws
    // original error 'Got 2 products for a single referenceKey'
    if (!productResponseRef.value?.error) {
      productModelRef.value = productModel(productResponseRef.value);
    } else {
      // retry to fetch same product using search
      await searchProductById(mopProductId, searchParams as ProductsSearchParameter, forceClientRefetch);
    }

    loadingRef.value.searchProductById = false;
  }

  return securedWrap({
    productModelRef,
    searchProductById,
    searchProductByEan,
    searchProductByRefId,
    registerBackInStockNotification,
    getProductStock,
    loadingRef,
  });
}
