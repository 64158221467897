import type { CartResponseData, CartLineItemModel, CartLineItemResponseData } from '@/types/cart';
import type { Availability, GiftCard, VariantModel } from '@/types/product';
import { cartLineItemModel } from '@/models';
import { getPrice } from '@/models/utils/priceUtils';

export function cartModel(responseData: CartResponseData | null) {
  const response: CartResponseData = responseData ?? {};

  let lineItems: CartLineItemModel[] = [];
  const soldOutlineItems: CartLineItemModel[] = [];
  let subTotal = 0;
  let totalDiscount = 0;
  let isBackOrder = false;
  let isSoldOut = true;
  let totalItemCount = 0;
  let totalItemCountExcludingGiveaway = 0;
  let giftCardProductCount = 0;

  if (response.data?.items !== undefined) {
    response.data.items.forEach((item: CartLineItemResponseData) => {
      const lineItem: CartLineItemModel = cartLineItemModel(item);
      const { basePrice, salesPrice } = lineItem.getTotalPrice();
      const variant: VariantModel = lineItem.getVariant();
      const availability: Availability = variant.getAvailability();

      totalItemCount += lineItem.getQuantity();
      if (!variant.isGiveaway()) {
        totalItemCountExcludingGiveaway += lineItem.getQuantity();
      }
      if (availability.isInStock) {
        isSoldOut = false;
        lineItems.push(lineItem);
      } else {
        soldOutlineItems.push(lineItem);
      }
      if (availability.isInStock || availability.isComingSoon) {
        subTotal += basePrice;
        totalDiscount += salesPrice - basePrice;
      }
      if (!isBackOrder) {
        isBackOrder = availability.isBackOrder;
      }
      if (lineItem.isGiftCard()) {
        giftCardProductCount += 1;
      }
    });

    // ensure sold out items are always in the bottom
    lineItems = lineItems.concat(soldOutlineItems);
  }

  return {
    hasError(): boolean {
      return response.error !== undefined;
    },

    hasErrorItemExists(): boolean {
      return response.error?.code !== constants.ERROR_CODE.CART.ITEM_NOT_ADDED;
    },

    getCount(includeGiveAway = true): number {
      return includeGiveAway ? totalItemCount : totalItemCountExcludingGiveaway;
    },

    getCurrency(): string {
      return response.data?.cost?.currencyCode ?? '';
    },

    getId(): string {
      return response.data?.key as string;
    },

    getLineItems(): CartLineItemModel[] {
      return lineItems;
    },

    getSubTotal(): number {
      return subTotal;
    },

    getTotalDiscount(): number {
      return totalDiscount;
    },

    getTotal(shippingCost = 0): number {
      return getPrice(response.data?.cost).salesPrice + shippingCost;
    },

    isBackOrder(): boolean {
      return isBackOrder;
    },

    isGiftCardOnlyOrder(): boolean {
      return giftCardProductCount > 0 && this.getCount(false) === giftCardProductCount;
    },

    getGiftCard(): CartLineItemModel | undefined {
      if (!giftCardProductCount) {
        return;
      }
      return this.getLineItems().find((item) => item.isGiftCard());
    },

    getGiftCardMessage(): GiftCard | undefined {
      return this.getGiftCard()?.getGiftMessage();
    },

    hasPrintGiftCard(): boolean {
      return giftCardProductCount > 0 && this.getGiftCardMessage()?.deliveryMethod === 'print';
    },

    hasMixOfPrintGiftCardAndProducts(): boolean {
      return this.hasPrintGiftCard() && !this.isGiftCardOnlyOrder();
    },

    isSoldOut(): boolean {
      return isSoldOut;
    },

    hasSomeSoldOut(): boolean {
      return soldOutlineItems.length > 0;
    },

    getLineItemByAyId(id: number): CartLineItemModel | undefined {
      return this.getLineItems().find((lineItem: CartLineItemModel) => {
        return lineItem.getVariant().getId() === id;
      });
    },

    getDebugData(): any {
      return response;
    },
  };
}
