import type { AxiosError, AxiosInstance } from 'axios';
import { handleAxiosError, errorHandler } from '@/api/utils';
import type { CustomerSupportEmailParams } from '@/types/customer';
import type { StatusResponseData } from '@/types/status';
const API_NAME = 'AWS - support email';

export function createSupportEmail(
  apiInstance: AxiosInstance,
  params: CustomerSupportEmailParams,
): Promise<StatusResponseData> {
  return errorHandler<StatusResponseData>(async () => ({
    data: (await apiInstance.post('/support', params)).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'createSupportEmail',
      params,
    }),
  }));
}
