<script setup lang="ts">
import type { ObserveVisibilityConfig } from '@mop/types';
import { getElementUiObject } from '@mop/cms/utils/utils';
import type { CmsUiTextCarousel } from './uiCmsTextCarousel';

defineOptions({
  name: 'UiCmsTextCarousel',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const data: any = props.data;

const element: CmsUiTextCarousel = getElementUiObject(data, {
  html: data.html,
  textColor: data.textColor?.color,
  backgroundColor: data.backgroundColor?.color,
});

const uiStyle = {
  '--color': element.textColor,
  backgroundColor: element.backgroundColor,
};
const pausedClassRef = ref('');

const visibilityConfig: ObserveVisibilityConfig = {
  callback: (isVisible) => {
    pausedClassRef.value = isVisible ? '' : 'ui-cms-text-carousel__loop--paused';
  },
  rootMargin: '0px 0px 0px 0px',
};
</script>

<template>
  <!-- TODO: find a better solution for v-html - try with a list -->
  <div class="ui-cms-text-carousel-wrapper">
    <div
      v-if="element.html"
      v-observe-visibility="visibilityConfig"
      v-storyblok-editable="element"
      :style="uiStyle"
      class="ui-cms-text-carousel"
      :data-track-element-item="'HTMLCode'"
    >
      <div :class="['ui-cms-text-carousel__loop', pausedClassRef]">
        <div v-for="n in 3" :key="n" class="ui-cms-text-carousel__loop-item" v-html="element.html" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@keyframes leftTransition {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.ui-cms-text-carousel-wrapper {
  position: relative;
  width: 100%;
  height: 32px;
}

.ui-cms-text-carousel {
  @include text-style(headline-condensed);

  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0 $space15;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  display: flex;

  :deep(.big-letter-space) {
    padding: 0 0.4em;
    letter-spacing: 0.4em;
  }
}

.ui-cms-text-carousel__loop {
  display: flex;
  flex-flow: row nowrap;
  align-self: center;
}

.ui-cms-text-carousel__loop-item {
  will-change: transform;
  animation-duration: 18s;
  animation-name: leftTransition;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-timing-function: linear;
}

.ui-cms-text-carousel__loop--paused {
  .ui-cms-text-carousel__loop-item {
    animation-play-state: paused;
  }
}
</style>
