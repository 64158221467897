import type { FilterValue, FilterValuesResponseData } from '@/types/filters';

export function filterValueModel(valueResponseData: FilterValue) {
  return {
    getId(): number | undefined {
      return valueResponseData.id || undefined;
    },
    getName(): string {
      return valueResponseData.name?.toString() || '';
    },
    getValue(): string {
      return valueResponseData.value?.toString() || '';
    },
    /**
     * "normal" / attribute filters have an id (number), boolean filters have a string value 'true' or 'false' in name
     * In both cases the filterKey can be used to identify and compare values.
     */
    getFilterKey(): string {
      return this.getId()?.toString() || this.getName() || '';
    },
    getProductCount(): number {
      return valueResponseData.productCount || 0;
    },
    getMin(): number {
      return valueResponseData.min || 0;
    },
    getMax(): number {
      return valueResponseData.max || 0;
    },
    getMinPrice(): number {
      return this.getMin() / 100;
    },
    getMaxPrice(): number {
      return this.getMax() / 100;
    },
  };
}

export function filterValuesModel(responseData: FilterValuesResponseData) {
  const response: FilterValuesResponseData = responseData;
  return response?.values.map((value: FilterValue) => filterValueModel(value)) || [];
}
