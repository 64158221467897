<script setup lang="ts">
defineOptions({
  name: 'UiNuxtLink',
});

const { componentName, ...attributes } = useAttrs();
// don't ask.. don't change!!
const componentToDisplay = componentName === 'NuxtLink' ? resolveComponent('NuxtLink') : String(componentName || 'div');
</script>

<template>
  <component :is="componentToDisplay" v-bind="attributes" no-prefetch>
    <slot />
  </component>
</template>
