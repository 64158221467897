/**
 * Detailed redirect and country selector logic described here:
 * https://marc-o-polo.atlassian.net/wiki/spaces/AYC/pages/1374888220/Global+Redirect+Handling
 */
import type { LocationQuery, RouteLocationNormalizedLoaded } from 'vue-router';
import type { ProductSearchQuery } from '@aboutyou/backbone/types/ProductSearchQuery';
import { isClient } from '@mop/shared/utils/util';
import { getLink } from '@mop/cms/utils/utils';
import type { CmsContentElementModel } from '@/types/cms';
import type { ProductModel } from '@/types/product';

type RedirectScope = {
  validateScopeByLegacyProduct?: boolean;
  validateScopeByRoot?: boolean;
  validateScopeByPath?: boolean;
  validateScopeByConfiguration?: boolean;
  validateScopeByMapping?: boolean;
  validateScopeByCustomer?: boolean;
  validateScopeByCustom?: boolean;
};

type Redirect = {
  source: string;
  destination: string;
  redirectCode: number;
};

type RedirectPaths = {
  PRODUCT_REDIRECT: string;
  CATEGORY_REDIRECT: string;
  PAGE_REDIRECT: string;
  EXTERNAL_REDIRECT: string;
};

export async function redirectHandler(
  route: RouteLocationNormalizedLoaded,
  scope: RedirectScope = {},
): Promise<boolean> {
  const { $mopI18n, $mopConfig, $urls } = useNuxtApp();
  const config = useRuntimeConfig();
  const requestEvent = useRequestEvent();
  const isRoot = route.path === '/';
  const baseUrl = isClient ? '' : config.public.BASE_URL;
  const validateScopeByLegacyProduct = scope.validateScopeByLegacyProduct ?? true;
  const validateScopeByRoot = scope.validateScopeByRoot ?? true;
  const validateScopeByPath = scope.validateScopeByPath ?? true;
  const validateScopeByConfiguration = !isClient && (scope.validateScopeByConfiguration ?? true);
  const validateScopeByMapping = scope.validateScopeByMapping ?? true;
  const validateScopeByCustomer = isClient && (scope.validateScopeByCustomer ?? true);
  const { locale } = $mopI18n;
  const localeCode = locale;
  const splitPath = route.path.split('/');
  const targetParameter = splitPath[splitPath.length - 1];
  const { cmsStoryModelRef, getCmsStoryByConfigId } = useMopCms(targetParameter);
  const { getCategoryByPropertyName } = useMopCategoryTree();
  const { initRedirects, cmsRedirectModelRef } = useMopCmsGlobalComponents();
  const { searchProductById, productModelRef } = useMopProduct();
  const { customerModelRef } = useMopCustomer();
  const router = useRouter();

  if (validateScopeByRoot && isRoot) {
    return true;
  }

  const containsLocale = /^\/[a-z]{2}-[a-z]{2}((\/.*$)|$)/gi.test(route.path);
  if (!containsLocale) {
    if (isClient) {
      // Handle client urls without locale
      router.replace($mopI18n.localePath(route.fullPath));
    }
    return true;
  }

  if (route.path.includes($urls.STORE_RESERVATION)) {
    if ($mopConfig && $mopConfig?.isReserveAndCollectEnabled() !== true) {
      return redirectToHome();
    }
  }

  // enforce redirect to finish registration in whole my account area
  if (validateScopeByCustomer) {
    const { requiresRegistration = false, requiresAuth = false } = route.meta;
    // exclude processing for thank you page redirect with new login=1 param
    if (route.path.includes(`/${URLS.CHECKOUT_SUCCESS}`)) {
      return false;
    }
    // setting manually a logout query param for client to pick up in appInit and clean cookies
    if (route.path.includes(`/${URLS.LOGOUT}`)) {
      // redirect handled in auth-redirect component
      return false;
    }
    const redirectedFromCheckout = isRedirectedFromCheckout(route) && !route?.name?.toString()?.includes('account');

    const customer = customerModelRef.value;

    /**
     * If you log in during the AYC checkout and you return to the shop, you should be logged in there too. To make
     * this possible we have to redirect to the account page. Accepted caveat: You are redirected to the registration
     * page if you weren't logged in and haven't logged in during the checkout.
     */
    if (redirectedFromCheckout && !customer.isRegistered()) {
      redirectTo(constants.REDIRECT_CODES.FOUND_REDIRECT, `${baseUrl}/${localeCode}/${URLS.ACCOUNT}`, route.query);
      return true;
    }

    if (redirectedFromCheckout && customer.isRegistered()) {
      return redirectToHome(false);
    }

    if (requiresRegistration && !customer.isRegistered()) {
      redirectTo(
        constants.REDIRECT_CODES.FOUND_REDIRECT,
        `${baseUrl}/${localeCode}/${URLS.ACCOUNT_REGISTER}`,
        route.query,
      );
      return true;
    }

    // prevent entering registration page for registered customers
    if (requiresAuth && !requiresRegistration && customer.isRegistered()) {
      redirectTo(constants.REDIRECT_CODES.FOUND_REDIRECT, `${baseUrl}/${localeCode}/${URLS.ACCOUNT}`, route.query);
      return true;
    }

    if (requiresAuth && customer.isRegistered() && route.query.gecheckoutredirect === '1') {
      window.location.href = `${baseUrl}/${localeCode}/${URLS.CHECKOUT_GLOBAL_E}`;
      return true;
    }
  }
  if (validateScopeByConfiguration && (await isConfiguredRedirect())) {
    return true;
  }
  if (validateScopeByMapping && isMappedRedirect()) {
    return true;
  }
  if (validateScopeByLegacyProduct && (await isLegacyRedirect())) {
    return true;
  }
  // check for to-* redirects
  if (validateScopeByPath && isRedirectRoutePath(route.path)) {
    const redirectPaths: RedirectPaths = constants.REDIRECT_PATHS;
    if (route.path.includes(redirectPaths.PRODUCT_REDIRECT)) {
      return redirectToProduct();
    } else if (route.path.includes(`${redirectPaths.CATEGORY_REDIRECT}/`)) {
      return redirectToCategory();
    } else if (route.path.includes(`${redirectPaths.PAGE_REDIRECT}/`)) {
      return redirectToPage();
    } else if (route.path.includes(`${redirectPaths.EXTERNAL_REDIRECT}/`)) {
      return redirectToExternal();
    }
    return false;
  }

  return false;

  // Helper methods

  function getRedirectFromContentElement(data: CmsContentElementModel): Redirect | undefined {
    const element = data.getData();
    if (!element || element.isEnabled === 'no') {
      return;
    }

    const removeSlashAndLocale = /^[/]?[a-z]{2}-[a-z]{2}\//gi;
    const destinationLink = getLink(element.destination);
    const source: string = (getLink(element.source).to || '').replace(removeSlashAndLocale, '');
    const destination: string = (destinationLink.to || destinationLink.href || '').replace(removeSlashAndLocale, '');
    if (!source || !destination) {
      return;
    }

    return {
      source,
      destination,
      redirectCode: parseInt(element.redirectType),
    };
  }

  async function isConfiguredRedirect(): Promise<boolean> {
    if (isClient) {
      return false;
    }
    await initRedirects();
    if (cmsRedirectModelRef.value?.hasError() || !cmsRedirectModelRef.value?.isInitialized()) {
      return false;
    }

    const redirects = cmsRedirectModelRef.value
      .getContentElements('redirects')
      .reduce((list: Redirect[], item: CmsContentElementModel) => {
        const redirect = getRedirectFromContentElement(item);
        if (redirect) {
          list.push(redirect);
        }
        return list;
      }, []);

    const foundRedirect = redirects.find((item) => {
      if (item.source.includes('?')) {
        return route.fullPath.startsWith(`/${localeCode}/${item.source}`);
      }
      return route.path === `/${localeCode}/${item.source}`;
    });

    if (!foundRedirect) {
      return false;
    }
    if (foundRedirect.destination.startsWith('http')) {
      redirectTo(foundRedirect.redirectCode, foundRedirect.destination, route.query);
    } else {
      redirectTo(foundRedirect.redirectCode, `${baseUrl}/${localeCode}/${foundRedirect.destination}`, route.query);
    }
    return true;
  }

  function isMappedRedirect() {
    // comment out the code below the return if ever need the logic
    return false;

    // if (isClient) {
    //   return false;
    // }

    // try {
    //   const { redirects } = await import(/* @vite-ignore */ `@/redirects/${lang}/index.ts`);
    //   if (!redirects) {
    //     return false;
    //   }
    //   const currentPathWithoutLocale = route.path.substring(7);
    //   const destinationPath: string = redirects[currentPathWithoutLocale];
    //   if (!destinationPath || currentPathWithoutLocale === destinationPath) {
    //     return false;
    //   }
    //   redirectTo(constants.REDIRECT_CODES.PERMANENT_REDIRECT, `${baseUrl}/${localeCode}/${destinationPath}`, route.query);
    //   return true;
    // } catch (err) {
    //   // Do nothing (i.e. for italy and spain)
    // }
    // return false;
  }

  async function isLegacyRedirect(): Promise<boolean> {
    if (
      !(route.name ??= '').toString().includes('product-legacy-page') &&
      !(route.name ??= '').toString().includes('variant-legacy-page')
    ) {
      return false;
    }

    // Check if legacy landing page
    const pathWithoutLocaleAndHtml = route.path.substring(6).slice(0, -5);
    if (pathWithoutLocaleAndHtml.startsWith('/list') || pathWithoutLocaleAndHtml.startsWith('/inspiration')) {
      const redirectPath = pathWithoutLocaleAndHtml.startsWith('/list')
        ? pathWithoutLocaleAndHtml.substring(5)
        : pathWithoutLocaleAndHtml.substring(12);
      redirectTo(
        constants.REDIRECT_CODES.PERMANENT_REDIRECT,
        `${baseUrl}/${localeCode}/inspiration${redirectPath}`,
        route.query,
      );
      return true;
    }

    // Check if legacy product
    let masterProductId = /(\d|\w{1}\d|\d+-\d)+(_.+)?$/gi.exec(route.params.id.toString())?.[0];

    const colorKey = Object.keys(route.query).find((key: string) => key.endsWith('color'));
    let color: string | undefined;
    if (colorKey) {
      color = route.query[colorKey] as string;
      if (!masterProductId) {
        // Try to get masterProductId from color key
        // Example url: /de-de/on/demandware.store/Sites-MOP-Site/de_DE/Product-Variation?dwvar_160901112072_color=P09&dwvar_160901112072_size=31%2B%2B34&pid=160901112072
        masterProductId = colorKey.replace('dwvar_', '').replace('_color', '');
      }
    }

    // Covering cases such as (old master ids in url): 182413018001, M21010810064, 730090-300
    if (!/(\w{1}\d|\d+-\d)+/gi.exec(masterProductId || '')?.[0]) {
      return false;
    }
    let product: ProductModel | null = null;
    if (masterProductId && color) {
      product = await searchProduct(`${masterProductId}_${color}`);
    } else if (masterProductId && !color) {
      product = await searchProduct(masterProductId, 'styleKey');
      if (!product || product.hasError()) {
        // Some legacy marketing urls still use ean, instead of master
        product = await searchProduct(masterProductId, 'ean');
      }
    }
    if (!product || product.hasError()) {
      return notFound();
    }
    redirectTo(constants.REDIRECT_CODES.PERMANENT_REDIRECT, `${baseUrl}/${localeCode}${product.getUrl()}`, route.query);
    return true;
  }

  /**
   * Supports redirects to master and to product
   */
  async function redirectToProduct(): Promise<boolean> {
    if (!targetParameter) {
      return notFound();
    }
    const isMasterProduct = !targetParameter.includes('_');
    const product = isMasterProduct
      ? await searchProduct(targetParameter, 'styleKey')
      : await searchProduct(targetParameter);

    if (product.hasError()) {
      return notFound();
    }
    redirectTo(constants.REDIRECT_CODES.PERMANENT_REDIRECT, `${baseUrl}/${localeCode}${product.getUrl()}`, route.query);
    return true;
  }

  /**
   * Redirects to category using internal mopId
   */
  function redirectToCategory(): boolean {
    if (!targetParameter) {
      return notFound();
    }
    const category = getCategoryByPropertyName('mopId', targetParameter);
    if (!category) {
      return notFound();
    }
    redirectTo(
      constants.REDIRECT_CODES.PERMANENT_REDIRECT,
      `${baseUrl}/${localeCode}${category.getPath()}`,
      route.query,
    );
    return true;
  }

  async function redirectToPage(): Promise<boolean> {
    await getCmsStoryByConfigId('customId', targetParameter);
    if (cmsStoryModelRef.value?.hasError() || !cmsStoryModelRef.value?.isInitialized) {
      return notFound();
    }
    redirectTo(
      constants.REDIRECT_CODES.PERMANENT_REDIRECT,
      `${baseUrl}/${cmsStoryModelRef.value.getFullSlug()}`,
      route.query,
    );
    return true;
  }

  function redirectToExternal(): boolean {
    const regex = new RegExp(`.*?${constants.REDIRECT_PATHS.EXTERNAL_REDIRECT}/`, 'i');
    const externalUrl = route.path.replace(regex, '');
    if (!externalUrl.startsWith('http')) {
      return false;
    }
    if (isClient) {
      setTimeout(() => {
        // Otherwise the customer would end up on error page
        window.history.back();
      }, 200);
      window.open(externalUrl, '_blank');
      return true;
    }
    redirectTo(constants.REDIRECT_CODES.PERMANENT_REDIRECT, externalUrl);
    return true;
  }

  function redirectToHome(withQuery = true, queryParams?: LocationQuery): boolean {
    const { homeUrlRef } = useMopRouter();
    redirectTo(constants.REDIRECT_CODES.FOUND_REDIRECT, homeUrlRef.value, withQuery ? route.query : queryParams);
    return true;
  }

  function notFound() {
    redirectTo(
      constants.REDIRECT_CODES.PERMANENT_REDIRECT,
      `${baseUrl}/${localeCode}/${URLS.REDIRECT_NOT_FOUND}?origin=${encodeURIComponent(route.fullPath)}`,
    );
    return true;
  }

  async function searchProduct(id: string, byAttribute?: 'ean' | 'styleKey'): Promise<ProductModel> {
    let where: ProductSearchQuery | undefined;
    if (byAttribute) {
      where = {
        attributes: [
          {
            type: 'attributes',
            key: byAttribute as any,
            values: [id],
          },
        ],
      };
    }
    await searchProductById(
      id,
      {
        where,
        with: {
          advancedAttributes: {
            withKey: ['slug'],
          },
        },
      },
      true,
    );
    return productModelRef.value;
  }

  function redirectTo(responseStatusCode: number, path: string, query?: any) {
    const redirectPath = path.replace(/\/$/, '');
    let finalUrl = redirectPath;
    if (query) {
      delete query[AB_TEST.QUERY_NAME];
    }
    if (query && JSON.stringify(query) !== '{}') {
      finalUrl += '?' + new URLSearchParams(query).toString();
    }

    if (isClient) {
      router.push({ path, query });
    } else {
      const { res } = requestEvent.node;
      res.statusCode = responseStatusCode;
      res.setHeader('location', finalUrl);
      res.end();
    }
  }
}
