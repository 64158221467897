<script setup lang="ts">
import { computed } from 'vue';
import type { UiPrice } from '@mop/ui2/types';
import useUi2Config from '@mop/ui2/composables/useUi2Config';

defineOptions({
  name: 'Ui2EcomPrice',
});

const props = defineProps({
  price: {
    type: Object as PropType<UiPrice | undefined>,
    required: true,
  },
  showSaving: {
    type: Boolean,
    default: false,
  },
  showPrice: {
    type: Boolean,
    default: false,
  },
  disableHighlight: {
    type: Boolean,
    default: false,
  },
  showGlobalE: {
    type: Boolean,
    default: false,
  },
});

const { isSaleEnabled, isSavingLabelEnabled, isGlobalEEnabled } = useUi2Config();

const priceState = computed(() => {
  const { salesPrice, basePrice, discountPercentage, currency } = props.price ?? ({} as UiPrice);
  const isSale = salesPrice !== basePrice;
  const showSaleHighlighted = isSale && !props.disableHighlight && isSaleEnabled();
  const showSavingLabel = isSale && isSavingLabelEnabled();

  return {
    salesPrice,
    basePrice,
    isSale,
    showSaleHighlighted,
    showSavingLabel,
    currency,
    discountPercentage,
  };
});

const showGlobalEBasketData = isGlobalEEnabled() && props.showGlobalE;
const showGlobalEPriceData = isGlobalEEnabled() && !props.showGlobalE;
</script>

<template>
  <span
    :class="[
      'ui-price',
      {
        'ui-price--sale': priceState.isSale,
        'ui-price--highlighted-sale': priceState.showSaleHighlighted,
      },
    ]"
  >
    <span
      v-show="showPrice"
      class="ui-price__price"
      :data-ge-price="showGlobalEPriceData ? '' : undefined"
      :data-ge-basket-productsaleprice="showGlobalEBasketData ? '' : undefined"
    >
      {{
        $ui2Config.formatPrice({
          price: priceState.salesPrice,
          currency: priceState.currency,
        })
      }}
    </span>

    <span
      v-show="showPrice && priceState.isSale"
      class="ui-price__original"
      :data-ge-price="showGlobalEPriceData ? '' : undefined"
      :data-ge-basket-productlistprice="showGlobalEBasketData ? '' : undefined"
    >
      {{
        $ui2Config.formatPrice({
          price: priceState.basePrice,
          currency: priceState.currency,
        })
      }}
    </span>
    <span
      v-show="showSaving && priceState.showSavingLabel"
      class="ui-price__saving"
      :data-ge-basket-productdiscountsprice="showGlobalEBasketData ? '' : undefined"
    >
      -{{ priceState.discountPercentage }}%
    </span>
  </span>
</template>

<style scoped lang="scss">
.ui-price {
  @include v2-text-style(sm, highlight);
  color: $color-text-body-primary;
  display: flex;
  flex-wrap: wrap;
  gap: $space-8;
  align-items: center;

  @include v2-apply-upto(tablet) {
    @include v2-text-style(xs, highlight);
    gap: $space-4;
  }
}

.ui-price__original {
  @include v2-text-style(sm);
  color: $color-text-body-secondary;
  text-decoration: line-through;
  display: inline-flex;

  @include v2-apply-upto(tablet) {
    @include v2-text-style(xs);
  }
}

.ui-price__saving {
  @include v2-text-style(sm);
  border-radius: $border-radius-rounded-md;
  border: 1px solid currentColor;
  padding: 0 $space-4;

  @include v2-apply-upto(tablet) {
    @include v2-text-style(xs);
  }
}

.ui-price--highlighted-sale {
  .ui-price__price,
  .ui-price__saving {
    color: $color-text-sale;
  }
}
</style>
