import type { AxiosError, AxiosInstance } from 'axios';
import { handleAxiosError, errorHandler } from '@/api/utils';
import type { RecommendationResponseData, EpoqParams } from '@/types/recommendation';
const API_NAME = 'Epoq';

export function getRecommendation(apiInstance: AxiosInstance, params: EpoqParams): Promise<RecommendationResponseData> {
  return errorHandler<RecommendationResponseData>(async () => ({
    data: (
      await apiInstance.get('/getRecommendations', {
        headers: {
          'Content-Type': 'application/json',
        },
        // Keep data parameter, otherwise Content-Type will not be sent (used to fix CORS)
        data: {},
        params,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getRecommendation',
      params,
    }),
  }));
}
