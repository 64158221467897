<script setup lang="ts">
import SVGRight from '@mop/shared/images/arrow/right.svg?component';
import type { CmsAccountOverviewCard } from '@/types/cms';

defineOptions({
  name: 'MopCmsAccountOverviewCard',
});

const props = defineProps({
  data: {
    type: Object as PropType<CmsAccountOverviewCard>,
    required: true,
  },
  lazyLoad: {
    type: Boolean,
    default: false,
  },
});

const { $mopConfig } = useNuxtApp();

const {
  headline = { text: '', isUppercase: false },
  hasBorder,
  subline,
  description = '',
  link = {},
  icon,
  backgroundColor = 'transparent',
  media,
  hideMobile,
  hideForNoLoyalty,
  textColor = 'black',
} = props.data;

const isVisible = !hideForNoLoyalty || $mopConfig.isLoyaltyProgramEnabled();

if (headline.text.includes('{name}')) {
  const { customerModelRef } = useMopCustomer();
  headline.text = headline.text.replace('{name}', customerModelRef.value.getFullName());
}
</script>

<template>
  <UiNuxtLink
    v-if="isVisible"
    v-storyblok-editable="data"
    v-bind="link"
    :style="`--background-color: ${backgroundColor}; --text-color: ${textColor}`"
    :class="[
      'mop-cms-account-overview-card',
      {
        'mop-cms-account-overview-card--has-border': hasBorder,
        'mop-cms-account-overview-card--hide-mobile': hideMobile,
        'mop-cms-account-overview-card--taller': !hasBorder,
        'mop-cms-account-overview-card--hoverable': link.to,
      },
    ]"
  >
    <div class="mop-cms-account-overview-card__container">
      <UiCmsMedia v-if="media" :data="media" :lazy="lazyLoad" class="mop-cms-account-overview-card__background-image" />

      <UiImage v-if="icon" class="mop-cms-account-overview-card__icon" :image="icon" :alt="icon.alt" no-background />
      <div class="mop-cms-account-overview-card__content">
        <div
          :class="[
            'mop-cms-account-overview-card__headline',
            {
              'mop-cms-account-overview-card__headline--uppercased': headline.isUppercase,
            },
          ]"
        >
          {{ headline.text }}
        </div>
        <div v-if="description.length > 0" class="mop-cms-account-overview-card__description">
          {{ description }}
        </div>
        <div class="mop-cms-account-overview-card__subline">
          {{ subline }}
          <SVGRight v-if="link.to" class="mop-cms-account-overview-card__arrow" width="18" height="18" />
        </div>
      </div>
    </div>
  </UiNuxtLink>
</template>

<style lang="scss" scoped>
$card-border-color: rgb(151, 151, 151);

.mop-cms-account-overview-card {
  background-color: var(--background-color);
}

.mop-cms-account-overview-card__container {
  padding-bottom: 50%;
  position: relative;

  @include apply-upto(medium) {
    padding-bottom: #{math.div(1, 3) * 100%};
  }
}

.mop-cms-account-overview-card__background-image {
  position: absolute;
}

.mop-cms-account-overview-card__icon {
  position: absolute;
  right: 3px;
  top: 0;
  width: 62px;
  height: 62px;
  margin: $space10 13px;

  @include apply-upto(tiny) {
    width: 42px;
    height: 42px;
  }
}

.mop-cms-account-overview-card__content {
  position: absolute;
  bottom: 0;
  margin: $space10;
}

.mop-cms-account-overview-card__headline {
  @include text-style(strong);

  font-size: 27px;
  line-height: 27px;
}

.mop-cms-account-overview-card__headline--uppercased {
  text-transform: uppercase;
}

.mop-cms-account-overview-card__description {
  @include text-style(paragraph);

  margin: $space10 0;
  line-height: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 4;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.mop-cms-account-overview-card__subline {
  @include text-style(sub-headline-card);

  margin-top: 3px;
  color: var(--text-color);
  line-height: 18px;
}

.mop-cms-account-overview-card__arrow {
  vertical-align: middle;
  transition: transform 0.3s;
  fill: var(--text-color);
}

.mop-cms-account-overview-card--has-border {
  border: 1px solid $card-border-color;
}

.mop-cms-account-overview-card--hide-mobile {
  @include apply-upto(medium) {
    display: none;
  }
}

.mop-cms-account-overview-card--taller {
  .mop-cms-account-overview-card__container {
    padding-bottom: #{math.div(1220, 1680) * 100%};

    @include apply-upto(small) {
      padding-bottom: #{math.div(19, 32) * 100%};
    }
  }
}

.mop-cms-account-overview-card--hoverable {
  &:hover {
    border-color: $black;

    .mop-cms-account-overview-card__arrow {
      transform: translateX(14px);
    }
  }
}
</style>
