<script setup lang="ts">
defineOptions({
  name: 'UiAccordion',
});

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  activeTitle: {
    type: [String, Array],
    required: true,
  },
  isTitleHidden: {
    type: Boolean,
    default: false,
  },
  allowedViewports: {
    type: Array as PropType<String[]>,
    default: () => [],
  },
  isLast: {
    type: Boolean,
    default: false,
  },
  anchor: {
    type: String,
    default: '',
  },
  noTheme: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['pick-menu']);

const allowedViewports = props.allowedViewports;
const id = props.title;
const { $breakpoint } = useNuxtApp();
const isOnlyOneAccordionAllowedToBeOpen = typeof props.activeTitle === 'string';
const isOpenRef = computed(() => props.activeTitle.includes(id));
const isEnabledRef = computed(
  () => !allowedViewports.length || allowedViewports.includes($breakpoint.currentRef.value),
);

function handleClick() {
  let titlesToBeActivated: string | string[];

  if (isOnlyOneAccordionAllowedToBeOpen) {
    titlesToBeActivated = isOpenRef.value ? '' : id;
  } else {
    const activeTitle = props.activeTitle as string[];
    titlesToBeActivated = isOpenRef.value ? activeTitle.filter((title) => title !== id) : activeTitle.concat([id]);
  }
  emit('pick-menu', titlesToBeActivated);
}
</script>

<template>
  <div
    v-if="title"
    :class="[
      'accordion',
      {
        'accordion--default-theme': !noTheme,
        'accordion--is-enabled': isEnabledRef,
        'accordion--is-open': isOpenRef,
        'accordion--is-last': isLast,
      },
    ]"
  >
    <span v-if="anchor" :id="anchor" class="accordion__anchor" />
    <div class="accordion__head" @click="handleClick">
      <span v-if="!isTitleHidden" class="accordion__title">
        <slot name="head" :title="title">
          {{ title }}
        </slot>
      </span>
      <i
        :class="[
          'accordion__icon icon icon--arrow-select',
          {
            'icon--rotate': isOpenRef,
          },
        ]"
      />
    </div>
    <div class="accordion__body">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
$accordion-border-width: 1px;

.accordion {
  // For anchor positioning.
  position: relative;
}

.accordion__anchor {
  position: absolute;
  top: -$sticky-big-header-height;

  @include apply-upto(small) {
    top: -$sticky-small-header-height;
  }
}

.accordion__head {
  display: flex;
  width: 100%;
}

.accordion--is-enabled .accordion__head {
  align-items: center;
  cursor: pointer;
}

.accordion__title {
  /* Equals 10px at 16px font size. For when the icon is directly next to the title, because of no theme modifier. */
  margin-right: 0.625em;
}

.accordion__icon {
  display: none;
}

.accordion--is-enabled .accordion__icon {
  display: inline;
}

.accordion__body {
  transition: opacity 0.3s ease;
  overflow: hidden;
}

.accordion--is-enabled .accordion__body {
  max-height: 0;
  opacity: 0;
  visibility: hidden;
}

.accordion--is-enabled.accordion--is-open .accordion__body {
  max-height: 100%;
  opacity: 1;
  visibility: visible;
}

.accordion--default-theme {
  .accordion__head {
    @include text-style(subtopic-headline);
  }

  &.accordion--is-enabled .accordion__head {
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 $global-padding;
    padding: $space15 0;
    border: solid $middle-grey;
    border-width: $accordion-border-width 0 0 0;
    width: auto;

    @include apply-upto(small) {
      margin-left: 0;
      width: 100%;
      padding: {
        left: $global-padding;
        right: $global-padding;
      }
    }
  }

  &.accordion--is-last .accordion__head {
    border-bottom-width: $accordion-border-width;
  }

  &.accordion--is-enabled.accordion--is-open .accordion__head {
    border-bottom-width: $accordion-border-width;
  }

  .accordion__icon {
    margin-right: columns(1);

    @include apply-upto(small) {
      margin-right: 0;
    }
  }
}
</style>
