import type { AuthenticationTokenResponseData } from '@/types/authenticationToken';

export function authenticationTokenModel(responseData: AuthenticationTokenResponseData | null) {
  const response: AuthenticationTokenResponseData = responseData ?? {};

  return {
    hasError(): boolean {
      return response.error !== undefined;
    },

    isInitialized(): boolean {
      return responseData !== null;
    },

    getAuthToken(): string {
      return response.data?.payload ?? '';
    },
  };
}
