<script setup lang="ts">
import type { CmsHeadlineWithUppercaseOption, CmsUiCta } from '@mop/cms/types';

defineOptions({
  name: 'MopTileText',
});

const props = defineProps({
  headline: {
    type: Object as PropType<CmsHeadlineWithUppercaseOption>,
    default: () => {},
  },
  headlineFontStyle: {
    type: String,
    default: '',
  },
  subline: {
    type: String,
    default: '',
  },
  isSublineUppercase: {
    type: Boolean,
    default: false,
  },
  link: {
    type: Object,
    default: null,
  },
  cta: {
    type: Object as PropType<CmsUiCta>,
    default: () => {},
  },
  layout: {
    type: String,
    default: 'single',
    validator(value: string) {
      return ['single', 'double', 'hero'].includes(value);
    },
  },
  alignment: {
    type: String,
    default: 'bottom',
    validator(value: string) {
      return ['bottom', 'top'].includes(value);
    },
  },
});

const headlineFontMap: Record<string, string> = {
  default: 'tile-text__headline--default',
  serifCondensed: 'tile-text__headline--serif-condensed',
};

const uiHeadlineFontClass = headlineFontMap[props.headlineFontStyle || 'default'];
</script>

<template>
  <UiNuxtLink
    v-bind="link"
    :class="[
      'tile-text-wrapper',
      layout === 'double' ? 'img--vertical-hero' : 'img--vertical',
      `tile-text-wrapper--${alignment}`,
      `tile-text-wrapper--${layout}`,
    ]"
  >
    <div class="tile-text">
      <div
        v-if="headline.text"
        :class="['tile-text__headline', uiHeadlineFontClass, { 'tile-text__uppercase': headline.isUppercase }]"
      >
        {{ headline.text }}
      </div>
      <div v-if="subline" :class="['tile-text__subline', { 'tile-text__uppercase': isSublineUppercase }]">
        {{ subline }}
      </div>
      <UiCmsCta v-if="cta" :data="cta" no-padding large />
    </div>
  </UiNuxtLink>
</template>

<style scoped lang="scss">
.tile-text-wrapper {
  display: block;
  position: relative;
  grid-column-start: var(--large-grid-column-start);
  grid-row-start: var(--large-grid-row-start);

  @include apply-upto(small) {
    grid-column-start: var(--small-grid-column-start);
    grid-row-start: var(--small-grid-row-start);
    padding-top: 0;
  }
}

.tile-text {
  position: absolute;

  @include apply-upto(small) {
    position: relative;
    padding-right: $space-16;
    padding-left: $space-16;
  }
}

.tile-text__headline {
  @include text-style(headline, true);

  @include apply-upto(large) {
    font-size: 22px;
    line-height: 32px;
  }

  // usually for small screens it has enough space for bigger font, as tile grid has 2 columns

  @include apply-only(small) {
    font-size: 30px;
    line-height: 34px;
  }
}

.tile-text__headline--serif-condensed {
  @include text-style(headline-serif-condensed, true);

  @include apply-upto(large) {
    font-size: 22px;
    line-height: 120%;
  }

  // usually for small screens it has enough space for bigger font, as tile grid has 2 columns

  @include apply-only(small) {
    font-size: 30px;
    line-height: 120%;
  }
}

.tile-text-wrapper--top {
  @include apply-upto(small) {
    padding-top: 0;
  }
  .tile-text {
    top: 0;
  }
}

.tile-text-wrapper--bottom .tile-text {
  bottom: 0;
}

.tile-text__uppercase {
  text-transform: uppercase;
}

.tile-text__subline {
  @include text-style(strong);

  font-size: 12px;
  line-height: 14px;
}
</style>
