<script setup lang="ts">
import { getElementUiObject, getHtmlFromRichText, getCta, getHeadlineWithAllOptions } from '@mop/cms/utils/utils';
import type { CmsUiRichText } from './UiCmsRichText';

defineOptions({
  name: 'UiCmsRichText',
});

const props = defineProps({
  data: {
    type: Object as PropType<CmsUiRichText>,
    required: true,
  },
});

const data: any = props.data;

const element: CmsUiRichText = getElementUiObject(data, {
  richText: getHtmlFromRichText(data.richText),
  headline: getHeadlineWithAllOptions(data),
  headlineFontStyle: data.headlineFontStyle,
  isUppercase: data.isUppercase,
  cta: getCta(data.cta?.[0]),
  maxWidth: data.maxWidth,
  align: data.align,
});

const headlineFontMap: Record<string, string> = {
  default: 'ui-cms-richt-text__headline--default',
  serifCondensed: 'ui-cms-richt-text__headline--serif-condensed',
};

const uiHeadlineFontClass = headlineFontMap[element.headlineFontStyle || 'default'];
</script>

<template>
  <div
    v-storyblok-editable="data"
    :style="element.maxWidth !== '100%' ? `--max-width: ${element.maxWidth}` : undefined"
    :class="[
      'ui-cms-richt-text',
      'ui-cms-padding',
      {
        'ui-cms-richt-text--uppercase': element.isUppercase,
        'ui-cms-richt-text--center': element.align === 'center',
        'ui-cms-richt-text--right': element.align === 'right',
      },
    ]"
    :data-track-element-item="element.headline"
  >
    <UiHeading
      v-if="element.headline?.text && element.headline.text !== 'undefined'"
      :class="['ui-cms-richt-text__headline', uiHeadlineFontClass]"
      :level="element.headline.level ?? 2"
    >
      {{ element.headline.text }}
    </UiHeading>
    <div class="ui-cms-richt-text__text" v-html="element.richText" />
    <UiCmsCta v-if="element.cta" :data="element.cta" class="ui-cms-richt-text__cta" no-padding />
  </div>
</template>

<style lang="scss" scoped>
.ui-cms-padding {
  padding: 0 $global-padding;
}

.ui-cms-richt-text {
  max-width: var(--max-width, 100%);

  @include apply-upto(medium) {
    max-width: 100%;
  }
}

.ui-cms-richt-text--uppercase {
  text-transform: uppercase;
}

.ui-cms-richt-text--center {
  margin-left: auto;
  margin-right: auto;
}

.ui-cms-richt-text--right {
  margin-left: auto;
}

.ui-cms-richt-text__headline {
  @include text-style(headline, true);
}

.ui-cms-richt-text__headline--serif-condensed {
  @include text-style(headline-serif-condensed, true);
}

.ui-cms-richt-text__cta {
  margin-top: $space10;
}

:deep(.ui-cms-cta__element--link),
:deep(.ui-cms-cta__element--emphasize) {
  padding-top: 0;
  font-size: 13px;
}
</style>
