import type { Filter, FilterValueModel, FilterModel, FilterListResponseData } from '@/types/filters';
import { filterValuesModel } from '@/models';

export function filterModel(response: Filter) {
  return {
    getId(): number | null {
      return response.id || null;
    },
    getSlug(): string {
      return response.slug;
    },
    getName(): string {
      return response.name;
    },
    getValues(): FilterValueModel[] {
      return filterValuesModel({ values: response.values });
    },
    isRange(): boolean {
      return this.getType() === 'range';
    },
    isColor(): boolean {
      return this.getSlug() === 'refinementColor';
    },
    isBoolean(): boolean {
      return this.getType() === 'boolean';
    },
    getType(): 'boolean' | 'attributes' | 'identifier' | 'range' {
      return response.type;
    },
    getDebugData(): any {
      return response;
    },
  };
}

export function filterListModel(responseData: FilterListResponseData | null) {
  const response: FilterListResponseData = responseData ?? {};

  return {
    hasError(): boolean {
      return response.error !== undefined;
    },

    isEmpty(): boolean {
      return response.data === undefined;
    },

    getFilterModelList(): FilterModel[] {
      if (response.data === undefined) {
        return [];
      }
      return response.data.map((filterData) => filterModel(filterData));
    },
  };
}
