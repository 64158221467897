import type {
  ReservationModel,
  ReservationResponseData,
  ReservationListResponseData,
  ReservationProductData,
  ReservationProductModel,
  ReservationStatus,
} from '@/types/reservation';
import type { StoreData, StoreModel } from '@/types/store';
import { reservationProductModel, storeModel } from '@/models';

export function reservationModel(responseData: ReservationResponseData | null) {
  const response: ReservationResponseData = responseData ?? {};

  return {
    hasError(): boolean {
      return response.error !== undefined;
    },

    isInitialized(): boolean {
      return responseData !== undefined;
    },

    getId(): string {
      return response.data?.reservationId || '';
    },

    getReservationStatus(): ReservationStatus {
      return response.data?.reservationStatus || 'requested';
    },

    getCount(): string {
      return this.getReservationStatus() === 'created' ? '1' : '';
    },

    getProduct(): ReservationProductModel | undefined {
      const product: ReservationProductData | undefined = response.data?.product;

      if (product) {
        return reservationProductModel(product);
      }
    },

    getStore(): StoreModel | undefined {
      const store: StoreData | undefined = response.data?.store;

      if (store) {
        return storeModel(store);
      }
    },

    hasActiveReservation(): boolean {
      return this.getReservationStatus() === 'created';
    },
    getEan(): string {
      return response.data?.variantId ?? '';
    },
  };
}

export function reservationListModel(responseData: ReservationListResponseData | null) {
  const response: ReservationListResponseData = responseData ?? {};

  return {
    isInitialized(): boolean {
      return responseData !== null;
    },

    hasError(): boolean {
      return response.error !== undefined;
    },

    getReservationModelList(): ReservationModel[] {
      if (response.data === undefined) {
        return [];
      }

      return response.data.map((product) => reservationModel({ data: product }));
    },
  };
}
