<script setup lang="ts">
import { getElementUiObject } from '@mop/cms/utils/utils';
import type { CmsUiHtml } from './uiCmsHtml';

defineOptions({
  name: 'UiCmsHtml',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const data: any = props.data;

const element: CmsUiHtml = getElementUiObject(data, {
  html: data.html,
});
</script>

<template>
  <div
    v-if="element.html"
    v-storyblok-editable="element"
    class="ui-cms-html ui-cms-padding"
    :data-track-element-item="`HTMLCode`"
    v-html="element.html"
  />
</template>

<style lang="scss" scoped>
.ui-cms-padding {
  padding: 0 $global-padding;
}
</style>
