import { isClient, isIE } from '@mop/shared/utils/util';
import cookie from '@mop/shared/utils/cookie';

export const isLocalhostOrIsDebugCookieEnabled: boolean =
  isClient && (window.location.host.includes('localhost') || Boolean(cookie().get('debug')));

export function logCustomError(error: string) {
  logError(`CUSTOM: ${error}`);
}

export function logError(error: any) {
  if (isIE()) {
    return;
  }
  if (isClient && window.mopLogger?.logError) {
    // TODO: think of adding a document.location.href to error logs for easier tracing
    window.mopLogger.logError(error);
  } else if (!isClient) {
    // @ts-ignore
    if (typeof process !== 'undefined' && process.logMonitoring) {
      if (error instanceof Error) {
        // @ts-ignore
        process.logMonitoring.trackException({
          exception: error,
        });
      } else if (typeof error === 'string') {
        // @ts-ignore
        process.logMonitoring.trackException({
          exception: new CustomError(error),
        });
      } else {
        // @ts-ignore
        process.logMonitoring.trackException({
          exception: new CustomError(error?.message ? error.message : 'Custom Error'),
          properties: error,
        });
      }
    }
  }

  if (console) {
    console.error(error); // eslint-disable-line
  }
}

export function logWarning(warning: any) {
  if (isIE()) {
    return;
  }
  if (isClient && window.mopLogger?.logWarning) {
    window.mopLogger.logWarning(warning);
    if (typeof console === 'undefined' || !console || !isLocalhostOrIsDebugCookieEnabled) {
      return;
    }
    console.warn(warning); // eslint-disable-line
  } else {
    console.warn(warning); // eslint-disable-line
  }
}

export function logInfo(info: any, logToExternalLogger = false) {
  if (isIE()) {
    return;
  }
  if (logToExternalLogger) {
    if (isClient && window.mopLogger?.logInfo) {
      window.mopLogger.logInfo(info);
      if (typeof console === 'undefined' || !console || !isLocalhostOrIsDebugCookieEnabled) {
        return;
      }
      console.info(info); // eslint-disable-line
    } else {
      console.info(info); // eslint-disable-line
    }
  } else if (isClient) {
    if (typeof console === 'undefined' || !console || !isLocalhostOrIsDebugCookieEnabled) {
      return;
    }
    console.info(info); // eslint-disable-line
  }
}

class CustomError extends Error {
  constructor(message: string) {
    super(message);
    this.name = message;
  }
}
