import { securedWrap } from '@mop/shared/utils/securedWrap';
import { authenticationTokenModel } from '@/models';
import type { AuthenticationTokenModelRef, AuthenticationTokenResponseData } from '@/types/authenticationToken';

type AuthenticationComposableStorage = {
  authenticationTokenModelRef: AuthenticationTokenModelRef;
};

export default function useMopAuthenticationClient() {
  const loading = ref(false);
  const storage = initStorage<AuthenticationComposableStorage>('useAuthenticationClient');

  const authenticationTokenModelRef: AuthenticationTokenModelRef =
    storage.get('authenticationTokenModelRef') ??
    storage.saveAndGet('authenticationTokenModelRef', ref(authenticationTokenModel(null)));

  async function fetchAuthenticationToken(_country: string, locale: string, callbackUrl: string) {
    loading.value = true;
    if (!authenticationTokenModelRef.value.getAuthToken()) {
      const authenticationTokenResponse: AuthenticationTokenResponseData =
        await useNuxtApp().$apiAws.getAuthenticationToken(locale, callbackUrl);
      authenticationTokenModelRef.value = authenticationTokenModel(authenticationTokenResponse);
    }
    loading.value = false;
  }

  return securedWrap({
    authenticationTokenModelRef,
    fetchAuthenticationToken,
    loading,
  });
}
