<script setup lang="ts">
import type { CmsUiCardItem } from '@mop/cms/types';

defineOptions({
  name: 'UiCmsCardItem',
});

const props = defineProps({
  data: {
    type: Object as PropType<CmsUiCardItem>,
    required: true,
  },
  lazyLoad: {
    type: Boolean,
    default: false,
  },
});

const { headline, headlineFontStyle, subline = '', cta, textColor = '', imageType, media } = props.data;

if (media) {
  media.imageType = imageType;
}

const headlineFontMap: Record<string, string> = {
  default: 'ui-cms-card-item__headline--default',
  serifCondensed: 'ui-cms-card-item__headline--serif-condensed',
};

const uiHeadlineFontClass = headlineFontMap[headlineFontStyle || 'default'];
</script>

<template>
  <UiNuxtLink v-storyblok-editable="data" v-bind="data.link" class="ui-cms-card-item">
    <div v-if="media" class="ui-cms-card-item__image">
      <UiCmsMedia :data="media" :lazy="lazyLoad" />
    </div>
    <div
      v-if="headline.text || subline || cta"
      class="ui-cms-card-item__content"
      :style="[textColor ? { '--color': textColor } : {}]"
    >
      <UiHeading
        v-if="headline.text"
        :class="[
          'ui-cms-card-item__headline',
          uiHeadlineFontClass,
          { 'ui-cms-card-item__headline--uppercase': headline.isUppercase },
        ]"
        :level="headline.level || 0"
      >
        {{ headline.text }}
      </UiHeading>
      <div v-if="subline" class="ui-cms-card-item__subline">
        {{ subline }}
      </div>
      <div v-if="cta" class="ui-cms-card-item__cta">
        <UiCmsCta :data="cta" no-padding />
      </div>
    </div>
  </UiNuxtLink>
</template>

<style lang="scss" scoped>
.ui-cms-card-item {
  display: flex;
  flex-direction: column;
  position: relative;
  flex-basis: 100%;
  height: 100%;
}

.ui-cms-card-item__image {
  flex-shrink: 0;
}

.ui-cms-card-item__content {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: $space20 $space-16;
  width: 100%;
}

.ui-cms-card-item__subline {
  @include text-style(sub-headline-card);
}

.ui-cms-card-item__headline {
  @include text-style(headline-card, true);

  margin: 0;
}

.ui-cms-card-item__headline--serif-condensed {
  @include text-style(headline-serif-condensed, true);

  margin: 0;
}

.ui-cms-card-item__headline--uppercase {
  text-transform: uppercase;
}

.ui-cms-card-item__cta {
  margin-bottom: -$space15;
  order: 4;

  @include apply-from(medium) {
    margin-top: $space5;
  }
}
</style>
