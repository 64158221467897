import type { LocationQueryRaw } from 'vue-router';
import { securedWrap } from '@mop/shared/utils/securedWrap';
import { isClient } from '@mop/shared/utils/util';
import type { Alternate } from '@/types/cms';
import type { CategoryModel } from '@/types/category';

type HomeUrlRef = Ref<string>;
type ActiveCategoryId = Ref<number | null>;
type ActiveCategoryPath = Ref<CategoryModel[]>;

type RouterComposableStorage = {
  homeUrlRef: HomeUrlRef;
  activeCategoryPathRef: ActiveCategoryPath;
  activeCategoryIdRef: ActiveCategoryId;
};

export default function useMopRouter() {
  const { $cookie, $mopI18n } = useNuxtApp();
  const { getCategoriesInPath, getCategoryById, getCategoryByPropertyName } = useMopCategoryTree();
  const { country, shopId, locale } = $mopI18n;
  const route = useRoute();
  const router = useRouter();
  const storage = initStorage<RouterComposableStorage>('useMopRouter');
  const activeCategoryIdRef: ActiveCategoryId =
    storage.get('activeCategoryIdRef') ??
    storage.saveAndGet('activeCategoryIdRef', useMopSSR('active-category-id', null));

  const activeCategoryPathRef: ActiveCategoryPath =
    storage.get('activeCategoryPathRef') ??
    storage.saveAndGet('activeCategoryPathRef', ref(initAndGetActiveCategory()));

  const homeUrlRef: HomeUrlRef = computed(() => $mopI18n.localePath(activeCategoryPathRef.value[0]?.getUrl() || ''));

  let checkoutBaseUrl = '';
  function getCheckoutBaseUrl(): string {
    if (checkoutBaseUrl === '') {
      const config = useRuntimeConfig();
      checkoutBaseUrl = config.public.CHECKOUT_URL_V3;
    }
    return checkoutBaseUrl;
  }

  async function getRegisterUrl(): Promise<string> {
    return `${getCheckoutBaseUrl()}${constants.CHECKOUT.REGISTER_SUFFIX}${await getAuthToken()}&appId=${shopId}`;
  }

  async function getLoginUrl(): Promise<string> {
    return `${getCheckoutBaseUrl()}${constants.CHECKOUT.LOGIN_SUFFIX}${await getAuthToken()}&appId=${shopId}`;
  }

  function getPasswordResetUrl(hash: string): string {
    return `${getCheckoutBaseUrl()}${constants.CHECKOUT.PASSWORD_RESET_SUFFIX}?hash=${hash}&appId=${shopId}`;
  }

  async function getLogoutUrl(): Promise<string> {
    return `${getCheckoutBaseUrl()}${constants.CHECKOUT.LOGOUT_SUFFIX}${await getAuthToken()}&appId=${shopId}`;
  }

  async function getAuthToken(): Promise<string> {
    const { authenticationTokenModelRef, fetchAuthenticationToken } = useMopAuthenticationClient();
    const config = useRuntimeConfig();
    await fetchAuthenticationToken(country, locale, `${config.public.BASE_URL}/${locale}/${URLS.AUTH_REDIRECT}`);

    if (!authenticationTokenModelRef.value.isInitialized()) {
      return '';
    }
    return authenticationTokenModelRef.value.getAuthToken();
  }

  function getQueryParamValue(name: string): string | undefined {
    let queryParamValue: any = route.query[name];
    // If duplicate parameters in url, use last
    if (Array.isArray(queryParamValue)) {
      queryParamValue = queryParamValue[queryParamValue.length - 1];
    }

    return queryParamValue;
  }

  function setActiveCategory(categoryId: number) {
    const categoryList: CategoryModel[] = findCategoryPath(categoryId);
    if (!categoryList || !categoryList.length || !categoryId) {
      return;
    }
    $cookie.store(constants.COOKIE.TOP_LEVEL_CATEGORY, String(categoryList[0].getId()));
    if (activeCategoryIdRef.value !== categoryId) {
      activeCategoryPathRef.value = categoryList;
      activeCategoryIdRef.value = categoryId;
    }
  }

  function isAuthRequired() {
    return !isClient || (Boolean(route.meta?.requiresAuth) && useMopCustomer().customerModelRef.value.isGuest());
  }

  function initAndGetActiveCategory(): CategoryModel[] {
    if (activeCategoryIdRef.value) {
      return findCategoryPath(activeCategoryIdRef.value);
    }

    let topCategory: CategoryModel | undefined;
    const isCachedPage = Boolean(route.meta?.cacheMaxAgeInSeconds);
    const redirectedFromCheckout: boolean = isRedirectedFromCheckout(route);
    /**
     * For cached pages (static pages like the Storefinder) there is some trouble getting the saved top category. We
     * would need to save a cached version for each top category. We decided to go for the more simple solution and
     * use the same fallback category for them.
     */
    // exclude product page to keep previously set top category, it also contains fallback
    if (isCachedPage && !redirectedFromCheckout && route.name !== 'product-page') {
      topCategory = getFallbackTopCategory();
    } else {
      topCategory = getSavedTopCategory();
    }

    return topCategory ? [topCategory] : [];
  }

  function getSavedTopCategory(): CategoryModel {
    let category: CategoryModel | undefined;

    const topCategoryId: string = $cookie.get(constants.COOKIE.TOP_LEVEL_CATEGORY);

    if (topCategoryId) {
      const categoryFromCookie: CategoryModel | undefined = getCategoryById(parseInt(topCategoryId));
      if (categoryFromCookie) {
        category = categoryFromCookie;
      }
    }

    return category || getFallbackTopCategory();
  }

  function getFallbackTopCategory(): CategoryModel {
    return getCategoryByPropertyName('mopId', 'women') as CategoryModel;
  }

  function findCategoryPath(categoryId: number) {
    if (!categoryId) {
      return [];
    }
    const foundCategory: CategoryModel | undefined = getCategoryById(categoryId);
    if (!foundCategory) {
      return [];
    }
    return getCategoriesInPath(foundCategory.getPath());
  }

  function performLocaleRedirect(oldLocale: string, newLocale: string, newLocaleFallback: string) {
    const alternates: Alternate[] = useMopSeo().getAlternates();
    let targetAlternate: Alternate | undefined = alternates.find((alternate) => alternate.lang === newLocale);
    if (!targetAlternate && newLocaleFallback) {
      targetAlternate = alternates.find((alternate) => alternate.lang === newLocaleFallback);
    }
    let target: string = window.location.href.replace(oldLocale, newLocale).split('#overlay-')[0];
    if (targetAlternate) {
      target = `/${newLocale}${targetAlternate.href}`;
    }

    window.location.href = target;
  }

  function isActiveRootCategory(id: number): boolean {
    const [activeTopCategory] = activeCategoryPathRef.value;
    const activeTopCategoryId: number = activeTopCategory ? activeTopCategory.getId() : -1;
    return id === activeTopCategoryId;
  }

  function isCurrentCategory(id: number): boolean {
    return id === activeCategoryIdRef?.value;
  }

  function getLocalePathFromQuery(query: LocationQueryRaw) {
    const fullPath = router.resolve({ query }).fullPath;
    return $mopI18n.localePath(fullPath);
  }

  function getLocalePathFromHash(hash: string) {
    const fullPath = router.resolve({ hash }).fullPath;
    return $mopI18n.localePath(fullPath);
  }

  function getLocalePathByPage(pageNumber: number) {
    const query = { ...route.query };
    if (pageNumber === 1) {
      delete query.page;
    } else {
      query.page = pageNumber.toString();
    }

    return getLocalePathFromQuery(query);
  }

  return securedWrap({
    homeUrlRef,
    activeCategoryPathRef,
    getSavedTopCategory,
    getQueryParamValue,
    setActiveCategory,
    isAuthRequired,
    getRegisterUrl,
    getLoginUrl,
    getLogoutUrl,
    getPasswordResetUrl,
    performLocaleRedirect,
    isActiveRootCategory,
    isCurrentCategory,
    getLocalePathByPage,
    getLocalePathFromQuery,
    getLocalePathFromHash,
  });
}
