<script setup lang="ts">
defineOptions({
  name: 'MopCmsPromotionItem',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  isFreeShippingEnabled: {
    // For promotion tiles
    type: Boolean,
    default: false,
  },
});

const element = getCmsPromoTile(props.data);
</script>

<template>
  <div>
    <MopTile :is-free-shipping-enabled="isFreeShippingEnabled" :data="element!" :image-type="element!.imageType" />
  </div>
</template>
