import type { AxiosError, AxiosInstance } from 'axios';
import { handleAxiosError, errorHandler } from '@/api/utils';
import type { OrderListResponseData, OrderResponseData } from '@/types/orders';
const API_NAME = 'AWS - orders';

export function getCustomerOrders(
  apiInstance: AxiosInstance,
  authToken: string,
  params?: any,
): Promise<OrderListResponseData> {
  const url = `/orders?shopId=${apiInstance.prototype.shopId}`;

  return errorHandler<OrderListResponseData>(async () => ({
    data: (
      await apiInstance.get(url, {
        params,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getCustomerOrders',
      url,
    }),
  }));
}
export function getCustomerOrder(
  apiInstance: AxiosInstance,
  authToken: string,
  orderId: string,
  customerId: number | undefined,
): Promise<OrderResponseData> {
  const url = `/orders?shopId=${apiInstance.prototype.shopId}`;

  return errorHandler<OrderResponseData>(async () => ({
    data: (
      await apiInstance.get(url, {
        params: {
          orderId,
          customerId,
          time: Date.now(),
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getCustomerOrder',
      url,
      orderId,
      customerId,
    }),
  }));
}
export function getCheckoutOrder(
  apiInstance: AxiosInstance,
  customerId: number,
  hash: string,
  orderId?: number,
): Promise<OrderResponseData> {
  const shopId: string = apiInstance.prototype.shopId;

  const params: any = {
    orderId,
    customerId,
    shopId,
    hash,
  };

  if (orderId) {
    params.orderId = orderId;
  }

  return errorHandler<OrderResponseData>(async () => ({
    data: (
      await apiInstance.get('/checkoutorders', {
        timeout: 20000,
        params,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getCheckoutOrder',
      ...params,
    }),
  }));
}
