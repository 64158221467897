<script setup lang="ts">
import type { Timer } from '@mop/types';
import SVGRight from '@mop/shared/images/arrow/right.svg?component';
import SVGLeft from '@mop/shared/images/arrow/left.svg?component';
import { getPassiveEventOption, scrollToPosition } from '@mop/shared/utils/util';
import type { NavigationItem } from '@mop/ui/types/uiComponents';

defineOptions({
  name: 'UiScrollNavigation',
});

const props = defineProps({
  navigationItems: {
    type: Array as PropType<NavigationItem[]>,
    default: () => [],
  },
  isNavInactive: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['item-click']);

const { $resize } = useNuxtApp();
const scrollNavigationElementRef = ref();
const navigationScrollDistance = 250;
const showScrollArrowRight = ref(false);
const showScrollArrowLeft = ref(false);
const scrollIntoViewClassRef = ref('scroll-into-view');

onMounted(() => {
  setTimeout(() => {
    scrollIntoViewClassRef.value = '';
    handleSlideIndicators();
  }, 200);

  scrollNavigationElementRef.value?.addEventListener('scroll', debouncedHandleSlideIndicators, getPassiveEventOption());
  watch($resize.viewportWidthRef, debouncedHandleSlideIndicators);
});

onUnmounted(() => {
  scrollNavigationElementRef.value?.removeEventListener('scroll', debouncedHandleSlideIndicators);
});

let scrollingTimer: Timer;
function debouncedHandleSlideIndicators() {
  clearTimeout(scrollingTimer);
  scrollingTimer = setTimeout(handleSlideIndicators, 200);
}

function handleSlideIndicators() {
  const { clientWidth, scrollWidth, scrollLeft } = scrollNavigationElementRef.value;
  const isSlideIndicatorNeeded = scrollWidth - 30 > clientWidth;
  showScrollArrowLeft.value = isSlideIndicatorNeeded && scrollLeft > 10;
  showScrollArrowRight.value = isSlideIndicatorNeeded && scrollLeft + clientWidth <= scrollWidth - 10;
}

function scrollTopNavigation(scrollLeftPosition: number) {
  scrollNavigationElementRef.value.scroll({
    left: scrollNavigationElementRef.value.scrollLeft + scrollLeftPosition,
    behavior: 'smooth',
  });
}

function scollTopNavigationLeft() {
  scrollTopNavigation(-navigationScrollDistance);
}

function scollTopNavigationRight() {
  scrollTopNavigation(navigationScrollDistance);
}

const hasNavigationItems = computed(() => {
  return props.navigationItems && props.navigationItems.length > 0;
});

function handleLinkClick(clickedItem: string) {
  emit('item-click', clickedItem);
  scrollToPosition(0, false);
}
</script>

<template>
  <div
    v-if="hasNavigationItems"
    :class="[
      'ui-mop-scroll-navigation',
      {
        'ui-mop-scroll-navigation--inactive': isNavInactive,
      },
    ]"
  >
    <div
      v-show="showScrollArrowLeft"
      class="ui-mop-scroll-navigation__gradient ui-mop-scroll-navigation__gradient--start"
      @click.prevent="scollTopNavigationLeft"
    >
      <SVGLeft class="ui-mop-scroll-navigation__gradient-arrow-start" width="14" height="14" />
    </div>
    <div ref="scrollNavigationElementRef" class="ui-mop-scroll-navigation__list-scroll">
      <div class="ui-mop-scroll-navigation__list">
        <NuxtLink
          v-for="navigationItem in navigationItems"
          :key="navigationItem.id"
          :to="navigationItem.url"
          :class="[
            'ui-mop-scroll-navigation__list-link toggle-opacity',
            navigationItem.isActive ? scrollIntoViewClassRef : '',
            {
              'ui-mop-scroll-navigation__list-link--opaque': navigationItem.isOpaque,
              'ui-mop-scroll-navigation__list-link--active': navigationItem.isActive,
            },
          ]"
          no-prefetch
          @click="handleLinkClick(navigationItem.name)"
        >
          {{ navigationItem.name }}
        </NuxtLink>
        <div class="ui-mop-scroll-navigation__list-link-spacer">
          {{ '&nbsp;' }}
        </div>
      </div>
    </div>
    <div
      v-show="showScrollArrowRight"
      class="ui-mop-scroll-navigation__gradient ui-mop-scroll-navigation__gradient--end"
      @click.prevent="scollTopNavigationRight"
    >
      <SVGRight class="ui-mop-scroll-navigation__gradient-arrow-end" width="14" height="14" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.ui-mop-scroll-navigation {
  grid-area: center;
  position: relative;
  list-style-type: none;
  margin: 0 $space30 0 $space30;

  @include apply-upto(medium) {
    grid-column-start: 1;
    grid-column-end: span 2;
    margin: 0;
    max-width: 100%;
    align-items: flex-end;
  }
}

.ui-mop-scroll-navigation__gradient {
  @include z(global, content);

  position: absolute;
  top: 1px;
  right: -$global-padding;
  width: 35px;
  height: 38px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  content: '';
}

.ui-mop-scroll-navigation__gradient--start {
  left: -$global-padding;
  border-left: 20px solid $white;
  justify-content: flex-start;
  background-image: linear-gradient(90deg, $white, hsla(0, 0%, 100%, 0.2));
}

.ui-mop-scroll-navigation__gradient--end {
  right: -$global-padding;
  border-right: 20px solid $white;
  justify-content: flex-end;
  background-image: linear-gradient(90deg, hsla(0, 0%, 100%, 0.6), $white);
}

.ui-mop-scroll-navigation__gradient-arrow-start {
  margin-left: -12px;
  opacity: $disabled-opacity;
}

.ui-mop-scroll-navigation__gradient-arrow-end {
  margin-right: -12px;
  opacity: $disabled-opacity;
}

.ui-mop-scroll-navigation__list-scroll {
  overflow-y: hidden;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  height: fit-content;
  height: 40px; /* otherwise ipad breaks due to no scroll height */
  position: relative;
  top: 2px;
  scrollbar-width: none; /* Firefox */
  scrollbar-color: $middle-grey transparent; /* Firefox */

  @include apply-upto(medium) {
    scrollbar-width: none; /* Firefox */
  }

  &::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
    height: 0;
    width: 0;
  }
}

.ui-mop-scroll-navigation__list {
  display: flex;
  align-items: center;
}

.ui-mop-scroll-navigation__list-link {
  @include link-not-underlined(10px);
  @include text-style(strong);

  text-transform: uppercase;
  white-space: nowrap;
  margin-left: $space20;

  &:first-of-type {
    margin-left: 0;
  }
}

.ui-mop-scroll-navigation__list-link-spacer {
  width: 20px;
  white-space: nowrap;
  margin-left: $space20;
}

.ui-mop-scroll-navigation__actions {
  grid-area: right;
  display: flex;

  @include apply-upto(medium) {
    padding-bottom: 0;
    align-items: center;
    justify-content: flex-end;
  }
}

.ui-mop-scroll-navigation--inactive {
  .toggle-opacity {
    opacity: $disabled-opacity;
  }

  .active {
    opacity: 1;
  }
}

.ui-mop-scroll-navigation__list-link--opaque {
  opacity: $disabled-opacity;

  &.active {
    opacity: $disabled-opacity;
  }
}

.scroll-into-view {
  scroll-snap-align: center;
}
</style>
