import { securedWrap } from '@mop/shared/utils/securedWrap';

export type ComposablesStorage<T> = {
  get<K extends keyof T>(key: K): T[K] | null;
  delete<K extends keyof T>(key: K): void;
  saveAndGet<K extends keyof T>(key: K, value: T[K]): T[K];
};

export function initStorage<T>(composableName: string): ComposablesStorage<T> {
  const { $composables } = useNuxtApp();

  $composables[composableName] ??= {};
  const storage: T = $composables[composableName];

  return securedWrap({
    get<K extends keyof T>(key: K): T[K] | null {
      return storage[key] ?? null;
    },
    delete<K extends keyof T>(key: K) {
      if (storage[key]) {
        delete storage[key];
      }
    },
    saveAndGet<K extends keyof T>(key: K, value: T[K]): T[K] {
      storage[key] = value;
      return value;
    },
  });
}
