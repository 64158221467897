import { getPassiveEventOption, isClient } from '@mop/shared/utils/util';

export default defineNuxtPlugin((nuxtApp) => {
  const offsetTopRef = ref(0);
  const scrollDirectionRef = ref(constants.SCROLL_DIRECTION_DOWN);
  function calculateScrollPosition() {
    const newOffsetTop: number = Math.max(0, window.scrollY || window.pageYOffset);
    if (newOffsetTop === offsetTopRef.value) {
      return;
    }
    scrollDirectionRef.value =
      newOffsetTop < offsetTopRef.value ? constants.SCROLL_DIRECTION_UP : constants.SCROLL_DIRECTION_DOWN;
    offsetTopRef.value = Math.max(0, window.scrollY || window.pageYOffset);
  }

  if (isClient) {
    window.addEventListener('scroll', () => requestAnimationFrame(calculateScrollPosition), getPassiveEventOption());
    calculateScrollPosition();
  }

  nuxtApp.provide('scroll', {
    offsetTopRef,
    scrollDirectionRef,
  });
});
