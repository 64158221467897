import type { AxiosError, AxiosInstance } from 'axios';
import { handleAxiosError, errorHandler } from '@/api/utils';
import type { AuthenticationTokenResponseData } from '@/types/authenticationToken';
import type { CustomerResponseData } from '@/types/customer';
const API_NAME = 'AWS - auth';

// Thie API returns the customer data, saves the basket and/or wishlistkey and merges basket and/or wishlist
export function getCustomer(
  apiInstance: AxiosInstance,
  accessToken: string,
  shopId: number,
  basketKey?: string,
  wishlistKey?: string,
): Promise<CustomerResponseData> {
  return errorHandler<CustomerResponseData>(async () => ({
    data: (
      await apiInstance.post('/customer', {
        bearer: accessToken,
        basketKey,
        wishlistKey,
        shopId,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getCustomer',
      payload: {
        bearer: accessToken,
        basketKey,
        wishlistKey,
        shopId,
      },
    }),
  }));
}

export function getAuthenticationToken(
  apiInstance: AxiosInstance,
  locale: string,
  callbackUrl: string,
): Promise<AuthenticationTokenResponseData> {
  const url = `/authtoken?locale=${locale}&callbackUrl=${callbackUrl}`;
  return errorHandler<AuthenticationTokenResponseData>(async () => ({
    data: (await apiInstance.get(url)).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getAuthenticationToken',
      url,
    }),
  }));
}
