import routerOptions0 from "/home/vsts/work/1/s/apps/b2c/app/router.options.ts";
import routerOptions1 from "/home/vsts/work/1/s/node_modules/nuxt/dist/pages/runtime/router.options";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions1,
...routerOptions0,
}