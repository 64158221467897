import type { ProductModel } from '@/types/product';
// Direct import to avoid circular dependency
import { productModel } from '@/models/productModel';
import type { WishlistResponseData } from '@/types/wishlist';

export function wishlistModel(responseData: WishlistResponseData | null) {
  const response: WishlistResponseData = responseData ?? {};
  const ayProductIds: any = {};
  // performance optimization for faster product and variant discovery
  if (response.data) {
    response.data.items.forEach((wishlistItem) => {
      ayProductIds[parseInt(wishlistItem.productId)] = wishlistItem.key;
    });
  }

  return {
    hasError(): boolean {
      return response.error !== undefined;
    },

    getCount(): number {
      return response.data?.items.length ?? 0;
    },

    isProductInWishlist(productId: number): boolean {
      return ayProductIds[productId] !== undefined;
    },

    getKeyByProductId(ayProductId: number): string {
      return (ayProductIds[ayProductId] as string) || '';
    },

    getProducts(): ProductModel[] {
      if (!response.data) {
        return [];
      }
      return response.data.items.reduce((productList: ProductModel[], wishlistItem) => {
        if (!wishlistItem.product) {
          return productList;
        }
        productList.push(productModel({ data: wishlistItem.product }));
        return productList;
      }, []);
    },
  };
}
