<script setup lang="ts">
import { getElementUiObject } from '@mop/cms/utils/utils';
import type { CmsUiSeparator } from './uiCmsSeparator';

defineOptions({
  name: 'UiCmsSeparator',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const data: any = props.data;

const element: CmsUiSeparator = getElementUiObject(data, {
  height: data.height,
});
</script>

<template>
  <div v-storyblok-editable="element" :class="['ui-cms-separator', `ui-cms-separator--space-${element.height}`]" />
</template>

<style lang="scss" scoped>
$spaces-map: (
  2: $space2,
  5: $space5,
  10: $space10,
  15: $space15,
  20: $space20,
  30: $space30,
  40: $space40,
  50: $space50,
  60: $space60,
  70: $space70,
  80: $space80,
);

// Iterate over every space and generate selectors which are used by the separator module

@each $space in map-keys($spaces-map) {
  .ui-cms-separator--space-#{$space} {
    padding-bottom: map-get($spaces-map, $space);
  }
}

.ui-cms-separator--space-0 {
  margin-bottom: $space30;
  padding-bottom: $space30;
  border-bottom: 1px solid $middle-grey;
}
</style>
