import type { WatchStopHandle } from 'vue';
import { throttle } from '@mop/shared/utils/util';
import { securedWrap } from '@mop/shared/utils/securedWrap';

type Loader = Function | Ref<{ loading: boolean }>;
type UsePageTransitionClient = {
  initTransition(classList: Ref<string[]>, loading: Ref<any>, loadingEntry?: string): void;
  setLoadingState(loader: Ref<{ loading: boolean }>, ...loaders: Loader[]): void;
  loadingRef: Ref<boolean>;
};

type TransitionStorage = {
  loading: Ref<boolean>;
};
export function useMopPageTransitionClient(page: string = TRANSITION_DEFAULT_CLASS): UsePageTransitionClient {
  const storage = initStorage<TransitionStorage>('usePageTransitionClient');
  const loadingRef = storage.get('loading') ?? storage.saveAndGet('loading', ref(false));

  let timer: any = null;
  let stopTransitionWatcher: WatchStopHandle | null = null;
  let stopLoadingWatcher: WatchStopHandle | null = null;
  let pageHasBeenLoaded = false;

  function initTransition(classListRef: Ref<string[]>, loading: Ref<any>, loadingEntry = 'loading') {
    onMounted(() => {
      stopTransitionWatcher = watch(
        () => loading.value[loadingEntry],
        (isLoading) => {
          handleLoadingState(isLoading, classListRef);
        },
        { immediate: true },
      );
    });

    onBeforeUnmount(() => {
      stopTransitionWatcher && stopTransitionWatcher();
      stopLoadingWatcher && stopLoadingWatcher();
      toggleGlobalLoadingIndicator(false);
    });
  }

  function handleLoadingState(isLoading: boolean, classListRef: Ref<string[]>) {
    clearTimeout(timer);
    if (isLoading) {
      loadingRef.value = true;
      toggleGlobalLoadingIndicator(true);
      removeValuesFromArrayRef(classListRef, `${page}--${TRANSITION_ENTER_CLASS}`, TRANSITION_ENTER_CLASS);
      addValuesToArrayRef(classListRef, `${page}--${TRANSITION_LOADING_CLASS}`, TRANSITION_LOADING_CLASS);
      return;
    }
    addValuesToArrayRef(classListRef, `${page}--${TRANSITION_ENTER_CLASS}`, TRANSITION_ENTER_CLASS);
    toggleGlobalLoadingIndicator(false);

    if (!pageHasBeenLoaded) {
      pageHasBeenLoaded = true;
      setTimeout(() => {
        const event: CustomEvent = new CustomEvent('transition-complete');
        window.dispatchEvent(event);
      }, 10);
    }
    timer = setTimeout(() => {
      removeValuesFromArrayRef(
        classListRef,
        `${page}--${TRANSITION_ENTER_CLASS}`,
        TRANSITION_ENTER_CLASS,
        `${page}--${TRANSITION_LOADING_CLASS}`,
        TRANSITION_LOADING_CLASS,
      );
      loadingRef.value = false;
    }, constants.TRANSITION_TIME.ENTER);
  }

  function toggleGlobalLoadingIndicator(value: boolean): void {
    if (value) {
      document.body.classList.add(TRANSITION_BODY_LOADING_CLASS);
    } else {
      document.body.classList.remove(TRANSITION_BODY_LOADING_CLASS);
    }
  }

  function setLoadingState(loader: Ref<{ loading: boolean }>, ...loaders: Loader[]) {
    const throttleLoadingState: Function = throttle((loading: boolean) => {
      loader.value.loading = loading;
    }, 200);
    const watchers: Function[] = loaders.map((loadRef: Loader) => {
      if (typeof loadRef === 'function') {
        return loadRef;
      }
      return () => loadRef.value.loading;
    });
    stopLoadingWatcher = watch(watchers, (states) => {
      throttleLoadingState(states.some((isLoading) => isLoading));
    });
  }

  return securedWrap({
    initTransition,
    setLoadingState,
    loadingRef,
  });
}
