<script setup lang="ts">
import SVGRight from '@mop/shared/images/arrow/right.svg?component';
import { getCta } from '@mop/cms/utils/utils';
import type { CmsUiCta } from './uiCmsCta';

defineOptions({
  name: 'UiCmsCta',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  noPadding: {
    type: Boolean,
    default: false,
  },
  large: {
    type: Boolean,
    default: false,
  },
});

const data: any = props.data;
const element = data.component ? getCta(data) ?? ({} as CmsUiCta) : (props.data as CmsUiCta);

const typeArrowRight = 'arrowRight';
const typeBigArrowRight = 'bigArrowRight';
const types = [
  {
    name: 'link',
    class: 'ui-cms-cta__element--link',
  },
  {
    name: 'buttonPrimary',
    class: 'button button--primary',
  },
  {
    name: 'buttonSecondary',
    class: 'button button--secondary',
  },
  {
    name: typeArrowRight,
    class: 'ui-cms-cta__element--emphasize',
  },
  {
    name: typeBigArrowRight,
    class: 'ui-cms-cta__element--big-arrow-right',
  },
];

// Make sure "is" is set to not break the component
const link = element.link || { componentName: 'div' };
link.componentName = link.componentName || 'div';

const typeArrow = types.find((type) => type.name === typeArrowRight);
const type = types.find((type) => type.name === element.type) || typeArrow;

let isLarge: boolean = props.large;

if (type?.name === typeBigArrowRight) {
  isLarge = true;
}

const isBackLink = element.isBackLink ? 'click' : '';

function browseBack() {
  useRouter().back();
}

const showArrowRight: boolean = type !== undefined && [typeArrowRight, typeBigArrowRight].includes(type.name);
</script>

<template>
  <div
    :class="[
      'ui-cms-cta',
      element.align ? `ui-cms-cta--${element.align}` : '',
      {
        'ui-cms-padding': !noPadding,
        'ui-cms-cta--uppercase': element.isUppercase,
        'ui-cms-cta--large': large,
      },
    ]"
    :data-track-element-item="
      data.component
        ? element.text || $mopI18n.t(`components.cta.${element.textTranslationKey || 'discover_now'}`) || ''
        : undefined
    "
  >
    <UiNuxtLink
      v-storyblok-editable="element"
      v-bind="link"
      :class="['ui-cms-cta__element', type?.class]"
      :style="`--color: ${element.textColor}`"
      @[isBackLink]="browseBack"
    >
      <span class="ui-cms-cta__text">
        {{ element.text || $mopI18n.t(`components.cta.${element.textTranslationKey || 'discover_now'}`) }}
      </span>

      <SVGRight
        v-if="showArrowRight"
        class="ui-cms-cta__arrow"
        :width="isLarge ? 35 : 15"
        :height="isLarge ? 35 : 15"
      />
    </UiNuxtLink>
  </div>
</template>

<style lang="scss" scoped>
$verticalPadding: $space15;

.ui-cms-cta {
  display: flex;
  width: 100%;
}

.ui-cms-padding {
  padding: 0 $global-padding;
}

.ui-cms-cta--uppercase {
  text-transform: uppercase;
}

.ui-cms-cta__element {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: $verticalPadding;
  padding-top: $verticalPadding;
  cursor: pointer;

  &.button {
    justify-content: center;
    width: auto;
    padding: 9px $space50;

    @include apply-upto(small) {
      width: 100%;
    }
  }
}

.ui-cms-cta--center {
  justify-content: center;
}

.ui-cms-cta--right {
  justify-content: flex-end;
}

.ui-cms-cta__arrow {
  fill: currentColor;
  margin-left: $space2;
}

.ui-cms-cta__text {
  display: flex;
  align-items: center;
}

.ui-cms-cta--large {
  @include apply-upto(small) {
    margin-bottom: -$space15;
  }

  .ui-cms-cta__text {
    @include text-style(headline, true);
  }
}

.ui-cms-cta__element--link {
  @include link-underlined($verticalPadding);
}

.ui-cms-cta__element--emphasize {
  @include text-style(strong-small, true);

  margin: 0;
}

.ui-cms-cta__element--big-arrow-right {
  @include text-style(headline, true);

  .ui-cms-cta__text {
    padding-right: 5px;
  }
}
</style>
